export default class DeleteParagraphModuleRequest {
    customerId;
    templateId;
    paragraphId;
    constructor(customerId, templateId, paragraphid) {
        this.customerId = customerId;
        this.templateId = templateId;
        this.paragraphId = paragraphid;
    }
}
