export default class TosModuleRequest {
    customerId;
    templateId;
    tos;
    body;
    updateBody;
    paragraphId;
    constructor(customerId, templateId, tos, body, updateBody, paragraphId) {
        this.templateId = templateId;
        this.customerId = customerId;
        this.tos = tos;
        this.body = body;
        this.updateBody = updateBody;
        this.paragraphId = paragraphId;
    }
}
