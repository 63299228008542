import { __decorate } from "tslib";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let ImageElement = class ImageElement extends Vue {
    imageSrc;
    imagePath;
    getSelectedClass() {
        const index = [...chargeEditorModule.getSelectedImagePaths].findIndex(p => p === this.imagePath);
        return index === 0
            ? "mdi-numeric-1-circle-outline"
            : index === 1
                ? "mdi-numeric-2-circle-outline"
                : "mdi-check";
    }
    selectImage() {
        chargeEditorModule.selectImage(this.imagePath);
        chargeEditorModule.previewPdf();
    }
};
__decorate([
    Prop()
], ImageElement.prototype, "imageSrc", void 0);
__decorate([
    Prop()
], ImageElement.prototype, "imagePath", void 0);
ImageElement = __decorate([
    Component
], ImageElement);
export default ImageElement;
