import { __decorate } from "tslib";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
import ActionPanel from "@/components/charge/editor/ActionPanel.vue";
import ChargeForm from "@/components/charge/editor/ChargeForm.vue";
import Component from "vue-class-component";
import ImagePanel from "@/components/charge/receipt/ImagePanel.vue";
import ReceiptPreview from "@/components/charge/receipt/ReceiptPreview.vue";
import Spinner from "@/components/shared/Spinner.vue";
import Vue from "vue";
let ChargeEditor = class ChargeEditor extends Vue {
    fetchParkingError = false;
    fetchParkingLoading = true;
    items = [
        {
            text: "charge",
            disabled: false,
            to: "/charge",
            translate: (x) => x.tc(2),
        },
        { text: "attachImages", disabled: true, translate: (x) => x.t() },
    ];
    async mounted() {
        const parkingRightID = parseInt(this.$route.params.id);
        if (isNaN(parkingRightID)) {
            this.fetchParkingError = true;
            this.fetchParkingLoading = false;
            return;
        }
        const unpaidParking = await chargeEditorModule.fetchUnpaidParking(parkingRightID);
        if (!unpaidParking) {
            this.fetchParkingError = true;
            this.fetchParkingLoading = false;
            return;
        }
        this.fetchParkingLoading = false;
    }
    get getEditedParking() {
        return chargeEditorModule.getEditedItem;
    }
    get getBreadCrumpItems() {
        return this.items.map(i => ({ ...i, text: i.translate(i.text) }));
    }
    get pdfLoading() {
        return chargeEditorModule.loading;
    }
};
ChargeEditor = __decorate([
    Component({
        components: {
            ActionPanel,
            ChargeForm,
            ImagePanel,
            ReceiptPreview,
            Spinner,
        },
    })
], ChargeEditor);
export default ChargeEditor;
