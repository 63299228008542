import { __decorate } from "tslib";
import { CloseParkingRightRequest, datawashModule, } from "@/store/modules/datawashmodule";
import { Component, Watch } from "vue-property-decorator";
import { utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import { WashModalModel } from "@/util/wash/util";
import ActionPanel from "@/components/datawash/ActionPanel.vue";
import AddLicensePlateDialog from "@/components/datawash/AddLicensePlateDialog.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomCheckbox from "@/components/shared/CustomCheckbox.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import ExpansionPanel from "@/components/shared/ExpansionPanel.vue";
import i18n from "@/i18n";
import ImageWrapper from "@/components/datawash/ImageWrapper.vue";
import SetParkingExitDialog from "@/components/datawash/SetParkingExitDialog.vue";
import ListRequest from "@/util/storerequest/ListRequest";
import ParkingPreview from "@/components/datawash/ParkingPreview.vue";
import TreeSelect from "@/components/shared/TreeSelect.vue";
import CustomTreeSelect from "@/components/shared/CustomTreeSelect.vue";
import Vue from "vue";
import AreaTreeSelect from "@/components/treeSelects/AreaTreeSelect.vue";
import CustomTagsFilter from "@/components/shared/CustomTagsFilter.vue";
import moment from "moment";
import { StorageService } from "@/util/storage";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { prsTagModule } from "@/store/modules/prsTagModule";
import CustomDateSearch from "@/components/shared/CustomDateSearch.vue";
import { InputType } from "@/components/shared/enums";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import debounce from "lodash.debounce";
import { copyArea } from "@/util/area/areautil";
import { areaModule } from "@/store/modules/areaModule";
const licenseplateHeader = `${i18n.t("edit")} ${i18n.t("licenseplate")}`;
const dateHeader = `${i18n.t("edit")} ${i18n.t("date")}`;
let DataWashPage = class DataWashPage extends Vue {
    action = Action;
    inputType = InputType;
    licensePlateModel = new WashModalModel(false, "", "", null);
    resetCount = 1;
    actionId = -1;
    editPlate = false;
    modalTitle = "";
    editMergeDialogueVisible = false;
    showDeleteDialog = false;
    showConfirmDateDialog = false;
    showBatchDeleteDialog = false;
    batchDeleteProgress = null;
    clearDates = false;
    isUnpaidBatchParking = false;
    mounted() {
        const filterPresets = new StorageService(`${authenticationModule.getLoggedInCustomerName}-datawash-tags-presets`).getStore();
        if ((filterPresets?.length <= 0 || !filterPresets.find(preset => preset.default)) &&
            !datawashModule.getHasSetTags) {
            datawashModule.SET_PRS_TAGS_SELECTED({
                inclusionTags: this.getTagsByName([
                    "lowocrtotal",
                    "customplate",
                    "lowocrimagecount",
                    "ocrnotread",
                ]),
                exclusionTags: this.getTagsByName(["dnk-syntax"]),
                acceptanceTags: this.getTagsByName(["dmr-light"]),
            });
        }
        if (this.getFilterOptions.areasSelected.length <= 0 && this.getAreas.length === 1) {
            datawashModule.SET_AREAS_SELECTED([copyArea(this.getAreas[0])]);
        }
        this.fetchParkings();
    }
    onDialogChanged() {
        this.isUnpaidBatchParking = this.containsUnpaidParking();
    }
    // This is a hack, if the parking is a ParkingRight and stop is set to a value it is unpaid.
    // If a parkingright is payed it is deleted.
    isUnpaidParking() {
        return Boolean(this.getSelectedParking.entityType == "ParkingRight" && this.getSelectedParking.stop);
    }
    containsUnpaidParking() {
        return Boolean(this.batchSelection.find(p => p.entityType == "ParkingRight" && p.stop));
    }
    getTagsByName(names) {
        return this.getPrsTags.filter(tag => {
            return names.map(name => name.toLowerCase()).includes(tag.name?.toLowerCase() ?? "");
        });
    }
    onManuallyAcceptedChange() {
        this.fetchParkings();
    }
    onAreasChange(areasSelected) {
        if (areasSelected.length === 0)
            return;
        if (JSON.stringify(areasSelected) == JSON.stringify(this.getFilterOptions.areasSelected))
            return;
        datawashModule.SET_AREAS_SELECTED(areasSelected);
        this.fetchParkings();
    }
    onPrsTagsChange(list) {
        this.fetchParkings();
    }
    onPageUpdate(pageNumber) {
        this.tableOptions.pageNumber = pageNumber;
    }
    onOptionsUpdate(options) {
        if (!this.anyAreasSelected)
            return;
        const tableOptions = new ListRequest(options.page, options.itemsPerPage, undefined, options.sortBy[0] ?? undefined, options.sortDesc[0] ?? undefined);
        datawashModule.SET_TABLE_OPTIONS(tableOptions);
        this.fetchParkings();
    }
    onTagsSaved(preparedTags) {
        datawashModule.SET_TABLE_OPTIONS({ ...datawashModule.tableOptions, pageNumber: 1 });
        datawashModule.SET_PRS_TAGS_SELECTED(preparedTags);
        this.fetchParkings();
    }
    fetchParkings(ignoreTags) {
        if (!this.anyAreasSelected)
            return;
        datawashModule.fetchParkings(ignoreTags);
    }
    isSelectedRow(item) {
        return this.getSelectedParking.id === item.id;
    }
    onRowSelect(key, item) {
        datawashModule.fetchParkingImageUrls(item);
    }
    onShowBatchDeleteDialogClick() {
        this.showBatchDeleteDialog = true;
    }
    async onConfirmBatchDeleteClick() {
        this.batchDeleteProgress = 0;
        let count = 0;
        for (let index = 0; index < this.batchSelection.length; index++) {
            count++;
            const parking = this.batchSelection[index];
            if (parking.entityType === "ParkingRight") {
                try {
                    await datawashModule.deleteParking(parking);
                    const progress = Math.round((count / this.batchSelection.length) * 100);
                    this.batchDeleteProgress = progress;
                }
                catch (error) {
                    // console.warn("Parking failed!");
                }
            }
            else if (parking.entityType === "Orphan") {
                try {
                    await datawashModule.deleteOrphan(parking);
                    const progress = Math.round((count / this.batchSelection.length) * 100);
                    this.batchDeleteProgress = progress;
                }
                catch (error) {
                    // console.warn("Orphan failed!");
                }
            }
        }
        this.fetchParkings();
        this.batchDeleteProgress = null;
        this.showBatchDeleteDialog = false;
    }
    onDeleteClick() {
        if (this.getSelectedParking.entityType == "Orphan") {
            datawashModule.deleteOrphan(this.getSelectedParking).then(() => {
                this.fetchParkings();
                //datawashModule.INCREMENT_REFETCHCOUNT();
            });
        }
        else {
            datawashModule.deleteParking(this.getSelectedParking).then(() => {
                this.fetchParkings();
                //datawashModule.INCREMENT_REFETCHCOUNT();
            });
        }
        this.showDeleteDialog = false;
    }
    getFormattedDate(date) {
        return date
            ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY")
            : "";
    }
    updateImageModalVisible(visible) {
        datawashModule.SET_IMAGE_MODAL_STATE(visible);
    }
    setImageModalState(state) {
        datawashModule.SET_IMAGE_MODAL_STATE(state);
    }
    handleActionClicked(action) {
        this.actionId = action;
        if (action === 0) {
            this.handleAcceptParking();
        }
        if (action === 1) {
            this.editPlate = true;
            this.modalTitle = licenseplateHeader;
            //this.licensePlateModel.visible = true
            this.licensePlateModel.licensePlate = undefined;
            this.editMergeDialogueVisible = true;
            this.resetCount++;
        }
        if (action === 2) {
            this.editPlate = false;
            this.modalTitle = dateHeader;
            this.licensePlateModel.visible = true;
            const date = this.getSelectedParking.start || "";
            this.licensePlateModel.date = date;
        }
        if (action === 3) {
            //Delete
            this.showDeleteDialog = true;
        }
    }
    handleAcceptParking() {
        datawashModule.acceptParkingRight(this.getSelectedParking.id).then(() => {
            this.fetchParkings();
        });
    }
    handleCloseByDate() {
        const req = new CloseParkingRightRequest(this.getSelectedParking.id, this.licensePlateModel.departure);
        if (!this.showConfirmDateDialog &&
            moment(this.getSelectedParking.reconciledTo)
                .local(true)
                .isSameOrAfter(moment(this.licensePlateModel.departure).local(true))) {
            this.showConfirmDateDialog = true;
            return;
        }
        datawashModule.closeParkingRight(req).then(() => {
            this.licensePlateModel.visible = false;
            this.showConfirmDateDialog = false;
        });
    }
    handleEditLicensePlate(licensePlate) {
        this.fetchParkings();
    }
    handleSearchSubmit(data) {
        this.clearDates = false;
        datawashModule.SET_FROM_DATE(data.fromDate
            ? moment(data.fromDate)
                .utc(false)
                .format()
            : undefined);
        datawashModule.SET_TO_DATE(data.toDate
            ? moment(data.toDate)
                .utc(false)
                .format()
            : undefined);
        datawashModule.SET_TABLE_OPTIONS({ ...datawashModule.tableOptions, pageNumber: 1 });
        this.fetchParkings();
    }
    removeDatesFilter() {
        datawashModule.SET_FROM_DATE("");
        datawashModule.SET_TO_DATE("");
        this.clearDates = true;
        datawashModule.SET_TABLE_OPTIONS({ ...datawashModule.tableOptions, pageNumber: 1 });
        this.fetchParkings();
    }
    onLicensePlateChange(licensePlate) {
        datawashModule.SET_LICENSE_PLATE(licensePlate);
        datawashModule.SET_TABLE_OPTIONS({ ...datawashModule.tableOptions, pageNumber: 1 });
        this.fetchParkings((licensePlate ?? "").trim() !== "");
    }
    debounceLicensePlateChange = debounce(this.onLicensePlateChange, 500);
    get batchSelection() {
        return datawashModule.getBatchSelection;
    }
    set batchSelection(parkings) {
        datawashModule.SET_BATCH_SELECTION(parkings);
    }
    get isLoading() {
        return datawashModule.getIsLoading;
    }
    get isManuallyAccepted() {
        return datawashModule.getIsManuallyAccepted;
    }
    set isManuallyAccepted(isManuallyAccepted) {
        datawashModule.SET_MANUALLY_ACCEPTED(isManuallyAccepted);
    }
    get getFilterOptions() {
        return datawashModule.filterOptions;
    }
    get anyAreasSelected() {
        return this.getFilterOptions.areasSelected.length > 0;
    }
    get tableOptions() {
        return datawashModule.getTableOptions;
    }
    get getHeaders() {
        return [
            {
                text: "licenseplate"
                    .t()
                    .capitalize()
                    .toString(),
                value: "licenseplate",
                sortable: false,
            },
            {
                text: "parkingEntry".tc(1),
                value: "start",
            },
            {
                text: "parkingExit".tc(1),
                value: "stop",
            },
        ];
    }
    get isImageModalVisible() {
        return datawashModule.getImageModalVisible;
    }
    get isParkingClosed() {
        return datawashModule.getIsStopDateSet;
    }
    get getCurrentImages() {
        return datawashModule.getCurrentImages;
    }
    get getServerItemsCount() {
        return datawashModule.getServerItemsCount;
    }
    get getImages() {
        return datawashModule.getImages;
    }
    get getIsImagesLoading() {
        return datawashModule.imagesLoading;
    }
    get getSelectedLicensePlates() {
        if (this.getSelectedParking && this.getSelectedParking.vehicleIdents) {
            return this.getSelectedParking.vehicleIdents
                .slice()
                .reverse()
                .join(", ");
        }
        return "";
    }
    get getSelectedParking() {
        //MAQ - IMPORTANT: This was property being watched previously and was responsible for fetching and clearing images. This functionality must be verified, need test data!!!
        return datawashModule.getSelectedParking;
    }
    get getParkings() {
        return datawashModule.getParkings;
    }
    get getShowManuallyAcceptedToggle() {
        return datawashModule.showManuallyAcceptedToggle;
    }
    get getPrsTags() {
        return prsTagModule.getPrsTags;
    }
    get getAreas() {
        return areaModule.getAreas;
    }
};
__decorate([
    Watch("showBatchDeleteDialog")
], DataWashPage.prototype, "onDialogChanged", null);
DataWashPage = __decorate([
    Component({
        methods: { moment },
        components: {
            ActionPanel,
            AddLicensePlateDialog,
            CustomButton,
            CustomCheckbox,
            CustomDialog,
            CustomSelect,
            ExpansionPanel,
            ImageWrapper,
            SetParkingExitDialog,
            ParkingPreview,
            TreeSelect,
            CustomTreeSelect,
            AreaTreeSelect,
            CustomTagsFilter,
            CustomDateSearch,
            CustomTextField,
        },
    })
], DataWashPage);
export default DataWashPage;
