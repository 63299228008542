import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { prsTagModule } from "@/store/modules/prsTagModule";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import Vue from "vue";
let CustomSelectExamples = class CustomSelectExamples extends Vue {
    action = Action;
    selectSingleFormValid = true;
    select1 = null;
    select2 = [];
    select3 = [];
    selectMultipleFormValid = true;
    select5 = null;
    select6 = [];
    select7 = [];
    onSelect3Change(selected) {
        // console.log("onSelect3Change");
        // console.log(selected);
    }
    get selectItems2() {
        return prsTagModule.prsTags;
    }
    get selectItems() {
        return areaModule.getAreas;
    }
    get getMultipleSelectionText() {
        const localeId = this.$i18n.locale == "da" ? 0 : 1;
        return this.select6.length > 0
            ? this.select6[0].prsTagTranslations[localeId].translatedName
            : undefined;
    }
    getTranslation(item) {
        return item.prsTagTranslations?.find((x) => x.languageCode === (this.$i18n.locale == "da" ? 1 : 2))?.translatedName;
    }
    selectSingleFormValidate() {
        this.$refs.selectSingleForm.validate();
    }
    selectSingleFormReset() {
        this.$refs.selectSingleForm.reset();
    }
    selectSingleFormResetValidation() {
        this.$refs.selectSingleForm.resetValidation();
    }
    selectMultipleFormValidate() {
        this.$refs.selectMultipleForm.validate();
    }
    selectMultipleFormReset() {
        this.$refs.selectMultipleForm.reset();
    }
    selectMultipleFormResetValidation() {
        this.$refs.selectMultipleForm.resetValidation();
    }
};
CustomSelectExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomSelect,
        },
        methods: {
            required,
        },
    })
], CustomSelectExamples);
export default CustomSelectExamples;
