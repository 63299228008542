import { __decorate } from "tslib";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import Vue from "vue";
import { generateTree } from "../treeSelects/util";
import CustomTreeSelect from "@/components/shared/CustomTreeSelect.vue";
import { areaModule } from "@/store/modules/areaModule";
import { Watch } from "vue-property-decorator";
let CustomTreeSelectExamples = class CustomTreeSelectExamples extends Vue {
    action = Action;
    treeSelectFormValid = true;
    nodesArrays = [];
    nodes1 = [];
    nodes2 = [];
    nodes3 = [];
    nodes4 = [];
    nodesSelection1 = [];
    nodesSelection2 = [];
    nodesSelection3 = [];
    nodesSelection4 = [];
    setSelection1(selection) {
        this.nodesSelection1 = selection;
    }
    setSelection2(selection) {
        this.nodesSelection2 = selection;
    }
    setSelection3(selection) {
        this.nodesSelection3 = selection;
    }
    setSelection4(selection) {
        this.nodesSelection4 = selection;
    }
    get areaTreeNodes() {
        return areaModule.areaTreeNodes;
    }
    areaTreeNodesWatcher() {
        if (!this.areaTreeNodes || this.areaTreeNodes.length <= 0)
            return;
        this.nodes1 = this.createAreasTree();
        this.nodes2 = this.createAreasTree();
        this.nodes3 = this.createAreasTree();
        this.nodes4 = this.createDeepChildTree();
    }
    createAreasTree() {
        return generateTree(this.areaTreeNodes.map(area => {
            return {
                id: area.id,
                name: area.name,
                mappedObj: area,
                nodes: area.subAreas.map(subArea => {
                    return {
                        id: subArea.id,
                        name: subArea.name,
                        mappedObj: subArea,
                    };
                })
            };
        }));
    }
    createDeepChildTree() {
        return generateTree([
            {
                id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                name: "Node1",
                nodes: [
                    {
                        id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                        name: "SubNode1"
                    },
                    {
                        id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                        name: "SubNode2"
                    }
                ]
            },
            {
                id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                name: "Node2",
                nodes: [
                    {
                        id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                        name: "SubNode1",
                        nodes: [
                            {
                                id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                                name: "SubSubNode1",
                                nodes: [
                                    {
                                        id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                                        name: "SubSubSubNode1",
                                    }
                                ]
                            },
                            {
                                id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                                name: "SubSubNode2",
                            }
                        ]
                    },
                    {
                        id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
                        name: "SubNode2"
                    }
                ]
            }
        ]);
    }
    treeSelectFormValidate() {
        this.$refs.treeSelectForm.validate();
    }
    treeSelectFormReset() {
        this.nodes1 = this.createAreasTree();
        this.nodes2 = this.createAreasTree();
        this.nodes3 = this.createAreasTree();
        this.nodes4 = this.createDeepChildTree();
        this.nodesSelection1 = [];
        this.nodesSelection2 = [];
        this.nodesSelection3 = [];
        this.nodesSelection4 = [];
        this.$refs.treeSelectForm.reset();
    }
    treeSelectFormResetValidation() {
        this.$refs.treeSelectForm.resetValidation();
    }
};
__decorate([
    Watch("areaTreeNodes", { immediate: true, deep: true })
], CustomTreeSelectExamples.prototype, "areaTreeNodesWatcher", null);
CustomTreeSelectExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomSelect,
            CustomTreeSelect
        },
        methods: {
            required,
        },
    })
], CustomTreeSelectExamples);
export default CustomTreeSelectExamples;
