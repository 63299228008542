import { __decorate } from "tslib";
import { Criticality, notificationModule } from "@/store/modules/notificationModule";
import { areaModule } from "@/store/modules/areaModule";
import { chargeModule } from "@/store/modules/chargeModule";
import { Component } from "vue-property-decorator";
import { getLicensePlateString } from "@/util/licenseplateutil";
import { utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import ExpansionPanel from "@/components/shared/ExpansionPanel.vue";
import i18n from "@/i18n";
import ListRequest from "@/util/storerequest/ListRequest";
import moment from "moment";
import router from "@/router";
import TreeSelect from "@/components/shared/TreeSelect.vue";
import Vue from "vue";
import CustomTreeSelect from "@/components/shared/CustomTreeSelect.vue";
import AreaTreeSelect from "@/components/treeSelects/AreaTreeSelect.vue";
import CustomTagsFilter from "@/components/shared/CustomTagsFilter.vue";
import { copyArea } from "@/util/area/areautil";
import { StorageService } from "@/util/storage";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { prsTagModule } from "@/store/modules/prsTagModule";
import CustomSearch from "@/components/shared/CustomDateSearch.vue";
import debounce from "lodash.debounce";
import { InputType } from "@/components/shared/enums";
import CustomTextField from "@/components/shared/CustomTextField.vue";
let ChargePage = class ChargePage extends Vue {
    action = Action;
    inputType = InputType;
    toDelete = null;
    showDeleteDialog = false;
    showBatchDeleteDialog = false;
    batchDeleteProgress = null;
    clearDates = false;
    mounted() {
        const filterPresets = new StorageService(`${authenticationModule.getLoggedInCustomerName}-charge-tags-presets`).getStore();
        if ((filterPresets?.length <= 0 || !filterPresets.find(preset => preset.default)) &&
            !chargeModule.getHasSetTags) {
            chargeModule.SET_PRS_TAGS_SELECTED({
                inclusionTags: this.getTagsByName(["nmr-light", "dmr-light"]),
                exclusionTags: this.getTagsByName([
                    "lowocrtotal",
                    "dnk-syntax",
                    "customplate",
                    "lowocrimagecount",
                    "ocrnotread",
                ]),
                acceptanceTags: [],
            });
        }
        if (this.getFilterOptions.areasSelected.length <= 0 && this.getAreas.length === 1) {
            chargeModule.SET_AREAS_SELECTED([copyArea(this.getAreas[0])]);
        }
        this.fetchUnpaidParkings();
    }
    getTagsByName(names) {
        return this.getPrsTags.filter(tag => {
            return names.map(name => name.toLowerCase()).includes(tag.name?.toLowerCase() ?? "");
        });
    }
    onAreasChange(areasSelected) {
        if (areasSelected.length === 0)
            return chargeModule.SET_AREAS_SELECTED(areasSelected);
        if (JSON.stringify(areasSelected) == JSON.stringify(this.getFilterOptions.areasSelected))
            return;
        chargeModule.SET_AREAS_SELECTED(areasSelected);
        this.fetchUnpaidParkings();
    }
    onOptionsUpdate(options) {
        if (!this.anyAreasSelected)
            return;
        const tableOptions = new ListRequest(options.page, options.itemsPerPage, undefined, options.sortBy[0] ?? undefined, options.sortDesc[0] ?? undefined);
        chargeModule.SET_TABLE_OPTIONS(tableOptions);
        this.fetchUnpaidParkings();
    }
    onPageUpdate(pageNumber) {
        this.tableOptions.pageNumber = pageNumber;
        this.fetchUnpaidParkings();
    }
    onItemsPerPageUpdate(itemsPerPage) {
        this.tableOptions.pageSize = itemsPerPage;
        this.fetchUnpaidParkings();
    }
    onTagsSaved(preparedTags) {
        chargeModule.SET_TABLE_OPTIONS({ ...chargeModule.tableOptions, pageNumber: 1 });
        chargeModule.SET_PRS_TAGS_SELECTED(preparedTags);
        this.fetchUnpaidParkings();
    }
    fetchUnpaidParkings(ignoreTags) {
        if (!this.anyAreasSelected)
            return;
        chargeModule.fetchUnpaidParkings(ignoreTags);
    }
    getLicensePlate(vehicleIdents) {
        return getLicensePlateString(vehicleIdents);
    }
    getFormattedDate(date) {
        return date
            ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY")
            : "";
    }
    getUnpaidTime(reconciledTo, stop) {
        // MAQ - Heads-up! We remove any seconds and miliseconds to avoid issues with rounding
        if (moment(stop).isBefore(moment(reconciledTo)))
            return `${0} ${i18n.t("time_minutesShort")}`;
        const reconciledToFormatted = moment(reconciledTo)
            .seconds(0)
            .milliseconds(0);
        const stopFormatted = moment(stop)
            .seconds(0)
            .milliseconds(0);
        var milliseconds = moment(stopFormatted).diff(moment(reconciledToFormatted));
        var duration = moment.duration(milliseconds);
        var time = Math.floor(duration.asHours()) + moment.utc(milliseconds).format(":mm");
        let [hours, minutes] = time.split(":");
        minutes = !isNaN(parseInt(minutes)) && parseInt(minutes) < 10 ? minutes[1] : minutes;
        if (!isNaN(parseInt(hours)) && parseInt(hours) <= 0)
            return `${minutes} ${i18n.t("time_minutesShort")}`;
        return `${hours} ${i18n.t("time_hoursShort")} ${minutes} ${i18n.t("time_minutesShort")}`;
    }
    promptDeleteCharge(event, item) {
        event.stopPropagation();
        this.toDelete = item;
        this.showDeleteDialog = true;
    }
    promptDeleteChargeAction(confirmDelete) {
        if (confirmDelete && this.toDelete) {
            this.deleteCharge(this.toDelete);
        }
        this.showDeleteDialog = false;
        this.toDelete = null;
    }
    deleteCharge(item) {
        if (!item.costumerID || !item.id) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
            return;
        }
        chargeModule
            .deleteUnpaidParking({
            costumerID: item.costumerID,
            parkingRightID: item.id,
        })
            .then(() => {
            chargeModule.fetchUnpaidParkings();
        });
    }
    goToUnpaidParkingDetails(item) {
        router.push(`/editcharge/${item.id}`);
    }
    onShowBatchDeleteDialogClick() {
        this.showBatchDeleteDialog = true;
    }
    removeDatesFilter() {
        chargeModule.SET_FROM_DATE("");
        chargeModule.SET_TO_DATE("");
        this.clearDates = true;
        chargeModule.SET_TABLE_OPTIONS({ ...chargeModule.tableOptions, pageNumber: 1 });
        this.fetchUnpaidParkings();
    }
    onLicensePlateChange(licensePlate) {
        chargeModule.SET_LICENSE_PLATE(licensePlate);
        chargeModule.SET_TABLE_OPTIONS({ ...chargeModule.tableOptions, pageNumber: 1 });
        this.fetchUnpaidParkings((licensePlate ?? "").trim() !== "");
    }
    debounceLicensePlateChange = debounce(this.onLicensePlateChange, 500);
    onFromDateChange(fromDate) {
        chargeModule.SET_FROM_DATE(moment(fromDate)
            .utc(false)
            .format());
    }
    onToDateChange(toDate) {
        chargeModule.SET_TO_DATE(moment(toDate)
            .utc(false)
            .format());
    }
    async onConfirmBatchDeleteClick() {
        this.batchDeleteProgress = 0;
        let count = 0;
        for (let index = 0; index < this.batchSelection.length; index++) {
            count++;
            const parking = this.batchSelection[index];
            try {
                await chargeModule.deleteUnpaidParking({
                    costumerID: parking.costumerID,
                    parkingRightID: parking.id,
                });
                const progress = Math.round((count / this.batchSelection.length) * 100);
                this.batchDeleteProgress = progress;
            }
            catch (error) {
                // console.warn("Parking failed!");
            }
        }
        chargeModule.fetchUnpaidParkings();
        this.batchDeleteProgress = null;
        this.showBatchDeleteDialog = false;
    }
    handleSearchSubmit(data) {
        this.clearDates = false;
        chargeModule.SET_FROM_DATE(data.fromDate
            ? moment(data.fromDate)
                .utc(false)
                .format()
            : undefined);
        chargeModule.SET_TO_DATE(data.toDate
            ? moment(data.toDate)
                .utc(false)
                .format()
            : undefined);
        chargeModule.SET_TABLE_OPTIONS({ ...chargeModule.tableOptions, pageNumber: 1 });
        this.fetchUnpaidParkings();
    }
    get anyAreasSelected() {
        return this.getFilterOptions.areasSelected.length > 0;
    }
    get batchSelection() {
        return chargeModule.getBatchSelection;
    }
    set batchSelection(parkings) {
        chargeModule.SET_BATCH_SELECTION(parkings);
    }
    get isLoading() {
        return chargeModule.getIsLoading;
    }
    get getAreas() {
        return areaModule.getAreas;
    }
    get getPrsTags() {
        return prsTagModule.getPrsTags;
    }
    get getHeaders() {
        if (this.getLoggedInCustomer.hasParkPay) {
            return [
                { text: "numberplate".t(), value: "licenseplate", sortable: false },
                { text: "parkingStart".t(), value: "start" },
                { text: "parkingReconciledTo".t(), value: "Reconciled", sortable: false },
                { text: "parkingEnd".t(), value: "stop" },
                { text: "unpaidTime".t(), value: "unpaidTime", sortable: false },
                { text: "ParkPay", value: "existsInParkPay", sortable: false },
                { text: "actions".t(), value: "action", sortable: false },
            ];
        }
        return [
            { text: "numberplate".t(), value: "licenseplate", sortable: false },
            { text: "parkingStart".t(), value: "start" },
            { text: "parkingReconciledTo".t(), value: "Reconciled", sortable: false },
            { text: "parkingEnd".t(), value: "stop" },
            { text: "unpaidTime".t(), value: "unpaidTime", sortable: false },
            { text: "actions".t(), value: "action", sortable: false },
        ];
    }
    get getFilterOptions() {
        return chargeModule.filterOptions;
    }
    get tableOptions() {
        return chargeModule.getTableOptions;
    }
    get getServerItemsCount() {
        return chargeModule.getServerItemsCount;
    }
    get getUnpaidParkings() {
        return chargeModule.getUnpaidParkings;
    }
    get getIsAdminOrSuperAdmin() {
        return authenticationModule.isAdmin || authenticationModule.isSuperAdmin;
    }
    get getLoggedInCustomer() {
        return authenticationModule.loggedInCustomer;
    }
};
ChargePage = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
            CustomSelect,
            ExpansionPanel,
            TreeSelect,
            CustomTreeSelect,
            AreaTreeSelect,
            CustomTagsFilter,
            CustomSearch,
            CustomTextField,
        },
        methods: { moment },
    })
], ChargePage);
export default ChargePage;
