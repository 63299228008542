import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";
let CustomDialog = class CustomDialog extends Vue {
    syncShow;
    title;
    persistent;
    maxWidth;
    hideActions;
};
__decorate([
    PropSync("show", { required: true, type: Boolean })
], CustomDialog.prototype, "syncShow", void 0);
__decorate([
    Prop({ type: String })
], CustomDialog.prototype, "title", void 0);
__decorate([
    Prop({ required: true, type: Boolean })
], CustomDialog.prototype, "persistent", void 0);
__decorate([
    Prop({ required: false, default: "600px", type: String })
], CustomDialog.prototype, "maxWidth", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], CustomDialog.prototype, "hideActions", void 0);
CustomDialog = __decorate([
    Component
], CustomDialog);
export default CustomDialog;
