import { __decorate } from "tslib";
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import CustomButton, { Action } from "./CustomButton.vue";
import CustomDialog from "./CustomDialog.vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import CustomTextField from "./CustomTextField.vue";
import moment from "moment";
let CustomDateSearch = class CustomDateSearch extends Vue {
    filterOptions;
    clearDates;
    action = Action;
    maxDay = moment().format();
    showSearchModal = false;
    fromDate = "";
    toDate = "";
    fromDateError = "";
    toDateError = "";
    clearDatesWatcher(shouldClear) {
        if (shouldClear) {
            this.fromDate = undefined;
            this.toDate = undefined;
            this.maxDay = moment().format();
        }
    }
    mounted() {
        this.toDate = this.filterOptions.toDate;
        this.fromDate = this.filterOptions.fromDate;
    }
    formatDateToLocal(date) {
        return date
            ? moment(date)
                .local(true)
                .format()
            : undefined;
    }
    getMaxDay() {
        return this.toDate ?? this.maxDay;
    }
    onFromDateChange(fromDate) {
        this.fromDateError = undefined;
        this.fromDate = fromDate;
    }
    onToDateChange(toDate) {
        this.toDateError = undefined;
        this.toDate = toDate;
    }
    onSubmit() {
        if (this.fromDate && !this.toDate) {
            return (this.toDateError = "search_dates_error".t());
        }
        if (this.toDate && !this.fromDate) {
            return (this.fromDateError = "search_dates_error".t());
        }
        this.fromDateError = undefined;
        this.toDateError = undefined;
        this.$emit("on-submit", {
            fromDate: this.fromDate,
            toDate: this.toDate,
        });
        this.showSearchModal = false;
    }
};
__decorate([
    Prop()
], CustomDateSearch.prototype, "filterOptions", void 0);
__decorate([
    Prop()
], CustomDateSearch.prototype, "clearDates", void 0);
__decorate([
    Watch("clearDates")
], CustomDateSearch.prototype, "clearDatesWatcher", null);
CustomDateSearch = __decorate([
    Component({
        name: "CustomDateSearch",
        components: {
            CustomButton,
            CustomDialog,
            CustomTextField,
            CustomDatePicker,
        },
        methods: {},
    })
], CustomDateSearch);
export default CustomDateSearch;
