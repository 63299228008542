import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);
Vue.config.productionTip = false;
import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);
import vuetify from './plugins/vuetify';
import i18n from './i18n.js';
String.prototype.capitalize = function () {
    return this.substring(0, 1).toUpperCase() + this.substring(1).toLowerCase();
};
String.prototype.possessive = function () {
    var locale = i18n.locale.split('-')[0];
    if (locale == 'da') {
        return ((this.endsWith('s') || this.endsWith('x') || this.endsWith('z')) ? this + "'" : this + "s").toString();
    }
    if (locale == 'en') {
        return ((this.endsWith('s') || this.endsWith('x') || this.endsWith('z')) ? this + "'" : this + "'s").toString();
    }
    return this;
};
String.prototype.t = function (values) {
    return i18n.t(this.toString(), values).toString();
};
String.prototype.tc = function (choice, values) {
    return i18n.tc(this.toString(), choice, values).toString();
};
Vue.mixin({
    methods: {}
});
new Vue({
    router,
    store,
    vuetify: vuetify,
    i18n,
    components: { ZoomOnHover },
    render: h => h(App)
}).$mount('#app');
