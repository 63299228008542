import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { featureModule } from "@/store/modules/featureModule";
import { InputType } from "../shared/enums";
import { required } from "../shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let Details = class Details extends Vue {
    type = InputType;
    action = Action;
    areaDetailsFormValid = true;
    mounted() {
        areaModule.fetchArea(parseInt(this.$route.params.id));
    }
    sortSubAreas(subAreas) {
        return subAreas
            ?.slice()
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    onBackClick() {
        this.$router.push(`/areas`);
    }
    onDetailsSaveClick() {
        if (!this.areaDetailsFormValid) {
            this.$refs.areaDetailsForm.validate();
            return;
        }
        areaModule.updateArea(this.area.defaultChargeAmount);
    }
    get area() {
        return areaModule.getArea;
    }
    get isChargeModuleFeatureEnabled() {
        return featureModule.getIsChargeModuleFeatureEnabled;
    }
};
Details = __decorate([
    Component({
        components: {
            CustomButton,
            CustomTextField,
        },
        methods: {
            required,
        },
    })
], Details);
export default Details;
