import { __decorate } from "tslib";
import QrPlaceholder from '@/util/qrplaceholder';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
let EmailTemplateView = class EmailTemplateView extends Vue {
    emailTemplate;
    get getEmailTemplateName() {
        if (this.emailTemplate) {
            return this.emailTemplate.emailTemplateName || '';
        }
        return '';
    }
    get getEmailTemplateSubject() {
        if (this.emailTemplate) {
            return this.emailTemplate.subject || '';
        }
        return '';
    }
    get getSignatureLogo() {
        return this.emailTemplate.signatureLogo || '';
    }
    get getSections() {
        if (this.emailTemplate) {
            return this.emailTemplate.paragraphs || new Array();
        }
        return new Array();
    }
    formatBodyText(body) {
        const imgTag = `<img src="${QrPlaceholder}">`;
        return body.replaceAll("{QRCode}", imgTag);
    }
};
__decorate([
    Prop()
], EmailTemplateView.prototype, "emailTemplate", void 0);
EmailTemplateView = __decorate([
    Component({})
], EmailTemplateView);
export default EmailTemplateView;
