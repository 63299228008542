import { __decorate } from "tslib";
import "moment/locale/da";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { StatisticsRequest, StatisticsResponse, StatisticsSummaryResponse, StatisticsGraphConfiguration, } from "@/apiclient/client";
import { authenticationModule } from "./authenticationModule";
import { downloadFileFromServer } from "@/util/csvutil";
import { notificationModule, Criticality } from "./notificationModule";
import moment from "moment";
import store from "@/store/index";
import { StorageService } from "@/util/storage";
export class StatisticsRequest2 {
    areas;
    intervalBegin;
    intervalEnd;
    parkingTypes;
    parkingSystems;
    constructor(areas, intervalBegin, intervalEnd, parkingTypes, parkingSystems) {
        this.areas = areas;
        this.intervalBegin = intervalBegin;
        this.intervalEnd = intervalEnd;
        this.parkingTypes = parkingTypes;
        this.parkingSystems = parkingSystems;
    }
}
export class SummaryStatistics {
    averageParkingTime;
    totalParkingTime;
    totalParkings;
}
export class DailyStatistics {
    occupancy;
    entries;
    exits;
}
export const getStatisticsDate = () => {
    return moment()
        .endOf("day")
        .subtract(1, "day");
};
let StatisticsModule = class StatisticsModule extends VuexModule {
    isLoading = false;
    areasSelected = [];
    fromDate = getStatisticsDate()
        .startOf("month")
        .utc(false)
        .format();
    toDate = getStatisticsDate()
        .utc(false)
        .format();
    parkingTypesSelected = [];
    parkingSystemsSelected = [];
    parkingTicksFrom = [];
    parkingTicksTo = [];
    parkingTicksBetweenDates = {
        from: [],
        to: [],
    };
    intervalBegin;
    intervalEnd;
    parkingDurationStorageService = new StorageService("parkingDurationSettings");
    parkingDurationSettings = {
        binSize: this.parkingDurationStorageService.getStore()[0]?.binSize ?? 15,
        bins: this.parkingDurationStorageService.getStore()[0]?.bins ?? 48 * 2,
        binsSkip: this.parkingDurationStorageService.getStore()[0]?.binsSkip ?? 0,
    };
    allowedToExportPDF = ["qpark", "jeudan"];
    statistics = new StatisticsResponse({
        statisticsSummary: new StatisticsSummaryResponse({
            totalNumberOfParkings: 0,
            averageParkingTimeInMinutes: 0,
            totalParkingTimeInHours: 0,
        }),
    });
    async getGraphData(payload) {
        this.context.commit("SET_LOADING", true);
        const parkingTypes = payload.parkingTypes.map(x => x.name);
        const parkingSystems = payload.parkingSystems.map(x => x.name);
        const body = new StatisticsRequest({
            areas: payload.areas,
            from: payload.intervalBegin,
            to: payload.intervalEnd,
            parkingRightType: parkingTypes,
            parkingRightSource: parkingSystems,
            durationGraphConfiguration: new StatisticsGraphConfiguration({
                minutesPerInterval: 60,
                numberOfIntervals: 24,
                skipIntervals: 0,
            }),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        apiClient
            .chartData(authenticationModule.getCustomerId, body)
            .then(response => {
            this.context.commit("SET_STATISTICS", response.data);
        })
            .catch(e => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_LOADING", false);
        });
    }
    async getGraphDataTest(payload) {
        this.context.commit("SET_LOADING", true);
        apiClient
            .chartDataTest(payload.searchStart, payload.searchEnd, payload.minutesPerInterval, payload.numberOfIntervals, payload.skipIntervals, payload.from, payload.to, payload.timeZone)
            .then(response => {
            this.context.commit("SET_STATISTICS", response.data);
        })
            .catch(e => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_LOADING", false);
        });
    }
    async exportToCsv() {
        this.context.commit("SET_LOADING", true);
        try {
            const parkingTypes = this.parkingTypesSelected.map(x => x.name);
            const parkingSystems = this.parkingSystemsSelected.map(x => x.name);
            const result = await apiClient.export(authenticationModule.getCustomerId, this.getFromDate, this.getToDate, parkingTypes, parkingSystems, this.getAreasSelected);
            const fileName = `${moment(this.getFromDate).format("DD-MM-YYYY")}-${moment(this.getToDate).format("DD-MM-YYYY")}.csv`; // MAQ: In the future we want to get fileName from server
            downloadFileFromServer(result.data, fileName);
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
        this.context.commit("SET_LOADING", false);
    }
    SET_LOADING(isLoading) {
        this.isLoading = isLoading;
    }
    SET_AREAS_SELECTED(areasSelected) {
        this.areasSelected = areasSelected;
    }
    SET_FROM_DATE(fromDate) {
        this.fromDate = fromDate;
    }
    SET_TO_DATE(toDate) {
        this.toDate = toDate;
    }
    SET_PARKING_TYPES_SELECTED(items) {
        this.parkingTypesSelected = items;
    }
    SET_PARKING_SYSTEMS_SELECTED(items) {
        this.parkingSystemsSelected = items;
    }
    SET_PARKINGDURATION_SETTINGS(settings) {
        this.parkingDurationStorageService.clearStore();
        this.parkingDurationStorageService.addItem({ ...settings });
        this.parkingDurationSettings = settings;
    }
    RESET_STATE() {
        this.areasSelected = [];
        this.fromDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        this.toDate = moment().format("YYYY-MM-DD");
        this.parkingTicksFrom = [];
        this.parkingTicksTo = [];
        this.parkingTicksBetweenDates = {
            from: [],
            to: [],
        };
        this.intervalBegin = "";
        this.intervalEnd = "";
        this.parkingDurationSettings = {
            binSize: 15,
            bins: 48 * 2,
            binsSkip: 0,
        };
        this.statistics = new StatisticsResponse({
            statisticsSummary: new StatisticsSummaryResponse({
                totalNumberOfParkings: 0,
                averageParkingTimeInMinutes: 0,
                totalParkingTimeInHours: 0,
            }),
        });
    }
    SET_STATISTICS(statistics) {
        this.statistics = statistics;
    }
    get getAllowedToExportPDF() {
        return this.allowedToExportPDF.includes(authenticationModule.getLoggedInCustomerName.toLocaleLowerCase());
    }
    get getAreasSelected() {
        return this.areasSelected;
    }
    get getFromDate() {
        return this.fromDate;
    }
    get getToDate() {
        return this.toDate;
    }
    get getParkingTypesSelected() {
        return this.parkingTypesSelected;
    }
    get getParkingSystemsSelected() {
        return this.parkingSystemsSelected;
    }
    get getStatistics() {
        return this.statistics;
    }
};
__decorate([
    Action({ rawError: true })
], StatisticsModule.prototype, "getGraphData", null);
__decorate([
    Action({ rawError: true })
], StatisticsModule.prototype, "getGraphDataTest", null);
__decorate([
    Action({ rawError: true })
], StatisticsModule.prototype, "exportToCsv", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_LOADING", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_AREAS_SELECTED", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_FROM_DATE", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_TO_DATE", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_PARKING_TYPES_SELECTED", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_PARKING_SYSTEMS_SELECTED", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_PARKINGDURATION_SETTINGS", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "RESET_STATE", null);
__decorate([
    Mutation
], StatisticsModule.prototype, "SET_STATISTICS", null);
StatisticsModule = __decorate([
    Module({ dynamic: true, store, name: "statisticsModule", namespaced: true })
], StatisticsModule);
export default StatisticsModule;
export const statisticsModule = getModule(StatisticsModule);
