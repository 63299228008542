export default class CreateParagrahModuleRequest {
    customerId;
    templateId;
    body;
    constructor(customerId, templateId, body) {
        this.customerId = customerId;
        this.templateId = templateId;
        this.body = body;
    }
}
