import { __decorate } from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Criticality, notificationModule } from "@/store/modules/notificationModule";
import { userModule } from "@/store/modules/userModule";
import { UserResponse } from "@/apiclient/client";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import ModeLoginCard from "@/components/modelogins/modeLoginCard.vue";
let ModeLoginsRow = class ModeLoginsRow extends Vue {
    dialogDelete = false;
    modeLogins;
    userDelete = new UserResponse();
    deleteLogin() {
        userModule
            .deleteUser(this.userDelete.id)
            .then(() => {
            this.$emit("refetch-login-list");
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
        this.dialogDelete = false;
    }
};
__decorate([
    Prop()
], ModeLoginsRow.prototype, "modeLogins", void 0);
ModeLoginsRow = __decorate([
    Component({
        components: { ModeLoginCard, ConfirmPrompt },
    })
], ModeLoginsRow);
export default ModeLoginsRow;
