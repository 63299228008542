import { __decorate } from "tslib";
import { Criticality, notificationModule } from "@/store/modules/notificationModule";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component } from "vue-property-decorator";
import { customerModule } from "@/store/modules/customerModule";
import CustomerCard from "@/components/customers/CustomerCard.vue";
import Vue from "vue";
import { appModule } from "@/store/modules/appModule";
let CustomersView = class CustomersView extends Vue {
    get getCustomers() {
        return customerModule.getCustomers;
    }
    mounted() {
        customerModule.fetchCustomers();
    }
    impersonateCustomer(customer) {
        customerModule
            .impersonateCustomer(customer.costumerID || 0)
            .then(response => {
            authenticationModule.SET_IMPERSONATION_STATE(response.data, customer.costumerName);
            appModule.fetchImpersonationModules();
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_login".t() + customer.costumerName,
                criticality: Criticality.Success,
            });
        })
            .finally(() => {
            authenticationModule.RESET_ALL_STATE();
        });
    }
};
CustomersView = __decorate([
    Component({
        components: { CustomerCard },
        methods: {},
    })
], CustomersView);
export default CustomersView;
