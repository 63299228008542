import { __decorate } from "tslib";
import { Prop, PropSync } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
let CustomTextArea = class CustomTextArea extends Vue {
    syncText;
    label;
    disabled;
    rows;
    rules;
    autoGrow;
    height;
    onBlur(e) {
        this.$emit("on-textarea-blur", e);
    }
    onInput(e) {
        this.$emit("on-textarea-input", e);
    }
    get getLabel() {
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
};
__decorate([
    PropSync("text", { required: true })
], CustomTextArea.prototype, "syncText", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomTextArea.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomTextArea.prototype, "disabled", void 0);
__decorate([
    Prop({ required: false, type: Number, default: 5 })
], CustomTextArea.prototype, "rows", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomTextArea.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean, default: true })
], CustomTextArea.prototype, "autoGrow", void 0);
__decorate([
    Prop({ required: false, type: String, default: undefined })
], CustomTextArea.prototype, "height", void 0);
CustomTextArea = __decorate([
    Component
], CustomTextArea);
export default CustomTextArea;
