import { __decorate } from "tslib";
import { authenticationModule } from '@/store/modules/authenticationModule';
import { tosModule } from '@/store/modules/tosModule';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
let TermsOfService = class TermsOfService extends Vue {
    get getHeaderLogo() {
        return tosModule.getHeaderLogoBase64;
    }
    get getFooterLogo() {
        return tosModule.getFooterLogoBase64;
    }
    get getEndSection() {
        return tosModule.getEndSection;
    }
    get getMainHeader() {
        return tosModule.getMainHeader;
    }
    get getSections() {
        return tosModule.getSections;
    }
    mounted() {
        tosModule.fetchTosTemplate(authenticationModule.getCustomerId);
    }
};
TermsOfService = __decorate([
    Component({})
], TermsOfService);
export default TermsOfService;
