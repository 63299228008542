import { __decorate } from "tslib";
import { EmailConfigurationRequest } from '@/apiclient/client';
import DefaultEmailTemplateToggle from '@/components/settings/DefaultEmailTemplateToggle.vue';
import { authenticationModule } from '@/store/modules/authenticationModule';
import { settingsModule } from '@/store/modules/settingsModule';
import { emailTemplateModule } from '@/store/modules/emailtemplateModule';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
let EmailConfigurationCard = class EmailConfigurationCard extends Vue {
    panel = [0];
    items = 1;
    get emailSettings() {
        return settingsModule.getEmailConfigurations;
    }
    get getEmailStates() {
        return settingsModule.getEmailStates;
    }
    async updateConfiguration(option, setting) {
        const updateSetting = this.getUpdateRequest(setting);
        updateSetting.emailSendingStateID = option.stateId;
        await settingsModule.updateEmailConfiguration(updateSetting);
        settingsModule.fetchConfigurations(authenticationModule.getCustomerId);
    }
    async updateDefaultTemplate(config, templateId) {
        const updateConfig = this.getUpdateRequest(config);
        updateConfig.defaultEmailTemplateID = templateId;
        config.emailSendingStateID++;
        updateConfig.emailSendingStateID = config.emailSendingStateID;
        await settingsModule.updateEmailConfiguration(updateConfig);
        settingsModule.fetchConfigurations(authenticationModule.getCustomerId);
    }
    getUpdateRequest(setting) {
        const updateSetting = new EmailConfigurationRequest();
        updateSetting.defaultEmailTemplateID = setting.defaultTemplate?.id;
        updateSetting.emailTemplateTypeID = setting.emailTemplateTypeID;
        return updateSetting;
    }
    getSettingNameTranslation(setting) {
        if (setting === 'QrReSend')
            return 'emailTemplateTypeResendQr'.t();
        if (setting === 'Edit')
            return 'emailTemplateTypeEdit'.t();
        if (setting === 'Welcome')
            return 'emailTemplateTypeWelcome'.t();
    }
    mounted() {
        settingsModule.fetchConfigurations(authenticationModule.getCustomerId);
        emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId);
    }
};
EmailConfigurationCard = __decorate([
    Component({
        components: { DefaultEmailTemplateToggle },
    })
], EmailConfigurationCard);
export default EmailConfigurationCard;
