import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component, Prop, Watch } from "vue-property-decorator";
import { constantsModule } from "@/store/modules/constantsModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import CardButtonActions from "@/components/shared/CardButtonActions.vue";
import ModeForm from "@/components/forms/ModeForm.vue";
import UserForm1 from "@/components/forms/UserForm1.vue";
import Vue from "vue";
let ModeLoginEditor = class ModeLoginEditor extends Vue {
    user;
    isEditing;
    modeConfig;
    formResetCount;
    tabs = [
        { icon: "mdi-account", title: "Login" },
        { icon: "mdi-wrench", title: "Mode config" },
    ];
    tab = 0;
    userFormValid = false;
    modeFormValid = false;
    modeId = null;
    resetCount = 0;
    mounted() {
        areaModule.fetchAreas();
        constantsModule.fetchModes();
        emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId);
        subscriptionModule.fetchSubscriptionTypes();
    }
    onModeConfigChanged() { }
    get getConfirmBtnText() {
        let updatetext = "";
        if (this.isUserFormInFocus) {
            updatetext = this.isEditing
                ? this.$t("update").toString()
                : this.$t("create", { entity: "" }).toString();
        }
        else {
            updatetext =
                this.modeConfig.modeConfigurationID != null
                    ? this.$t("update").toString()
                    : this.$t("create", { entity: "" }).toString();
        }
        return `${updatetext} ${this.isUserFormInFocus ? "bruger" : "Modeconfig"}`;
    }
    handleFormSubmit() {
        if (this.isUserFormInFocus) {
            this.$emit("userform-confirmed");
        }
        else {
            this.$emit("modeform-confirmed");
        }
    }
    get isUserFormInFocus() {
        return this.tab === 0;
    }
    get isEditingUser() {
        return this.isEditing;
    }
    get showModeForm() {
        this.resetCount++;
        return this.isEditingUser;
    }
    get isOkButtonEnabled() {
        return ((this.tab === 0 && this.userFormValid) ||
            (this.tab === 1 && this.modeFormValid));
    }
    get tabItems() {
        return this.showModeForm
            ? this.tabs
            : this.tabs.filter((t) => t.title !== "Mode config");
    }
    get showDeletegateTab() {
        const isDelegate = this.user.modeID === 3;
        return isDelegate || this.isDelegateSelected;
    }
    get getAreas() {
        return areaModule.getAreas;
    }
    get isDelegateSelected() {
        const modeId = this.modeConfig.modeConfigurationID;
        //Mode config not visible for delegate
        return modeId !== undefined && modeId !== null && modeId !== 3;
    }
    get getSubscriptionTypes() {
        return subscriptionModule.getSubscriptionTypes;
    }
    get getWelcomeEmailTemplates() {
        return emailTemplateModule.getEmailTemplates;
    }
    get appMode() {
        const mode = constantsModule.getModes.find((m) => m.modeID === this.user.modeID);
        return mode ? mode.modeID : 0;
    }
    set appMode(mode) {
        if (mode)
            this.modeConfig.modeConfigurationID = mode;
    }
    get getAppModes() {
        return constantsModule.getModes;
    }
};
__decorate([
    Prop()
], ModeLoginEditor.prototype, "user", void 0);
__decorate([
    Prop()
], ModeLoginEditor.prototype, "isEditing", void 0);
__decorate([
    Prop()
], ModeLoginEditor.prototype, "modeConfig", void 0);
__decorate([
    Prop()
], ModeLoginEditor.prototype, "formResetCount", void 0);
__decorate([
    Watch("modeConfig")
], ModeLoginEditor.prototype, "onModeConfigChanged", null);
ModeLoginEditor = __decorate([
    Component({
        components: {
            CardButtonActions,
            ModeForm,
            UserForm1,
        },
        methods: {},
    })
], ModeLoginEditor);
export default ModeLoginEditor;
