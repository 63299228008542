import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import ChargeFineIcon from '@/components/customicons/ChargeFine.vue'
import PlateWashIcon from '@/components/customicons/PlateWash.vue'



export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#4c84ec',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
      icons: {
        values: {
          chargefine: { // name of our custom icon
            component: ChargeFineIcon, // our custom component
          },
          platewash: {
            component: PlateWashIcon
          }
        },
      },
});
