import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { ParkingQueryRequest } from "@/apiclient/client";
import store from '@/store/index';
import { notificationModule, Criticality } from "./notificationModule";
let PrsTagModule = class PrsTagModule extends VuexModule {
    prsTags = [];
    selectedTags = []; //MAQ: This state should not be maintained in this store. It should be moved to the component or store using PrsTags (otherwise all pages would share the same selected PrsTags)
    selectedAreas = []; //MAQ: This state should not be maintained in this store. It should be moved to the component or store using PrsTags (otherwise all pages would share the same selected PrsTags)
    manuallyAccepted = false; //MAQ: This state should not be maintained in this store. It should be moved to the component or store using PrsTags (otherwise all pages would share the same selected PrsTags)
    async fetchPrsTags(customerId) {
        return apiClient.prsTags(customerId).then((response) => {
            //MAQ: All tags with name 'ManuallyAccepted' is removed. NOTE! This is due to an upcoming redesign in the tags, this will be revisited later            
            const filteredPrsTags = response.data?.filter(x => {
                return x.name !== 'ManuallyAccepted';
            });
            this.context.commit('SET_PRSTAGS', filteredPrsTags);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_SELECTED_LIST(list) {
        this.selectedAreas = list;
    }
    SET_PRSTAGS(prsTags) {
        this.prsTags = prsTags;
    }
    ADD_SELECTED_TAG(tags) {
        this.selectedTags = tags;
    }
    SET_MANUALLY_ACCEPTED(accepted) {
        this.manuallyAccepted = accepted;
    }
    ADD_AREA(area) {
        const exists = this.selectedAreas.some(x => x.name === area.name && x.id === area.id);
        if (!exists)
            this.selectedAreas.push(area);
    }
    REMOVE_AREA(area) {
        this.selectedAreas = this.selectedAreas.filter(item => item !== area);
    }
    get getSelectedAreas() {
        return this.selectedAreas;
    }
    get getSelectedTags() {
        return this.selectedTags;
    }
    get getManuallyAccepted() {
        return this.manuallyAccepted;
    }
    get getPrsTags() {
        return this.prsTags;
    }
    get getTagQuery() {
        return new ParkingQueryRequest({
            inclusionTags: this.selectedTags,
            areas: this.selectedAreas,
            exclusionTags: this.getPrsTags.filter(f => !this.selectedTags.includes(f)),
            acceptanceTags: []
        });
    }
};
__decorate([
    Action({ rawError: true })
], PrsTagModule.prototype, "fetchPrsTags", null);
__decorate([
    Mutation
], PrsTagModule.prototype, "SET_SELECTED_LIST", null);
__decorate([
    Mutation
], PrsTagModule.prototype, "SET_PRSTAGS", null);
__decorate([
    Mutation
], PrsTagModule.prototype, "ADD_SELECTED_TAG", null);
__decorate([
    Mutation
], PrsTagModule.prototype, "SET_MANUALLY_ACCEPTED", null);
__decorate([
    Mutation
], PrsTagModule.prototype, "ADD_AREA", null);
__decorate([
    Mutation
], PrsTagModule.prototype, "REMOVE_AREA", null);
PrsTagModule = __decorate([
    Module({ dynamic: true, store, name: 'prsTagModule', namespaced: true })
], PrsTagModule);
export default PrsTagModule;
export const prsTagModule = getModule(PrsTagModule);
