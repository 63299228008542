import { __decorate } from "tslib";
import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import { apiClient } from "@/apiclient/baseclient";
let ParagraphModule = class ParagraphModule extends VuexModule {
    async patchParagraph(request) {
        var paragraph = await apiClient.paragraphsPATCH(request.customerId, request.templateId, request.paragraphId, request.updateBody);
    }
    async deleteParagrah(request) {
        return await apiClient.paragraphsDELETE(request.customerId, request.templateId, request.paragraphId || 0);
    }
    async createParagraph(request) {
        return await apiClient.paragraphsPOST(request.customerId, request.templateId, request.body);
    }
};
__decorate([
    Action({ rawError: true })
], ParagraphModule.prototype, "patchParagraph", null);
__decorate([
    Action({ rawError: true })
], ParagraphModule.prototype, "deleteParagrah", null);
__decorate([
    Action({ rawError: true })
], ParagraphModule.prototype, "createParagraph", null);
ParagraphModule = __decorate([
    Module({ dynamic: true, store, name: "paragraphModule", namespaced: true })
], ParagraphModule);
export default ParagraphModule;
export const paragraphModule = getModule(ParagraphModule);
