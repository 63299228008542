import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
import { statusModule } from "@/store/modules/statusModule";
import { SubscriberCompanyRequest, SubscriberPersonRequest, } from "@/apiclient/client";
import store from "@/store/index";
let SubscriberModule = class SubscriberModule extends VuexModule {
    subscriber = null;
    subscribers = [];
    loading = false;
    totalRecords = 0;
    get getSubscriber() {
        return this.subscriber;
    }
    get getSubscribers() {
        return this.subscribers;
    }
    get getCount() {
        return this.totalRecords;
    }
    async fetchSubscribers(request) {
        statusModule.updateLoading(true);
        try {
            var subscribers = await apiClient.subscribersGET(request.pageNumber, request.pageSize, request.sortOrderAsc, request.orderBy, request.search, request.includeInactive);
            this.context.commit("SET_SUBSCRIBERS", subscribers.data);
            this.context.commit("SET_PAGECOUNT", subscribers.totalRecords);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            statusModule.updateLoading(false);
        }
    }
    async fetchSubscriberById(subscriberId) {
        return await apiClient
            .subscribersGET2(subscriberId)
            .then(response => {
            this.context.commit("SET_SUBSCRIBER", response.data);
        })
            .catch(e => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async updateSubscriber(updateDispatch) {
        return await apiClient
            .subscribersPUT(updateDispatch.subscriberId, authenticationModule.getCustomerId, updateDispatch.subscriberType === 2, updateDispatch.subscriber)
            .then(response => {
            this.context.dispatch("fetchSubscriberById", updateDispatch.subscriberId);
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_subscriber".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(e => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async deleteSubscriber(subscriber) {
        return apiClient
            .subscribersDELETE(subscriber.id || 0)
            .then(response => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_subscriber".t(),
                criticality: Criticality.Success,
            });
            return response;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async activateSubscriber(subscriber) {
        return apiClient
            .activate(subscriber.id || 0, subscriber.active) //PATCH
            .then(response => {
            if (subscriber.hasBeenMasked) {
                this.context.dispatch("fetchSubscriberById", subscriber.id);
            }
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_subscriber".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async createSubscriber(subscriber) {
        statusModule.updateLoading(true);
        var createdSubscriber;
        try {
            if (subscriber.subscriberType === 2) {
                var person = new SubscriberPersonRequest();
                person.address1 = subscriber.address1;
                person.address2 = subscriber.address2;
                person.city = subscriber.city;
                person.email = subscriber.email;
                person.externalCostumerID = subscriber.externalCostumerID;
                person.firstName = subscriber.firstName;
                person.lastName = subscriber.lastName;
                person.phoneNumber = subscriber.phonenumber;
                person.recieveNewsLetter = subscriber.recieveNewsLetter;
                person.zipcode = subscriber.zipCode;
                let created = await apiClient.person(person);
                createdSubscriber = created?.data;
            }
            else if (subscriber.subscriberType === 1 || subscriber.subscriberType === undefined) {
                var company = new SubscriberCompanyRequest();
                company.address1 = subscriber.address1;
                company.address2 = subscriber.address2;
                company.city = subscriber.city;
                company.email = subscriber.email;
                company.externalCostumerID = subscriber.externalCostumerID;
                company.cvr = subscriber.cvr;
                company.name = subscriber.name;
                company.attPersonName = subscriber.attPersonName;
                company.phoneNumber = subscriber.phonenumber;
                company.recieveNewsLetter = subscriber.recieveNewsLetter;
                company.zipcode = subscriber.zipCode;
                let created = await apiClient.company(authenticationModule.getCustomerId, company);
                createdSubscriber = created?.data;
            }
            statusModule.updateLoading(false);
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_subscriber".t(),
                criticality: Criticality.Success,
            });
            return createdSubscriber;
        }
        catch {
            statusModule.updateLoading(false);
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
    }
    //Mutations
    SET_SUBSCRIBERS(subscribers) {
        this.subscribers = subscribers;
    }
    SET_SUBSCRIBER(subscriber) {
        this.subscriber = subscriber;
    }
    SET_PAGECOUNT(pagecount) {
        this.totalRecords = pagecount;
    }
    SET_LOADING(loading) {
        this.loading = loading;
    }
};
__decorate([
    Action({ rawError: true })
], SubscriberModule.prototype, "fetchSubscribers", null);
__decorate([
    Action({ rawError: true })
], SubscriberModule.prototype, "fetchSubscriberById", null);
__decorate([
    Action({ rawError: true })
], SubscriberModule.prototype, "updateSubscriber", null);
__decorate([
    Action({ rawError: true })
], SubscriberModule.prototype, "deleteSubscriber", null);
__decorate([
    Action({ rawError: true })
], SubscriberModule.prototype, "activateSubscriber", null);
__decorate([
    Action({ rawError: true })
], SubscriberModule.prototype, "createSubscriber", null);
__decorate([
    Mutation
], SubscriberModule.prototype, "SET_SUBSCRIBERS", null);
__decorate([
    Mutation
], SubscriberModule.prototype, "SET_SUBSCRIBER", null);
__decorate([
    Mutation
], SubscriberModule.prototype, "SET_PAGECOUNT", null);
__decorate([
    Mutation
], SubscriberModule.prototype, "SET_LOADING", null);
SubscriberModule = __decorate([
    Module({ dynamic: true, store, name: "subscriberModule", namespaced: true })
], SubscriberModule);
export default SubscriberModule;
export const subscriberModule = getModule(SubscriberModule);
