import { __decorate } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
let ExpansionPanel = class ExpansionPanel extends Vue {
    expanded = [0];
};
ExpansionPanel = __decorate([
    Component
], ExpansionPanel);
export default ExpansionPanel;
