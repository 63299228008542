import { __decorate } from "tslib";
import { password, passwordMatch, required } from "@/components/shared/validationRules";
import { InputType } from "../shared/enums";
import { subscriberModule } from "@/store/modules/subscriberModule";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
import CustomSwitch from "../shared/CustomSwitch.vue";
import CustomTextArea from "../shared/CustomTextArea.vue";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { userModule } from "@/store/modules/userModule";
import { SubscriberUpdateRequest } from "@/apiclient/client";
import UpdateSubscriberDispatch from "@/util/storerequest/updateSubscriberDispatch";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import SubscriptionTable from "../subscription/SubscriptionTable.vue";
import CustomCheckbox from "../shared/CustomCheckbox.vue";
let Details = class Details extends Vue {
    async mounted() {
        this.subscriberId = parseInt(this.$route.params.id);
        await subscriberModule.fetchSubscriberById(this.subscriberId);
        if (this.subscriberId)
            await userModule.fetchModeUsers(this.subscriberId);
    }
    destroyed() {
        subscriberModule.SET_SUBSCRIBER(null);
    }
    type = InputType;
    action = Action;
    subscriberId;
    subscriberDetailsFormValid = true;
    activateChanged = false;
    includeInactive = false;
    saveSubscriber() {
        const isFormValid = this.$refs.subscriberDetailsForm.validate();
        if (!isFormValid) {
            return;
        }
        this.handleSubscriberActivation();
        if (this.getSubscriber?.hasBeenMasked)
            return;
        let subscriber = new SubscriberUpdateRequest();
        subscriber.firstName = this.getSubscriber?.firstName;
        subscriber.lastName = this.getSubscriber?.lastName;
        subscriber.name = this.getSubscriber?.name;
        subscriber.email = this.getSubscriber?.email;
        subscriber.phoneNumber = this.getSubscriber?.phonenumber;
        subscriber.city = this.getSubscriber?.city;
        subscriber.zipcode = this.getSubscriber?.zipCode;
        subscriber.externalCostumerID = this.getSubscriber?.externalCostumerID;
        subscriber.cvr = this.getSubscriber?.cvr;
        subscriber.address1 = this.getSubscriber?.address1;
        subscriber.address2 = this.getSubscriber?.address2;
        subscriber.remark = this.getSubscriber?.remark;
        subscriber.recieveNewsLetter = this.getSubscriber?.recieveNewsLetter ?? false;
        let subscriberUpdate = new UpdateSubscriberDispatch(subscriber, this.subscriberId ?? -1, this.getSubscriber?.subscriberType ?? -1);
        subscriberModule.updateSubscriber(subscriberUpdate);
    }
    handleSubscriberActivation() {
        if (!this.activateChanged || !this.getSubscriber)
            return;
        subscriberModule.activateSubscriber(this.getSubscriber);
        this.activateChanged = false;
    }
    showCreateAffiliationModal() {
        this.$router.push("/modelogins/" + this.getSubscriber?.id);
    }
    onBackClick() {
        this.$router.go(-1);
    }
    onRowClick(user) {
        this.$router.push(`/users/${user.id}`);
    }
    get headers() {
        return [
            {
                text: this.$tc("username", 2).toString(),
                sortable: true,
                value: "username",
            },
            {
                text: this.$tc("name", 2).toString(),
                sortable: true,
                value: "name",
            },
            {
                text: this.$tc("mode", 2).toString(),
                sortable: true,
                value: "mode",
            },
        ];
    }
    get getSubscriber() {
        return subscriberModule.getSubscriber;
    }
    get getIsCompany() {
        return this.getSubscriber?.subscriberType === 1;
    }
    get getIsSuperadmin() {
        return authenticationModule.isSuperAdmin;
    }
    get getAffiliations() {
        return userModule.getModeLogins;
    }
    get getIsAdmin() {
        return authenticationModule.isAdmin || authenticationModule.isSuperAdmin;
    }
    get getSubscriptions() {
        return subscriptionModule.getSubscriptions;
    }
};
Details = __decorate([
    Component({
        components: {
            CustomButton,
            CustomSelect,
            CustomTextField,
            CustomSwitch,
            CustomCheckbox,
            CustomTextArea,
            SubscriptionTable,
        },
        methods: {
            required,
            password,
            passwordMatch,
        },
    })
], Details);
export default Details;
