import { __decorate } from "tslib";
import { Criticality, notificationModule, } from "@/store/modules/notificationModule";
import { Component, Prop, Watch } from "vue-property-decorator";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Vue from "vue";
let qrManagementModal = class qrManagementModal extends Vue {
    action = Action;
    headerTitle;
    show;
    maxWidth;
    qrCode;
    subscriberId;
    loading = false;
    qrBtnDisabled = false;
    onShowChanged() {
        this.qrBtnDisabled = false;
    }
    get getImgSrc() {
        return `data:image/png;base64,${this.qrCode.base64}`;
    }
    get visible() {
        return this.show;
    }
    set visible(show) {
        if (!show) {
            this.$emit("dialog-close");
        }
    }
    resendEmail() {
        this.loading = true;
        subscriptionModule
            .emailTemplateTypeResendQr(this.subscriberId)
            .then(() => {
            setTimeout(() => {
                this.loading = false;
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_create_email".t(),
                    criticality: Criticality.Success,
                });
                this.qrBtnDisabled = true;
            }, 2000);
        })
            .catch(() => {
            setTimeout(() => {
                this.loading = false;
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            }, 2000);
        });
    }
    print() {
        //htmlToPaper('printMe');
        //this.$htmlToPaper('card');
        this.printDiv("wrapper");
    }
    printDiv(elementId) {
        let printElement = document.getElementById(elementId);
        var printWindow = window.open("", "PRINT");
        printWindow.document.write(document.documentElement.innerHTML);
        setTimeout(() => {
            // Needed for large documents
            printWindow.document.body.style.margin = "0 0";
            printWindow.document.body.innerHTML = printElement.outerHTML;
            printWindow.document.close(); // necessary for IE >= 10
            printWindow.focus(); // necessary for IE >= 10*/
            printWindow.print();
            printWindow.close();
        }, 1000);
    }
};
__decorate([
    Prop()
], qrManagementModal.prototype, "headerTitle", void 0);
__decorate([
    Prop()
], qrManagementModal.prototype, "show", void 0);
__decorate([
    Prop()
], qrManagementModal.prototype, "maxWidth", void 0);
__decorate([
    Prop()
], qrManagementModal.prototype, "qrCode", void 0);
__decorate([
    Prop()
], qrManagementModal.prototype, "subscriberId", void 0);
__decorate([
    Watch("show")
], qrManagementModal.prototype, "onShowChanged", null);
qrManagementModal = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
        },
        methods: {},
    })
], qrManagementModal);
export default qrManagementModal;
