import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
import ImageDraggable from "@/components/charge/editor/ImageDraggable.vue";
import ImageElement from "./ImageElement.vue";
import TestImageElement from "./TestImageElement.vue";
let ImagePanel = class ImagePanel extends Vue {
    get allImages() {
        return chargeEditorModule.getCurrentImages;
    }
    get getCurrentImages() {
        return chargeEditorModule.getCurrentImages;
    }
};
ImagePanel = __decorate([
    Component({
        components: {
            ImageDraggable,
            ImageElement,
            TestImageElement,
        },
    })
], ImagePanel);
export default ImagePanel;
