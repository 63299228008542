class CsvArguments {
    data;
    columnDelimiter;
    lineDelimiter;
    columnRules;
    constructor(data, columnDelimiter, lineDelimiter, columnRules) {
        this.data = data;
        this.columnDelimiter = columnDelimiter;
        this.lineDelimiter = lineDelimiter;
        this.columnRules = columnRules;
    }
}
class ColumnRule {
    attributeName;
    objProperty;
    isArray;
    constructor(attributeName, objProperty, isArray) {
        this.attributeName = attributeName;
        this.objProperty = objProperty;
        this.isArray = isArray;
    }
}
function downloadCSV(entities, rules, filename) {
    //help from https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-sideS
    let data = '';
    const args = new CsvArguments(entities, ';', '\n', rules);
    let res = convertArrayOfObjectsToCSV(args, rules);
    if (!res.match(/^data:text\/csv/i)) {
        res = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(res);
    }
    data = res;
    downloadDocument(data, filename);
}
function downloadDocument(data, filename) {
    let link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
function convertArrayOfObjectsToCSV(args, rules) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }
    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';
    keys = Object.keys(data[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
            if (ctr > 0)
                result += columnDelimiter;
            const value = item[key];
            if (typeof value === 'object' && !Array.isArray(value)) {
                const rule = rules.filter(r => r.attributeName === key);
                if (rule && rule.length > 0) {
                    const objRule = rule[0];
                    const objValue = value[rule[0].objProperty];
                    result += objValue;
                }
                else {
                    result += JSON.stringify(value);
                }
            }
            else if (Array.isArray(value)) {
                const arrayRule = rules.filter(r => r.attributeName === key);
                if (arrayRule && arrayRule.length > 0) {
                    const objRule = arrayRule[0];
                    //const objValue = value[rule[0].objProperty];
                    let arrayResult = '';
                    for (let i = 0; i < value.length; i++) {
                        arrayResult += `${value[i][arrayRule[0].objProperty]} `;
                    }
                    result += arrayResult;
                }
                else {
                    result += JSON.stringify(value);
                }
            }
            else {
                result += value;
            }
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;
}
function downloadFileFromServer(data, fileName) {
    let fileURL = window.URL.createObjectURL(new Blob([data]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    let filename = `${fileName}`; // MAQ: In the future we want to get fileName from server
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
}
export { downloadCSV, convertArrayOfObjectsToCSV, downloadFileFromServer, ColumnRule, CsvArguments };
