import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { secureStorage } from "@/global";
Vue.use(Vuex);
//https://dev.to/nickitax/persistent-store-with-vuejs-vuex-and-vuex-persisted-state-354n
//https://github.com/titan-suite/ide/blob/master/src/store/index.ts#L11
const storeOptions = {
    plugins: [
        createPersistedState({
            paths: ["authenticationModule"],
            storage: {
                getItem: key => secureStorage.get(key),
                setItem: (key, value) => secureStorage.set(key, value),
                removeItem: key => secureStorage.remove(key),
            },
        }),
    ],
};
// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store(storeOptions);
