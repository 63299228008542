import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let ImageDraggable = class ImageDraggable extends Vue {
    image;
};
__decorate([
    Prop()
], ImageDraggable.prototype, "image", void 0);
ImageDraggable = __decorate([
    Component
], ImageDraggable);
export default ImageDraggable;
