//const dkNumberPlate = /[A-Z|a-z]{2}[0-9]{5}$/;
/**
 *
 * @param vehicleIdents array of strings
 */
function getLastItemInArray(array) {
    return array[array.length - 1];
}
const getLicensePlateString = (vehicleIdents) => { return formatLicensePlate(getLastItemInArray(vehicleIdents)); };
const formatLicensePlate = (licensePlate) => { return isDkLicensePlate(licensePlate) ? `${licensePlate.substring(0, 2)} ${licensePlate.substring(2, 4)} ${licensePlate.substring(4, 7)}` : licensePlate; };
const isDkLicensePlate = (licensePlate) => { return /^[A-Z|a-z]{2}[0-9]{5}$/.test(licensePlate); };
export { getLicensePlateString, formatLicensePlate, isDkLicensePlate, getLastItemInArray };
