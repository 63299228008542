import { __decorate } from "tslib";
import Component from "vue-class-component";
import CustomSlider from "@/components/shared/CustomSlider.vue";
import Vue from "vue";
let CustomSliderExamples = class CustomSliderExamples extends Vue {
    slider1 = 0;
    slider2 = 0;
    onSliderChange(value) {
        // console.log("onSliderChange: " + value);
    }
};
CustomSliderExamples = __decorate([
    Component({
        components: {
            CustomSlider,
        },
        methods: {},
    })
], CustomSliderExamples);
export default CustomSliderExamples;
