import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";
let CustomCheckbox = class CustomCheckbox extends Vue {
    syncChecked;
    label;
    disabled;
    onChange(isChecked) {
        this.$emit("on-checkbox-change", isChecked);
    }
};
__decorate([
    PropSync("checked", { required: true, type: Boolean })
], CustomCheckbox.prototype, "syncChecked", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomCheckbox.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomCheckbox.prototype, "disabled", void 0);
CustomCheckbox = __decorate([
    Component
], CustomCheckbox);
export default CustomCheckbox;
