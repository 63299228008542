import { __decorate } from "tslib";
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { areaModule } from "@/store/modules/areaModule";
import CustomDialog from "../shared/CustomDialog.vue";
import CustomTreeSelect from "../shared/CustomTreeSelect.vue";
import { updateShowInParkadminProp } from "@/util/area/areautil";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import AreaTreeSelect from "../treeSelects/AreaTreeSelect.vue";
let FeatureAreasToggle = class FeatureAreasToggle extends Vue {
    action = Action;
    selectedCustomer;
    showAreasModal = false;
    areasToUpdate = [];
    initialSelection = [];
    treeNodesWatcher() {
        this.initialSelection = this.getAreas.filter(area => area.showInParkAdmin).map(area => ({
            ...area,
            subAreas: area.subAreas.filter(subArea => subArea.showInParkAdmin)
        }));
    }
    onAreasChange(areas) {
        this.areasToUpdate = updateShowInParkadminProp(areas, this.getAreas);
    }
    updateAreas() {
        if (this.areasToUpdate.length <= 0)
            return;
        areaModule.updateAreas({ customerID: this.selectedCustomer.costumerID, areas: this.areasToUpdate }).then(() => {
            this.showAreasModal = false;
        });
    }
    get getAreas() {
        return areaModule.getCustomerAreas;
    }
    get getMultipleSelectionText() {
        return this.initialSelection.filter(node => node.selected).length > 0 ? this.initialSelection.filter(node => node.selected)[0].name : "";
    }
};
__decorate([
    Prop()
], FeatureAreasToggle.prototype, "selectedCustomer", void 0);
__decorate([
    Watch("getAreas", { immediate: true, deep: true })
], FeatureAreasToggle.prototype, "treeNodesWatcher", null);
FeatureAreasToggle = __decorate([
    Component({
        components: {
            CustomDialog,
            CustomTreeSelect,
            CustomButton,
            AreaTreeSelect
        },
        methods: {}
    })
], FeatureAreasToggle);
export default FeatureAreasToggle;
