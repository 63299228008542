import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ListRequest from "../storerequest/ListRequest";
let ListBase = class ListBase extends Vue {
    includeInactive;
    validationCount = 1;
    pageSize = 10;
    pageNumber = 1;
    dialog = false;
    dialogDelete = false;
    editing = false;
    editedItem = null;
    resetCount = 0;
    expandedItems = [];
    formValid = false;
    orderBy = "";
    sortBy = "";
    sortDesc = false;
    sortOrderAsc = false;
    search = "";
    onDialogChanged() {
        this.resetCount++;
    }
    handleCreate() {
        this.editing = false;
        this.editedItem = {};
        this.dialog = true;
    }
    editItem(item) {
        this.editing = true;
        this.editedItem = { ...item };
        this.dialog = true;
        this.resetCount += 1;
    }
    updatePagination(pagination) {
        this.pageNumber = pagination;
    }
    updateOptions(options) {
        if (!options)
            return;
        this.pageNumber = options.page;
        this.sortDesc = options.sortDesc[0];
        this.sortBy = options.sortBy[0];
        this.pageSize = options.itemsPerPage;
    }
    get getListRequest() {
        return new ListRequest(this.pageNumber, this.pageSize, this.search, this.sortBy, this.sortDesc, this.includeInactive);
    }
    close() {
        this.dialog = false;
        this.$nextTick(() => {
            this.editedItem = {};
        });
    }
    deleteItem(item) {
        this.editedItem = item;
        this.dialogDelete = true;
    }
    closeDelete() {
        this.dialogDelete = false;
        this.editedItem = {};
    }
};
__decorate([
    Prop({ default: false })
], ListBase.prototype, "includeInactive", void 0);
__decorate([
    Watch("dialog")
], ListBase.prototype, "onDialogChanged", null);
ListBase = __decorate([
    Component
], ListBase);
export default ListBase;
