import { SubscriberUpdateRequest, SubscriptionRequest, SubscriptionTypeRequest, SubscriptionUpdateRequest } from "@/apiclient/client";
function castToSubscriberUpdate(subscriber) {
    const update = new SubscriberUpdateRequest();
    update.address1 = subscriber.address1;
    update.address2 = subscriber.address2;
    update.attPersonName = subscriber.attPersonName;
    update.city = subscriber.city;
    update.country = subscriber.country;
    update.cvr = subscriber.cvr;
    update.defaultWelcomeEmailID = subscriber.defaultWelcomeEmailID;
    update.email = subscriber.email;
    update.externalCostumerID = subscriber.externalCostumerID;
    update.firstName = subscriber.firstName;
    update.lastName = subscriber.lastName;
    update.name = subscriber.name;
    update.phoneNumber = subscriber.phonenumber;
    update.recieveNewsLetter = subscriber.recieveNewsLetter;
    update.remark = subscriber.remark;
    update.zipcode = subscriber.zipCode;
    return update;
}
/**
 * cast SUbscription2 to SUbscriptionUpdate
 * @param subscription
 * @returns
 */
function castToSubscriptionUpdate(subscription) {
    const update = new SubscriptionUpdateRequest();
    update.name = subscription.name;
    update.recieveNewsLetter = subscription.recieveNewsLetter;
    update.email = subscription.email;
    update.toDate = subscription.toDate;
    update.subscriptionType = new SubscriptionTypeRequest({ subscriptionTypeId: subscription.subscriptionType?.subscriptionTypeId, subscriptionType: subscription.subscriptionType?.subscriptionType });
    update.phoneNumber = subscription.phonenumber;
    update.remark = subscription.remark;
    update.active = subscription.active;
    update.areas = subscription.areas;
    return update;
}
/**
 * Cast Subscription2 to Subscription
 * @param subscription
 * @returns
 */
function castToSubscription(subscription) {
    const newSubscription = new SubscriptionRequest();
    newSubscription.name = subscription.name;
    newSubscription.email = subscription.email;
    newSubscription.primaryAreaID = subscription.primaryArea?.id;
    newSubscription.fromDate = subscription.fromDate;
    newSubscription.toDate = subscription.toDate;
    newSubscription.subscriptionType = new SubscriptionTypeRequest({ subscriptionTypeId: subscription.subscriptionType?.subscriptionTypeId, subscriptionType: subscription.subscriptionType?.subscriptionType });
    newSubscription.areas = subscription.areas;
    newSubscription.phoneNumber = subscription.phonenumber;
    newSubscription.recieveNewsLetter = subscription.recieveNewsLetter;
    newSubscription.remark = subscription.remark;
    return newSubscription;
}
export { castToSubscriptionUpdate, castToSubscription, castToSubscriberUpdate };
