import { __decorate } from "tslib";
import { Criticality, notificationModule, } from "@/store/modules/notificationModule";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomSnackbar from "@/components/shared/CustomSnackbar.vue";
import Vue from "vue";
let CustomSnackbarExamples = class CustomSnackbarExamples extends Vue {
    action = Action;
    onSnackbar1Click() {
        notificationModule.SHOW_NOTIFICATION({
            message: "notification_success_example".t(),
            criticality: Criticality.Success,
        });
    }
    onSnackbar2Click() {
        notificationModule.SHOW_NOTIFICATION({
            message: "notification_warning_example".t(),
            criticality: Criticality.Warning,
        });
    }
    onSnackbar3Click() {
        notificationModule.SHOW_NOTIFICATION({
            message: "notification_error_example".t(),
            criticality: Criticality.Error,
        });
    }
    onSnackbar4Click() {
        notificationModule.SHOW_NOTIFICATION({
            message: "notification_success_example".t(),
            criticality: Criticality.Success,
            timeout: 500,
        });
    }
};
CustomSnackbarExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomSnackbar,
        },
        methods: {},
    })
], CustomSnackbarExamples);
export default CustomSnackbarExamples;
