import { __decorate } from "tslib";
import Component from "vue-class-component";
import CustomSwitch from "@/components/shared/CustomSwitch.vue";
import Vue from "vue";
let CustomSwitchExamples = class CustomSwitchExamples extends Vue {
    switch1 = false;
    switch2 = false;
    onSwitchChange(switched) {
        // console.log("onSwitchChange: " + switched);
    }
};
CustomSwitchExamples = __decorate([
    Component({
        components: {
            CustomSwitch,
        },
        methods: {},
    })
], CustomSwitchExamples);
export default CustomSwitchExamples;
