import { __decorate } from "tslib";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
let TemplateModule = class TemplateModule extends VuexModule {
    showTemplateForm = false;
    resetCount = 0;
    get getResetCount() {
        return this.resetCount;
    }
    get getShowTemplateForm() {
        return this.showTemplateForm;
    }
    SET_SHOW_TEMPLATE_FORM(show) {
        this.showTemplateForm = show;
    }
    INCREMENT_RESET_COUNT() {
        this.resetCount++;
    }
};
__decorate([
    Mutation
], TemplateModule.prototype, "SET_SHOW_TEMPLATE_FORM", null);
__decorate([
    Mutation
], TemplateModule.prototype, "INCREMENT_RESET_COUNT", null);
TemplateModule = __decorate([
    Module({ dynamic: true, store, name: "templateModule", namespaced: true })
], TemplateModule);
export default TemplateModule;
export const templateModule = getModule(TemplateModule);
