import { __decorate } from "tslib";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { castToSubscriberUpdate } from "@/util/subscriptionConverter";
import { InputType } from "@/components/shared/enums";
import { statusModule } from "@/store/modules/statusModule";
import { subscriberModule } from "@/store/modules/subscriberModule";
import { SubscriberType } from "@/apiclient/client";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import { Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import EntityToolTip from "@/components/entitypreview/EntityTooltip.vue";
import ListBase from "@/util/mixins/listbase";
import ModeLoginManagement from "@/views/ModeLoginManagement.vue";
import SubscriberForm from "@/components/forms/SubscriberForm.vue";
import SubscriberPreview from "@/components/entitypreview/SubscriberPreview.vue";
import SubscriptionTable from "@/components/subscription/SubscriptionTable.vue";
import UpdateSubscriberDispatch from "@/util/storerequest/updateSubscriberDispatch";
import { CustomerType } from "@/components/subscribers/enums";
import debounce from "lodash.debounce";
let Overview = class Overview extends ListBase {
    type = InputType;
    action = Action;
    awaitingSearch = false;
    customerTypeText = CustomerType.Private;
    get isPrivate() {
        return this.customerTypeText == CustomerType.Private;
    }
    get getCount() {
        return subscriberModule.getCount;
    }
    get getSubscribers() {
        return subscriberModule.getSubscribers;
    }
    get getSubscriptions() {
        return subscriptionModule.getSubscriptions;
    }
    get getLoadingStatus() {
        return statusModule.getLoadingState;
    }
    get editedItemIsPrivateCustomer() {
        return this.editedItem ? this.editedItem.subscriberType === 2 : false;
    }
    get isSuperAdmin() {
        return authenticationModule.isSuperAdmin;
    }
    get formTitle() {
        return this.editing ? `${this.$t("edit")} ${this.editedItemName || ""}` : this.$t("createnew");
    }
    get getHeaders() {
        return [
            { text: this.$t("name").toString(), value: "name" },
            { text: "Email", value: "email" },
            { text: this.$t("phone").toString(), value: "phonenumber" },
            { text: this.$t("status").toString(), value: "activeStatus" },
            {
                text: this.$t("actions").toString(),
                value: "actions",
                sortable: false,
            },
        ];
    }
    get subscriber() {
        return this.expandedItems[0];
    }
    get editedItemName() {
        if (!this.editedItem)
            return "";
        if (this.editedItem.firstName && this.editedItem.lastName) {
            return `${this.editedItem.firstName} ${this.editedItem.lastName}`;
        }
        else {
            return this.editedItem.name;
        }
    }
    onListRequestChanged() {
        subscriberModule.fetchSubscribers(this.getListRequest);
    }
    onPropertyChanged() {
        const request = this.getListRequest;
        request.includeInactive = this.includeInactive;
        subscriberModule.fetchSubscribers(this.getListRequest);
    }
    onSearchChange(search) {
        this.pageNumber = 1;
        this.search = search;
        subscriberModule.fetchSubscribers({
            ...this.getListRequest,
            search,
        });
    }
    debouncedSearchChange = debounce(this.onSearchChange, 500);
    // @Watch("searchTopLevel")
    // onSearchPropertyChanged() {
    //   if (!this.awaitingSearch) {
    //     setTimeout(() => {
    //       this.pageNumber = 1;
    //       subscriberModule.fetchSubscribers({
    //         ...this.getListRequest,
    //         search: this.searchTopLevel,
    //       });
    //       this.awaitingSearch = false;
    //     }, 400); // 0.4 sec delay
    //   }
    //   this.awaitingSearch = true;
    // }
    async deleteItemConfirm() {
        subscriberModule.deleteSubscriber(this.editedItem).then(() => {
            subscriberModule.fetchSubscribers(this.getListRequest);
            this.closeDelete();
        });
    }
    editItem(subscriber) {
        return this.$router.push(`/subscribers/${subscriber.id}`);
    }
    setSubscriber(subscriber) {
        subscriptionModule.SET_SUBSCRIBER(subscriber);
    }
    getSubscriberName(subscriber) {
        return subscriber.name ?? subscriber.firstName + " " + subscriber.lastName;
    }
    updateActiveStatus(subscriber) {
        if (subscriber.active) {
            subscriberModule.activateSubscriber(subscriber).then(() => {
                subscriberModule.fetchSubscribers(this.getListRequest);
            });
        }
        else {
            subscriberModule.deleteSubscriber(subscriber).then(() => {
                subscriberModule.fetchSubscribers(this.getListRequest);
            });
        }
    }
    save() {
        const isFormValid = this.$refs.subSubscriberForm.$refs
            .subscriberform.validate();
        if (!isFormValid) {
            return;
        }
        if (this.editing) {
            //update
            subscriberModule
                .updateSubscriber(new UpdateSubscriberDispatch(castToSubscriberUpdate(this.editedItem), this.editedItem.id, this.isPrivate ? 2 : 1))
                .then(() => {
                subscriberModule.fetchSubscribers(this.getListRequest);
            });
        }
        else {
            //create
            if (this.isPrivate) {
                this.editedItem.subscriberType = SubscriberType._2;
            }
            else {
                this.editedItem.subscriberType = SubscriberType._1;
            }
            subscriberModule.createSubscriber(this.editedItem).then(() => {
                subscriberModule.fetchSubscribers(this.getListRequest);
            });
        }
        this.close();
    }
};
__decorate([
    Watch("getListRequest")
], Overview.prototype, "onListRequestChanged", null);
__decorate([
    Watch("includeInactive")
], Overview.prototype, "onPropertyChanged", null);
Overview = __decorate([
    Component({
        components: {
            ConfirmPrompt,
            CustomButton,
            CustomDialog,
            CustomTextField,
            EntityToolTip,
            ModeLoginManagement,
            SubscriberForm,
            SubscriberPreview,
            SubscriptionTable,
        },
    })
], Overview);
export default Overview;
