import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
let StatusModule = class StatusModule extends VuexModule {
    status = "";
    message = "";
    msgCount = 0;
    loading = false;
    authenticationLoading = false;
    get getLoadingState() {
        return this.loading;
    }
    get getMessageText() {
        return this.message;
    }
    get getStatus() {
        return this.status;
    }
    get getMsgCount() {
        return this.msgCount;
    }
    get getAuthenticationLoading() {
        return this.authenticationLoading;
    }
    updateLoading(loading) {
        this.context.commit("SET_LOADING", loading);
    }
    SET_LOADING(loading) {
        this.loading = loading;
    }
    SET_STATUS(status) {
        this.status = status;
    }
    SET_MESSAGE(message) {
        this.message = message;
    }
    INCREMENT_COUNT() {
        this.msgCount += 1;
    }
    SET_AUTHENTICATION_LOADING(loading) {
        this.authenticationLoading = loading;
    }
};
__decorate([
    Action({ rawError: true })
], StatusModule.prototype, "updateLoading", null);
__decorate([
    Mutation
], StatusModule.prototype, "SET_LOADING", null);
__decorate([
    Mutation
], StatusModule.prototype, "SET_STATUS", null);
__decorate([
    Mutation
], StatusModule.prototype, "SET_MESSAGE", null);
__decorate([
    Mutation
], StatusModule.prototype, "INCREMENT_COUNT", null);
__decorate([
    Mutation
], StatusModule.prototype, "SET_AUTHENTICATION_LOADING", null);
StatusModule = __decorate([
    Module({ dynamic: true, store, name: "statusModule" })
], StatusModule);
export const statusModule = getModule(StatusModule);
