import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import { EmailTemplateResponse } from "@/apiclient/client";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
let EmailTemplateModule = class EmailTemplateModule extends VuexModule {
    templates = new Array();
    signatureLogo;
    signatureLogoBase64 = "";
    emailTemplates = new Array();
    selectedEmailTemplate = new EmailTemplateResponse();
    selectedTemplateId = 0;
    get getSelectedEmailTemplate() {
        return (this.emailTemplates.find(t => t.id === this.selectedTemplateId) || new EmailTemplateResponse());
    }
    get getSignatureLogo() {
        return this.signatureLogo;
    }
    get getSignatureLogoBase64() {
        return this.signatureLogoBase64;
    }
    get getEmailTemplates() {
        return this.emailTemplates;
    }
    async toggleFirstTemplate() {
        if (this.emailTemplates.length > 0) {
            this.context.commit("SET_SELECTED_EMAIL_TEMPLATE_ID", this.emailTemplates[0].id);
        }
    }
    async createEmailTemplate(template) {
        return apiClient
            .emailPOST(authenticationModule.getCustomerId, template)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_email_template".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.fetchEmailTemplates(authenticationModule.customerId);
        });
    }
    async deleteEmailTemplate(emailTemplateId) {
        return apiClient
            .emailDELETE(emailTemplateId, authenticationModule.getCustomerId)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_email_template".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_delete_email_template".t(),
                criticality: Criticality.Warning,
            });
        })
            .finally(() => {
            this.fetchEmailTemplates(authenticationModule.customerId);
        });
    }
    async fetchEmailTemplates(customerId) {
        const result = await apiClient.emailGET(customerId);
        const res = result.data;
        if (res) {
            this.context.commit("SET_EMAIL_TEMPLATES", res);
        }
    }
    async fetchEmailConfigurations(customerId) {
        var configs = await apiClient.configurationsGET(customerId);
        this.context.commit("SET_EMAILCONFIGS", configs.data);
    }
    updateSignatureLogo(logo) {
        this.context.commit("SET_SIGNATURELOGO", logo);
        try {
            const reader = new FileReader();
            reader.readAsDataURL(logo);
            reader.onload = () => {
                this.context.commit("SET_SIGNATURELOGO_BASE64", reader.result);
            };
        }
        catch (error) {
            //Not a file
            this.context.commit("SET_SIGNATURELOGO_BASE64", "");
        }
    }
    async patchEmailTemplate(request) {
        return await apiClient.emailPATCH(request.customerId, request.templateId, request.emailTemplateUpdate);
    }
    SET_EMAIL_TEMPLATES(emailTemplates) {
        this.emailTemplates = emailTemplates;
    }
    SET_SIGNATURELOGO(logo) {
        this.signatureLogo = logo;
    }
    SET_SIGNATURELOGO_BASE64(logo) {
        this.signatureLogoBase64 = logo;
    }
    SET_EMAILCONFIGS(emailTemplates) {
        this.templates = emailTemplates;
    }
    SET_SELECTED_EMAIL_TEMPLATE(emailTemplate) {
        this.selectedEmailTemplate = emailTemplate;
    }
    SET_SELECTED_EMAIL_TEMPLATE_ID(id) {
        this.selectedTemplateId = id;
    }
};
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "toggleFirstTemplate", null);
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "createEmailTemplate", null);
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "deleteEmailTemplate", null);
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "fetchEmailTemplates", null);
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "fetchEmailConfigurations", null);
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "updateSignatureLogo", null);
__decorate([
    Action({ rawError: true })
], EmailTemplateModule.prototype, "patchEmailTemplate", null);
__decorate([
    Mutation
], EmailTemplateModule.prototype, "SET_EMAIL_TEMPLATES", null);
__decorate([
    Mutation
], EmailTemplateModule.prototype, "SET_SIGNATURELOGO", null);
__decorate([
    Mutation
], EmailTemplateModule.prototype, "SET_SIGNATURELOGO_BASE64", null);
__decorate([
    Mutation
], EmailTemplateModule.prototype, "SET_EMAILCONFIGS", null);
__decorate([
    Mutation
], EmailTemplateModule.prototype, "SET_SELECTED_EMAIL_TEMPLATE", null);
__decorate([
    Mutation
], EmailTemplateModule.prototype, "SET_SELECTED_EMAIL_TEMPLATE_ID", null);
EmailTemplateModule = __decorate([
    Module({ dynamic: true, store, name: "emailTemplateModule", namespaced: true })
], EmailTemplateModule);
export default EmailTemplateModule;
export const emailTemplateModule = getModule(EmailTemplateModule);
