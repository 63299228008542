export class WashModalModel {
    visible;
    licensePlate;
    date;
    time;
    departure;
    constructor(visible, date, time, departure, licensePlate) {
        this.visible = visible;
        this.licensePlate = licensePlate;
        this.date = date;
        this.time = time;
        this.departure = departure;
    }
}
