import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { AreaRequest, AreaResponse, UpdateAreaRequest } from "@/apiclient/client";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
import { modeModule } from "./modeModule";
import store from "@/store";
import { userModule } from "./userModule";
let AreaModule = class AreaModule extends VuexModule {
    areaSearch = null;
    areas = [];
    customerAreas = [];
    area = new AreaResponse();
    async fetchAreas(customerId) {
        return await apiClient
            .areasGET2(undefined, customerId)
            .then(response => {
            const sortedAreas = sortAreas(response.data, null)
                .filter(area => area.showInParkAdmin)
                .map(area => ({
                ...area,
                subAreas: area.subAreas.filter(subArea => subArea.showInParkAdmin),
            }));
            this.context.commit("SET_AREAS", sortedAreas);
        })
            .catch(error => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchCustomerAreas(customerId) {
        return await apiClient
            .areasGET2(undefined, customerId)
            .then(response => {
            const sortedAreas = sortAreas(response.data, null);
            this.context.commit("SET_CUSTOMER_AREAS", sortedAreas);
            if (customerId === authenticationModule.customerId) {
                const sortedAreas = sortAreas(response.data, null)
                    .filter(area => area.showInParkAdmin)
                    .map(area => ({
                    ...area,
                    subAreas: area.subAreas.filter(subArea => subArea.showInParkAdmin),
                }));
                this.context.commit("SET_AREAS", sortedAreas);
            }
        })
            .catch(error => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchArea(areaId) {
        return await apiClient
            .areasGET(areaId)
            .then(response => {
            this.context.commit("SET_AREA", response.data);
        })
            .catch(error => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async updateArea(defaultChargeAmount) {
        const updatedArea = new UpdateAreaRequest();
        updatedArea.defaultChargeAmount = defaultChargeAmount;
        apiClient
            .areaPATCH(authenticationModule.customerId, this.getArea.id, updatedArea)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_area".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async updateAreas(request) {
        const { customerID, areas } = request;
        apiClient
            .areasPATCH(customerID, areas)
            .then(() => {
            this.fetchCustomerAreas(customerID).then(() => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_update_area".t(),
                    criticality: Criticality.Success,
                });
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async deleteArea(area) { }
    SET_AREA_SEARCH(text) {
        this.areaSearch = text;
    }
    SET_AREAS(areas) {
        this.areas = areas;
    }
    SET_AREA(area) {
        this.area = area;
    }
    SET_CUSTOMER_AREAS(areas) {
        this.customerAreas = areas;
    }
    get getAreaSearch() {
        return this.areaSearch;
    }
    get getAllSubAreas() {
        const returnArray = this.areas;
        this.areas.forEach(area => {
            //add all the subareas as areas
            area.subAreas?.forEach(subArea => {
                const newArea = new AreaRequest({
                    id: subArea.id,
                    name: subArea.name,
                    subAreas: undefined,
                });
                returnArray.push(newArea);
                //returnArray.push(...area.subAreas!)
            });
        });
        return returnArray;
    }
    get areaTreeNodes() {
        return modeModule.isDelegateLogin ? userModule.getModeAreas : this.areas;
    }
    get getAreas() {
        if (modeModule.isDelegateLogin) {
            return sortAreas(userModule.getModeAreas, this.areaSearch);
        }
        else {
            return sortAreas(this.areas, this.areaSearch);
        }
    }
    get getCustomerAreas() {
        return sortAreas(this.customerAreas, this.areaSearch);
    }
    get getArea() {
        return this.area;
    }
};
__decorate([
    Action({ rawError: true })
], AreaModule.prototype, "fetchAreas", null);
__decorate([
    Action({ rawError: true })
], AreaModule.prototype, "fetchCustomerAreas", null);
__decorate([
    Action({ rawError: true })
], AreaModule.prototype, "fetchArea", null);
__decorate([
    Action({ rawError: true })
], AreaModule.prototype, "updateArea", null);
__decorate([
    Action({ rawError: true })
], AreaModule.prototype, "updateAreas", null);
__decorate([
    Action({ rawError: true })
], AreaModule.prototype, "deleteArea", null);
__decorate([
    Mutation
], AreaModule.prototype, "SET_AREA_SEARCH", null);
__decorate([
    Mutation
], AreaModule.prototype, "SET_AREAS", null);
__decorate([
    Mutation
], AreaModule.prototype, "SET_AREA", null);
__decorate([
    Mutation
], AreaModule.prototype, "SET_CUSTOMER_AREAS", null);
AreaModule = __decorate([
    Module({ dynamic: true, store, name: "areasModule" })
], AreaModule);
export default AreaModule;
const sortAreas = (areas, searchFilter) => {
    if (searchFilter === null || searchFilter === "") {
        return areas?.slice().sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    else {
        return areas
            ?.slice()
            .filter(x => x.name.toLowerCase().includes(searchFilter.toLowerCase()))
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
};
export const areaModule = getModule(AreaModule);
