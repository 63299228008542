import { __decorate } from "tslib";
import { maxLength, maxNumber, minLength, minNumber, numberRange, password, passwordMatch, required, } from "@/components/shared/validationRules";
import { InputType } from "@/components/shared/enums";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let CustomTextFieldExamples = class CustomTextFieldExamples extends Vue {
    type = InputType;
    action = Action;
    textInputFormValid = true;
    input1 = null;
    input2 = null;
    numberInputFormValid = true;
    input4 = null;
    input5 = null;
    searchInputFormValid = true;
    input7 = null;
    input8 = null;
    passwordInputFormValid = true;
    input10 = null;
    input11 = null;
    onInputBlur(e) {
        // console.log(e.target.value);
    }
    textInputFormValidate() {
        this.$refs.textInputForm.validate();
    }
    textInputFormReset() {
        this.$refs.textInputForm.reset();
    }
    textInputFormResetValidation() {
        this.$refs.textInputForm.resetValidation();
    }
    numberInputFormValidate() {
        this.$refs.numberInputForm.validate();
    }
    numberInputFormReset() {
        this.$refs.numberInputForm.reset();
    }
    numberInputFormResetValidation() {
        this.$refs.numberInputForm.resetValidation();
    }
    searchInputFormValidate() {
        this.$refs.searchInputForm.validate();
    }
    searchInputFormReset() {
        this.$refs.searchInputForm.reset();
    }
    searchInputFormResetValidation() {
        this.$refs.searchInputForm.resetValidation();
    }
    passwordInputFormValidate() {
        this.$refs.passwordInputForm.validate();
    }
    passwordInputFormReset() {
        this.$refs.passwordInputForm.reset();
    }
    passwordInputFormResetValidation() {
        this.$refs.passwordInputForm.resetValidation();
    }
};
CustomTextFieldExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomTextField,
        },
        methods: {
            maxLength,
            maxNumber,
            minLength,
            minNumber,
            numberRange,
            password,
            passwordMatch,
            required,
        },
    })
], CustomTextFieldExamples);
export default CustomTextFieldExamples;
