import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { authenticationModule } from "@/store/modules/authenticationModule";
import EmailTemplateCard from "@/components/settings/EmailTemplateCard.vue";
let EmailTemplateConfigPicker = class EmailTemplateConfigPicker extends Vue {
    get emailTemplates() {
        return emailTemplateModule.getEmailTemplates;
    }
    mounted() {
        emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId);
    }
};
EmailTemplateConfigPicker = __decorate([
    Component({
        components: { EmailTemplateCard },
    })
], EmailTemplateConfigPicker);
export default EmailTemplateConfigPicker;
