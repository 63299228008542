import { __decorate } from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import InList from "@/components/parking/InList.vue";
let ParkingPage = class ParkingPage extends Vue {
};
ParkingPage = __decorate([
    Component({
        components: {
            InList,
        },
        methods: {},
    })
], ParkingPage);
export default ParkingPage;
