import { __decorate } from "tslib";
import { passwordConfirmationRule, passwordRule, clearValidation } from "@/util/forms/formutils";
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { password, passwordMatch, required } from "@/components/shared/validationRules";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let UserForm1 = class UserForm1 extends Vue {
    type = InputType;
    formValid = false;
    passwordConfirm = "";
    userProp;
    form;
    resetCount;
    modes;
    isEditing;
    onCountChaged(count) {
        this.$refs.userform.resetValidation();
        this.passwordConfirm = "";
    }
    onValidChanged() {
        this.$emit("state-changed", this.formValid);
    }
    get appMode() {
        const mode = this.modes.find(m => m.modeID === this.userProp.modeID);
        return mode ? mode.modeID : 0;
    }
    set appMode(modeId) {
        if (modeId)
            this.userProp.modeID = modeId;
    }
};
__decorate([
    PropSync("user", { type: Object })
], UserForm1.prototype, "userProp", void 0);
__decorate([
    Ref("userform")
], UserForm1.prototype, "form", void 0);
__decorate([
    Prop()
], UserForm1.prototype, "resetCount", void 0);
__decorate([
    Prop()
], UserForm1.prototype, "modes", void 0);
__decorate([
    Prop()
], UserForm1.prototype, "isEditing", void 0);
__decorate([
    Watch("resetCount")
], UserForm1.prototype, "onCountChaged", null);
__decorate([
    Watch("formValid")
], UserForm1.prototype, "onValidChanged", null);
UserForm1 = __decorate([
    Component({
        components: {
            CustomSelect,
            CustomTextField,
        },
        methods: {
            clearValidation,
            passwordConfirmationRule,
            passwordRule,
            password,
            passwordMatch,
            required,
        },
    })
], UserForm1);
export default UserForm1;
