export const hasAccessLevelToRoute = (customerAccessLevel, routeAccessLevel) => {
    return customerAccessLevel <= routeAccessLevel;
};
export const hasFeatureAccessToRoute = (customerFeatures, routeFeature) => {
    return Boolean(customerFeatures.find(feature => feature.featureID == routeFeature));
};
export const hasModeAccessToRoute = (customerMode, routeModes) => {
    return routeModes.includes(customerMode);
};
export const hasAccessToRoute = (route, customerAccessLevel, customerMode, activeFeatures) => {
    const accessLevel = route.meta?.accessLevel;
    const modes = route.meta?.modes;
    const feature = route.meta?.feature;
    let hasAccess = true;
    if (accessLevel && !hasAccessLevelToRoute(customerAccessLevel, accessLevel)) {
        hasAccess = false;
    }
    if (feature && !hasFeatureAccessToRoute(activeFeatures, feature)) {
        hasAccess = false;
    }
    if (modes && !hasModeAccessToRoute(customerMode, modes)) {
        hasAccess = false;
    }
    return hasAccess;
};
export const getPathWithID = (path, id) => {
    return id && path.includes(":id") ? path.replace(":id", id) : path;
};
