import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
import { ModeConfigurationRequest, ModeConfigurationResponse, } from "@/apiclient/client";
import store from "@/store/index";
let SettingsModule = class SettingsModule extends VuexModule {
    emailConfigurations = [];
    emailStates = [
        { stateId: 1, value: "On", text: "active" },
        { stateId: 2, value: "EnQueue", text: "enqueued" },
        { stateId: 3, value: "Off", text: "inactive" },
    ];
    modeConfig = new ModeConfigurationResponse();
    modeHeaderLogo;
    modeFooterLogo;
    async fetchConfigurations(customerNumber) {
        var result = await apiClient.configurationsGET(customerNumber);
        this.context.commit("SET_EMAILCONFIGURATIONS", result.data);
    }
    async updateEmailConfiguration(emailConfigurationRequest) {
        var result = await apiClient.configurationsPOST(authenticationModule.getCustomerId, emailConfigurationRequest);
    }
    async updateModeConfiguration(config) {
        const config2 = new ModeConfigurationRequest();
        config2.headerLogo = config.headerLogo;
        config2.footerLogo = config.footerLogo;
        config2.areas = config.areas;
        config2.subscriptonTypeID = config.subscriptonTypeID;
        config2.welcomeEmailEmplateID = config.welcomeEmailEmplateID;
        config2.features = config.features;
        return apiClient
            .configurationPOST(config.userId, config2)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_modeConfig".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    RESET_HEADERFILE() {
        this.modeHeaderLogo = null;
    }
    SET_HEADERLOGO_FILE(headerLogo) {
        this.modeHeaderLogo = headerLogo;
    }
    SET_FOOTERLOGO_FILE(footerLogo) {
        this.modeFooterLogo = footerLogo;
    }
    SET_EMAILCONFIGURATIONS(emailConfigurations) {
        //zero based index for v-for indexing
        for (var i = 0; i < emailConfigurations.length; i++) {
            let setting = emailConfigurations[i];
            if (setting.emailSendingStateID !== undefined) {
                setting.emailSendingStateID = setting.emailSendingStateID - 1;
                emailConfigurations[i] = setting;
            }
        }
        this.emailConfigurations = emailConfigurations;
    }
    get getModeHeaderLogo() {
        return this.modeHeaderLogo;
    }
    get getModeFooterLogo() {
        return this.modeFooterLogo;
    }
    get getEmailConfigurations() {
        return this.emailConfigurations;
    }
    get getEmailStates() {
        return this.emailStates;
    }
};
__decorate([
    Action({ rawError: true })
], SettingsModule.prototype, "fetchConfigurations", null);
__decorate([
    Action({ rawError: true })
], SettingsModule.prototype, "updateEmailConfiguration", null);
__decorate([
    Action({ rawError: true })
], SettingsModule.prototype, "updateModeConfiguration", null);
__decorate([
    Mutation
], SettingsModule.prototype, "RESET_HEADERFILE", null);
__decorate([
    Mutation
], SettingsModule.prototype, "SET_HEADERLOGO_FILE", null);
__decorate([
    Mutation
], SettingsModule.prototype, "SET_FOOTERLOGO_FILE", null);
__decorate([
    Mutation
], SettingsModule.prototype, "SET_EMAILCONFIGURATIONS", null);
SettingsModule = __decorate([
    Module({ dynamic: true, store, name: "settingsModule", namespaced: true })
], SettingsModule);
export default SettingsModule;
export class UpdateModeconfigRequest {
    constructor(userId, configuration) {
        this.userId = userId;
        this.configuration = configuration;
    }
    userId;
    configuration;
}
export const settingsModule = getModule(SettingsModule);
