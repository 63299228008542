import { __decorate } from "tslib";
import { featureModule } from "@/store/modules/featureModule";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
let SubscriptionHistoryDetails = class SubscriptionHistoryDetails extends Vue {
    details;
    primitiveValues = {};
    areaValues = [];
    mounted() {
        if (!this.details)
            return;
        const areaKeys = ["areas"];
        const primitiveValues = Object.keys(this.details).reduce((acc, key) => {
            if (!areaKeys.includes(key)) {
                acc[key] = this.details[key];
            }
            if (key === "primaryArea") {
                acc[key] = {
                    to: this.details[key].to.name,
                };
            }
            return acc;
        }, {});
        const areaValues = Object.keys(this.details).reduce((acc, key) => {
            if (areaKeys.includes(key)) {
                acc[key] = this.details[key];
            }
            return acc;
        }, {});
        this.primitiveValues = primitiveValues;
        this.areaValues = areaValues.areas;
    }
    get useSubAreas() {
        return featureModule.useSubAreas;
    }
};
__decorate([
    Prop()
], SubscriptionHistoryDetails.prototype, "details", void 0);
SubscriptionHistoryDetails = __decorate([
    Component({
        name: "SubscriptionHistoryDetails",
        components: {},
        methods: {},
    })
], SubscriptionHistoryDetails);
export default SubscriptionHistoryDetails;
