import { __decorate } from "tslib";
import { Component, Mixins, PropSync, Watch } from "vue-property-decorator";
import { Bar, mixins } from "vue-chartjs";
let BarChart = class BarChart extends Mixins(mixins.reactiveProp, Bar) {
    options;
    optionsWatcher(options) {
        this.renderChart(this.chartData, options);
    }
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
};
__decorate([
    PropSync("chartOptions", {})
], BarChart.prototype, "options", void 0);
__decorate([
    Watch("options", { deep: true })
], BarChart.prototype, "optionsWatcher", null);
BarChart = __decorate([
    Component({
        extends: Bar,
        mixins: [mixins.reactiveProp],
    })
], BarChart);
export default BarChart;
