import { __decorate } from "tslib";
import { ModeConfigurationRequest, UserUpdateRequest, } from "@/apiclient/client";
import { maxFileSize, password, passwordMatch, required, } from "@/components/shared/validationRules";
import { areaModule } from "@/store/modules/areaModule";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { featureModule } from "@/store/modules/featureModule";
import { InputType } from "../shared/enums";
import { readUploadedFileAsText } from "@/util/imageconverter";
import { subscriberModule } from "@/store/modules/subscriberModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import { userModule } from "@/store/modules/userModule";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomFileInput from "@/components/shared/CustomFileInput.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import CustomTreeSelect from "../shared/CustomTreeSelect.vue";
import AreaTreeSelect from "@/components/treeSelects/AreaTreeSelect.vue";
import Vue from "vue";
import { ModeEnum } from "@/store/modules/enums";
import router from "@/router";
import { Watch } from "vue-property-decorator";
let Details = class Details extends Vue {
    type = InputType;
    action = Action;
    userId;
    userDetailsFormValid = true;
    newPassword = null;
    newPasswordConfirm = null;
    async paramsWatcher() {
        this.userId = parseInt(this.$route.params.id);
        await userModule.fetchUser(this.userId);
        if (this.userStemData?.subscriberID) {
            subscriberModule.fetchSubscriberById(this.userStemData.subscriberID);
            userModule.fetchAffiliationConfiguration(this.userId);
        }
    }
    useSubareas() {
        return featureModule.useSubAreas;
    }
    onAreasChange(list) {
        this.affiliationConfiguration.areas = list;
    }
    onBackClick() {
        this.$router.back();
    }
    goTo(subscriberId) {
        this.$router.push("/subscribers/" + subscriberId);
    }
    async setHeaderLogo(file) {
        this.$refs.userDetailsForm.validate();
        if (!file)
            return;
        try {
            const fileContents = (await readUploadedFileAsText(file));
            this.affiliationConfiguration.headerLogo = fileContents;
        }
        catch {
            // console.error("Failed to read upload file!");
        }
    }
    async setFooterLogo(file) {
        this.$refs.userDetailsForm.validate();
        if (!file)
            return;
        try {
            const fileContents = (await readUploadedFileAsText(file));
            this.affiliationConfiguration.footerLogo = fileContents;
        }
        catch {
            // console.error("Failed to read upload file!");
        }
    }
    onSaveClick() {
        const isFormValid = this.$refs.userDetailsForm.validate();
        if (!isFormValid) {
            return;
        }
        const user = new UserUpdateRequest();
        user.name = this.userStemData?.name;
        user.accessLevel = this.userStemData?.accessLevel;
        user.password = this.newPassword ? this.newPassword : null;
        userModule
            .updateUser({
            userId: this.userId,
            user: user,
        })
            .finally(() => {
            this.newPassword = null;
            this.newPasswordConfirm = null;
        });
        if (this.affiliationConfiguration &&
            authenticationModule.isSuperAdmin &&
            this.userStemData?.subscriberID) {
            const affiliation = new ModeConfigurationRequest();
            affiliation.areas = this.affiliationConfiguration?.areas
                ? this.affiliationConfiguration.areas
                : [];
            affiliation.features = this.affiliationConfiguration?.features
                ? this.affiliationConfiguration.features
                : [];
            affiliation.welcomeEmailEmplateID = this.affiliationConfiguration?.welcomeEmailEmplateID;
            affiliation.subscriptonTypeID = this.affiliationConfiguration?.subscriptonTypeID;
            affiliation.headerLogo = this.affiliationConfiguration?.headerLogo;
            affiliation.footerLogo = this.affiliationConfiguration?.footerLogo;
            const payload = {
                userId: this.userId,
                affiliationConfiguration: affiliation,
            };
            userModule.updateAffiliationConfiguration(payload);
        }
    }
    getBtnsContainerSize(size) {
        return this.userStemData && this.userStemData.subscriberID && !this.isExternal
            ? size * 2
            : size;
    }
    get isExternal() {
        return authenticationModule.isExternal;
    }
    get isSuperAdmin() {
        return authenticationModule.isSuperAdmin;
    }
    get accessLevels() {
        return userModule.getAccessLevelsOnUpdate;
    }
    get userStemData() {
        return userModule.getUser;
    }
    get subscriber() {
        return subscriberModule.getSubscriber;
    }
    get affiliationConfiguration() {
        return userModule.getAffiliationConfiguration;
    }
    get areas() {
        return areaModule.getAreas;
    }
    get emailTemplates() {
        return emailTemplateModule.getEmailTemplates;
    }
    get subscriptionTypes() {
        return subscriptionModule.getSubscriptionTypes;
    }
    get getFeatures() {
        return featureModule.getLoggedInCustomerFeatures.filter(feature => router
            .getRoutes()
            .find(route => route.meta &&
            route.meta.modes &&
            route.meta.modes.includes(this.userStemData?.modeID) &&
            route.meta.feature &&
            route.meta.feature == feature.featureID));
    }
    get getMultipleSelectionText() {
        return this.affiliationConfiguration?.features &&
            this.affiliationConfiguration.features.length > 0
            ? this.affiliationConfiguration.features[0].feature
            : "";
    }
    get showFeatures() {
        return (this.userStemData?.modeID &&
            (this.userStemData.modeID == parseInt(ModeEnum.Delegate.toString()) ||
                this.userStemData.modeID == parseInt(ModeEnum.ParkAdmin.toString())));
    }
};
__decorate([
    Watch("$route.params", { immediate: true })
], Details.prototype, "paramsWatcher", null);
Details = __decorate([
    Component({
        components: {
            CustomButton,
            CustomFileInput,
            CustomSelect,
            CustomTextField,
            CustomTreeSelect,
            AreaTreeSelect,
        },
        methods: {
            maxFileSize,
            password,
            passwordMatch,
            required,
        },
    })
], Details);
export default Details;
