import moment from "moment";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
function isValidInputDate(date) {
    return /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(date) || "";
}
function utcStringToBrowserLocalTime(date, fromFormat, toFormat) {
    const result = moment
        .utc(date, fromFormat)
        .local()
        .format(toFormat);
    return result;
}
/**
 * format date from yyyy-mm-dd to dd-mm-yyyy
 * @param date
 */
function formatDate(date) {
    /*if(!date) {
          throw new Error('date must be defined');
      }*/
    return date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "";
}
/*function formatDate1(date : string, fromFormat: string, toFormat : string) : string {
    
    return date ? moment(date, fromFormat).format(toFormat) : ''
}*/
/**
 * format from dd-mm-yyyy to yyyy-mm-dd
 * @param date
 * @returns
 */
function formatInputDate(date) {
    return date
        .split("-")
        .reverse()
        .join("-");
}
/**
 * return UTC.now minus 48 hours
 * @returns
 */
function getDepartedBefore() {
    //let utc = dayjs.utc().subtract(48,'hour')
    //@ts-ignore
    return moment
        .utc()
        .subtract(48, "hour")
        .format();
}
/**
 *
 * @param date
 * @param format
 * @returns date as a string in the desired format
 */
function castToUTCtime(date, format) {
    const localtime = moment(date, format);
    return moment.utc(localtime).format();
}
export { utcStringToBrowserLocalTime, formatDate, isValidInputDate, formatInputDate, castToUTCtime, getDepartedBefore, };
