import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { constantsModule } from "@/store/modules/constantsModule";
import { required } from "@/components/shared/validationRules";
import { getStatisticsDate, statisticsModule } from "@/store/modules/statisticsModule";
import Component from "vue-class-component";
import CustomDatePicker from "@/components/shared/CustomDatePicker.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import ExpansionPanel from "@/components/shared/ExpansionPanel.vue";
import moment from "moment";
import CustomTreeSelect from "../shared/CustomTreeSelect.vue";
import AreaTreeSelect from "../treeSelects/AreaTreeSelect.vue";
import Vue from "vue";
import { getAreasFromNodes } from "@/util/area/areautil";
import { Prop } from "vue-property-decorator";
import CustomButton, { Action } from "../shared/CustomButton.vue";
let Filters = class Filters extends Vue {
    isLoading;
    action = Action;
    toDatePicker = null;
    fromDateSync = getStatisticsDate()
        .startOf("month")
        .format();
    toDateSync = getStatisticsDate().format();
    maxDaysBetween = 31;
    maxDay = moment().format();
    async mounted() {
        const preSelectedParkingTypes = constantsModule.getParkingTypes
            .slice()
            .filter(x => x.name === "FreeFlow");
        statisticsModule.SET_PARKING_TYPES_SELECTED(preSelectedParkingTypes);
        const preSelectedParkingSystems = constantsModule.getParkingSystems
            .slice()
            .filter(x => x.name === "EasyParkCameraPark" || x.name === "PRS");
        statisticsModule.SET_PARKING_SYSTEMS_SELECTED(preSelectedParkingSystems);
    }
    handleSearch() {
        this.$emit("on-search-submit", this.fromDate, this.toDate);
    }
    onAreasChange(nodes) {
        const areasSelected = getAreasFromNodes(nodes);
        statisticsModule.SET_AREAS_SELECTED(areasSelected);
        this.$emit("selection-changed", this.fromDate, this.toDate);
    }
    onFromDateChange(date) {
        this.toDatePicker = date;
        statisticsModule.SET_FROM_DATE(moment(date)
            .utc(false)
            .format());
        if (!this.isFromDateBeforeToDate) {
            this.toDateSync = null;
            statisticsModule.SET_TO_DATE(null);
        }
        else {
            this.$emit("selection-changed", this.fromDate, this.toDate);
        }
    }
    onToDateChange(date) {
        statisticsModule.SET_TO_DATE(moment(date)
            .utc(false)
            .format());
        if (!this.isFromDateBeforeToDate) {
            this.fromDateSync = null;
            statisticsModule.SET_FROM_DATE(null);
        }
        else {
            this.$emit("selection-changed", this.fromDate, this.toDate);
        }
    }
    onParkingTypesChange() {
        this.$emit("selection-changed", this.fromDate, this.toDate);
    }
    onParkingSystemsChange() {
        this.$emit("selection-changed", this.fromDate, this.toDate);
    }
    getParkingTypeTranslation(item) {
        return item.parkingTypeTranslations?.find(x => x.languageCode === (this.$i18n.locale == "da" ? 1 : 2))?.translatedName;
    }
    getParkingSystemTranslation(item) {
        return item.parkingSystemTranslations?.find(x => x.languageCode === (this.$i18n.locale == "da" ? 1 : 2))?.translatedName;
    }
    get getParkingTypeMultipleSelectionText() {
        const localeId = this.$i18n.locale == "da" ? 0 : 1;
        return this.parkingTypesSelected.length > 0
            ? this.parkingTypesSelected[0].parkingTypeTranslations[localeId].translatedName
            : undefined;
    }
    get getParkingSystemMultipleSelectionText() {
        const localeId = this.$i18n.locale == "da" ? 0 : 1;
        return this.parkingSystemsSelected.length > 0
            ? this.parkingSystemsSelected[0].parkingSystemTranslations[localeId].translatedName
            : undefined;
    }
    get getAreas() {
        return areaModule.getAreas;
    }
    get areasSelected() {
        return statisticsModule.areasSelected;
    }
    get fromDate() {
        return statisticsModule.getFromDate;
    }
    get toDate() {
        return statisticsModule.getToDate;
    }
    get parkingTypes() {
        return constantsModule.getParkingTypes;
    }
    get parkingSystems() {
        return constantsModule.getParkingSystems;
    }
    get parkingTypesSelected() {
        return statisticsModule.getParkingTypesSelected;
    }
    set parkingTypesSelected(items) {
        statisticsModule.SET_PARKING_TYPES_SELECTED(items);
    }
    get parkingSystemsSelected() {
        return statisticsModule.getParkingSystemsSelected;
    }
    set parkingSystemsSelected(items) {
        statisticsModule.SET_PARKING_SYSTEMS_SELECTED(items);
    }
    get isFromDateBeforeToDate() {
        const startDate = moment(this.fromDate);
        const endDate = moment(this.toDate);
        const days = moment.duration(endDate.diff(startDate)).asDays();
        return days >= 0 ? true : false;
    }
    get isRangeValid() {
        const startDate = moment(this.fromDate);
        const endDate = moment(this.toDate);
        const days = moment.duration(endDate.diff(startDate)).asDays();
        return days <= this.maxDaysBetween ? true : false;
    }
    get fromDateMax() {
        return moment().format("YYYY-MM-DD"); //set current date
    }
    get toDateMax() {
        if (this.fromDate === null)
            return moment().format("YYYY-MM-DD"); //set current date
        if (!moment(this.fromDate)
            .add(this.maxDaysBetween, "days")
            .isBefore(moment()))
            return moment().format("YYYY-MM-DD"); //set current date
        return moment(this.fromDate)
            .add(this.maxDaysBetween - 1, "days")
            .format("YYYY-MM-DD");
    }
};
__decorate([
    Prop({ required: true, type: Boolean })
], Filters.prototype, "isLoading", void 0);
Filters = __decorate([
    Component({
        components: {
            CustomDatePicker,
            CustomSelect,
            ExpansionPanel,
            AreaTreeSelect,
            CustomTreeSelect,
            CustomButton,
        },
        methods: {
            required,
        },
    })
], Filters);
export default Filters;
