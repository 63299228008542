import { __decorate } from "tslib";
import store from "@/store";
import { FeatureToggleRequest } from "@/apiclient/client";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { Criticality, notificationModule } from "./notificationModule";
export class ParkAdminSettingTab {
    title;
    icon;
    accesslevels;
    constructor(title, icon, accesslevels) {
        this.title = title;
        this.icon = icon;
        this.accesslevels = accesslevels;
    }
}
export class ParkadminFeature {
    featureID;
    active;
    header;
    description;
    constructor(featureID, active, header, description) {
        this.featureID = featureID;
        this.active = active;
        this.header = header;
        this.description = description;
    }
}
const featuresArray = [];
function getFeaturesArray(toggles, features) {
    return toggles.map(t => new ParkadminFeature(t.featureID, t.active, t.feature, t.description));
}
let FeatureModule = class FeatureModule extends VuexModule {
    features = [];
    serverFeatures = [];
    selectedCustomer = null;
    loggedInCustomerFeatures = [];
    tabResetCount = 0;
    async fetchFeatures(customerId) {
        if (customerId) {
            return apiClient
                .featuresGET(customerId)
                .then(response => {
                this.context.commit("SET_LOGGEDIN_CUSTOMER_FEATURES", response.data);
            })
                .catch(() => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
        else {
            return apiClient
                .featuresGET(this.selectedCustomer?.costumerID)
                .then(response => {
                const result = response.data;
                if (result) {
                    this.context.commit("SET_FEATURES", getFeaturesArray(result, featuresArray));
                }
            })
                .catch(() => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
    }
    async updateFeature(parkadminFeature) {
        const toggle = new FeatureToggleRequest();
        toggle.active = parkadminFeature.active;
        return apiClient
            .featuresPUT(this.selectedCustomer?.costumerID, parkadminFeature.featureID, toggle)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_feature".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    INCREMENT_RESET_COUNT() {
        this.tabResetCount++;
    }
    SET_SELECTED_COSTUMER(costumer) {
        this.selectedCustomer = costumer;
    }
    SET_FEATURES(features) {
        this.features = features;
    }
    SET_LOGGEDIN_CUSTOMER_FEATURES(features) {
        this.loggedInCustomerFeatures = features;
    }
    get getTabResetCount() {
        return this.tabResetCount;
    }
    get getLoggedInCustomerFeatures() {
        return this.loggedInCustomerFeatures;
    }
    get getFeatures() {
        return this.features;
    }
    get getSelectedCustomer() {
        return this.selectedCustomer;
    }
    get useSubAreas() {
        return this.loggedInCustomerFeatures.some(s => s.featureID === 9 && s.active);
    }
    get getIsChargeModuleFeatureEnabled() {
        return this.loggedInCustomerFeatures.some(feature => feature.featureID === 6 && feature.active);
    }
    get getActiveCustomerFeatures() {
        return this.loggedInCustomerFeatures.filter(feature => feature.active);
    }
};
__decorate([
    Action({ rawError: true })
], FeatureModule.prototype, "fetchFeatures", null);
__decorate([
    Action({ rawError: true })
], FeatureModule.prototype, "updateFeature", null);
__decorate([
    Mutation
], FeatureModule.prototype, "INCREMENT_RESET_COUNT", null);
__decorate([
    Mutation
], FeatureModule.prototype, "SET_SELECTED_COSTUMER", null);
__decorate([
    Mutation
], FeatureModule.prototype, "SET_FEATURES", null);
__decorate([
    Mutation
], FeatureModule.prototype, "SET_LOGGEDIN_CUSTOMER_FEATURES", null);
FeatureModule = __decorate([
    Module({ dynamic: true, store, name: "featureModule" })
], FeatureModule);
export default FeatureModule;
export const featureModule = getModule(FeatureModule);
