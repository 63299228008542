import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import Vue from "vue";
let CardActions = class CardActions extends Vue {
    action = Action;
    formValid;
    confirmBtnText;
};
__decorate([
    Prop()
], CardActions.prototype, "formValid", void 0);
__decorate([
    Prop()
], CardActions.prototype, "confirmBtnText", void 0);
CardActions = __decorate([
    Component({
        components: {
            CustomButton,
        },
    })
], CardActions);
export default CardActions;
