import { __decorate } from "tslib";
import { clearValidation } from "@/util/forms/formutils";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { required } from "@/components/shared/validationRules";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDateTimePicker from "@/components/shared/CustomDateTimePicker.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import moment from "moment";
import Vue from "vue";
let SetParkingExitDialog = class SetParkingExitDialog extends Vue {
    type = InputType;
    action = Action;
    dateRef;
    date;
    cancelText;
    confirmText;
    editPlate;
    model;
    title;
    validationCount;
    plateformValid = false;
    dateformValid = true;
    originalDate = null;
    originalTime = null;
    minExitDate = null;
    minExitTime = null;
    onHide(val) {
        if (!val) {
            this.model.time = null;
        }
        else {
            const date = moment(this.model.date).format("YYYY-MM-DD");
            const time = moment(this.model.date).format("HH:mm");
            this.originalDate = date;
            this.originalTime = time;
            this.minExitDate = date;
            this.minExitTime = time;
        }
        this.$refs.dateRef?.reset();
    }
    onCountChanged() {
        clearValidation(this.$refs.dateRef);
    }
    onDepartureDateChange(e) {
        if (!e.includes("-"))
            return;
        if (moment(this.originalDate).isSame(e)) {
            this.model.time = null;
            this.minExitTime = this.originalTime;
        }
        else {
            this.minExitTime = null;
        }
    }
    close() {
        this.$refs.dateRef.reset();
        this.$emit("dialog-closed");
    }
    save() {
        if (!this.dateformValid) {
            this.$refs.dateRef.validate();
            return;
        }
        if (this.editPlate) {
            this.$emit("plate-committed");
        }
        else {
            this.$emit("date-committed");
        }
    }
    get licensePlate() {
        return this.model.licensePlate ? this.model.licensePlate.toUpperCase() : undefined;
    }
    set licensePlate(licensePlate) {
        this.model.licensePlate = licensePlate;
    }
    get getIsDateFormValid() {
        return this.model.time !== null;
    }
};
__decorate([
    Ref("dateRef")
], SetParkingExitDialog.prototype, "dateRef", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "date", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "cancelText", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "confirmText", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "editPlate", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "model", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "title", void 0);
__decorate([
    Prop()
], SetParkingExitDialog.prototype, "validationCount", void 0);
__decorate([
    Watch("model.visible", { immediate: true, deep: true })
], SetParkingExitDialog.prototype, "onHide", null);
__decorate([
    Watch("validationCount")
], SetParkingExitDialog.prototype, "onCountChanged", null);
SetParkingExitDialog = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDateTimePicker,
            CustomDialog,
            CustomTextField,
        },
        methods: {
            required,
        },
    })
], SetParkingExitDialog);
export default SetParkingExitDialog;
