import { __decorate } from "tslib";
import { AreaResponse } from "@/apiclient/client";
import { emailValid, clearValidation } from "@/util/forms/formutils";
import { areaModule } from "@/store/modules/areaModule";
import { Component, Prop, Watch } from "vue-property-decorator";
import { featureModule } from "@/store/modules/featureModule";
import { InputType } from "@/components/shared/enums";
import { modeModule } from "@/store/modules/modeModule";
import { required } from "@/components/shared/validationRules";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import CustomDatePicker from "@/components/shared/CustomDatePicker.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import CustomTreeSelect from "../shared/CustomTreeSelect.vue";
import AreaTreeSelect from "@/components/treeSelects/AreaTreeSelect.vue";
import Vue from "vue";
import moment from "moment";
let SubscriptionForm = class SubscriptionForm extends Vue {
    showSubscriptionType;
    value;
    editMode;
    formTitle;
    formResetCount;
    type = InputType;
    valid = false;
    qrBtnDisabled = false;
    loading = false;
    oldAreas = new Array();
    fromPickerDate = null;
    toPickerDate = null;
    mounted() {
        clearValidation(this.$refs.subscriptionform);
        if (modeModule.isDelegateLogin) {
            modeModule.fetchConfiguration();
        }
        else {
            subscriptionModule.fetchSubscriptionTypes();
        }
    }
    onCountChanged(count) {
        this.qrBtnDisabled = false;
        if (!this.editMode) {
            this.value.recieveNewsLetter = false;
            this.value.areas = [];
        }
        clearValidation(this.$refs.subscriptionform);
    }
    onValueChanged(value) {
        this.oldAreas = this.value.areas || new Array();
        if (!this.editMode) {
            this.value.recieveNewsLetter = false;
        }
        if (!value.fromDate && !value.toDate) {
            this.fromPickerDate = moment().format("YYYY-MM-DD");
            this.toPickerDate = moment().format("YYYY-MM-DD");
            return;
        }
        if (!value.toDate && value.fromDate) {
            this.toPickerDate = value.fromDate;
            return;
        }
        if (value.toDate) {
            this.toPickerDate = value.toDate;
            return;
        }
    }
    onFromDateChanged(date) {
        this.value.fromDate = moment(date)
            .utc(false)
            .format();
    }
    onToDateChanged(date) {
        this.value.toDate = moment(date)
            .utc(false)
            .format();
    }
    onValidChanged(valid) {
        this.$emit("form-valid", this.valid);
    }
    onAreasChange(areas) {
        this.value.areas = areas;
    }
    get primaryAreaId() {
        return this.value.primaryArea?.id ?? null;
    }
    set primaryAreaId(id) {
        if (this.value.primaryArea === undefined)
            this.value.primaryArea = new AreaResponse();
        this.value.primaryArea.id = id;
    }
    get getNewsPaperSwitchLabel() {
        return this.value.recieveNewsLetter
            ? this.$tc("receivesnewsletter", 1)
            : this.$tc("receivesnewsletter", 2);
    }
    get subscriptionAreas() {
        return this.value.areas || [];
    }
    set subscriptionAreas(areas) {
        this.$emit("update-areas", { new: areas, old: this.getOldAreas });
        this.value.areas = areas;
    }
    get getOldAreas() {
        return this.oldAreas ? this.oldAreas : [];
    }
    get getSubscriptionTypes() {
        return subscriptionModule.getSubscriptionTypes;
    }
    get switchLabel() {
        return this.value.active ? this.$t("active") : this.$t("inactive");
    }
    get getAreas() {
        return areaModule.getAreas;
    }
    get useSubareas() {
        return featureModule.useSubAreas;
    }
    get getKey() {
        return this.value.id ?? this.uuidv4();
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    }
};
__decorate([
    Prop({ default: true })
], SubscriptionForm.prototype, "showSubscriptionType", void 0);
__decorate([
    Prop()
], SubscriptionForm.prototype, "value", void 0);
__decorate([
    Prop()
], SubscriptionForm.prototype, "editMode", void 0);
__decorate([
    Prop({ default: "" })
], SubscriptionForm.prototype, "formTitle", void 0);
__decorate([
    Prop({ default: 1 })
], SubscriptionForm.prototype, "formResetCount", void 0);
__decorate([
    Watch("formResetCount")
], SubscriptionForm.prototype, "onCountChanged", null);
__decorate([
    Watch("value", { immediate: true, deep: true })
], SubscriptionForm.prototype, "onValueChanged", null);
__decorate([
    Watch("valid")
], SubscriptionForm.prototype, "onValidChanged", null);
SubscriptionForm = __decorate([
    Component({
        components: {
            CustomDatePicker,
            CustomSelect,
            CustomTextField,
            CustomTreeSelect,
            AreaTreeSelect,
        },
        methods: {
            emailValid,
            required,
            moment,
        },
    })
], SubscriptionForm);
export default SubscriptionForm;
