import { __decorate } from "tslib";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
export var Criticality;
(function (Criticality) {
    Criticality[Criticality["Success"] = 0] = "Success";
    Criticality[Criticality["Warning"] = 1] = "Warning";
    Criticality[Criticality["Error"] = 2] = "Error";
})(Criticality || (Criticality = {}));
let NotificationModule = class NotificationModule extends VuexModule {
    show = false;
    message = "";
    criticality;
    timeout;
    SET_SHOW(show) {
        this.show = show;
    }
    SHOW_NOTIFICATION(payload) {
        this.show = true;
        this.message = payload.message;
        this.criticality = payload.criticality;
        this.timeout = payload.timeout;
    }
    get getMessage() {
        return this.message;
    }
    get getCriticality() {
        return this.criticality;
    }
    get getTimeout() {
        return this.timeout;
    }
};
__decorate([
    Mutation
], NotificationModule.prototype, "SET_SHOW", null);
__decorate([
    Mutation
], NotificationModule.prototype, "SHOW_NOTIFICATION", null);
NotificationModule = __decorate([
    Module({ dynamic: true, store, name: "notificationModule" })
], NotificationModule);
export const notificationModule = getModule(NotificationModule);
