<template>
  <v-card>
    <v-card-title>
      {{ $tc("car", 2) }}
    </v-card-title>
    <v-data-table
      :loading="getLoadingStatus"
      :server-items-length="getCount"
      @update:page="updatePagination"
      @update:options="updateOptions"
      :headers="getHeaders"
      :items="getCarList"
      :search="search"
      item-key="id"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [10, 25, 100],
        'items-per-page-text': 'rows_per_page'.t(),
      }"
    >
      <template v-slot:[`footer.page-text`]="items"> {{ items.pageStart }} - {{ items.pageStop }} {{ "table_footer_page_text".t() }} {{ items.itemsLength }} </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <CustomButton v-if="isAllowedToAddCars" :text="'button_create'.t()" :action="action.Primary" @on-button-click="dialog = true" />
          <CustomDialog :show.sync="dialog" :title="formTitle" :persistent="false">
            <template v-slot:content>
              <v-form v-model="carFormValid" ref="carForm">
                <v-row>
                  <v-col cols="6">
                    <CustomTextField :text.sync="editedItem.name" :label="$t('carname')" :type="type.Text" />
                  </v-col>
                  <v-col cols="6">
                    <CustomTextField :text.sync="editedItem.licensePlate" :label="$t('numberplate')" :type="type.Text" :rules="[required]" />
                  </v-col>
                </v-row>
              </v-form>
            </template>

            <template v-slot:actions>
              <CustomButton
                :text="'button_cancel'.t()"
                :action="action.Cancel"
                @on-button-click="
                  $refs.carForm.reset();
                  dialog = false;
                "
              />

              <CustomButton :text="'button_save'.t()" :action="action.Primary" @on-button-click="save" />
            </template>
          </CustomDialog>

          <ConfirmPrompt
            v-model="dialogDelete"
            :title="`${$tc('delete', 1)} ${editedItem.licensePlate}?`"
            :persistent="false"
            @state-changed="dialogDelete = $event"
            @dialog-confirmed="deleteItemConfirm()"
            @dialog-close="closeDelete()"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <CustomButton icon="mdi-pencil" :action="action.Primary" @on-button-click="editItem(item)" />

        <CustomButton v-show="showdeleteicon" icon="mdi-delete" :action="action.Delete" @on-button-click="deleteItem(item)" />
      </template>

      <template v-slot:no-data> </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { editItem, deleteItem, closeDelete, close } from "@/helperfunctions/listhelpers.js";
import { InputType } from "@/components/shared/enums";
import { mapGetters } from "vuex";
import { required } from "@/components/shared/validationRules";
import { stepperModule } from "@/store/modules/stepperModule";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";

export default {
  props: {
    singleSubscriptionMode: null,
    subscription: null,
    showdeleteicon: Boolean,
  },
  data() {
    return {
      type: InputType,
      action: Action,
      carFormValid: true,
      pageNumber: 1,
      sortBy: null,
      sortByDesc: null,
      awaitingSearch: false,
      pageSize: 10,
      subscriptionId: null,
      expanded: [],
      search: "",
      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    };
  },
  components: {
    ConfirmPrompt,
    CustomButton,
    CustomDialog,
    CustomTextField,
  },
  watch: {
    dialog: function() {
      if (!this.dialog) {
        this.editedIndex = -1;
      }
    },
    search: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          if (this.singleSubscriptionMode) {
            this.$store.dispatch("carModule/fetchCarsBySubscriptionId", this.getSearchParams);
          } else {
            this.$store.dispatch("carModule/fetchCars", this.getSearchParams);
          }
          this.awaitingSearch = false;
        }, 400); // 0.4 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    isAllowedToAddCars() {
      return this.$route.params.id > 0 || this.singleSubscriptionMode;
    },

    hasSubscriptionId() {
      return this.$route.params.id > 0;
    },
    getSubscriptionId() {
      return this.subscriptionId || this.$route.params.id;
    },
    getSearchParams() {
      return {
        subscriptionID: this.getSubscriptionId,
        pageNumber: this.pageNumber,
        search: this.search,
        sortBy: this.sortBy,
        pageSize: this.pageSize,
        sortOrderAsc: this.sortByDesc,
        includeInactive: this.includeInactive,
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t("createnew") : this.$t("edit");
    },
    getCarList() {
      return stepperModule.getCars;
    },
    getHeaders() {
      return [
        { text: "carname".t(), value: "name" },
        { text: this.$t("numberplate"), value: "licensePlate" },
        { text: "actions".t(), value: "actions", sortable: false },
      ];
    },

    getCount() {
      return stepperModule.getCarCount;
    },

    getLoadingStatus() {
      return stepperModule.getLoadingStatus;
    },
    ...mapGetters({
      getStepperCars: "stepperModule/getCars",
      getSubscriptions: "subscriptionModule/getSubscriptions",
    }),
  },
  methods: {
    required,
    editItem,

    deleteItem,

    deleteItemConfirm() {
      /*if(this.subscription && this.singleSubscriptionMode) {
          this.$emit('remove-car', this.editedItem);
          this.$store.dispatch('stepperModule/removeCarFromSubscription', this.editedItem);
        }else {
          this.$store.dispatch('carModule/deleteCar', this.editedItem)
          .then(response => {
            this.$store.dispatch('carModule/fetchCars',this.getSearchParams);

          });

        }*/
      stepperModule.removeCarFromSubscription(this.editedItem);
      this.closeDelete();
    },

    close,

    closeDelete,
    updateOptions(options) {
      this.pageNumber = options.page;
      this.sortByDesc = options.sortDesc[0];
      this.sortBy = options.sortBy[0];
      this.itemsPerPage = options.itemsPerPage;
      if (this.singleSubscriptionMode || this.hasSubscriptionId) {
        //this.$store.dispatch('carModule/fetchCarsBySubscriptionId',this.getSearchParams);
      } else {
        //this.$store.dispatch('carModule/fetchCars',this.getSearchParams);
      }
    },
    updatePagination(pagination) {
      this.page = pagination;
    },

    async save() {
      this.$refs.carForm.validate();

      if (!this.carFormValid) {
        return;
      }

      if (this.subscription && this.singleSubscriptionMode) {
        //update
        if (this.editedIndex > -1 || !this.editedIndex) {
          this.$emit("car-updated", this.editedItem);
        } else {
          //create
          this.$emit("car-created", this.editedItem);
        }
      } else {
        if (this.editedIndex > -1) {
          //update
          //this.$store.dispatch('carModule/updateCar', this.editedItem);
        } else {
          //create
          let payload = {
            subscriptionId: this.getSubscriptionId,
            car: this.editedItem,
          };
          //this.$store.dispatch('carModule/createCarForSubscription', payload);
        }

        if (this.singleSubscriptionMode || this.hasSubscriptionId) {
          //this.$store.dispatch('carModule/fetchCarsBySubscriptionId',this.getSearchParams);
        } else {
          //this.$store.dispatch('carModule/fetchCars',this.getSearchParams);
        }
      }

      this.close();
    },
  },
  created() {},
};
</script>
