import { __decorate } from "tslib";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Vue from "vue";
let CustomDialogExamples = class CustomDialogExamples extends Vue {
    action = Action;
    showDialog = false;
    onCancelClick() {
        this.showDialog = false;
    }
    onSaveClick() {
        // console.log("save");
        this.showDialog = false;
    }
};
CustomDialogExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
        },
        methods: {},
    })
], CustomDialogExamples);
export default CustomDialogExamples;
