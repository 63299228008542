import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
export var Action;
(function (Action) {
    Action[Action["Primary"] = 0] = "Primary";
    Action[Action["Normal"] = 1] = "Normal";
    Action[Action["Delete"] = 2] = "Delete";
    Action[Action["Cancel"] = 3] = "Cancel";
    Action[Action["Back"] = 4] = "Back";
    Action[Action["Export"] = 5] = "Export";
})(Action || (Action = {}));
let CustomButton = class CustomButton extends Vue {
    text;
    icon;
    action;
    disabled;
    isLoading;
    type;
    width;
    actionEnum = Action;
    onClick(e) {
        this.$emit("on-button-click", e);
    }
    get showIconOnly() {
        return this.text === undefined && this.icon !== undefined;
    }
    get showIconAndText() {
        return this.text !== undefined && this.icon !== undefined;
    }
    get color() {
        switch (this.action) {
            case Action.Primary:
            case Action.Cancel:
            case Action.Back:
            case Action.Export:
                return "primary";
            case Action.Delete:
                return "red";
            default:
                return "";
        }
    }
    get textLook() {
        switch (this.action) {
            case Action.Cancel:
            case Action.Back:
                return false;
            default:
                return false;
        }
    }
    get outlined() {
        switch (this.action) {
            case Action.Cancel:
            case Action.Back:
            case Action.Export:
                return true;
            default:
                return false;
        }
    }
    get dark() {
        if (this.disabled)
            return false;
        switch (this.action) {
            case Action.Delete:
            case Action.Cancel:
            case Action.Export:
                return true;
            default:
                return false;
        }
    }
};
__decorate([
    Prop({ required: false, type: String })
], CustomButton.prototype, "text", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomButton.prototype, "icon", void 0);
__decorate([
    Prop({ required: true })
], CustomButton.prototype, "action", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomButton.prototype, "disabled", void 0);
__decorate([
    Prop({ required: false, type: Boolean, default: false })
], CustomButton.prototype, "isLoading", void 0);
__decorate([
    Prop({ required: false, type: String, default: "button" })
], CustomButton.prototype, "type", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomButton.prototype, "width", void 0);
CustomButton = __decorate([
    Component
], CustomButton);
export default CustomButton;
