import { __decorate } from "tslib";
import { CarUpdateRequest } from "@/apiclient/client";
import { notificationModule, Criticality } from "@/store/modules/notificationModule";
import { carModule } from "@/store/modules/carModule";
import { Component, Prop } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { required } from "@/components/shared/validationRules";
import { UpdateCarRequest } from "@/util/storerequest/subscriptionCarRequest";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import ListBase from "@/util/mixins/listbase";
import SubscriptionCarHistory from "../subscription/SubscriptionCarHistory.vue";
import { authenticationModule } from "@/store/modules/authenticationModule";
let CarSubList = class CarSubList extends ListBase {
    type = InputType;
    action = Action;
    subscription;
    carFormValid = true;
    showHistory = false;
    deleteItemConfirm() {
        carModule
            .deleteCar(new UpdateCarRequest(this.editedItem, this.subscription.id, this.editedItem.id))
            .then(() => {
            this.$emit("refetch");
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
        this.closeDelete();
    }
    save() {
        const isFormValid = this.$refs.carForm.validate();
        if (!isFormValid) {
            return;
        }
        if (this.editing) {
            let update = new CarUpdateRequest();
            update.name = this.editedItem.name;
            carModule
                .updateCar(new UpdateCarRequest(update, this.subscription.id, this.editedItem.id))
                .then(() => {
                this.$emit("refetch");
            })
                .catch(() => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
        else {
            let payload = {
                subscriptionId: this.subscription.id,
                car: this.editedItem,
                showStatus: true,
            };
            carModule
                .createSubscriptionCar(payload)
                .then(() => {
                this.$emit("refetch");
            })
                .catch(() => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
        this.close();
    }
    get formTitle() {
        return this.editing ? this.$t("edit") + " " + this.editedItemName : this.$t("createnew");
    }
    get getHeaders() {
        var headers = [
            { text: "carname".t(), value: "name" },
            { text: "numberplate".t(), value: "licensePlate" },
            { text: "actions".t(), value: "actions", sortable: false },
        ];
        return headers;
    }
    get getHeader() {
        return this.subscription.name ? this.subscription.name : "";
    }
    get editedItemName() {
        if (this.editedItem) {
            return this.editedItem.name
                ? this.editedItem.name + " " + this.editedItem.licensePlate
                : this.editedItem.licensePlate;
        }
        return "";
    }
    get showHistoryBtn() {
        return authenticationModule.isAdmin || authenticationModule.isSuperAdmin;
    }
};
__decorate([
    Prop()
], CarSubList.prototype, "subscription", void 0);
CarSubList = __decorate([
    Component({
        components: {
            ConfirmPrompt,
            CustomButton,
            CustomDialog,
            CustomTextField,
            SubscriptionCarHistory,
        },
        methods: {
            required,
        },
    })
], CarSubList);
export default CarSubList;
