import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { apiClient } from "@/apiclient/baseclient";
let SessionModule = class SessionModule extends VuexModule {
    showSessionPrompt = false;
    get getShowSEssionPrompt() {
        return this.showSessionPrompt;
    }
    async pingServer() {
        return apiClient.ping();
    }
    SET_SHOW_SESSION_PROMPT(showSessionPrompt) {
        this.showSessionPrompt = showSessionPrompt;
    }
};
__decorate([
    Action({ rawError: true })
], SessionModule.prototype, "pingServer", null);
__decorate([
    Mutation
], SessionModule.prototype, "SET_SHOW_SESSION_PROMPT", null);
SessionModule = __decorate([
    Module({ dynamic: true, store, name: "sessionModule" })
], SessionModule);
export default SessionModule;
export const sessionModule = getModule(SessionModule);
