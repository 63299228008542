import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/util/date/dateutil';
let SubscriptionPreview = class SubscriptionPreview extends Vue {
    entity;
    areas;
    getPriaryAreaName() {
        return '';
    }
    get switchLabel() {
        return this.entity.active ? "Aktiv" : "Inaktiv";
    }
    get getNewsPaperSwitchLabel() {
        return this.entity.recieveNewsLetter ? this.$tc('receivesnewsletter', 1) : this.$tc('receivesnewsletter', 2);
    }
};
__decorate([
    Prop()
], SubscriptionPreview.prototype, "entity", void 0);
__decorate([
    Prop()
], SubscriptionPreview.prototype, "areas", void 0);
SubscriptionPreview = __decorate([
    Component({
        components: {},
        methods: {
            formatDate
        },
    })
], SubscriptionPreview);
export default SubscriptionPreview;
