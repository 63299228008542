import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { Component } from "vue-property-decorator";
import { featureModule } from "@/store/modules/featureModule";
import { parkingModule } from "@/store/modules/parkingModule";
import { InputType } from "@/components/shared/enums";
import { prsTagModule } from "@/store/modules/prsTagModule";
import { required } from "@/components/shared/validationRules";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDateTimePicker from "@/components/shared/CustomDateTimePicker.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import ExpansionPanel from "@/components/shared/ExpansionPanel.vue";
import TreeSelect from "@/components/shared/TreeSelect.vue";
import Vue from "vue";
let InList = class InList extends Vue {
    type = InputType;
    action = Action;
    createParkingFormValid = true;
    showCreateParkingDialog = false;
    createParking = {
        licensePlate: null,
        start: null,
        area: [],
    };
    setDepartureFormValid = true;
    showDepartureDialog = false;
    departure = null;
    showPaymentDialog = false;
    payment = {
        area: "Logos plads",
        licensePlate: "AB12345",
        start: "2022-01-02 12:22",
        stop: "2022-01-02 14:44",
        price: (235.0).toFixed(2),
    };
    useSubareas() {
        return featureModule.useSubAreas;
    }
    onAreasChange(areasSelected) {
        if (areasSelected.length === 0)
            return;
        parkingModule.SET_AREAS_SELECTED(areasSelected);
    }
    onPrsTagsChange() { }
    onCancelParking() {
        this.showCreateParkingDialog = false;
        this.$refs.createParkingForm.reset();
    }
    onSaveParking() {
        this.$refs.createParkingForm.validate();
    }
    onCancelDeparture() {
        this.showDepartureDialog = false;
        this.$refs.setDepartureForm.reset();
    }
    onSaveDeparture() {
        this.$refs.setDepartureForm.validate();
    }
    onDeleteParking(item) { }
    onDepartParking(item) {
        this.showDepartureDialog = true;
    }
    onPayParking(item) {
        this.showPaymentDialog = true;
    }
    onCashPayment() { }
    onCancelPayment() {
        this.showPaymentDialog = false;
    }
    get headers() {
        return [
            {
                text: "licenseplate".tc(2),
                align: "start",
                sortable: true,
                value: "licensePlate",
            },
            { text: "parkingStart".t(), value: "start" },
            { text: "validUntil".t(), value: "validUntil" },
            { text: "area".tc(1), value: "area" },
            { text: "subArea".t(), value: "subArea" },
            { text: "sourceOfParking".t(), value: "sourceOfParking" },
            { text: "typeOfParking".t(), value: "typeOfParking" },
            { text: "actions".t(), value: "actions" },
        ];
    }
    get areasSelected() {
        return parkingModule.areasSelected;
    }
    get getAreas() {
        return areaModule.getAreas;
    }
    get getPrsTags() {
        return prsTagModule.getPrsTags;
    }
    get prsTagsSelected() {
        return parkingModule.prsTagsSelected;
    }
    set prsTagsSelected(prsTagsSelected) {
        parkingModule.SET_PRS_TAGS_SELECTED(prsTagsSelected);
    }
    get getInList() {
        return parkingModule.getInList;
    }
};
InList = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDateTimePicker,
            CustomDialog,
            CustomSelect,
            CustomTextField,
            ExpansionPanel,
            TreeSelect,
        },
        methods: {
            required,
        },
    })
], InList);
export default InList;
