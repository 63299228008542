import { __decorate } from "tslib";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import Vue from "vue";
let CustomButtonExamples = class CustomButtonExamples extends Vue {
    action = Action;
    onButtonClick(e) {
        // console.log("onButtonClick: " + e.target.textContent);
    }
};
CustomButtonExamples = __decorate([
    Component({
        components: {
            CustomButton,
        },
        methods: {},
    })
], CustomButtonExamples);
export default CustomButtonExamples;
