import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import { EmailTemplateType } from "@/apiclient/client";
import { apiClient } from "@/apiclient/baseclient";
import { notificationModule, Criticality } from "./notificationModule";
export class EmailTemplateTypeVal {
    title;
    emailTemplateTypeID;
    enumVal;
    constructor(title, emailTemplateTypeID, enumVal) {
        this.title = title;
        this.emailTemplateTypeID = emailTemplateTypeID;
        this.enumVal = enumVal;
    }
}
const types = [
    new EmailTemplateTypeVal("emailTemplateTypeResendQr", 3, EmailTemplateType._3),
    new EmailTemplateTypeVal("emailTemplateTypeEdit", 2, EmailTemplateType._2),
    new EmailTemplateTypeVal("emailTemplateTypeWelcome", 1, EmailTemplateType._1),
];
let EmailModule = class EmailModule extends VuexModule {
    count = 1;
    emails = new Array();
    get emailTemplateTypeValues() {
        return types;
    }
    get getCount() {
        return this.count;
    }
    get getEmails() {
        return this.emails;
    }
    async fetchPendingEmails(request) {
        let result = await apiClient.pendingGET(request.customerId, request.listRequest.pageNumber, request.listRequest.pageSize, request.listRequest.sortOrderAsc, request.listRequest.orderBy, request.listRequest.search);
        this.context.commit("SET_PENDING_EMAILS", result.data);
        this.context.commit("SET_COUNT", result.totalRecords);
    }
    async updateEmail(email) { }
    async deleteEmail(request) {
        return await apiClient
            .pendingDELETE(request.customerId, request.emailId)
            .then(() => { })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_COUNT(count) {
        this.count = count;
    }
    SET_PENDING_EMAILS(emails) {
        this.emails = emails;
    }
};
__decorate([
    Action({ rawError: true })
], EmailModule.prototype, "fetchPendingEmails", null);
__decorate([
    Action({ rawError: true })
], EmailModule.prototype, "updateEmail", null);
__decorate([
    Action({ rawError: true })
], EmailModule.prototype, "deleteEmail", null);
__decorate([
    Mutation
], EmailModule.prototype, "SET_COUNT", null);
__decorate([
    Mutation
], EmailModule.prototype, "SET_PENDING_EMAILS", null);
EmailModule = __decorate([
    Module({ dynamic: true, store, name: "emailsModule", namespaced: true })
], EmailModule);
export default EmailModule;
export const emailModule = getModule(EmailModule);
