import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { ParkingQueryRequest, PrsTagRequest, } from "@/apiclient/client";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
import ListRequest from "@/util/storerequest/ListRequest";
import store from "@/store/index";
import { getDepartedBefore } from "@/util/date/dateutil";
let ChargeModule = class ChargeModule extends VuexModule {
    isLoading = false;
    filterOptions = {
        areasSelected: [],
        tagsSelected: {
            inclusionTags: [],
            exclusionTags: [],
            acceptanceTags: [],
        },
        licensePlate: "",
        fromDate: undefined,
        toDate: undefined,
    };
    hasSetTags = false;
    tableOptions = new ListRequest(1, 10);
    unpaidParkings = new Array();
    serverItemsCount = 0;
    batchSelection = [];
    async fetchUnpaidParkings(ignoreTags) {
        this.context.commit("SET_IS_LOADING", true);
        const query = new ParkingQueryRequest({
            areas: this.filterOptions.areasSelected,
            inclusionTags: ignoreTags ? [] : this.filterOptions.tagsSelected.inclusionTags,
            exclusionTags: ignoreTags ? [] : this.filterOptions.tagsSelected.exclusionTags,
            acceptanceTags: ignoreTags ? [] : this.filterOptions.tagsSelected.acceptanceTags,
            from: ignoreTags ? undefined : this.filterOptions.fromDate,
            to: ignoreTags ? undefined : this.filterOptions.toDate,
            vehicleIdent: this.filterOptions.licensePlate,
        });
        const departedBefore = getDepartedBefore();
        return apiClient
            .unpaidPOST(authenticationModule.getCustomerId, this.getTableOptions.pageNumber, this.getTableOptions.pageSize, this.getTableOptions.sortOrderAsc, this.getTableOptions.orderBy, this.filterOptions.fromDate && this.filterOptions.toDate ? undefined : departedBefore, query)
            .then(response => {
            this.context.commit("SET_UNPAID_PARKINGS", response.data);
            if (response.data?.length === 0 &&
                this.getTableOptions.pageNumber &&
                this.getTableOptions.pageNumber !== 1) {
                this.tableOptions.pageNumber = this.tableOptions.pageNumber - 1;
                this.fetchUnpaidParkings();
            }
            this.context.commit("SET_SERVER_ITEMS_COUNT", response.totalRecords);
            this.context.commit("SET_BATCH_SELECTION", []);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    async deleteUnpaidParking(payload) {
        this.context.commit("SET_IS_LOADING", true);
        return apiClient
            .parking(payload.costumerID, payload.parkingRightID)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_parking".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    SET_IS_LOADING(isLoading) {
        this.isLoading = isLoading;
    }
    SET_AREAS_SELECTED(areasSelected) {
        this.filterOptions.areasSelected = areasSelected;
    }
    SET_PRS_TAGS_SELECTED(prsTagsSelected) {
        const selectedTags = {
            inclusionTags: prsTagsSelected.inclusionTags.map(tag => {
                return Object.assign(Object.create(PrsTagRequest.prototype), {
                    ...tag,
                });
            }),
            exclusionTags: prsTagsSelected.exclusionTags.map(tag => {
                return Object.assign(Object.create(PrsTagRequest.prototype), {
                    ...tag,
                });
            }),
            acceptanceTags: prsTagsSelected.acceptanceTags.map(tag => {
                return Object.assign(Object.create(PrsTagRequest.prototype), {
                    ...tag,
                });
            }),
        };
        this.filterOptions.tagsSelected = selectedTags;
        this.hasSetTags = true;
    }
    SET_TABLE_OPTIONS(tableOptions) {
        this.tableOptions = tableOptions;
    }
    SET_UNPAID_PARKINGS(unpaidParkings) {
        this.unpaidParkings = unpaidParkings;
    }
    SET_SERVER_ITEMS_COUNT(serverItemsCount) {
        this.serverItemsCount = serverItemsCount;
    }
    SET_BATCH_SELECTION(batchSelection) {
        this.batchSelection = batchSelection;
    }
    SET_LICENSE_PLATE(licensePlate) {
        this.filterOptions.licensePlate = licensePlate;
    }
    SET_FROM_DATE(fromDate) {
        this.filterOptions.fromDate = fromDate;
    }
    SET_TO_DATE(toDate) {
        this.filterOptions.toDate = toDate;
    }
    RESET_STATE() {
        this.isLoading = false;
        this.tableOptions = new ListRequest(1, 10);
        this.unpaidParkings = new Array();
        this.serverItemsCount = 0;
        this.hasSetTags = false;
        this.filterOptions = {
            areasSelected: [],
            tagsSelected: {
                inclusionTags: [],
                exclusionTags: [],
                acceptanceTags: [],
            },
            licensePlate: "",
            fromDate: undefined,
            toDate: undefined,
        };
    }
    get getIsLoading() {
        return this.isLoading;
    }
    get getTableOptions() {
        return this.tableOptions;
    }
    get getServerItemsCount() {
        return this.serverItemsCount;
    }
    get getUnpaidParkings() {
        return this.unpaidParkings;
    }
    get getBatchSelection() {
        return this.batchSelection;
    }
    get getHasSetTags() {
        return this.hasSetTags;
    }
    get getFilterOptions() {
        return this.filterOptions;
    }
};
__decorate([
    Action({ rawError: true })
], ChargeModule.prototype, "fetchUnpaidParkings", null);
__decorate([
    Action({ rawError: true })
], ChargeModule.prototype, "deleteUnpaidParking", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_IS_LOADING", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_AREAS_SELECTED", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_PRS_TAGS_SELECTED", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_TABLE_OPTIONS", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_UNPAID_PARKINGS", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_SERVER_ITEMS_COUNT", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_BATCH_SELECTION", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_LICENSE_PLATE", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_FROM_DATE", null);
__decorate([
    Mutation
], ChargeModule.prototype, "SET_TO_DATE", null);
__decorate([
    Mutation
], ChargeModule.prototype, "RESET_STATE", null);
ChargeModule = __decorate([
    Module({ dynamic: true, store, name: "chargeModule", namespaced: true })
], ChargeModule);
export default ChargeModule;
export const chargeModule = getModule(ChargeModule);
