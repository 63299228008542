export default class ListRequest {
    pageNumber;
    pageSize;
    search;
    orderBy;
    sortOrderAsc;
    includeInactive;
    entityId;
    constructor(pageNumber, pageSize, search, orderBy, sortOrderAsc, includeInactive, entityId) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.search = search;
        this.orderBy = orderBy;
        this.sortOrderAsc = sortOrderAsc;
        this.includeInactive = includeInactive;
        this.entityId = entityId;
    }
}
