import i18n from "@/i18n";
export const required = (value) => {
    if (Array.isArray(value) && value.length === 0) {
        return i18n.t("validation_required");
    }
    return value ? !!value : i18n.t("validation_required");
};
export const minLength = (value, minLength) => {
    if (value)
        return value.length >= minLength ? true : `${i18n.t("validation_minLength")} ${minLength}`;
    return true;
};
export const maxLength = (value, maxLength) => {
    if (value)
        return value.length <= maxLength ? true : `${i18n.t("validation_maxLength")} ${maxLength}`;
    return true;
};
export const minNumber = (value, minNumber) => {
    if (Number(value) || value === "0")
        return Number(value) >= minNumber ? true : `${i18n.t("validation_minNumber")} ${minNumber}`;
    return true;
};
export const maxNumber = (value, maxNumber) => {
    if (Number(value))
        return Number(value) <= maxNumber ? true : `${i18n.t("validation_maxNumber")} ${maxNumber}`;
    return true;
};
export const numberRange = (value, minNumber, maxNumber) => {
    if (Number(value))
        return Number(value) >= minNumber && Number(value) <= maxNumber ? true : `${i18n.t("validation_numberRange")} ${minNumber} - ${maxNumber}`;
    return true;
};
export const password = (password) => {
    if (!password)
        return false;
    const minLength = (str) => str.length >= 8;
    const whiteSpace = (str) => /^\S*$/gu.test(str);
    const hasLowerCaseLetter = (str) => /[a-z]/gu.test(str);
    const hasUpperCaseLetter = (str) => /[A-Z]/gu.test(str);
    const hasNumber = (str) => /[\p{N}]+/gu.test(str);
    const hasSpecialCharacter = (str) => /[^\p{L}\p{N}]+/gu.test(str);
    if (!minLength(password)) {
        return `${i18n.t("validation_password_minimum_length")}`;
    }
    else if (!whiteSpace(password)) {
        return `${i18n.t("validation_password_no_white_space")}`;
    }
    else {
        return (hasLowerCaseLetter(password) && hasUpperCaseLetter(password) && hasNumber(password) && hasSpecialCharacter(password)) || `${i18n.t("validation_password_characters")}`;
    }
};
export const passwordMatch = (password1, password2) => {
    return password1 === password2 || `${i18n.t("validation_password_match")}`;
};
export const maxFileSize = (file, max) => {
    if (!file)
        return true;
    return file.length < max || `${i18n.t("validation_maxFileSize")} (max ${max / 1000} kB)`;
};
