import { __decorate } from "tslib";
import { ParagraphRequest, ParagraphUpdateRequest, TermsOfServiceRequest, } from "@/apiclient/client";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { paragraphModule } from "@/store/modules/paragrahModule";
import { tosModule } from "@/store/modules/tosModule";
import CreateParagraphModuleRequest from "@/util/paragraphModule/createParagraphModuleRequest";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomFileInput from "@/components/shared/CustomFileInput.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import DeleteParagraphModuleRequest from "@/util/paragraphModule/deleteParagraphModuleRequest";
import draggable from "vuedraggable";
import TemplateSection from "@/components/settings/TemplateSection.vue";
import TermsOfServiceComponent from "@/components/TermsOfService.vue";
import TosModuleRequest from "@/util/storerequest/tosModuleRequest";
import UpdateParagrahModuleRequest from "@/util/paragraphModule/updateParagraphModuleRequest";
import Vue from "vue";
let TosConfig = class TosConfig extends Vue {
    type = InputType;
    action = Action;
    checkMove(event) {
        const templates = this.getTosSections;
        const indexFrom = event.draggedContext.index;
        const indexTo = event.draggedContext.futureIndex;
        const replacedItem = templates[indexTo];
        const movedItem = templates[indexFrom];
        this.updateSection(replacedItem);
        this.updateSection(movedItem);
    }
    updateTos(paragraphs) {
        let tos = new TermsOfServiceRequest();
        tos.headerLogo = tosModule.getHeaderLogoBase64;
        tos.footerLogo = tosModule.getFooterLogoBase64;
        tos.mainTitle = tosModule.getMainHeader;
        let request = new TosModuleRequest(authenticationModule.getCustomerId);
        request.tos = tos;
        tosModule.updateTos(request);
    }
    addSection() {
        let paragraphCreate = new ParagraphRequest();
        paragraphCreate.title = "title";
        paragraphCreate.body = "text";
        let request = new CreateParagraphModuleRequest(authenticationModule.getCustomerId, tosModule.getTemplateId, paragraphCreate);
        paragraphModule.createParagraph(request).then((response) => {
            tosModule.fetchTosTemplate(authenticationModule.getCustomerId);
        });
    }
    updateSection(templateSection) {
        let update = new ParagraphUpdateRequest();
        update.title = templateSection.title;
        update.body = templateSection.body;
        update.order = templateSection.order;
        let request = new UpdateParagrahModuleRequest(authenticationModule.getCustomerId, tosModule.getTemplateId, update, templateSection.id || 0);
        paragraphModule.patchParagraph(request);
    }
    deleteParagraph(paragrahId) {
        let request = new DeleteParagraphModuleRequest(authenticationModule.getCustomerId, tosModule.getTemplateId, paragrahId);
        paragraphModule.deleteParagrah(request).then((response) => {
            tosModule.fetchTosTemplate(authenticationModule.getCustomerId);
        });
    }
    get getTosSections() {
        return tosModule.getSections;
    }
    set getTosSections(paragraphs) {
        for (let i = 1; i < paragraphs.length; i++) {
            paragraphs[i - 1].order = i;
        }
        tosModule.SET_TOS_PARAGRAPHS(paragraphs);
        this.updateTos(paragraphs);
        //tosModule.fetchTosTemplate(authenticationModule.getCustomerId);
    }
    get headerLogo() {
        return tosModule.getHeaderLogo;
    }
    set headerLogo(value) {
        tosModule.updateHeaderLogo(value);
        var self = this;
        setTimeout(function () {
            self.updateTos();
        }, 2500);
    }
    get footerLogo() {
        return tosModule.getFooterLogo;
    }
    set footerLogo(logo) {
        tosModule.updateFooterLogo(logo);
        var self = this;
        setTimeout(function () {
            self.updateTos();
        }, 2500);
    }
    get mainHeader() {
        return tosModule.getMainHeader;
    }
    set mainHeader(value) {
        tosModule.updateMainHeader(value);
        this.updateTos();
    }
};
TosConfig = __decorate([
    Component({
        components: {
            CustomButton,
            CustomFileInput,
            CustomTextField,
            draggable,
            TemplateSection,
            TermsOfServiceComponent,
        },
    })
], TosConfig);
export default TosConfig;
