import { __decorate } from "tslib";
import { AreaRequest, SubAreaRequest } from "@/apiclient/client";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
let TreeSelect = class TreeSelect extends Vue {
    // Local data for selection
    selection = [];
    // Show sub areas or not. Should probably just be renamed to useSubareas or sth.
    showChildren;
    // Icon to prepend in selection field.
    icon;
    // Areas to choose from in dropdown.
    areas;
    // Since we use local state for selection, need this to allow initial values if e.g. editing an existing subscription.
    initialSelection;
    rules;
    disabled;
    get mapRules() {
        return this.rules.map(r => r(this.selection));
    }
    copyArea(area) {
        return Object.assign(Object.create(AreaRequest.prototype), { ...area });
    }
    copySubArea(subArea) {
        return Object.assign(Object.create(SubAreaRequest.prototype), {
            ...subArea,
        });
    }
    toggleValue = false;
    toggleMenu() {
        this.toggleValue = true;
    }
    toggleArea(area) {
        // If area to toggle is selected
        if (this.selection.some(a => a.id == area.id)) {
            // remove area from selected
            this.selection = this.selection.filter(a => a.id != area.id);
        }
        else {
            // else add area. Use copy functions to preserve prototype (needed for client.ts toJson() func)
            this.selection.push(this.copyArea(area));
        }
    }
    toggleSubArea(subArea, area) {
        var selectedParent = this.selection.find(a => a.id === area.id);
        // If parent area is selected, and has selected subareas
        if (selectedParent?.subAreas) {
            // if the subarea to toggle is selected
            if (selectedParent.subAreas.some(a => a.id == subArea.id)) {
                // filter it out
                var newAreas = selectedParent.subAreas.filter(a => a.id != subArea.id);
                // if the parent area has 0 selected subareas, remove it
                if (newAreas.length <= 0) {
                    this.toggleArea(area);
                }
                // set selected subareas to filtered list (which can be empty)
                selectedParent.subAreas = newAreas;
            }
            else {
                // if subarea to toggle is not selected, push it to the selected parents subareas
                selectedParent.subAreas.push(this.copySubArea(subArea));
            }
        }
        else {
            // if parent area is not selected, push parent + subarea to selected
            const ar = this.copyArea(area);
            ar.subAreas = [subArea];
            this.selection.push(ar);
        }
    }
    getChips() {
        //TODO: remove LEQ 1 if not want to conditionally override showchildren based on # selected areas
        if (this.showChildren && this.selection.length <= 1) {
            // return this.selection.concat(
            //   this.selection.flatMap((a) => a.subAreas) as any
            // );
            return this.selection.flatMap(s => [s, ...(s.subAreas ? s.subAreas : [])]);
        }
        return this.selection;
    }
    removeChip(area) {
        // chip doesn't know what kind of area it has.
        if (this.isSubArea(area)) {
            // Toggle subarea, and find the right parent
            this.toggleSubArea(area, this.selection.find(a => a.subAreas?.some(sa => sa.id === area.id)));
        }
        else {
            // Else it's an area, and we toggle that instead.
            this.toggleArea(this.selection.find(a => a.id === area.id));
        }
    }
    isSubArea(area) {
        // If subAreas is undefined, it's a subarea.
        // Yes, this is a horrible hack that will break if the property name is changed in the API.
        return !("subAreas" in area);
    }
    onSelectionChanged() {
        this.$emit("change", this.selection);
    }
    remounted() {
        // Needed because Vue doesn't unmount the component when it's in a dialog.
        this.updateInitialSelection();
    }
    updateInitialSelection() {
        if (this.initialSelection) {
            this.selection = this.initialSelection;
        }
    }
};
__decorate([
    Prop({ default: false })
], TreeSelect.prototype, "showChildren", void 0);
__decorate([
    Prop()
], TreeSelect.prototype, "icon", void 0);
__decorate([
    Prop()
], TreeSelect.prototype, "areas", void 0);
__decorate([
    Prop()
], TreeSelect.prototype, "initialSelection", void 0);
__decorate([
    Prop({ default: () => [(arr) => true] })
], TreeSelect.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], TreeSelect.prototype, "disabled", void 0);
__decorate([
    Watch("selection", { deep: true })
], TreeSelect.prototype, "onSelectionChanged", null);
__decorate([
    Watch("initialSelection", { deep: true, immediate: true })
], TreeSelect.prototype, "remounted", null);
TreeSelect = __decorate([
    Component({
        methods: {},
        components: {},
    })
], TreeSelect);
export default TreeSelect;
