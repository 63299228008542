import { __decorate } from "tslib";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { customerModule } from "@/store/modules/customerModule";
import { featureModule } from "@/store/modules/featureModule";
import { InputType } from "@/components/shared/enums";
import { Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import FeatureToggle from "@/components/settings/FeatureToggle.vue";
import Vue from "vue";
import { areaModule } from "@/store/modules/areaModule";
let ToggleCustomerFeatures = class ToggleCustomerFeatures extends Vue {
    type = InputType;
    featureFilter = "";
    mounted() {
        customerModule.fetchCustomers();
    }
    updateFeatureState(feature, state) {
        feature.active = state;
        featureModule.updateFeature(feature).then(() => {
            featureModule
                .fetchFeatures() //fetch all
                .then(() => {
                //fetch for the logged in customer
                featureModule.fetchFeatures(authenticationModule.getCustomerId).then(() => {
                    featureModule.INCREMENT_RESET_COUNT();
                });
            });
        });
    }
    onCustomerChanged(customer) {
        featureModule.fetchFeatures();
        areaModule.fetchCustomerAreas(customer.costumerID);
    }
    get selectedCustomer() {
        return featureModule.getSelectedCustomer;
    }
    set selectedCustomer(customer) {
        featureModule.SET_SELECTED_COSTUMER(customer);
    }
    get getCustomers() {
        return customerModule.getCustomers;
    }
    get getFeatures() {
        if (this.featureFilter)
            return featureModule.getFeatures.filter(f => f.header?.toLocaleLowerCase().includes(this.featureFilter?.toLocaleLowerCase()));
        return featureModule.getFeatures;
    }
};
__decorate([
    Watch("selectedCustomer")
], ToggleCustomerFeatures.prototype, "onCustomerChanged", null);
ToggleCustomerFeatures = __decorate([
    Component({
        components: {
            CustomSelect,
            CustomTextField,
            FeatureToggle,
        },
    })
], ToggleCustomerFeatures);
export default ToggleCustomerFeatures;
