import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import Vue from "vue";
let ActionPanel = class ActionPanel extends Vue {
    actionId;
    isParkingClosed;
    disableActions;
    //toggle_exclusive : number = -1;
    action = Action;
    get actionIdModel() {
        return this.actionId;
    }
    set actionIdModel(id) {
        this.$emit("action-clicked", id);
    }
    get getPrimaryBtnText() {
        return this.isParkingClosed ? "approve".tc(1) : "Tilføj udkørsel og gem";
    }
};
__decorate([
    Prop()
], ActionPanel.prototype, "actionId", void 0);
__decorate([
    Prop()
], ActionPanel.prototype, "isParkingClosed", void 0);
__decorate([
    Prop()
], ActionPanel.prototype, "disableActions", void 0);
ActionPanel = __decorate([
    Component({
        components: {
            CustomButton,
        },
        methods: {},
    })
], ActionPanel);
export default ActionPanel;
