import { AuthenticateRequest } from "@/apiclient/client";
export default class AuthModuleRequest {
    username;
    password;
    loginMode;
    request;
    constructor(username, password, loginMode) {
        this.username = username;
        this.password = password;
        this.request = new AuthenticateRequest();
        this.request.password = password;
        this.request.username = username;
        this.loginMode = loginMode;
    }
}
