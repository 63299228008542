import { __decorate } from "tslib";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ListRequest from "@/util/storerequest/ListRequest";
import store from "@/store";
let ParkingModule = class ParkingModule extends VuexModule {
    isLoading = false;
    areasSelected = [];
    prsTagsSelected = [];
    tableOptions = new ListRequest(1, 10);
    SET_AREAS_SELECTED(areasSelected) {
        this.areasSelected = areasSelected;
    }
    SET_PRS_TAGS_SELECTED(prsTagsSelected) {
        this.prsTagsSelected = prsTagsSelected;
    }
    get getInList() {
        return [
            {
                licensePlate: "AB10111",
                start: "2022-09-01",
                validUntil: "2022-09-25",
                area: "Logos Plads",
                subArea: "Gammel Mønt",
                sourceOfParking: "PRS",
                typeOfParking: "FreeFlow",
            },
            {
                licensePlate: "CD20222",
                start: "2021-01-01",
                validUntil: "2021-01-15",
                area: "Logos Plads",
                subArea: "ParkAdmin",
                sourceOfParking: "PRS",
                typeOfParking: "FreeFlow",
            },
        ];
    }
};
__decorate([
    Mutation
], ParkingModule.prototype, "SET_AREAS_SELECTED", null);
__decorate([
    Mutation
], ParkingModule.prototype, "SET_PRS_TAGS_SELECTED", null);
ParkingModule = __decorate([
    Module({ dynamic: true, store, name: "parkingModule" })
], ParkingModule);
export default ParkingModule;
export const parkingModule = getModule(ParkingModule);
