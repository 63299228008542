import { __decorate } from "tslib";
import { SubscriberResponse, SubscriptionRequest } from "@/apiclient/client";
import store from "@/store/index";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
let StepperModule = class StepperModule extends VuexModule {
    subscriber = new SubscriberResponse();
    subscription = new SubscriptionRequest();
    cars = [];
    signature = "";
    get getSubscriber() {
        return this.subscriber;
    }
    get getSubscription() {
        return this.subscription;
    }
    get getCars() {
        return this.cars;
    }
    get getSignature() {
        return this.signature;
    }
    get getCarCount() {
        return this.cars.length;
    }
    clearModule() {
        this.context.commit("CLEAR");
    }
    updateSubscriber(subscriber) {
        this.context.commit("SET_SUBSCRIBER", subscriber);
    }
    updateCars(cars) {
        this.context.commit("SET_CARS", cars);
    }
    updateSignature(signature) {
        this.context.commit("SET_SIGNATURE", signature);
    }
    updateSubscription(subscription) {
        this.context.commit("SET_SUBSCRIPTION", subscription);
    }
    removeCarFromSubscription(car) {
        this.context.commit("REMOVE_CAR_FROM_SUBSCRIPTION", car);
    }
    createSubscriptionCar(car) {
        this.context.commit("SET_CAR_ON_SUBSCRIPTION", car);
    }
    updateSubscriptionCar(car) {
        this.context.commit("UPDATE_CAR_ON_SUBSCRIPTION", car);
    }
    SET_EMAILS_ACCEPTED(accepted) {
        this.subscriber.recieveNewsLetter = accepted;
    }
    UPDATE_CAR_ON_SUBSCRIPTION(car) {
        var foundIndex = this.cars.findIndex(x => x.name === car.name || x.licensePlate === car.licensePlate);
        this.cars.splice(foundIndex, 1, car);
    }
    SET_CAR_ON_SUBSCRIPTION(car) {
        this.cars.push(car);
    }
    REMOVE_CAR_FROM_SUBSCRIPTION(car) {
        this.cars = this.cars.filter(c => c.name !== car.name || c.licensePlate !== car.licensePlate);
    }
    SET_SUBSCRIPTION(subscription) {
        this.subscription = subscription;
    }
    SET_SIGNATURE(signature) {
        this.signature = signature;
    }
    SET_CARS(cars) {
        this.cars = cars;
    }
    SET_SUBSCRIBER(subscriber) {
        this.subscriber = subscriber;
    }
    CLEAR() {
        this.subscriber = new SubscriberResponse();
        this.subscription = new SubscriptionRequest();
        this.cars = [];
        this.signature = "";
    }
};
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "clearModule", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "updateSubscriber", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "updateCars", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "updateSignature", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "updateSubscription", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "removeCarFromSubscription", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "createSubscriptionCar", null);
__decorate([
    Action({ rawError: true })
], StepperModule.prototype, "updateSubscriptionCar", null);
__decorate([
    Mutation
], StepperModule.prototype, "SET_EMAILS_ACCEPTED", null);
__decorate([
    Mutation
], StepperModule.prototype, "UPDATE_CAR_ON_SUBSCRIPTION", null);
__decorate([
    Mutation
], StepperModule.prototype, "SET_CAR_ON_SUBSCRIPTION", null);
__decorate([
    Mutation
], StepperModule.prototype, "REMOVE_CAR_FROM_SUBSCRIPTION", null);
__decorate([
    Mutation
], StepperModule.prototype, "SET_SUBSCRIPTION", null);
__decorate([
    Mutation
], StepperModule.prototype, "SET_SIGNATURE", null);
__decorate([
    Mutation
], StepperModule.prototype, "SET_CARS", null);
__decorate([
    Mutation
], StepperModule.prototype, "SET_SUBSCRIBER", null);
__decorate([
    Mutation
], StepperModule.prototype, "CLEAR", null);
StepperModule = __decorate([
    Module({ dynamic: true, store, name: "steppersModule", namespaced: true })
], StepperModule);
export default StepperModule;
export const stepperModule = getModule(StepperModule);
