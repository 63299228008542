import { __decorate } from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
let ModalDialog = class ModalDialog extends Vue {
    title;
    maxWidth;
    maxHeight;
    IconTopRight;
    value;
    get show() {
        return this.value;
    }
    set show(show) {
        this.$emit("state-changed", show);
    }
};
__decorate([
    Prop()
], ModalDialog.prototype, "title", void 0);
__decorate([
    Prop()
], ModalDialog.prototype, "maxWidth", void 0);
__decorate([
    Prop()
], ModalDialog.prototype, "maxHeight", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], ModalDialog.prototype, "IconTopRight", void 0);
__decorate([
    Prop({ default: false, type: Boolean }) //this bool will make the dialogue visible
], ModalDialog.prototype, "value", void 0);
ModalDialog = __decorate([
    Component({})
], ModalDialog);
export default ModalDialog;
