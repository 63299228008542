var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pa-6 mb-5"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('h1',[_vm._v(_vm._s("subscriber".tc(2)))])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('includedisabled'),"color":"primary"},model:{value:(_vm.includeInactive),callback:function ($$v) {_vm.includeInactive=$$v},expression:"includeInactive"}})],1),_c('v-col',{staticClass:"pb-0 d-flex justify-end",attrs:{"cols":"4"}},[_c('CustomTextField',{attrs:{"type":_vm.type.Search,"placeholder":_vm.$t('placeholder_search')},on:{"on-text-field-input":_vm.debouncedSearchChange}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.pageNumber,"server-items-length":_vm.getCount,"loading":_vm.getLoadingStatus,"headers":_vm.getHeaders,"items":_vm.getSubscribers,"show-expand":"","expanded":_vm.expandedItems,"item-key":"id","single-expand":"","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [10, 25, 100],
      'items-per-page-text': 'rows_per_page'.t(),
    },"id":"subscriberlist_table"},on:{"update:page":[function($event){_vm.pageNumber=$event},function($event){_vm.pageNumber = $event}],"update:options":_vm.updateOptions,"item-expanded":_vm.setSubscriber,"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expandedItems=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s("table_footer_page_text".t())+" "+_vm._s(items.itemsLength)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('CustomButton',{attrs:{"text":'button_create'.t(),"action":_vm.action.Primary},on:{"on-button-click":function($event){_vm.handleCreate();
            _vm.resetCount += 1;}}}),_c('CustomDialog',{attrs:{"show":_vm.dialog,"title":'button_create'.t(),"persistent":false,"maxWidth":"1000px"},on:{"update:show":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('SubscriberForm',{ref:"subSubscriberForm",attrs:{"showNewsPaperSwitch":true,"subscriber":_vm.editedItem,"customerTypeText":_vm.customerTypeText,"showCustomerTypeToggle":!_vm.editing,"isPrivateCustomerProp":_vm.editedItemIsPrivateCustomer,"isPrivate":_vm.isPrivate,"formTitle":_vm.formTitle,"editMode":_vm.editing,"resetFormCount":_vm.resetCount,"id":"subscriberform"},on:{"update:subscriber":function($event){_vm.editedItem=$event},"update:customerTypeText":function($event){_vm.customerTypeText=$event},"update:customer-type-text":function($event){_vm.customerTypeText=$event},"form-updated":function($event){_vm.editedItem = $event},"form-valid":function($event){_vm.formValid = $event},"toggle-active-status":function($event){return _vm.updateActiveStatus($event)}}})]},proxy:true},{key:"actions",fn:function(){return [_c('CustomButton',{attrs:{"text":'button_cancel'.t(),"action":_vm.action.Cancel},on:{"on-button-click":_vm.close}}),_c('CustomButton',{attrs:{"text":'button_save'.t(),"action":_vm.action.Primary},on:{"on-button-click":_vm.save}})]},proxy:true}])}),_c('ConfirmPrompt',{attrs:{"title":((_vm.$tc('delete', 1).capitalize()) + " " + _vm.editedItemName + "?"),"persistent":false,"msgBody":_vm.$t('subscriber_delete', { subscriber: _vm.editedItemName })},on:{"state-changed":function($event){_vm.dialogDelete = $event},"dialog-confirmed":function($event){return _vm.deleteItemConfirm()},"dialog-close":function($event){return _vm.closeDelete()}},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)]},proxy:true},{key:"item.activeStatus",fn:function(ref){
            var item = ref.item;
return [(item.active)?_c('span',[_vm._v(_vm._s(_vm.$t("active")))]):_c('span',[_vm._v(_vm._s(_vm.$t("inactive")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('CustomButton',{attrs:{"icon":"mdi-pencil","action":_vm.action.Primary},on:{"on-button-click":function($event){$event.stopPropagation();return _vm.editItem(item)}}}),_c('CustomButton',{attrs:{"icon":"mdi-delete","action":_vm.action.Delete},on:{"on-button-click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}})]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"no-data",fn:function(){return undefined},proxy:true},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('SubscriptionTable',{staticClass:"mt-4 mb-4",attrs:{"includeInactive":_vm.includeInactive,"subscriber":item,"subscriptions":_vm.getSubscriptions},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("belongingTo", { entity: _vm.$tc("subscription", 2), owner: _vm.getSubscriberName(_vm.subscriber), })))]},proxy:true}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }