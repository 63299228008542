import { __decorate } from "tslib";
import { Component, Prop, PropSync } from "vue-property-decorator";
import moment from "moment";
import Vue from "vue";
let CustomDatePicker = class CustomDatePicker extends Vue {
    syncDate; // This component is designed to take zulu format from the server (2020-04-30T22:00:00Z) and convert a selected date to local format to the server (2020-04-10T00:00:00+02:00). IMPORTANT! v-date-picker accepts ISO 8601 date strings (YYYY-MM-DD)...and NOT (DD-MM-YYYY)
    syncPickerDate;
    label;
    min;
    max;
    inclusive; // If true the selected day will be included e.g. (2022-05-04T23:59:59+02:00) instead of (2022-05-04T00:00:00+02:00)
    rules;
    disabled;
    errorMessages;
    useCloseIcon;
    menu = false;
    get visualDate() {
        if (!this.syncDate)
            return null;
        if (this.syncDate.length === 10) {
            // syncDate is ISO date ONLY format (2020-04-03)
            const inclusiveDate = this.inclusive
                ? moment(this.syncDate)
                    .endOf("day")
                    .local()
                    .format()
                : moment(this.syncDate)
                    .local()
                    .format();
            return moment(inclusiveDate).format("DD-MM-YYYY");
        }
        else if (this.syncDate.includes("Z")) {
            // syncDate is UTC format (2020-04-30T22:00:00Z)
            return moment
                .utc(this.syncDate)
                .local()
                .format("DD-MM-YYYY");
        }
        else {
            // syncDate is local format (2022-05-01T00:00:00+02:00)
            return moment(this.syncDate)
                .local()
                .format("DD-MM-YYYY");
        }
    }
    set visualDate(date) {
        this.syncDate = null;
    }
    get getLabel() {
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
    onDatePickerChange(date) {
        const dateInServerFormat = this.inclusive
            ? moment(date)
                .endOf("day")
                .local()
                .format()
            : moment(date)
                .local()
                .format();
        this.$emit("on-date-picker-change", dateInServerFormat);
    }
    onCloseIconClick() {
        this.$emit("on-close-icon-click");
    }
};
__decorate([
    PropSync("date", { required: true })
], CustomDatePicker.prototype, "syncDate", void 0);
__decorate([
    PropSync("pickerDate", { required: false })
], CustomDatePicker.prototype, "syncPickerDate", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomDatePicker.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomDatePicker.prototype, "min", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomDatePicker.prototype, "max", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomDatePicker.prototype, "inclusive", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomDatePicker.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomDatePicker.prototype, "disabled", void 0);
__decorate([
    Prop({ required: false })
], CustomDatePicker.prototype, "errorMessages", void 0);
__decorate([
    Prop({ required: false })
], CustomDatePicker.prototype, "useCloseIcon", void 0);
CustomDatePicker = __decorate([
    Component({
        components: {},
        methods: {},
    })
], CustomDatePicker);
export default CustomDatePicker;
