var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pa-6 mb-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.requestParams.pageNumber,"loading":_vm.getLoadingStatus,"server-items-length":_vm.getCount,"headers":_vm.getHeaders,"items":_vm.getEmails,"search":_vm.search,"item-key":"mailID","show-expand":"","expanded":_vm.expanded,"single-expand":true,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [10, 25, 100],
      'items-per-page-text': 'rows_per_page'.t(),
    }},on:{"update:page":[function($event){return _vm.$set(_vm.requestParams, "pageNumber", $event)},_vm.updatePagination],"update:options":_vm.updateOptions,"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s("table_footer_page_text".t())+" "+_vm._s(items.itemsLength)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('ConfirmPrompt',{attrs:{"title":((_vm.$tc('delete', 1)) + " Email?"),"persistent":false},on:{"state-changed":function($event){_vm.dialogDelete = $event},"dialog-confirmed":function($event){return _vm.deleteQueuedEmail()},"dialog-close":function($event){_vm.dialogDelete = false}},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('CustomButton',{attrs:{"icon":"mdi-delete","action":_vm.action.Delete},on:{"on-button-click":function($event){return _vm.deleteItem(item)}}})]}},{key:"item.emailTemplateType",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(("emailTemplateTypeName" + item.emailTemplateType).t())+" ")]}},{key:"item.expectedSendTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.expectedSendTime))+" ")]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('v-container',{staticClass:"pa-4",staticStyle:{"width":"700px"},attrs:{"fluid":""}},[_c('b',{domProps:{"innerHTML":_vm._s(item.subject)}},[_vm._v(_vm._s(item.subject))]),_vm._v(" "),_c('br'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.getItemBodyText(item.body, item.emailTemplateType))}})])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }