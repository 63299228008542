import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
import { downloadFileFromServer } from "@/util/csvutil";
import { UnpaidParkingResponse, } from "@/apiclient/client";
import moment from "moment";
import store from "@/store";
import { getLastItemInArray } from "@/util/licenseplateutil";
export class ImageMap {
    path;
    base64;
    constructor(path, base64) {
        this.path = path;
        this.base64 = base64;
    }
}
let ChargeEditorModule = class ChargeEditorModule extends VuexModule {
    editedItem = null;
    chargeEditorData = {
        customerName: "",
        customerAddress: "",
        customerCity: "",
        chargeAmount: 0,
        parkingFee: 0,
        vehicleIdent: "",
    };
    images = [""];
    htmlPreview = "";
    imagePaths = [];
    imageMap = [];
    selectedImagePaths = [];
    chargeTemplate = null;
    loading = false;
    async fetchImagesPaths(parking) {
        this.context.commit("SET_LOADING_STATUS", true);
        return apiClient
            .images(authenticationModule.getCustomerId, parking.parkingRightID)
            .then(response => {
            this.context.commit("SET_IMAGE_PATHS", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => { });
    }
    selectImage(path) {
        this.context.commit("SET_SELECTED_IMAGE_PATH", path);
    }
    async previewPdf() {
        this.context.commit("SET_LOADING_STATUS", true);
        return apiClient
            .preview(authenticationModule.getCustomerId, this.editedItem?.parkingRightID, this.chargeEditorData.vehicleIdent, this.chargeEditorData.chargeAmount, [...this.selectedImagePaths], this.chargeEditorData.customerName, this.chargeEditorData.customerAddress, this.chargeEditorData.customerCity, this.chargeEditorData.parkingFee)
            .then(response => {
            this.context.commit("SET_HTML_PREVIEW", response);
        })
            .catch(() => {
            this.context.commit("RESET_STATE");
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_LOADING_STATUS", false);
        });
    }
    markCurrentAsPaid() {
        return apiClient.markAsPaid(authenticationModule.getCustomerId, this.editedItem?.parkingRightID);
    }
    async downloadPdf() {
        this.context.commit("SET_PDF_LOADING", true);
        try {
            const result = await apiClient.charge(authenticationModule.getCustomerId, this.editedItem?.parkingRightID, this.chargeEditorData.vehicleIdent, this.chargeEditorData.chargeAmount, [...this.selectedImagePaths], this.chargeEditorData.customerName, this.chargeEditorData.customerAddress, this.chargeEditorData.customerCity, this.chargeEditorData.parkingFee);
            const fileName = this.chargeEditorData.vehicleIdent + "_" + moment(new Date()).format("DD-MM-YYYY") + ".pdf"; // MAQ: In the future we want to get fileName from server
            downloadFileFromServer(result.data, fileName);
        }
        catch (error) {
            this.context.commit("RESET_STATE");
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
        finally {
            this.context.commit("SET_PDF_LOADING", false);
        }
    }
    async fetchUnpaidParking(parkingRightID) {
        return apiClient
            .unpaidGET(authenticationModule.customerId, parkingRightID)
            .then(async (parking) => {
            this.context.commit("SET_EDITED_PARKING", parking);
            this.context.commit("SET_CHARGE_EDITOR_DATA", {
                ...this.chargeEditorData,
                chargeAmount: parking.area?.defaultChargeAmount ?? 0,
                vehicleIdent: getLastItemInArray(parking?.vehicleIdents ?? []),
                parkingFee: parking.price,
            });
            const imageMaps = parking.images?.map(image => {
                return new ImageMap(image?.imagePath ?? "", `data:image/png;base64,${image.imageSource}`);
            }) ?? [];
            this.context.commit("SET_IMAGE_MAP", imageMaps);
            this.context.commit("SET_SELECTED_IMAGE_PATHS", []);
            await chargeEditorModule.previewPdf();
            return parking;
        })
            .catch(err => {
            this.context.commit("SET_PDF_LOADING", false);
        });
    }
    async fetchChargeTemplate() {
        this.context.commit("SET_LOADING_STATUS", true);
        return apiClient.chargeGET(authenticationModule.customerId).then(response => {
            this.context.commit("SET_LOADING_STATUS", false);
            return response.data?.template ?? "";
        });
    }
    async saveChargeTemplate(templateRequest) {
        this.context.commit("SET_LOADING_STATUS", true);
        return apiClient
            .chargePOST(authenticationModule.customerId, templateRequest)
            .then(response => {
            this.context.commit("SET_LOADING_STATUS", false);
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_charge_template".t(),
                criticality: Criticality.Success,
            });
            return response.data?.template ?? "";
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async previewChargeTemplate(templateRequest) {
        return apiClient
            .previewChargeTemplate(authenticationModule.customerId, templateRequest)
            .then(response => {
            const fileName = "preview_template_" + moment(new Date()).format("DD-MM-YYYY") + ".pdf"; // MAQ: In the future we want to get fileName from server
            downloadFileFromServer(response.data, fileName);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_SELECTED_IMAGE_PATH(path) {
        if (this.selectedImagePaths.includes(path)) {
            this.selectedImagePaths = this.selectedImagePaths.filter(item => item !== path);
            return;
        }
        if (this.selectedImagePaths.length < 2) {
            this.selectedImagePaths.push(path);
            return;
        }
    }
    RESET_STATE() {
        this.loading = false;
        this.editedItem = new UnpaidParkingResponse();
        this.images = [""];
        this.htmlPreview = "";
        this.imagePaths = [];
        this.imageMap = [];
        this.selectedImagePaths = [];
    }
    SET_LOADING_STATUS(isLoading) {
        this.loading = isLoading;
    }
    SET_SELECTED_IMAGE_PATHS(paths) {
        this.selectedImagePaths = paths;
    }
    SET_IMAGE_PATHS(paths) {
        this.imagePaths = paths;
    }
    SET_HTML_PREVIEW(preview) {
        this.htmlPreview = preview;
    }
    SET_IMAGES(images) {
        this.images = images;
    }
    SET_IMAGE_MAP(maps) {
        this.imageMap = maps;
    }
    SET_EDITED_PARKING(item) {
        this.editedItem = item;
    }
    SET_PDF_LOADING(loading) {
        this.loading = loading;
    }
    SET_CHARGE_EDITOR_DATA(data) {
        this.chargeEditorData = data;
    }
    SET_CHARGE_TEMPLATE(template) {
        this.chargeTemplate = template;
    }
    get getSelectedImagePaths() {
        return this.selectedImagePaths;
    }
    get getImagePaths() {
        return this.imagePaths;
    }
    get getHtmlPreview() {
        return this.htmlPreview;
    }
    get getCurrentImages() {
        return this.imageMap;
    }
    get getEditedItem() {
        return this.editedItem;
    }
    get getChargeEditorData() {
        return this.chargeEditorData;
    }
    get getChargeTemplate() {
        return this.chargeTemplate;
    }
};
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "fetchImagesPaths", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "selectImage", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "previewPdf", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "markCurrentAsPaid", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "downloadPdf", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "fetchUnpaidParking", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "fetchChargeTemplate", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "saveChargeTemplate", null);
__decorate([
    Action({ rawError: true })
], ChargeEditorModule.prototype, "previewChargeTemplate", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_SELECTED_IMAGE_PATH", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "RESET_STATE", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_LOADING_STATUS", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_SELECTED_IMAGE_PATHS", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_IMAGE_PATHS", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_HTML_PREVIEW", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_IMAGES", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_IMAGE_MAP", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_EDITED_PARKING", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_PDF_LOADING", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_CHARGE_EDITOR_DATA", null);
__decorate([
    Mutation
], ChargeEditorModule.prototype, "SET_CHARGE_TEMPLATE", null);
ChargeEditorModule = __decorate([
    Module({ dynamic: true, store, name: "chargeEditorModule" })
], ChargeEditorModule);
export const chargeEditorModule = getModule(ChargeEditorModule);
