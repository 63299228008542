import { __decorate } from "tslib";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDatePicker from "@/components/shared/CustomDatePicker.vue";
import moment from "moment";
import Vue from "vue";
let CustomDatePickerExamples = class CustomDatePickerExamples extends Vue {
    action = Action;
    datePickerFormValid = true;
    datepicker1 = "2020-04-30T22:00:00Z"; // "2020-04-30T22:00:00Z";
    datepicker2 = moment().endOf("day").format(); //moment().endOf("day").format();
    datepicker3 = "2020-04-10T00:00:00+02:00"; // "2020-04-10T00:00:00+02:00";
    onDatePicker3Change(date) {
        // console.log("onDatePicker3Change: " + date);
    }
    datePickerFormValidate() {
        this.$refs.datePickerForm.validate();
    }
    datePickerFormReset() {
        this.$refs.datePickerForm.reset();
    }
    datePickerFormResetValidation() {
        this.$refs.datePickerForm.resetValidation();
    }
};
CustomDatePickerExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDatePicker,
        },
        methods: {
            required,
        },
    })
], CustomDatePickerExamples);
export default CustomDatePickerExamples;
