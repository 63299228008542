import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import { apiClient } from "@/apiclient/baseclient";
import { Criticality, notificationModule } from "./notificationModule";
let CarModule = class CarModule extends VuexModule {
    car = null;
    cars = [];
    totalRecords = 0;
    get getCar() {
        return this.car;
    }
    get getCars() {
        return this.cars;
    }
    get getCount() {
        return this.totalRecords;
    }
    async fetchCars(request) {
        apiClient.carsGET();
    }
    async deleteCar(request) {
        apiClient
            .carsDELETE(request.subscriptionId, request.carId)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_car".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async updateCar(request) {
        apiClient
            .carsPUT(request.carId, request.carUpdate)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_car".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async createSubscriptionCar(request) {
        return apiClient
            .car(request.subscriptionId, request.car)
            .then(() => {
            if (request.showStatus) {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_create_subscription".t(),
                    criticality: Criticality.Success,
                });
            }
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_CARS(cars) {
        this.cars = cars;
    }
};
__decorate([
    Action({ rawError: true })
], CarModule.prototype, "fetchCars", null);
__decorate([
    Action({ rawError: true })
], CarModule.prototype, "deleteCar", null);
__decorate([
    Action({ rawError: true })
], CarModule.prototype, "updateCar", null);
__decorate([
    Action({ rawError: true })
], CarModule.prototype, "createSubscriptionCar", null);
__decorate([
    Mutation
], CarModule.prototype, "SET_CARS", null);
CarModule = __decorate([
    Module({ dynamic: true, store, name: "carsModule", namespaced: true })
], CarModule);
export default CarModule;
export const carModule = getModule(CarModule);
