import { __decorate } from "tslib";
import { SubscriberResponse, UserRequest } from "@/apiclient/client";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component, Prop, Watch } from "vue-property-decorator";
import { constantsModule } from "@/store/modules/constantsModule";
import { settingsModule } from "@/store/modules/settingsModule";
import { subscriberModule } from "@/store/modules/subscriberModule";
import { userModule } from "@/store/modules/userModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import ModalDialog from "@/components/shared/ModalDialog.vue";
import ModeConfigDispatch from "@/util/storerequest/modeconfigdispatch";
import ModeLoginEditor from "@/components/forms/ModeLoginEditor.vue";
import ModeLoginsRow from "@/components/modelogins/modeLoginsRow.vue";
import ParkAdminUser from "@/util/user/user";
import UserStoreRequest from "@/util/storerequest/userRequest";
import Vue from "vue";
import ModeCreationForm from "@/components/forms/ModeCreationForm.vue";
let ModeLoginManagement = class ModeLoginManagement extends Vue {
    action = Action;
    id;
    subscriber;
    internalSubscriber = new SubscriberResponse();
    items = [
        { title: "Mode Logins", icon: "mdi-login" },
        { title: "Lav login", icon: "mdi-account-key" },
    ];
    tabs = 0;
    resetCount = 0;
    toggleModeForm = false;
    modeUser = new ParkAdminUser();
    modeConfig = new ModeConfigDispatch();
    isEditing = false;
    mounted() {
        if (!this.subscriber.name) {
            subscriberModule.fetchSubscriberById(this.id).then(() => {
                if (subscriberModule.getSubscriber != null)
                    this.internalSubscriber = subscriberModule.getSubscriber;
            });
        }
        else {
            this.internalSubscriber = this.subscriber;
        }
        userModule.fetchModeUsers(this.id);
        constantsModule.fetchModes();
    }
    onDialogChanged(show) {
        if (!show) {
            this.modeUser = new ParkAdminUser();
            this.modeConfig = new ModeConfigDispatch();
        }
    }
    refetchList() {
        userModule.fetchModeUsers(this.id);
    }
    handleCreateUser() {
        const userRequest = new UserRequest();
        userRequest.name = this.modeUser.name;
        userRequest.username = this.modeUser.username;
        userRequest.password = this.modeUser.password;
        userRequest.accessLevel = 4;
        userRequest.userTypeID = 2;
        userRequest.modeID = this.modeUser.modeID;
        userRequest.subscriberID = this.internalSubscriber.id;
        const userStoreRequest = new UserStoreRequest(authenticationModule.getCustomerId, this.internalSubscriber.id, undefined, userRequest);
        this.modeConfig.userId = this.modeUser.id;
        userModule
            .createUserModeLoginAndUpdateConfig({ userStoreRequest, modeConfigRequest: this.modeConfig })
            .then((res) => {
            if (res) {
                this.refetchList();
                this.toggleModeForm = false;
            }
        });
    }
    updateUserInfo() {
        const userUpdate = new UserRequest();
        userUpdate.modeID = this.modeUser.modeID;
        userUpdate.name = this.modeUser.name;
        userUpdate.username = this.modeUser.username;
        userUpdate.password = this.modeUser.password;
        userUpdate.accessLevel = this.modeUser.accessLevel;
        const request = new UserStoreRequest(authenticationModule.getCustomerId, this.modeUser.id, userUpdate);
        userModule.updateUserModeLogin(request).then(() => {
            this.refetchList();
        });
    }
    modeLoginClicked(modeLogin) {
        this.isEditing = true;
        this.modeUser = modeLogin;
        //fetch the mode config for this user
        userModule
            .fetchAffiliationConfiguration(modeLogin.id || 0)
            .then(() => {
            const modeConfig = userModule.getAffiliationConfiguration;
            this.modeConfig.modeConfigurationID = modeConfig?.modeConfigurationID;
            this.modeConfig.subscriptonTypeID = modeConfig?.subscriptonTypeID;
            this.modeConfig.welcomeEmailEmplateID = modeConfig?.welcomeEmailEmplateID;
            this.modeConfig.headerLogo = modeConfig?.headerLogo;
            this.modeConfig.footerLogo = modeConfig?.footerLogo;
            this.modeConfig.areas = modeConfig?.areas ?? [];
            this.modeConfig.features = modeConfig?.features ?? [];
        })
            .catch(() => {
            this.modeConfig = new ModeConfigDispatch();
        })
            .finally(() => {
            this.resetCount++;
            this.toggleModeForm = true;
        });
    }
    createdClicked() {
        this.isEditing = false;
        this.toggleModeForm = true;
        this.modeUser = new ParkAdminUser();
        this.modeConfig = new ModeConfigDispatch();
        this.resetCount++;
    }
    updateModeConfig() {
        this.modeConfig.userId = this.modeUser.id;
        settingsModule.updateModeConfiguration(this.modeConfig).finally(() => {
            this.toggleModeForm = false;
            this.resetCount++;
        });
    }
    get getModeLogins() {
        return userModule.getModeLogins;
    }
};
__decorate([
    Prop()
], ModeLoginManagement.prototype, "id", void 0);
__decorate([
    Prop()
], ModeLoginManagement.prototype, "subscriber", void 0);
__decorate([
    Watch("dialog")
], ModeLoginManagement.prototype, "onDialogChanged", null);
ModeLoginManagement = __decorate([
    Component({
        components: {
            CustomButton,
            ModalDialog,
            ModeLoginEditor,
            ModeLoginsRow,
            ModeCreationForm,
        },
    })
], ModeLoginManagement);
export default ModeLoginManagement;
