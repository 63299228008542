import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { ParkingSearchResponse, Orphan, ParkingRight, ParkingQueryRequest, PrsTagRequest, } from "@/apiclient/client";
import { authenticationModule } from "./authenticationModule";
import { Criticality, notificationModule } from "./notificationModule";
import ListRequest from "@/util/storerequest/ListRequest";
import store from "@/store/index";
export class EditOprhanPlateRequest {
    orphanId;
    licensePlate;
    constructor(orphanId, licensePlate) {
        this.orphanId = orphanId;
        this.licensePlate = licensePlate;
    }
}
export class MergeRequest {
    parkingRightId;
    orphanId;
    constructor(parkingRightId, orphanId) {
        this.parkingRightId = parkingRightId;
        this.orphanId = orphanId;
    }
}
export class CloseParkingRightRequest {
    parkingRightId;
    departedDate;
    constructor(parkingRightId, departedDate) {
        this.parkingRightId = parkingRightId;
        this.departedDate = departedDate;
    }
}
export class UpdatePrsNumberPlateRequest {
    parkingRightId;
    licensePlate;
    constructor(parkingRightId, licensePlate) {
        this.parkingRightId = parkingRightId;
        this.licensePlate = licensePlate;
    }
}
let DatawashModule = class DatawashModule extends VuexModule {
    isLoading = false;
    isManuallyAccepted = false;
    isUnpaidParkingRight = false;
    hasSetTags = false;
    tableOptions = new ListRequest(1, 10);
    parkings = [];
    selectedParking = new ParkingSearchResponse();
    selectedOrphan = new Orphan();
    selectedParkingRight = new ParkingRight();
    images = [""];
    currentImages = [];
    imageModalVisible = false;
    imagesLoading = false;
    orphans = [];
    parkingRights = [];
    serverItemsCount = 0;
    batchSelection = [];
    showManuallyAcceptedToggle = false;
    filterOptions = {
        areasSelected: [],
        tagsSelected: {
            inclusionTags: [],
            exclusionTags: [],
            acceptanceTags: [],
        },
        licensePlate: "",
        fromDate: undefined,
        toDate: undefined,
    };
    /**
     * Fetch ParkingsRights for a given orphan ID
     */
    async fetchParkingRights(vehicleIdent) {
        return apiClient
            .parkingrights(authenticationModule.getCustomerId, this.selectedParking.area?.id, vehicleIdent, this.selectedOrphan.departed)
            .then(response => {
            const result = response;
            this.context.commit("SET_PARKING_RIGHTS", result);
            return result;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async mergeSelected(tagAsAccepted) {
        let orphanId;
        let parentId;
        if (this.shouldCheckForOrphans) {
            orphanId = this.selectedOrphan.orphanID;
            parentId = this.selectedParking.id;
        }
        else {
            parentId = this.selectedParkingRight.parkingRightID;
            orphanId = this.selectedParking.id;
        }
        let promise = apiClient.orhpans(authenticationModule.getCustomerId, parentId, orphanId);
        if (tagAsAccepted) {
            return promise
                .then(response => apiClient
                .accept(authenticationModule.getCustomerId, response.data?.id)
                .then(() => response))
                .catch(() => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
        return promise;
    }
    async mergeParkingRightWithOrphan(request) {
        return apiClient.orhpans(authenticationModule.getCustomerId, request.parkingRightId, request.orphanId);
    }
    async fetchOrphans(vehicleIdent) {
        return apiClient
            .orphans(authenticationModule.getCustomerId, this.selectedParking.area?.id, vehicleIdent, this.selectedParking.start)
            .then(response => {
            const result = response;
            this.context.commit("SET_ORPHANS", result);
            return result;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    /**
     *
     * @param silent pass param to avoid updating selected parking
     * @returns
     */
    async fetchParkings(ignoreTags) {
        this.context.commit("SET_IS_LOADING", true);
        let query = new ParkingQueryRequest({
            areas: this.filterOptions.areasSelected,
            inclusionTags: ignoreTags ? [] : this.filterOptions.tagsSelected.inclusionTags,
            exclusionTags: ignoreTags ? [] : this.filterOptions.tagsSelected.exclusionTags,
            acceptanceTags: ignoreTags ? [] : this.filterOptions.tagsSelected.acceptanceTags,
            from: ignoreTags ? undefined : this.filterOptions.fromDate,
            to: ignoreTags ? undefined : this.filterOptions.toDate,
            vehicleIdent: this.filterOptions.licensePlate,
        });
        return apiClient
            .unterminated(authenticationModule.getCustomerId, this.getTableOptions.pageNumber, this.getTableOptions.pageSize, this.getTableOptions.sortOrderAsc, this.getTableOptions.orderBy, this.getIsManuallyAccepted, query)
            .then(response => {
            const result = response.data;
            if (result && result.length > 0) {
                const index = result.findIndex(parking => {
                    return parking.id === this.selectedParking.id;
                });
                index === -1
                    ? this.context.commit("SET_SELECTED_PARKING", result[0])
                    : this.context.commit("SET_SELECTED_PARKING", result[index]);
                this.context.dispatch("fetchParkingImageUrls", this.getSelectedParking);
            }
            this.context.commit("SET_PARKINGS", result);
            this.context.commit("SET_SERVER_ITEMS_COUNT", response.totalRecords);
            this.context.commit("SET_BATCH_SELECTION", []);
        })
            .catch(error => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    /**
     * DELETE the parking right in PRS
     * @param Parking
     * @returns
     */
    async deleteParking(parking) {
        this.context.commit("SET_IS_LOADING", true);
        return apiClient
            .parking(authenticationModule.getCustomerId, parking.id || 0)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_parking".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    /**
     * DELETE the orphan in PRS
     * @param Parking
     * @returns
     */
    async deleteOrphan(parking) {
        this.context.commit("SET_IS_LOADING", true);
        return apiClient
            .orphan(authenticationModule.getCustomerId, parking.id || 0)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_exit".t(),
                criticality: Criticality.Success,
            });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    /**
     * close a parkingright in PRS by giving it an end date
     * @param request
     * @returns
     */
    async closeParkingRight(request) {
        this.context.commit("SET_IS_LOADING", true);
        return apiClient
            .checkout(authenticationModule.getCustomerId, request.parkingRightId, request.departedDate)
            .then(() => {
            this.fetchParkings();
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_exit".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async editOrphanLicensePlate(request) {
        return apiClient
            .edit2(authenticationModule.getCustomerId, request.orphanId, request.licensePlate)
            .catch(response => {
            const expected = "Orphan already includes vehicle ident";
            const errorMessage = response.response.Message;
            if (errorMessage === expected || errorMessage.includes(expected)) {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_update_licenseplate".t(),
                    criticality: Criticality.Success,
                });
                return Promise.reject("vehiceident exists");
            }
            else {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            }
            return Promise.reject(response);
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    /**
     * Edit a license plate in PRS
     * @param request
     * @returns
     */
    async editParkingRightLicensePlate(request) {
        this.context.commit("SET_IS_LOADING", true);
        return apiClient
            .edit(authenticationModule.getCustomerId, request.parkingRightId, request.licensePlate)
            .then(() => {
            this.fetchParkings();
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_licenseplate".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(response => {
            const expected = "Parkings already includes vehicle ident";
            const errorMessage = response.response.Message;
            if (errorMessage === expected || errorMessage.includes(expected)) {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_warning_update_licenseplate".t(),
                    criticality: Criticality.Warning,
                });
                return Promise.reject("vehiceident exists");
            }
            else {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            }
            return Promise.reject(response);
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    /**
     * accept a parking right in PRS
     * @param parkingRightId
     * @returns
     */
    async acceptParkingRight(parkingRightId) {
        this.context.commit("SET_IS_LOADING", true);
        return apiClient
            .accept(authenticationModule.getCustomerId, parkingRightId)
            .then(response => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_parkingApproved".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(error => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    async fetchParkingImageUrls(parking) {
        this.context.commit("SET_IS_LOADING", true);
        this.context.commit("SET_IMAGES_LOADING", true);
        this.context.commit("SET_SELECTED_PARKING", parking);
        this.context.commit("SET_IMAGES", []);
        this.context.commit("SET_CURRENT_IMAGES", []);
        return apiClient
            .images(authenticationModule.getCustomerId, parking.id, parking.entityType == "Orphan")
            .then(response => {
            this.context.commit("SET_IMAGES", response.data);
            this.context.dispatch("fetchParkingImageFromUrl");
        })
            .catch(() => {
            //MAQ: Error notification has temporarily been commented out. It's expected to be dealt with in next sprint 5
            // notificationModule.SHOW_NOTIFICATION({
            //     message: "notification_error_occured".t(),
            //     criticality: Criticality.Error,
            // });
        })
            .finally(() => {
            this.context.commit("SET_IS_LOADING", false);
        });
    }
    fetchParkingImageFromUrl() {
        if (!this.images)
            return;
        this.context.commit("SET_IMAGES_LOADING", true);
        this.context.commit("SET_CURRENT_IMAGES", []);
        const fetchImaage = imageName => apiClient.images2(authenticationModule.getCustomerId, this.selectedParking.id, imageName);
        const promiseArray = this.images.map(imageName => fetchImaage(imageName));
        return Promise.all(promiseArray)
            .then(response => {
            this.context.commit("SET_CURRENT_IMAGES", response.map(s => `data:image/png;base64,${s.data}`));
        })
            .catch(() => {
            //MAQ: Error notification has temporarily been commented out. It's expected to be dealt with in next sprint 5
            // notificationModule.SHOW_NOTIFICATION({
            //     message: "notification_error_occured".t(),
            //     criticality: Criticality.Error,
            // });
        })
            .finally(() => {
            this.context.commit("SET_IMAGES_LOADING", false);
        });
    }
    SET_IS_LOADING(isLoading) {
        this.isLoading = isLoading;
    }
    SET_MANUALLY_ACCEPTED(isManuallyAccepted) {
        this.isManuallyAccepted = isManuallyAccepted;
    }
    SET_AREAS_SELECTED(areasSelected) {
        this.filterOptions.areasSelected = areasSelected;
    }
    SET_PRS_TAGS_SELECTED(prsTagsSelected) {
        const selectedTags = {
            inclusionTags: prsTagsSelected.inclusionTags.map(tag => {
                return Object.assign(Object.create(PrsTagRequest.prototype), {
                    ...tag,
                });
            }),
            exclusionTags: prsTagsSelected.exclusionTags.map(tag => {
                return Object.assign(Object.create(PrsTagRequest.prototype), {
                    ...tag,
                });
            }),
            acceptanceTags: prsTagsSelected.acceptanceTags.map(tag => {
                return Object.assign(Object.create(PrsTagRequest.prototype), {
                    ...tag,
                });
            }),
        };
        this.filterOptions.tagsSelected = selectedTags;
        this.hasSetTags = true;
    }
    SET_TABLE_OPTIONS(tableOptions) {
        this.tableOptions = tableOptions;
    }
    SET_SELECTED_PARKING_RIGHT(parkingRight) {
        this.selectedParkingRight = parkingRight;
    }
    SET_SELECTED_ORPHAN(orphan) {
        this.selectedOrphan = orphan;
    }
    SET_PARKING_RIGHTS(parkingRights) {
        this.parkingRights = parkingRights;
    }
    SET_ORPHANS(orphans) {
        this.orphans = orphans;
    }
    SET_IMAGES_LOADING(loading) {
        this.imagesLoading = loading;
    }
    SET_IMAGES(images) {
        this.images = images;
    }
    SET_SELECTED_PARKING(parking) {
        this.selectedParking = parking;
    }
    SET_PARKINGS(parkings) {
        this.parkings = parkings;
    }
    SET_SERVER_ITEMS_COUNT(count) {
        this.serverItemsCount = count;
    }
    CLEAR_IMAGES() {
        this.currentImages = [];
    }
    CLEAR() {
        this.selectedParking = new ParkingSearchResponse();
        this.images = [];
        this.currentImages = [];
    }
    SET_CURRENT_IMAGES(images) {
        this.currentImages = images;
    }
    SET_CURRENT_IMAGE(imageBase64) {
        if (!this.currentImages.includes(imageBase64))
            this.currentImages.push(imageBase64);
    }
    SET_IMAGE_MODAL_STATE(state) {
        this.imageModalVisible = state;
    }
    SET_BATCH_SELECTION(batchSelection) {
        this.batchSelection = batchSelection;
    }
    SET_LICENSE_PLATE(licensePlate) {
        this.filterOptions.licensePlate = licensePlate;
    }
    SET_FROM_DATE(fromDate) {
        this.filterOptions.fromDate = fromDate;
    }
    SET_TO_DATE(toDate) {
        this.filterOptions.toDate = toDate;
    }
    RESET_STATE() {
        this.isLoading = false;
        this.isManuallyAccepted = false;
        this.tableOptions = new ListRequest(1, 10);
        this.parkings = [];
        this.selectedParking = new ParkingSearchResponse();
        this.selectedOrphan = new Orphan();
        this.selectedParkingRight = new ParkingRight();
        this.images = [""];
        this.currentImages = [];
        this.imageModalVisible = false;
        this.imagesLoading = false;
        this.orphans = [];
        this.parkingRights = [];
        this.serverItemsCount = 0;
        this.hasSetTags = false;
        this.filterOptions = {
            areasSelected: [],
            tagsSelected: {
                inclusionTags: [],
                exclusionTags: [],
                acceptanceTags: [],
            },
            licensePlate: "",
            fromDate: undefined,
            toDate: undefined,
        };
    }
    get getIsLoading() {
        return this.isLoading;
    }
    get getIsManuallyAccepted() {
        return this.isManuallyAccepted;
    }
    get getFilterOptions() {
        return this.filterOptions;
    }
    get getHasSetTags() {
        return this.hasSetTags;
    }
    get getTableOptions() {
        return this.tableOptions;
    }
    get shouldCheckForOrphans() {
        return this.selectedParking.entityType === "ParkingRight" && !this.selectedParking.stop;
    }
    get getSelectedOrphan() {
        return this.selectedOrphan;
    }
    get getSelectedParkingRight() {
        return this.selectedParkingRight;
    }
    get getParkingRights() {
        return this.parkingRights;
    }
    get getOrphans() {
        return this.orphans;
    }
    get getIsImagesLoading() {
        return this.imagesLoading;
    }
    get getImageModalVisible() {
        return this.imageModalVisible;
    }
    get getIsStopDateSet() {
        if (!this.selectedParking)
            return false;
        return this.selectedParking.stop !== null && this.selectedParking.stop !== undefined;
    }
    get getCurrentImages() {
        return this.currentImages;
    }
    get getImages() {
        return this.images;
    }
    get getParkings() {
        const filteredParkings = [];
        this.parkings.slice().forEach(parking => {
            // By design, the array of vehicle idents can contain a QR-code (CustomerID_SubscriberID_SubscriptionID e.g. 1_922_20095). This is not a true vehicle ident, - hence we remove it, so it doesn't show in lists
            parking.vehicleIdents = parking.vehicleIdents?.filter(x => !x.match("^[0-9]+_[0-9]+_[0-9]+$"));
            filteredParkings.push(parking);
        });
        return filteredParkings;
    }
    get getSelectedParking() {
        return this.selectedParking;
    }
    get getServerItemsCount() {
        return this.serverItemsCount;
    }
    get getBatchSelection() {
        return this.batchSelection;
    }
};
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "fetchParkingRights", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "mergeSelected", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "mergeParkingRightWithOrphan", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "fetchOrphans", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "fetchParkings", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "deleteParking", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "deleteOrphan", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "closeParkingRight", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "editOrphanLicensePlate", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "editParkingRightLicensePlate", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "acceptParkingRight", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "fetchParkingImageUrls", null);
__decorate([
    Action({ rawError: true })
], DatawashModule.prototype, "fetchParkingImageFromUrl", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_IS_LOADING", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_MANUALLY_ACCEPTED", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_AREAS_SELECTED", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_PRS_TAGS_SELECTED", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_TABLE_OPTIONS", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_SELECTED_PARKING_RIGHT", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_SELECTED_ORPHAN", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_PARKING_RIGHTS", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_ORPHANS", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_IMAGES_LOADING", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_IMAGES", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_SELECTED_PARKING", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_PARKINGS", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_SERVER_ITEMS_COUNT", null);
__decorate([
    Mutation
], DatawashModule.prototype, "CLEAR_IMAGES", null);
__decorate([
    Mutation
], DatawashModule.prototype, "CLEAR", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_CURRENT_IMAGES", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_CURRENT_IMAGE", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_IMAGE_MODAL_STATE", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_BATCH_SELECTION", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_LICENSE_PLATE", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_FROM_DATE", null);
__decorate([
    Mutation
], DatawashModule.prototype, "SET_TO_DATE", null);
__decorate([
    Mutation
], DatawashModule.prototype, "RESET_STATE", null);
DatawashModule = __decorate([
    Module({ dynamic: true, store, name: "datawashModule", namespaced: true })
], DatawashModule);
export default DatawashModule;
export const datawashModule = getModule(DatawashModule);
