export default class UpdateParagrahModuleRequest {
    customerId;
    templateId;
    paragraphId;
    updateBody;
    constructor(customerId, templateId, updateBody, paragraphId) {
        this.customerId = customerId;
        this.templateId = templateId;
        this.updateBody = updateBody;
        this.paragraphId = paragraphId;
    }
}
