import { __decorate } from "tslib";
import { Criticality, notificationModule, } from "@/store/modules/notificationModule";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { emailModule } from "@/store/modules/emailModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { EmailTemplateRequest } from "@/apiclient/client";
import { InputType } from "../shared/enums";
import { required } from "@/components/shared/validationRules";
import { templateModule } from "@/store/modules/templateModule";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import ModalDialog from "@/components/shared/ModalDialog.vue";
import Vue from "vue";
let EmailTemplateDialog = class EmailTemplateDialog extends Vue {
    type = InputType;
    action = Action;
    template = new EmailTemplateRequest();
    emailTemplateFormValid = false;
    emailTemplateName = null;
    templateType = null;
    onCancelClick() {
        this.showDialog = false;
        this.$refs.emailTemplateForm.reset();
        this.emailTemplateFormValid = false;
    }
    saveTemplate() {
        const isFormValid = this.$refs.emailTemplateForm.validate();
        if (!isFormValid) {
            return;
        }
        this.template.subject = "New Template";
        this.template.emailTemplateTypeID = this.templateType?.enumVal;
        this.template.emailTemplateName = this.emailTemplateName;
        emailTemplateModule
            .createEmailTemplate(this.template)
            .then(() => {
            templateModule.SET_SHOW_TEMPLATE_FORM(false);
            templateModule.INCREMENT_RESET_COUNT();
            emailTemplateModule.fetchEmailConfigurations(authenticationModule.getCustomerId);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        })
            .finally(() => {
            this.template = new EmailTemplateRequest();
            this.showDialog = false;
            this.$refs.emailTemplateForm.reset();
            this.emailTemplateFormValid = false;
        });
    }
    get showDialog() {
        return templateModule.getShowTemplateForm;
    }
    set showDialog(show) {
        templateModule.SET_SHOW_TEMPLATE_FORM(show);
    }
    get getFormResetCount() {
        return templateModule.getResetCount;
    }
    get getTemplateTypes() {
        return emailModule.emailTemplateTypeValues;
    }
};
EmailTemplateDialog = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
            CustomSelect,
            CustomTextField,
            ModalDialog,
        },
        methods: {
            required,
        },
    })
], EmailTemplateDialog);
export default EmailTemplateDialog;
