export const generateTree = (tree) => {
    if (!tree || tree.length <= 0)
        return [];
    const nodeTree = getNodeTree(tree);
    assignNodeLevels(nodeTree);
    return nodeTree;
};
const getNodeTree = (tree, parentNode = null) => {
    const nodeTree = tree.map(node => {
        return {
            id: node.id,
            name: node.name,
            mappedObj: node.mappedObj,
            parentNode: parentNode,
            selected: Boolean(node.selected),
            nodes: node.nodes ? getNodeTree(node.nodes, { id: node.id, name: node.name }) : []
        };
    });
    return nodeTree;
};
const assignNodeLevels = (nodes, level = 0) => {
    nodes.forEach(node => {
        node.level = level;
        if (node.nodes && node.nodes.length > 0) {
            assignNodeLevels(node.nodes, level + 1);
        }
    });
};
export const searchNestedObjects = (list, key, keyValue, onlyFirstLevel = false) => {
    let results = [];
    if (!list || list.length <= 0)
        return [];
    list.forEach(item => {
        for (const [name, value] of Object.entries(item)) {
            if (Array.isArray(value) && !onlyFirstLevel) {
                let res = searchNestedObjects(value, key, keyValue);
                results = results.concat(res);
            }
            else if (typeof value == "string" && value && keyValue && value.toLowerCase().includes(keyValue.toLowerCase())) {
                results.push({ ...item });
            }
        }
    });
    return results;
};
export const getObjectsByKeyValue = (list, key, keyValue, onlyFirstLevel = false) => {
    let results = [];
    if (!list || list.length <= 0)
        return [];
    list.forEach(item => {
        if (!item)
            return;
        for (const [name, value] of Object.entries(item)) {
            if (Array.isArray(value) && !onlyFirstLevel) {
                let res = getObjectsByKeyValue(value, key, keyValue);
                results = results.concat(res);
            }
            else if (value && value.toString() == keyValue) {
                results.push(item);
            }
        }
    });
    return results;
};
export const getFirstLevelNode = (nodes, node) => {
    if (node?.parentNode) {
        const [parent] = searchNestedObjects(nodes, "id", node.parentNode.name);
        return getFirstLevelNode(nodes, parent);
    }
    return node;
};
export const toggleSubNodes = (nodes, selected) => {
    nodes.forEach(node => {
        node.selected = selected;
        if (node.nodes && node.nodes.length > 0) {
            toggleSubNodes(node.nodes, selected);
        }
    });
};
export const toggleParentNode = (nodes, node) => {
    if (!node)
        return;
    const hasNodesSelected = node.nodes?.some(n => n.selected);
    node.selected = hasNodesSelected;
    if (node.parentNode) {
        toggleParentNode(nodes, getObjectsByKeyValue(nodes, "id", node.parentNode.id.toString())[0]);
    }
};
export const setAndGetSelectedNodes = (selection, nodes) => {
    const selectedNodes = [];
    const setSelectedNodes = (selection, nodes) => {
        nodes.forEach(node => {
            const foundNodeInSelection = Boolean(getObjectsByKeyValue(selection, "id", node.id.toString())[0]);
            node.selected = foundNodeInSelection;
            if (foundNodeInSelection) {
                selectedNodes.push(node);
            }
            if (node.nodes && node.nodes.length > 0) {
                setSelectedNodes(selection, node.nodes);
            }
        });
    };
    setSelectedNodes(selection, nodes);
    return selectedNodes;
};
