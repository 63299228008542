import { __decorate } from "tslib";
import { Component, Prop, PropSync } from "vue-property-decorator";
import Vue from "vue";
let CustomTimePicker = class CustomTimePicker extends Vue {
    syncTime; // IMPORTANT! v-time-picker accepts HH:MM
    label;
    min;
    max;
    rules;
    disabled;
    menu = false;
    get visualTime() {
        return this.syncTime;
    }
    set visualTime(time) {
        this.syncTime = null;
    }
    get getLabel() {
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
    onTimePickerChange(e) {
        this.$emit("on-time-picker-change", e);
    }
};
__decorate([
    PropSync("time", { required: true })
], CustomTimePicker.prototype, "syncTime", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomTimePicker.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomTimePicker.prototype, "min", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomTimePicker.prototype, "max", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomTimePicker.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomTimePicker.prototype, "disabled", void 0);
CustomTimePicker = __decorate([
    Component({
        components: {},
        methods: {},
    })
], CustomTimePicker);
export default CustomTimePicker;
