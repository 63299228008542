import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { password, required } from "@/components/shared/validationRules";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let LoginForm = class LoginForm extends Vue {
    type = InputType;
    action = Action;
    loading;
    show1 = false;
    valid = false;
    username = "";
    userPassword = "";
    mounted() {
        this.valid = false;
    }
    submit() {
        const isFormValid = this.$refs.loginform.validate();
        if (!isFormValid)
            return;
        this.$emit("form-submitted", {
            username: this.username,
            password: this.userPassword,
        });
    }
};
__decorate([
    Prop({ default: false })
], LoginForm.prototype, "loading", void 0);
LoginForm = __decorate([
    Component({
        components: {
            CustomButton,
            CustomTextField,
        },
        methods: {
            password,
            required,
        },
    })
], LoginForm);
export default LoginForm;
