import moment from "moment";
export const sharedChartOptions = (chartOptions, isZoomActive = false) => {
    return {
        title: {
            display: true,
            text: chartOptions?.chartTitle.t(),
        },
        plugins: {
            zoom: {
                pan: {
                    enabled: isZoomActive,
                    mode: "xy",
                    rangeMin: chartOptions?.zoomMinRange ?? {
                        x: 0,
                        y: 0,
                    },
                    rangeMax: chartOptions?.zoomMaxRange,
                },
                zoom: {
                    enabled: isZoomActive,
                    mode: "xy",
                    rangeMin: chartOptions?.zoomMinRange ?? {
                        x: 0,
                        y: 0,
                    },
                    rangeMax: chartOptions?.zoomMaxRange,
                    speed: 0.05,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            labels: {
                usePointStyle: true,
            },
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        callback: function (value, index, values) {
                            const x = moment(values[index]).format("HH:mm");
                            return value;
                        },
                        maxTicksLimit: 24,
                    },
                },
            ],
            yAxes: [{}],
        },
        tooltips: {
            callbacks: {
                title: chartOptions.titleOnHover,
                label: chartOptions.labelOnHover,
            },
        },
        animation: { duration: 0 },
    };
};
export const default_colors = ["#002e85", "#2f6dd1", "#83b2f6", "#000000", "#ff9868", "#d83712", "#7c0000"];
