import { __decorate } from "tslib";
import { SubscriberResponse, SubscriptionRequest, } from "@/apiclient/client";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import { areaModule } from "@/store/modules/areaModule";
import { customerModule } from "@/store/modules/customerModule";
import { subscriberModule } from "@/store/modules/subscriberModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
let ParkingPreview = class ParkingPreview extends Vue {
    parking;
    onParkingChanged(parking) {
        if (parking.subscriberID) {
            subscriberModule.fetchSubscriberById(parking.subscriberID);
        }
        else {
            subscriberModule.SET_SUBSCRIBER(new SubscriberResponse()); //clear the store value
        }
        if (parking.subscriptionID) {
            subscriptionModule.fetchSubscriptionById(parking.subscriptionID);
        }
        else {
            subscriptionModule.SET_SUBSCRIPTION(new SubscriptionRequest());
        }
    }
    getFormattedDate(date) {
        return date
            ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY")
            : "-";
    }
    getAreaName(areaId) {
        return areaModule.getAreas.find((a) => a.id === areaId)?.name ?? "";
    }
    get getCustomerName() {
        const costumer = customerModule.getCustomer;
        if (costumer)
            return costumer.costumerName || "";
        return "";
    }
    get getSubscriberName() {
        const subscriber = subscriberModule.getSubscriber;
        if (subscriber) {
            if (subscriber.subscriberType === 2) {
                return `${subscriber.firstName} ${subscriber.lastName}`;
            }
            else {
                return subscriber.name || "";
            }
        }
        return "";
    }
    get getSubscriptionName() {
        return subscriptionModule.getSubscription?.name ?? "";
    }
};
__decorate([
    Prop()
], ParkingPreview.prototype, "parking", void 0);
__decorate([
    Watch("parking")
], ParkingPreview.prototype, "onParkingChanged", null);
ParkingPreview = __decorate([
    Component({
        methods: {},
        components: {},
    })
], ParkingPreview);
export default ParkingPreview;
