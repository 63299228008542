/**
 * throws if mode id doesnt have access to parkadmin
 * @param mode  * @returns
 */
export default function evaluateAccess(mode) {
    if (mode !== 1 && mode !== 3) {
        throw new Error(`no access for user mode ${mode}`);
    }
    return true;
}
