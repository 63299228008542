import { __decorate } from "tslib";
import { SubscriptionTypeResponse, } from "@/apiclient/client";
import { castToSubscription, castToSubscriptionUpdate } from "@/util/subscriptionConverter";
import { notificationModule, Criticality } from "@/store/modules/notificationModule";
import { accessControlModule } from "@/store/modules/accessControlModule";
import { modeModule } from "@/store/modules/modeModule";
import { Prop } from "vue-property-decorator/lib/decorators/Prop";
import { statusModule } from "@/store/modules/statusModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import { synchronizeAreas } from "@/util/area/areautil";
import { Watch } from "vue-property-decorator";
import CarSubList from "@/components/subLists/CarSubList.vue";
import Component from "vue-class-component";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import EntityToolTip from "@/components/entitypreview/EntityTooltip.vue";
import ListBase from "@/util/mixins/listbase";
import moment from "moment";
import QrManagementModal from "@/components/modals/QrManagementModal.vue";
import SubscriberSubscriptionRequest from "@/util/storerequest/subscriberSubscriptionRequest";
import SubscriptionForm from "@/components/forms/SubscriptionForm.vue";
import SubscriptionPreview from "@/components/entitypreview/SubscriptionPreview.vue";
import CustomSwitch from "../shared/CustomSwitch.vue";
import SubscriptionHistory from "./SubscriptionHistory.vue";
import CustomDialog from "../shared/CustomDialog.vue";
let SubscriptionTable = class SubscriptionTable extends ListBase {
    subscriber;
    subscriptions;
    searchTerm;
    showCreateButton;
    showIncludeInactive;
    action = Action;
    showQrModal = false;
    awaitingSearch = false;
    qrHeaderTitle = "";
    updateAreas = [];
    unmodifiedEditItem = null;
    qrEntityId = 0;
    showHistory = false;
    exportAsCSV() {
        subscriptionModule.exportCurrentSubscriptionsAsPDF();
    }
    toggleShowHistory(subscription) {
        this.editedItem = subscription;
        this.showHistory = Boolean(subscription);
    }
    get editedItemName() {
        return this.editedItem?.name ?? "";
    }
    get getSubscriptions() {
        // As our data comes from the store there can be data from a previous subscriber.
        // To not show that, we return an empty array for the table until loading finishes.
        return this.getLoadingStatus ? [] : this.subscriptions;
    }
    get getId() {
        return parseInt(this.$route.params.id) || this.subscriber?.id;
    }
    get getLoadingStatus() {
        return statusModule.getLoadingState;
    }
    get getCount() {
        return subscriptionModule.getCount;
    }
    get getQrResult() {
        return subscriptionModule.getQrResult;
    }
    get hasSubscriberId() {
        return this.$route.params.id ? true : false;
    }
    get isIndelegateMode() {
        return modeModule.isDelegateLogin;
    }
    get showQrBtn() {
        return accessControlModule.isQrEnabled;
    }
    getFormattedDate(date) {
        if (date)
            return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
    get headers() {
        let headers = [
            { text: this.$t("name").toString(), value: "name" },
            { text: "Email", value: "email" },
            {
                text: this.$tc("subscriber", 1),
                value: "subscriberName",
            },
            { text: this.$t("startdate").toString(), value: "fromDate" },
            { text: this.$t("enddate").toString(), value: "toDate" },
            {
                text: this.$tc("area", 2).toString(),
                value: "areas",
                sortable: false,
            },
            {
                text: this.$t("actions").toString(),
                value: "actions",
                sortable: false,
                width: "15%",
            },
        ];
        return headers;
    }
    onSubscriberChanged() {
        this.fetchItem();
    }
    onListRequestChanged() {
        this.fetchItem();
    }
    onShowQrModalChanged(showModal) {
        if (!showModal) {
            this.qrHeaderTitle = "";
        }
    }
    onEditChanged(val) {
        if (!this.unmodifiedEditItem) {
            this.unmodifiedEditItem = JSON.parse(JSON.stringify(val));
        }
        if (val.areas !== undefined) {
            this.updateAreas = val.areas ?? [];
        }
    }
    onSearchTermChanged(searchTerm) {
        this.pageNumber = 1;
        this.search = searchTerm;
    }
    deleteItemConfirm() {
        subscriptionModule
            .deleteSubscription(this.editedItem.id)
            .then(() => {
            subscriptionModule.fetchSubscriptions(this.getListRequest);
            this.closeDelete();
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    save() {
        const isFormValid = this.$refs.delegateSubscriberForm.$refs
            .subscriptionform.validate();
        if (!isFormValid) {
            return;
        }
        if (this.isIndelegateMode) {
            this.editedItem.subscriptionType = new SubscriptionTypeResponse({
                subscriptionTypeId: modeModule.getSubscriptonTypeId,
                subscriptionType: this.editedItem.subscriptionType?.subscriptionType,
            });
        }
        if (this.editing) {
            if (this.editedItem.hasBeenMasked) {
                return subscriptionModule
                    .activateSubscription({
                    subscriptionId: this.editedItem.id,
                    active: this.editedItem.active,
                })
                    .then(res => {
                    if (res) {
                        this.editedItem = res;
                    }
                    this.fetchItem();
                });
            }
            const update = new SubscriberSubscriptionRequest(-1, castToSubscription(this.editedItem), castToSubscriptionUpdate(this.editedItem), this.editedItem.id);
            subscriptionModule.updateSubscription(update).then(() => {
                this.fetchItem();
                this.close();
            });
        }
        else {
            //create
            subscriptionModule
                .createSubscription({
                subscription: castToSubscription(this.editedItem),
                subscriberId: this.getId,
            })
                .then(() => {
                this.fetchItem();
                this.close();
            });
        }
    }
    get formTitle() {
        return (this.editing ? this.$t("edit") : this.$t("createnew"))
            .toString()
            .concat(` ${this.$tc("subscription", 1)}`);
    }
    showQr(subscription) {
        this.qrEntityId = subscription.id;
        //fetch qr from store
        subscriptionModule
            .fetchQrForSubscription(subscription.id)
            .then(() => {
            //set modal title
            this.qrHeaderTitle = `Qr code for ${subscription.name}`;
            //enable modal
            this.showQrModal = true;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    resetAreasAndFetch() {
        this.unmodifiedEditItem = null;
        this.updateAreas = [];
        this.fetchItem();
    }
    fetchItem() {
        const request = this.getListRequest;
        request.includeInactive = this.includeInactive;
        request.entityId = this.getId;
        subscriptionModule.fetchSubscriptions(request);
    }
};
__decorate([
    Prop()
], SubscriptionTable.prototype, "subscriber", void 0);
__decorate([
    Prop()
], SubscriptionTable.prototype, "subscriptions", void 0);
__decorate([
    Prop()
], SubscriptionTable.prototype, "searchTerm", void 0);
__decorate([
    Prop({ default: false })
], SubscriptionTable.prototype, "showCreateButton", void 0);
__decorate([
    Prop({ default: false })
], SubscriptionTable.prototype, "showIncludeInactive", void 0);
__decorate([
    Watch("subscriber")
], SubscriptionTable.prototype, "onSubscriberChanged", null);
__decorate([
    Watch("getListRequest")
], SubscriptionTable.prototype, "onListRequestChanged", null);
__decorate([
    Watch("showQrModal")
], SubscriptionTable.prototype, "onShowQrModalChanged", null);
__decorate([
    Watch("editedItem", { deep: true })
], SubscriptionTable.prototype, "onEditChanged", null);
__decorate([
    Watch("searchTerm")
], SubscriptionTable.prototype, "onSearchTermChanged", null);
SubscriptionTable = __decorate([
    Component({
        components: {
            CarSubList,
            ConfirmPrompt,
            CustomButton,
            CustomSwitch,
            EntityToolTip,
            QrManagementModal,
            SubscriptionForm,
            SubscriptionPreview,
            SubscriptionHistory,
            CustomDialog,
        },
        methods: {
            synchronizeAreas,
        },
    })
], SubscriptionTable);
export default SubscriptionTable;
