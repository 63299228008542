import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Vue from "vue";
let ConfirmPrompt = class ConfirmPrompt extends Vue {
    action = Action;
    persistent;
    title;
    msgBody;
    value;
    get show() {
        return this.value;
    }
    set show(show) {
        this.$emit("state-changed", show);
    }
};
__decorate([
    Prop()
], ConfirmPrompt.prototype, "persistent", void 0);
__decorate([
    Prop()
], ConfirmPrompt.prototype, "title", void 0);
__decorate([
    Prop()
], ConfirmPrompt.prototype, "msgBody", void 0);
__decorate([
    Prop({ default: false, type: Boolean }) //this bool will make the dialogue visible
], ConfirmPrompt.prototype, "value", void 0);
ConfirmPrompt = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
        },
        methods: {},
    })
], ConfirmPrompt);
export default ConfirmPrompt;
