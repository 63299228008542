import { __decorate } from "tslib";
import { sharedChartOptions, default_colors, } from "@/components/statistics/chartOptions";
import { statisticsModule } from "@/store/modules/statisticsModule";
import Component from "vue-class-component";
import InfoCircle from "@/components/shared/InfoCircle.vue";
import LineChart from "@/components/shared/LineChart.vue";
import moment from "moment";
import Vue from "vue";
import { mapLabel, toLocaleFormattedDayOfWeek } from "@/components/statistics/chartHelpers";
import ChartWrapper from "./ChartWrapper.vue";
let OccupancyGraphs = class OccupancyGraphs extends Vue {
    chartOptions = sharedChartOptions;
    averageOccupancyGraphOptions = {
        chartTitle: "statistics_average_occupancy",
        label: "parkings",
        xAxisTitle: "time",
        yAxisTitle: "statistics_average_occupancy",
        labelOnHover: (tooltipItem, data) => {
            return `${"statistics_average_occupancy".t()}: ${tooltipItem.value ?? 0}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : " - 24:00").toString()}`;
        },
    };
    averageOccupancyPerWeekdayGraphOptions = {
        chartTitle: "statistics_average_occupancy_per_weekday",
        label: "parkings",
        xAxisTitle: "time",
        yAxisTitle: "statistics_average_occupancy_per_weekday",
        labelOnHover: (tooltipItem, data) => {
            return `${data?.datasets[tooltipItem.datasetIndex]?.label}: ${tooltipItem.value ??
                0} ${mapLabel(Number(tooltipItem.value), "parking").capitalize()}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : " - 24:00").toString()}`;
        },
    };
    occupancyGraphOptions = {
        chartTitle: "statistics_occupancy",
        label: "parkings",
        xAxisTitle: "time",
        yAxisTitle: "statistics_occupancy",
        labelOnHover: (tooltipItem, data) => {
            return `${"parkings".t()}: ${tooltipItem.value ?? 0}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : "").toString()}`;
        },
    };
    get getAverageOccupancyGraphData() {
        const data = statisticsModule.getStatistics?.statisticsOccupancyGraphs?.averageOccupancyGraph ?? [];
        return {
            labels: data?.map(row => row.from.slice(0, 5)),
            datasets: [
                {
                    label: "statistics_average_occupancy".t(),
                    data: data?.map(row => row.numberOfParkings),
                    borderWidth: 1,
                    borderColor: default_colors[1],
                    backgroundColor: "rgb(76,132,236,0.2)",
                    fill: true,
                    pointRadius: 2,
                    pointHitRadius: 4,
                },
            ],
        };
    }
    get getAverageOccupancyPerWeekdayGraphData() {
        const data = statisticsModule.getStatistics?.statisticsOccupancyGraphs?.averageOccupancyPerWeekdayGraph ??
            [];
        return {
            labels: data?.[0]?.graphIntervals?.map(row => row.from.slice(0, 5)),
            datasets: data?.map((data, i) => ({
                label: toLocaleFormattedDayOfWeek(data.dayOfWeekAsNumber),
                data: data.graphIntervals?.map(row => row.numberOfParkings),
                borderWidth: 1,
                backgroundColor: default_colors[i],
                borderColor: default_colors[i],
                fill: false,
                pointRadius: 3,
            })),
        };
    }
    get getOccupancyGraphData() {
        const data = statisticsModule.getStatistics?.statisticsOccupancyGraphs?.occupancyGraph ?? [];
        return {
            labels: this.occupancyGraphXaxisLabels(data).map(d => d.capitalize()),
            datasets: [
                {
                    label: "parkings".t(),
                    data: data?.map(row => row.numberOfParkings),
                    borderWidth: 1,
                    borderColor: default_colors[1],
                    backgroundColor: "rgb(76,132,236,0.2)",
                    fill: true,
                    pointRadius: 2,
                    pointHitRadius: 4,
                },
            ],
        };
    }
    occupancyGraphXaxisLabels = (data) => {
        if (data === undefined) {
            return [];
        }
        if (data[0]?.fromDay) {
            return data?.map(row => moment(row.fromDay)
                .utc(true)
                .format("DD-MM-YYYY HH:mm"));
        }
        if (data[0]?.fromWeek) {
            return data?.map(row => row.fromWeek.toString());
        }
        return data?.map(row => moment(row.fromMonth, "MM").format("MMM"));
    };
};
OccupancyGraphs = __decorate([
    Component({
        components: {
            InfoCircle,
            LineChart,
            ChartWrapper,
        },
        methods: {
            sharedChartOptions,
        },
    })
], OccupancyGraphs);
export default OccupancyGraphs;
