import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { InputType } from "../shared/enums";
import Component from "vue-class-component";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let Overview = class Overview extends Vue {
    type = InputType;
    onAreaClick(area) {
        this.$router.push(`/areas/${area.id}`);
    }
    sortSubAreas(subAreas) {
        const sortedSubAreas = subAreas
            ?.slice()
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        return sortedSubAreas;
    }
    get search() {
        return areaModule.getAreaSearch;
    }
    set search(text) {
        areaModule.SET_AREA_SEARCH(text);
    }
    get areas() {
        return areaModule.getAreas;
    }
};
Overview = __decorate([
    Component({
        components: {
            CustomTextField,
        },
        methods: {},
    })
], Overview);
export default Overview;
