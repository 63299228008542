import { __decorate } from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
let Spinner = class Spinner extends Vue {
    isLoading;
};
__decorate([
    Prop()
], Spinner.prototype, "isLoading", void 0);
Spinner = __decorate([
    Component
], Spinner);
export default Spinner;
