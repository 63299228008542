import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import CustomButtonExamples from "@/components/designGuide/CustomButtonExamples.vue";
import CustomCheckboxExamples from "@/components/designGuide/CustomCheckboxExamples.vue";
import CustomDatePickerExamples from "@/components/designGuide/CustomDatePickerExamples.vue";
import CustomDateTimePickerExamples from "@/components/designGuide/CustomDateTimePickerExamples.vue";
import CustomDialogExamples from "@/components/designGuide/CustomDialogExamples.vue";
import CustomFileInputExamples from "@/components/designGuide/CustomFileInputExamples.vue";
import CustomSelectExamples from "@/components/designGuide/CustomSelectExamples.vue";
import CustomSliderExamples from "@/components/designGuide/CustomSliderExamples.vue";
import CustomSnackbarExamples from "@/components/designGuide/CustomSnackbarExamples.vue";
import CustomSwitchExamples from "@/components/designGuide/CustomSwitchExamples.vue";
import CustomTextAreaExamples from "@/components/designGuide/CustomTextAreaExamples.vue";
import CustomTextFieldExamples from "@/components/designGuide/CustomTextFieldExamples.vue";
import CustomTimePickerExamples from "@/components/designGuide/CustomTimePickerExamples.vue";
import CustomTreeSelectExamples from "@/components/designGuide/CustomTreeSelectExamples.vue";
import Vue from "vue";
let DesignGuidePage = class DesignGuidePage extends Vue {
};
DesignGuidePage = __decorate([
    Component({
        components: {
            CustomButtonExamples,
            CustomCheckboxExamples,
            CustomDatePickerExamples,
            CustomDateTimePickerExamples,
            CustomDialogExamples,
            CustomFileInputExamples,
            CustomSelectExamples,
            CustomSliderExamples,
            CustomSnackbarExamples,
            CustomSwitchExamples,
            CustomTextAreaExamples,
            CustomTextFieldExamples,
            CustomTimePickerExamples,
            CustomTreeSelectExamples
        },
        methods: {},
    })
], DesignGuidePage);
export default DesignGuidePage;
