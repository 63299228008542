import { __decorate } from "tslib";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { featureModule } from "@/store/modules/featureModule";
import { readUploadedFileAsText } from "@/util/imageconverter";
import { required, maxFileSize } from "@/components/shared/validationRules";
import CustomFileInput from "@/components/shared/CustomFileInput.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTreeSelect from "../shared/CustomTreeSelect.vue";
import AreaTreeSelect from "@/components/treeSelects/AreaTreeSelect.vue";
import Vue from "vue";
import { ModeEnum } from "@/store/modules/enums";
import router from "@/router";
let ModeForm = class ModeForm extends Vue {
    form;
    valid;
    resetCount;
    subscriptionTypes;
    areas;
    modeConfig;
    emailTemplates;
    user;
    headerLogo = null;
    footerLogo = null;
    onCountChaged() {
        this.$refs.modeform.resetValidation();
    }
    useSubareas() {
        return featureModule.useSubAreas;
    }
    onAreasChange(list) {
        this.modeConfig.areas = list;
    }
    async setImage(target, file) {
        try {
            const fileContents = await readUploadedFileAsText(file);
            this.modeConfig[target] = fileContents;
        }
        catch (e) {
            // console.log()
        }
    }
    get formValid() {
        return this.valid;
    }
    set formValid(valid) {
        this.$emit("valid-changed", valid);
    }
    get modeAreas() {
        return this.modeConfig.areas;
    }
    set modeAreas(areas) {
        this.modeConfig.areas = areas;
    }
    get getFeatures() {
        return featureModule.getLoggedInCustomerFeatures
            .filter(feature => router.getRoutes().find(route => route.meta && route.meta.modes && route.meta.modes.includes(this.user?.modeID) && route.meta.feature && route.meta.feature == feature.featureID));
    }
    get getMultipleSelectionText() {
        return this.modeConfig.features.length > 0 ? this.modeConfig.features[0].feature : "";
    }
    get showFeatures() {
        return this.user?.modeID && (this.user.modeID == parseInt(ModeEnum.Delegate.toString()) || this.user.modeID == parseInt(ModeEnum.ParkAdmin.toString()));
    }
};
__decorate([
    Ref("modeform")
], ModeForm.prototype, "form", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "valid", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "resetCount", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "subscriptionTypes", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "areas", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "modeConfig", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "emailTemplates", void 0);
__decorate([
    Prop()
], ModeForm.prototype, "user", void 0);
__decorate([
    Watch("resetCount")
], ModeForm.prototype, "onCountChaged", null);
ModeForm = __decorate([
    Component({
        components: {
            CustomFileInput,
            CustomSelect,
            CustomTreeSelect,
            AreaTreeSelect
        },
        methods: {
            maxFileSize,
            required,
        },
    })
], ModeForm);
export default ModeForm;
