import { __decorate } from "tslib";
import { datawashModule } from "@/store/modules/datawashmodule";
import { Prop } from "vue-property-decorator";
import { utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import Component from "vue-class-component";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import Vue from "vue";
let SuccessPanel = class SuccessPanel extends Vue {
    text;
    get getStatusText() {
        if (datawashModule.shouldCheckForOrphans) {
            return "foundOrphanParkings".tc(this.getOrphanCount > 1 ? 2 : 1, {
                count: this.getOrphanCount,
            });
        }
        return "foundParentParkings".tc(this.getParkingRightCount > 1 ? 2 : 1, {
            count: this.getParkingRightCount,
        });
    }
    get selectedParkingRight() {
        return datawashModule.getSelectedParkingRight;
    }
    set selectedParkingRight(parkingRight) {
        datawashModule.SET_SELECTED_PARKING_RIGHT(parkingRight);
    }
    get selectedOrphan() {
        return datawashModule.getSelectedOrphan;
    }
    set selectedOrphan(orphan) {
        datawashModule.SET_SELECTED_ORPHAN(orphan);
    }
    get getOrphans() {
        return datawashModule.getOrphans;
    }
    get getParkingRights() {
        return datawashModule.getParkingRights;
    }
    get getOrphanCount() {
        return datawashModule.getOrphans.length;
    }
    getFormattedDate(date) {
        return date
            ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY")
            : "";
    }
    get getParkingRightCount() {
        return datawashModule.getParkingRights ? datawashModule.getParkingRights.length : 0;
    }
    getLicensePlateText(idents) {
        return idents ? idents[idents.length - 1] : "";
    }
};
__decorate([
    Prop()
], SuccessPanel.prototype, "text", void 0);
SuccessPanel = __decorate([
    Component({
        components: {
            CustomSelect,
        },
        methods: {},
    })
], SuccessPanel);
export default SuccessPanel;
