import { __decorate } from "tslib";
import { areaModule } from "@/store/modules/areaModule";
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { featureModule } from "@/store/modules/featureModule";
import { generateTree, getObjectsByKeyValue } from "@/components/treeSelects/util";
import { getAreasFromNodes } from "@/util/area/areautil";
let AreaTreeSelect = class AreaTreeSelect extends Vue {
    nodes = [];
    initialSelection;
    customAreas;
    treeNodesWatcher() {
        this.nodes = generateTree([...this.areaTreeNodes].map(area => {
            return {
                id: `${area.name}-${area.id}-1`,
                name: area.name,
                mappedObj: area,
                selected: this.getInitialSelection
                    ? Boolean(getObjectsByKeyValue(this.getInitialSelection, "id", `${area.name}-${area.id}-1`)[0])
                    : false,
                nodes: area.subAreas.map(subArea => {
                    return {
                        id: `${subArea.name}-${subArea.id}-2`,
                        name: subArea.name,
                        mappedObj: subArea,
                        selected: this.getInitialSelection
                            ? Boolean(getObjectsByKeyValue(this.getInitialSelection, "id", `${subArea.name}-${subArea.id}-2`)[0])
                            : false,
                    };
                }),
            };
        }));
    }
    onSelectionChange(nodes) {
        const areasSelected = getAreasFromNodes(nodes);
        this.$emit("onAreasChange", areasSelected);
    }
    get areaTreeNodes() {
        const watcher = this.initialSelection;
        return this.customAreas ?? areaModule.areaTreeNodes;
    }
    get useSubAreas() {
        return featureModule.useSubAreas;
    }
    get getInitialSelection() {
        return this.initialSelection == null
            ? []
            : this.initialSelection.map(area => ({
                ...area,
                id: `${area.name}-${area.id}-1`,
                subAreas: area.subAreas.map(subArea => ({
                    ...subArea,
                    id: `${subArea.name}-${subArea.id}-2`,
                })),
            }));
    }
};
__decorate([
    Prop()
], AreaTreeSelect.prototype, "initialSelection", void 0);
__decorate([
    Prop()
], AreaTreeSelect.prototype, "customAreas", void 0);
__decorate([
    Watch("areaTreeNodes", { immediate: true, deep: true })
], AreaTreeSelect.prototype, "treeNodesWatcher", null);
AreaTreeSelect = __decorate([
    Component({
        components: {},
        methods: {},
    })
], AreaTreeSelect);
export default AreaTreeSelect;
