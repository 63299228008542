import { __decorate } from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
let InfoCircle = class InfoCircle extends Vue {
    data;
    title;
};
__decorate([
    Prop({ required: true })
], InfoCircle.prototype, "data", void 0);
__decorate([
    Prop({ required: true })
], InfoCircle.prototype, "title", void 0);
InfoCircle = __decorate([
    Component
], InfoCircle);
export default InfoCircle;
