import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import { constantsModule } from "@/store/modules/constantsModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import Vue from "vue";
let ModeLoginCard = class ModeLoginCard extends Vue {
    action = Action;
    modeLogin;
    editLogin() {
        this.$emit("modeloginclicked", this.modeLogin);
    }
    getModeText() {
        let mode;
        if (constantsModule.getModes)
            mode = constantsModule.getModes.filter((m) => m.modeID === this.modeLogin.modeID)[0];
        if (mode)
            return mode.mode;
        return "";
    }
};
__decorate([
    Prop()
], ModeLoginCard.prototype, "modeLogin", void 0);
ModeLoginCard = __decorate([
    Component({
        components: {
            CustomButton,
        },
    })
], ModeLoginCard);
export default ModeLoginCard;
