import { __decorate } from "tslib";
import { Criticality, notificationModule, } from "@/store/modules/notificationModule";
import { EmailTemplateUpdateRequest, ParagraphRequest, ParagraphUpdateRequest, } from "@/apiclient/client";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component, Prop } from "vue-property-decorator";
import { convertFileToBase64 } from "@/util/imageconverter";
import { emailModule } from "@/store/modules/emailModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { InputType } from "@/components/shared/enums";
import { paragraphModule } from "@/store/modules/paragrahModule";
import CreateParagrahModuleRequest from "@/util/paragraphModule/createParagraphModuleRequest";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomFileInput from "@/components/shared/CustomFileInput.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import DeleteParagraphModuleRequest from "@/util/paragraphModule/deleteParagraphModuleRequest";
import draggable from "vuedraggable";
import EmailTemplateModuleRequest from "@/util/storerequest/emailTemplateModuleRequest";
import TemplateSection from "@/components/settings/TemplateSection.vue";
import UpdateParagrahModuleRequest from "@/util/paragraphModule/updateParagraphModuleRequest";
import Vue from "vue";
let EmailTemplateEditor = class EmailTemplateEditor extends Vue {
    type = InputType;
    action = Action;
    emailTemplate;
    checkMove(event) {
        //Make a put to save the order on the backend here
        const indexFrom = event.draggedContext.index;
        const indexTo = event.draggedContext.futureIndex;
        if (this.emailTemplate.paragraphs) {
            const replacedItem = this.emailTemplate.paragraphs[indexTo];
            const movedItem = this.emailTemplate.paragraphs[indexFrom];
            //update the sort order
            replacedItem.order = indexFrom;
            movedItem.order = indexTo;
            this.updateSection(replacedItem);
            this.updateSection(movedItem);
        }
    }
    getEmailTemplateName() {
        if (this.emailTemplate) {
            return this.emailTemplate.emailTemplateName;
        }
    }
    updateTemplate() {
        //Set the update
        let update = new EmailTemplateUpdateRequest();
        update.emailTemplateName = this.emailTemplate.emailTemplateName;
        update.emailTemplateTypeID = emailModule.emailTemplateTypeValues.find((t) => t.enumVal == this.emailTemplate.emailTemplateType)?.emailTemplateTypeID;
        update.subject = this.emailTemplate.subject;
        update.config = this.emailTemplate.config;
        update.signatureLogo = this.emailTemplate.signatureLogo;
        let request = new EmailTemplateModuleRequest(authenticationModule.getCustomerId, this.emailTemplate.id || 0, update);
        emailTemplateModule
            .patchEmailTemplate(request)
            .then(() => {
            //emailTemplateModule.fetchEmailConfigurations(authenticationModule.getCustomerId);
            emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    deleteParagraph(paragrahId) {
        let request = new DeleteParagraphModuleRequest(authenticationModule.getCustomerId, this.emailTemplate.id || 0, paragrahId);
        paragraphModule
            .deleteParagrah(request)
            .then(() => {
            //emailTemplateModule.fetchEmailConfigurations(authenticationModule.getCustomerId);
            emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    addParagraph() {
        let paragraphCreate = new ParagraphRequest();
        paragraphCreate.title = "title";
        paragraphCreate.body = "text";
        let request = new CreateParagrahModuleRequest(authenticationModule.getCustomerId, this.emailTemplate.id || 0, paragraphCreate);
        paragraphModule
            .createParagraph(request)
            .then(() => {
            //emailTemplateModule.fetchEmailConfigurations(authenticationModule.getCustomerId);
            emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    updateSection(templateSection) {
        let update = new ParagraphUpdateRequest();
        update.title = templateSection.title;
        update.body = templateSection.body;
        update.order = templateSection.order;
        let request = new UpdateParagrahModuleRequest(authenticationModule.getCustomerId, this.emailTemplate.id || 0, update, templateSection.id || 0);
        paragraphModule.patchParagraph(request);
    }
    get subject() {
        return this.emailTemplate.subject || "";
    }
    set subject(subject) {
        this.emailTemplate.subject = subject;
    }
    get signatureLogo() {
        return new File([], "");
    }
    set signatureLogo(logo) {
        convertFileToBase64(logo).then((response) => {
            this.emailTemplate.signatureLogo = response;
            this.updateTemplate();
        });
    }
};
__decorate([
    Prop()
], EmailTemplateEditor.prototype, "emailTemplate", void 0);
EmailTemplateEditor = __decorate([
    Component({
        components: {
            CustomButton,
            CustomFileInput,
            CustomTextField,
            draggable,
            TemplateSection,
        },
    })
], EmailTemplateEditor);
export default EmailTemplateEditor;
