import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import CustomCheckbox from "@/components/shared/CustomCheckbox.vue";
let CustomCheckboxExamples = class CustomCheckboxExamples extends Vue {
    checkbox1 = false;
    checkbox2 = false;
    onCheckbox2Change(isChecked) {
        // console.log("onCheckbox2Change: " + isChecked);
    }
};
CustomCheckboxExamples = __decorate([
    Component({
        components: {
            CustomCheckbox,
        },
        methods: {},
    })
], CustomCheckboxExamples);
export default CustomCheckboxExamples;
