import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import Vue from "vue";
import moment from "moment";
import { statisticsModule } from "@/store/modules/statisticsModule";
import { InputType } from "../shared/enums";
import CustomDateTimePicker from "../shared/CustomDateTimePicker.vue";
import CustomDatePicker from "../shared/CustomDatePicker.vue";
let TestSearch = class TestSearch extends Vue {
    type = InputType;
    action = Action;
    testChartData = {
        searchStart: moment()
            .startOf("day")
            .format(),
        searchEnd: moment()
            .endOf("day")
            .format(),
        minutesPerInterval: 60,
        numberOfIntervals: 24,
        skipIntervals: 0,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    parkings = [];
    submitChartData() {
        const fromParkings = this.parkings.map((parking) => moment(parking.from).unix());
        const toParkings = this.parkings.map((parking) => moment(parking.to).unix());
        statisticsModule.getGraphDataTest({
            searchStart: moment(this.testChartData.searchStart)
                .startOf("day")
                .utc(false)
                .format(),
            searchEnd: moment(this.testChartData.searchEnd)
                .endOf("day")
                .utc(false)
                .format(),
            minutesPerInterval: this.testChartData.minutesPerInterval,
            numberOfIntervals: this.testChartData.numberOfIntervals,
            skipIntervals: this.testChartData.skipIntervals,
            from: fromParkings,
            to: toParkings,
            timeZone: this.testChartData.timeZone,
        });
    }
    addParking() {
        this.parkings.push({
            id: this.uuidv4(),
            from: moment().format(),
            to: moment()
                .add("hours", 1)
                .format(),
        });
    }
    removeParking(id) {
        this.parkings = this.parkings.filter((parking) => parking.id !== id);
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    }
};
TestSearch = __decorate([
    Component({
        components: {
            CustomTextField,
            CustomDateTimePicker,
            CustomDatePicker,
            CustomButton,
        },
        methods: {},
    })
], TestSearch);
export default TestSearch;
