import { __decorate } from "tslib";
import Vue from "vue";
import draggable from "vuedraggable";
import { Component } from "vue-property-decorator";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
let ReceiptPreview = class ReceiptPreview extends Vue {
    get getPreviewMarkup() {
        return chargeEditorModule.getHtmlPreview;
    }
};
ReceiptPreview = __decorate([
    Component({ components: { draggable } })
], ReceiptPreview);
export default ReceiptPreview;
