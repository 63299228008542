export class StorageService {
    key;
    storage;
    constructor(key) {
        this.key = key;
        this.storage = JSON.parse(localStorage.getItem(this.key) ?? JSON.stringify([]));
    }
    saveStore() {
        localStorage.setItem(this.key, JSON.stringify(this.storage));
    }
    getStore() {
        return JSON.parse(localStorage.getItem(this.key) ?? JSON.stringify([]));
    }
    clearStore() {
        this.storage = [];
        this.saveStore();
    }
    addItem(item) {
        this.storage.push(item);
        this.saveStore();
    }
    addItems(items) {
        this.storage.push(...items);
        this.saveStore();
    }
    getItem(item) {
        return this.storage.find(i => JSON.stringify(i) === JSON.stringify(item));
    }
    getItemByProperty(prop, value) {
        return this.storage.find(i => Object.prototype.hasOwnProperty.call(i, prop) && i[prop] == value);
    }
    removeItem(item) {
        this.storage = this.storage.filter(i => JSON.stringify(i) !== JSON.stringify(item));
        this.saveStore();
    }
    removeItemByProperty(prop, value) {
        const item = this.getItemByProperty(prop, value);
        if (!item)
            return;
        this.removeItem(item);
    }
    updateItem(item, updatedItem) {
        for (let key of Object.keys(updatedItem)) {
            item[key] = updatedItem[key];
        }
        this.saveStore();
    }
    updateItemPropertyValue(prop, searchValue, updatedValue) {
        const item = this.getItemByProperty(prop, searchValue);
        if (!item)
            return;
        item[prop] = updatedValue;
        this.saveStore();
    }
}
