import { __decorate } from "tslib";
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { prsTagModule } from "@/store/modules/prsTagModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomCheckbox from "@/components/shared/CustomCheckbox.vue";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { InputType } from "./enums";
import { StorageService } from "@/util/storage";
import { required } from "@/components/shared/validationRules";
var Tags;
(function (Tags) {
    Tags["Ignored"] = "ignored";
    Tags["InclusionTags"] = "inclusionTags";
    Tags["ExclusionTags"] = "exclusionTags";
    Tags["AcceptanceTags"] = "acceptanceTags";
})(Tags || (Tags = {}));
let CustomTagsFilter = class CustomTagsFilter extends Vue {
    selectedTags;
    page;
    showTooltip = false;
    showFilterModal = false;
    showPresetsModal = false;
    presetFormValid = false;
    presetTitle = "";
    presetTitleToOverwrite = "";
    presetTitleToDelete = "";
    overWritePresetCheckbox = false;
    existingPresets = [];
    selectedPreset = null;
    tab = null;
    tags = [];
    presetsStorage;
    action = Action;
    type = InputType;
    chosenHeader = null;
    beforeMount() {
        this.presetsStorage = new StorageService(`${authenticationModule.getLoggedInCustomerName}-${this.page}-tags-presets`);
        this.selectedPreset = this.presetsStorage.getItemByProperty("default", true);
        this.existingPresets = this.presetsStorage.getStore();
    }
    mounted() {
        if (this.selectedPreset) {
            this.$emit("on-tags-saved", this.selectedPreset.selectedTags);
        }
    }
    filterModalWatcher() {
        this.tags =
            this.tags.length > 0
                ? this.tags
                : this.mapPrsTags(this.selectedPreset ? this.selectedPreset.selectedTags : this.selectedTags);
    }
    selectedPresetWatcher() {
        this.presetsStorage.updateItemPropertyValue("default", true, false);
        if (this.selectedPreset) {
            const selectedPreset = this.presetsStorage.getItemByProperty("title", this.selectedPreset.title);
            this.presetsStorage.updateItem(selectedPreset, {
                ...selectedPreset,
                default: true,
            });
        }
        this.tags = this.mapPrsTags(this.selectedPreset ? this.selectedPreset.selectedTags : this.selectedTags);
    }
    tagsWatcher(tags) {
        const allIgnored = tags.every(tag => tag.tag === Tags.Ignored);
        const allExcluded = tags.every(tag => tag.tag === Tags.ExclusionTags);
        const allIncluded = tags.every(tag => tag.tag === Tags.InclusionTags);
        const allAccepted = tags.every(tag => tag.tag === Tags.AcceptanceTags);
        if (allIgnored) {
            return (this.chosenHeader = Tags.Ignored);
        }
        if (allExcluded) {
            return (this.chosenHeader = Tags.ExclusionTags);
        }
        if (allIncluded) {
            return (this.chosenHeader = Tags.InclusionTags);
        }
        if (allAccepted) {
            return (this.chosenHeader = Tags.AcceptanceTags);
        }
        this.chosenHeader = null;
    }
    presetsModalWatcher() {
        this.existingPresets = this.presetsStorage.getStore();
    }
    saveTags() {
        this.$emit("on-tags-saved", this.mapSelectedTags());
        this.showFilterModal = false;
    }
    savePreset() {
        const existingPreset = this.presetsStorage.getItemByProperty("title", this.presetTitleToOverwrite);
        if (this.presetTitle?.trim() == "")
            return;
        if (this.overWritePresetCheckbox && this.presetTitleToOverwrite && existingPreset) {
            this.presetsStorage.updateItem(existingPreset, {
                title: this.presetTitle,
                selectedTags: this.mapSelectedTags(),
                default: true,
                headerValue: this.chosenHeader,
            });
        }
        else {
            this.presetsStorage.addItem({
                title: this.presetTitle,
                selectedTags: this.mapSelectedTags(),
                default: true,
                headerValue: this.chosenHeader,
            });
        }
        this.selectedPreset = this.presetsStorage.getItemByProperty("title", this.presetTitle);
        this.clearPresetModalValues();
    }
    deletePreset() {
        this.presetsStorage.removeItemByProperty("title", this.presetTitleToDelete);
        this.selectedPreset = null;
        this.clearPresetModalValues();
    }
    clearPresetModalValues() {
        this.presetTitle = "";
        this.presetTitleToOverwrite = "";
        this.overWritePresetCheckbox = false;
        this.showPresetsModal = false;
    }
    getTranslation(item) {
        return item.prsTagTranslations?.find(x => x.languageCode === (this.$i18n.locale == "da" ? 1 : 2))?.translatedName;
    }
    mapSelectedTags(incTags = null) {
        const tags = incTags ?? this.tags;
        return {
            inclusionTags: tags.filter(t => t.tag === Tags.InclusionTags).map(t => t.tagObj),
            exclusionTags: tags.filter(t => t.tag === Tags.ExclusionTags).map(t => t.tagObj),
            acceptanceTags: tags.filter(t => t.tag === Tags.AcceptanceTags).map(t => t.tagObj),
        };
    }
    mapPrsTags(selectedTags) {
        return this.getPrsTags.map(tag => {
            let tagName = Tags.Ignored;
            if (selectedTags.inclusionTags.find(t => t.name == tag.name)) {
                tagName = Tags.InclusionTags;
            }
            if (selectedTags.exclusionTags.find(t => t.name == tag.name)) {
                tagName = Tags.ExclusionTags;
            }
            if (selectedTags.acceptanceTags.find(t => t.name == tag.name)) {
                tagName = Tags.AcceptanceTags;
            }
            return {
                name: tag.name,
                tag: tagName,
                tagObj: tag,
            };
        });
    }
    handleHeaderClick(headerValue) {
        this.tags = this.tags.map(tag => ({
            ...tag,
            tag: headerValue,
        }));
    }
    get filterHeaders() {
        return [
            {
                sortable: false,
                align: "left",
                text: "Filtre",
            },
            {
                text: "filterTags_ignored".t(),
                value: Tags.Ignored,
                sortable: false,
                align: "center",
            },
            {
                text: "filterTags_exclusionTag".t(),
                value: Tags.ExclusionTags,
                sortable: false,
                align: "center",
            },
            {
                text: "filterTags_inclusionTag".t(),
                value: Tags.InclusionTags,
                sortable: false,
                align: "center",
            },
            {
                text: "filterTags_acceptanceTag".t(),
                value: Tags.AcceptanceTags,
                sortable: false,
                align: "center",
            },
        ];
    }
    get getAnyTagsSelected() {
        return [
            ...this.tags.filter(t => t.tag == Tags.InclusionTags),
            ...this.tags.filter(t => t.tag == Tags.ExclusionTags),
            ...this.tags.filter(t => t.tag == Tags.AcceptanceTags),
        ].length;
    }
    get getPrsTags() {
        return prsTagModule.getPrsTags;
    }
};
__decorate([
    Prop()
], CustomTagsFilter.prototype, "selectedTags", void 0);
__decorate([
    Prop()
], CustomTagsFilter.prototype, "page", void 0);
__decorate([
    Watch("showFilterModal")
], CustomTagsFilter.prototype, "filterModalWatcher", null);
__decorate([
    Watch("selectedPreset")
], CustomTagsFilter.prototype, "selectedPresetWatcher", null);
__decorate([
    Watch("tags", { deep: true })
], CustomTagsFilter.prototype, "tagsWatcher", null);
__decorate([
    Watch("showPresetsModal")
], CustomTagsFilter.prototype, "presetsModalWatcher", null);
CustomTagsFilter = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
            CustomTextField,
            CustomSelect,
            CustomCheckbox,
        },
        methods: {
            required,
        },
        data() {
            return {
                Tags,
            };
        },
    })
], CustomTagsFilter);
export default CustomTagsFilter;
