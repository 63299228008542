import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import { CostumerResponse, } from "@/apiclient/client";
import { apiClient } from "@/apiclient/baseclient";
import { notificationModule, Criticality } from "./notificationModule";
import { authenticationModule } from "./authenticationModule";
let CustomerModule = class CustomerModule extends VuexModule {
    customers = [];
    customer = new CostumerResponse();
    dataConfigurations = [];
    customerLogo = "";
    get getCustomers() {
        return this.customers;
    }
    get getCustomer() {
        return this.customer;
    }
    get getDataConfigurations() {
        return this.dataConfigurations;
    }
    get getCustomerLogo() {
        return this.customerLogo;
    }
    async fetchCustomers() {
        var result = await apiClient.costumersGET();
        this.context.commit("SET_CUSTOMERS", result.data);
    }
    async fetchCustomerById(customerId) {
        return await apiClient
            .costumersGET2(customerId)
            .then(response => {
            this.context.commit("SET_CUSTOMER", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async impersonateCustomer(customerId) {
        return await apiClient.personate(customerId);
    }
    async fetchDataConfigurations(costumerId) {
        return await apiClient
            .maskingGET(costumerId)
            .then(response => {
            this.context.commit("SET_DATA_CONFIGURATIONS", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async updateDataConfiguration({ customerId, dataConfig, }) {
        return await apiClient
            .maskingPUT(customerId, dataConfig)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_data_config".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchCustomerLogo() {
        return await apiClient.logoGET(authenticationModule.customerId).then(logo => {
            this.context.commit("SET_CUSTOMER_LOGO", logo);
            return logo;
        });
    }
    async addCustomerLogo(customerLogo) {
        return await apiClient
            .logoPOST(authenticationModule.customerId, customerLogo)
            .then(logo => {
            this.context.commit("SET_CUSTOMER_LOGO", logo);
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_logo".t(),
                criticality: Criticality.Success,
            });
            return logo;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
            return "";
        });
    }
    SET_CUSTOMER(customer) {
        this.customer = customer;
    }
    SET_CUSTOMERS(customers) {
        this.customers = customers;
    }
    SET_DATA_CONFIGURATIONS(dataConfigurations) {
        this.dataConfigurations = dataConfigurations;
    }
    SET_CUSTOMER_LOGO(logo) {
        this.customerLogo = logo;
    }
};
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "fetchCustomers", null);
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "fetchCustomerById", null);
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "impersonateCustomer", null);
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "fetchDataConfigurations", null);
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "updateDataConfiguration", null);
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "fetchCustomerLogo", null);
__decorate([
    Action({ rawError: true })
], CustomerModule.prototype, "addCustomerLogo", null);
__decorate([
    Mutation
], CustomerModule.prototype, "SET_CUSTOMER", null);
__decorate([
    Mutation
], CustomerModule.prototype, "SET_CUSTOMERS", null);
__decorate([
    Mutation
], CustomerModule.prototype, "SET_DATA_CONFIGURATIONS", null);
__decorate([
    Mutation
], CustomerModule.prototype, "SET_CUSTOMER_LOGO", null);
CustomerModule = __decorate([
    Module({ dynamic: true, store, name: "customerModule", namespaced: true })
], CustomerModule);
export default CustomerModule;
export const customerModule = getModule(CustomerModule);
