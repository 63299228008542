export default class SubscriberSubscriptionRequest {
    subscriberId;
    subscription;
    subscriptionUpdate;
    subscriptionId;
    constructor(subscriberId, subscription, subscriptionUpdate, subscriptionId) {
        this.subscriberId = subscriberId;
        this.subscription = subscription;
        this.subscriptionUpdate = subscriptionUpdate;
        this.subscriptionId = subscriptionId;
    }
}
