import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import CustomTreeSelectOption from "@/components/shared/CustomTreeSelectOption.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import { InputType } from "@/components/shared/enums";
import { searchNestedObjects, getObjectsByKeyValue, getFirstLevelNode, toggleSubNodes, toggleParentNode, } from "@/components/treeSelects/util";
let CustomTreeSelect = class CustomTreeSelect extends Vue {
    nodes;
    initialSelection;
    showChildNodes;
    label;
    icon;
    rules;
    disabled;
    flatMode;
    showSearch;
    required;
    searchQuery = "";
    toggleMenuValue = false;
    selection = [];
    handleNodeClick(node) {
        const [clickedNode] = getObjectsByKeyValue(this.nodes, "id", node.id.toString());
        clickedNode.selected = !clickedNode.selected;
        if (this.flatMode)
            return this.setSelection();
        if (node.nodes && node.nodes.length > 0) {
            toggleSubNodes(node.nodes, clickedNode.selected);
        }
        if (!node.parentNode)
            return this.setSelection();
        toggleParentNode(this.nodes, getObjectsByKeyValue(this.nodes, "id", node.parentNode.id.toString())[0]);
        this.setSelection();
    }
    setSelection() {
        const tempSelection = this.mapSelection();
        this.selection = tempSelection;
        this.$emit("onSelectionChange", tempSelection);
    }
    mapSelection() {
        if (this.flatMode)
            return this.getSelectedNodes;
        const nodesSelection = this.getSelectedNodes.map(n => {
            return {
                ...n,
                nodes: [],
            };
        });
        nodesSelection.forEach(node => {
            if (node.parentNode) {
                const parent = nodesSelection.find(n => n.id == node.parentNode?.id);
                parent?.nodes.push(node);
            }
        });
        return nodesSelection.filter(n => !n.parentNode);
    }
    toggleMenu() {
        this.toggleMenuValue = true;
        this.searchQuery = "";
    }
    get getNodes() {
        if (!this.showSearch || !this.searchQuery || this.searchQuery == "")
            return this.nodes;
        if (!this.showChildNodes)
            return this.nodes.filter(node => node.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
        const nodesFound = searchNestedObjects(this.nodes, "name", this.searchQuery, !this.showChildNodes);
        if (!nodesFound || nodesFound.length <= 0)
            return this.nodes;
        const firstLevelNodes = [];
        nodesFound.forEach(node => firstLevelNodes.push(getFirstLevelNode(this.nodes, node)));
        return [...new Map(firstLevelNodes.map(node => [node.id, node])).values()];
    }
    get getSelectedNodes() {
        return getObjectsByKeyValue(JSON.parse(JSON.stringify(this.nodes)), "selected", "true");
    }
    get getSelectedNodesLength() {
        if (!this.showChildNodes)
            return getObjectsByKeyValue(JSON.parse(JSON.stringify(this.nodes)), "selected", "true", true).length;
        return this.getSelectedNodes.length;
    }
    get getLabel() {
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
};
__decorate([
    Prop({ required: true })
], CustomTreeSelect.prototype, "nodes", void 0);
__decorate([
    Prop({ required: false })
], CustomTreeSelect.prototype, "initialSelection", void 0);
__decorate([
    Prop({ required: false, default: true })
], CustomTreeSelect.prototype, "showChildNodes", void 0);
__decorate([
    Prop({ required: true })
], CustomTreeSelect.prototype, "label", void 0);
__decorate([
    Prop({ required: false })
], CustomTreeSelect.prototype, "icon", void 0);
__decorate([
    Prop({ required: false })
], CustomTreeSelect.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, default: false })
], CustomTreeSelect.prototype, "disabled", void 0);
__decorate([
    Prop({ required: false, default: false })
], CustomTreeSelect.prototype, "flatMode", void 0);
__decorate([
    Prop({ required: false, default: false })
], CustomTreeSelect.prototype, "showSearch", void 0);
__decorate([
    Prop({ required: false, default: false })
], CustomTreeSelect.prototype, "required", void 0);
CustomTreeSelect = __decorate([
    Component({
        methods: {},
        name: "CustomTreeSelect",
        components: {
            CustomTreeSelectOption,
            CustomTextField,
        },
        data() {
            return {
                InputType,
            };
        },
    })
], CustomTreeSelect);
export default CustomTreeSelect;
