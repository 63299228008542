import { __decorate } from "tslib";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomFileInput from "@/components/shared/CustomFileInput.vue";
import Vue from "vue";
let CustomFileInputExamples = class CustomFileInputExamples extends Vue {
    action = Action;
    fileInputFormValid = true;
    fileInput1 = null;
    fileInput2 = null;
    fileInput3 = null;
    onFileInput2Change(date) {
        // console.log("onFileInput2Change: " + date);
    }
    onFileInputFormValidate() {
        this.$refs.fileInputForm.validate();
    }
    onFileInputFormReset() {
        this.$refs.fileInputForm.reset();
    }
    onFileInputFormResetValidation() {
        this.$refs.fileInputForm.resetValidation();
    }
};
CustomFileInputExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomFileInput,
        },
        methods: {
            required,
        },
    })
], CustomFileInputExamples);
export default CustomFileInputExamples;
