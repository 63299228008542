import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTextArea from "../shared/CustomTextArea.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let TemplateSection = class TemplateSection extends Vue {
    type = InputType;
    action = Action;
    templateSection;
    dialogDelete = false;
    closeDelete() {
        this.dialogDelete = false;
    }
    deleteParagraph() {
        this.dialogDelete = false;
        this.$emit("delete-paragraph", this.templateSection.id);
    }
    updateSection() {
        this.$emit("update-section", this.templateSection);
    }
    get templateBody() {
        return this.templateSection.body || "";
    }
    set templateBody(body) {
        this.templateSection.body = body;
    }
    get templateHeader() {
        return this.templateSection.title || "";
    }
    set templateHeader(header) {
        this.templateSection.title = header;
    }
};
__decorate([
    Prop()
], TemplateSection.prototype, "templateSection", void 0);
TemplateSection = __decorate([
    Component({
        components: {
            ConfirmPrompt,
            CustomButton,
            CustomTextArea,
            CustomTextField,
        },
    })
], TemplateSection);
export default TemplateSection;
