import { __decorate } from "tslib";
import { statisticsModule } from "@/store/modules/statisticsModule";
import Component from "vue-class-component";
import InfoCircle from "@/components/shared/InfoCircle.vue";
import LineChart from "@/components/shared/LineChart.vue";
import Vue from "vue";
import { sharedChartOptions, default_colors, } from "@/components/statistics/chartOptions";
import { toLocaleFormattedDayOfWeek } from "./chartHelpers";
import ChartWrapper from "./ChartWrapper.vue";
let ActivityGraphs = class ActivityGraphs extends Vue {
    chartOptions = sharedChartOptions;
    activityGraphOptions = {
        chartTitle: "statistics_activity",
        label: "parkings",
        xAxisTitle: "time",
        yAxisTitle: "entries_and_exits",
        labelOnHover: (tooltipItem, data) => {
            return `${tooltipItem.datasetIndex == 0 ? "parkingEntry".tc(2) : "parkingExit".tc(2)}: ${Math.abs(Number(tooltipItem.value ?? 0))}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : " - 24:00").toString()}`;
        },
        zoomMinRange: {
            y: undefined,
        },
    };
    activityPerWeekdayGraphOptions = {
        chartTitle: "statistics_activity_per_weekday",
        label: "parkings",
        xAxisTitle: "time",
        yAxisTitle: "entries_and_exits",
        labelOnHover: (tooltipItem, data) => {
            return `${data?.datasets[tooltipItem.datasetIndex]?.label}: ${Math.abs(Number(tooltipItem.value ?? 0))}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : " - 24:00").toString()}`;
        },
        zoomMinRange: {
            y: undefined,
        },
    };
    get getActivityGraphData() {
        let data = statisticsModule.getStatistics?.statisticsActivityGraphs?.activityGraph ?? [];
        return {
            labels: data?.map(row => row.from.slice(0, 5)),
            datasets: [
                {
                    label: "statistics_arrivals".t(),
                    data: data?.map(row => row.numberOfArrivals),
                    borderWidth: 1,
                    borderColor: default_colors[0],
                    pointBackgroundColor: default_colors[0],
                    backgroundColor: "rgb(76,132,236,0.2)",
                    fill: true,
                    pointRadius: 2,
                },
                {
                    label: "statistics_departures".t(),
                    data: data?.map(row => -row.numberOfDepartures),
                    borderWidth: 1,
                    borderColor: default_colors[6],
                    pointBackgroundColor: default_colors[6],
                    backgroundColor: "rgb(124,0,0,0.2)",
                    fill: true,
                    pointRadius: 2,
                },
            ],
        };
    }
    get getActivityPerWeekdayGraphData() {
        let data = statisticsModule.getStatistics?.statisticsActivityGraphs?.activityPerWeekdayGraph ?? [];
        return {
            labels: data?.[0]?.graphIntervals?.map(row => row.from.slice(0, 5)),
            datasets: this.getWeekDayDatasets(data),
        };
    }
    getWeekDayDatasets(data) {
        let dataset = [];
        data?.forEach((data, i) => {
            dataset.push({
                label: `${toLocaleFormattedDayOfWeek(data?.dayOfWeekAsNumber)} ${"parkingEntry".tc(2)}`,
                data: data?.graphIntervals?.map(row => row.numberOfArrivals),
                borderWidth: 1,
                backgroundColor: default_colors[i],
                borderColor: default_colors[i],
                fill: false,
                pointRadius: 2,
            });
            dataset.push({
                label: `${toLocaleFormattedDayOfWeek(data?.dayOfWeekAsNumber)} ${"parkingExit".tc(2)}`,
                data: data?.graphIntervals?.map(row => -row.numberOfDepartures),
                borderWidth: 1,
                backgroundColor: default_colors[i],
                borderColor: default_colors[i],
                fill: false,
                pointRadius: 2,
            });
        });
        return dataset;
    }
};
ActivityGraphs = __decorate([
    Component({
        components: {
            InfoCircle,
            LineChart,
            ChartWrapper,
        },
        methods: {
            sharedChartOptions,
        },
    })
], ActivityGraphs);
export default ActivityGraphs;
