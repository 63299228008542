import { __decorate } from "tslib";
import { statisticsModule } from "@/store/modules/statisticsModule";
import Component from "vue-class-component";
import i18n from "@/i18n";
import InfoCircle from "@/components/shared/InfoCircle.vue";
import Vue from "vue";
let Summary = class Summary extends Vue {
    get getSummaryItems() {
        let statistics = statisticsModule.getStatistics;
        return [
            {
                number: statistics.statisticsSummary?.totalNumberOfParkings,
                title: i18n.t("totalNumberOfParkings").toString(),
            },
            {
                number: statistics.statisticsSummary
                    ?.averageParkingTimeInMinutes,
                title: i18n.t("averageParkingTimeInMinutes").toString(),
            },
            {
                number: statistics.statisticsSummary?.totalParkingTimeInHours,
                title: i18n.t("totalParkingTimeInHours").toString(),
            },
        ];
    }
};
Summary = __decorate([
    Component({
        components: {
            InfoCircle,
        },
        methods: {},
    })
], Summary);
export default Summary;
