import { __decorate } from "tslib";
import { CustomerEnum, FeatureEnum } from "@/store/modules/enums";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CustomDialog from "../shared/CustomDialog.vue";
import CustomSelect from "../shared/CustomSelect.vue";
import FeatureAreasToggle from "./FeatureAreasToggle.vue";
let FeatureToggle = class FeatureToggle extends Vue {
    value; //true if feature is toggled
    feature;
    selectedCustomer;
    get internalValue() {
        return this.value;
    }
    get featureHeader() {
        return this.feature?.header;
    }
    get featureDescription() {
        return this.feature?.description;
    }
    get featureDisabled() {
        return this.feature?.featureID === FeatureEnum.Settings && this.selectedCustomer.costumerID === CustomerEnum.Logos;
    }
    get showAreasToggle() {
        return this.feature?.featureID === FeatureEnum.Areas;
    }
    set internalValue(newValue) {
        this.$emit("valueupdated", newValue);
    }
};
__decorate([
    Prop()
], FeatureToggle.prototype, "value", void 0);
__decorate([
    Prop()
], FeatureToggle.prototype, "feature", void 0);
__decorate([
    Prop()
], FeatureToggle.prototype, "selectedCustomer", void 0);
FeatureToggle = __decorate([
    Component({
        components: {
            CustomDialog,
            CustomSelect,
            FeatureAreasToggle
        },
    })
], FeatureToggle);
export default FeatureToggle;
