import { __decorate } from "tslib";
import { Prop, PropSync } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
let CustomSwitch = class CustomSwitch extends Vue {
    syncSwitched;
    label;
    disabled;
    onChange(switched) {
        this.$emit("on-switch-change", switched);
    }
};
__decorate([
    PropSync("switched", { required: true, type: Boolean })
], CustomSwitch.prototype, "syncSwitched", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomSwitch.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomSwitch.prototype, "disabled", void 0);
CustomSwitch = __decorate([
    Component
], CustomSwitch);
export default CustomSwitch;
