import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import { emailModule } from "@/store/modules/emailModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import Vue from "vue";
let DefaultEmailTemplateToggle = class DefaultEmailTemplateToggle extends Vue {
    templateTypeId;
    defaultEmailTemplateID;
    label;
    get selectedTemplate() {
        return emailTemplateModule.getEmailTemplates.filter((t) => t.id === this.defaultEmailTemplateID)[0];
    }
    get NoTemplateText() {
        return this.$tc("emailTemplateNoTemplates");
    }
    set selectedTemplate(template) {
        this.$emit("set-default-template", template.id);
    }
    get emailTemplates() {
        const template = emailModule.emailTemplateTypeValues.find((t) => t.enumVal === this.templateTypeId);
        return emailTemplateModule.getEmailTemplates.filter((t) => t.emailTemplateType === template?.enumVal);
    }
};
__decorate([
    Prop()
], DefaultEmailTemplateToggle.prototype, "templateTypeId", void 0);
__decorate([
    Prop()
], DefaultEmailTemplateToggle.prototype, "defaultEmailTemplateID", void 0);
__decorate([
    Prop()
], DefaultEmailTemplateToggle.prototype, "label", void 0);
DefaultEmailTemplateToggle = __decorate([
    Component({
        components: {
            CustomSelect,
        },
    })
], DefaultEmailTemplateToggle);
export default DefaultEmailTemplateToggle;
