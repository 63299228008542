import { __decorate } from "tslib";
import { QrCodeResponse, SubscriberResponse, } from "@/apiclient/client";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import { statusModule } from "@/store/modules/statusModule";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { ColumnRule, downloadCSV } from "@/util/csvutil";
import { Criticality, notificationModule } from "./notificationModule";
import moment from "moment";
export class CsvDownloadRequest {
    subscriber;
    fetchAll;
    constructor(subscriber, fetchAll) {
        this.subscriber = subscriber;
        this.fetchAll = fetchAll;
    }
}
let SubscriptionModule = class SubscriptionModule extends VuexModule {
    subscription = null;
    subscriptions = [];
    loading = false;
    totalRecords = 0;
    subscriptionTypes = [];
    subscriber = new SubscriberResponse();
    qrResult = new QrCodeResponse();
    entities = [];
    get getQrResult() {
        return this.qrResult;
    }
    get getSubscriberName() {
        if (this.subscriber === null || this.subscriber === undefined)
            return "";
        let name = this.subscriber.subscriberType === 1
            ? this.subscriber.name
            : this.subscriber.firstName + " " + this.subscriber.lastName;
        return name;
    }
    get getSubscription() {
        return this.subscription;
    }
    get getSubscriptions() {
        return this.subscriptions.map(subscription => {
            return {
                ...subscription,
                fromDate: subscription.fromDate
                    ? moment(subscription.fromDate)
                        .local()
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                    : "",
                toDate: subscription.toDate
                    ? moment(subscription.toDate)
                        .local()
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                    : "",
            };
        });
    }
    get getLoadingStatus() {
        return this.loading;
    }
    get getCount() {
        return this.totalRecords;
    }
    get getSubscriptionTypes() {
        return this.subscriptionTypes;
    }
    /**
     * Export the current subscriptions as pdf
     * @param request
     */
    async exportCurrentSubscriptionsAsPDF() {
        const rules = [
            new ColumnRule("primaryArea", "name"),
            new ColumnRule("cars", "licensePlate", true),
            new ColumnRule("areas", "name", true),
            new ColumnRule("subscriptionType", "subscriptionType"),
        ];
        downloadCSV(this.subscriptions.map(subscription => ({
            ...subscription,
            remark: subscription.remark?.replace(/\n/g, " ") ?? "",
        })), rules, `${"subscriptions"}.csv`);
    }
    /**
     * Download all subscriptions for a given subscriber
     * @param subscriber
     */
    async triggerCsvDownload(subscriber) {
        const filename = subscriber.name || `${subscriber.firstName} ${subscriber.lastName} `;
        const pageSize = 25;
        let pageNumber = 1;
        let totalPages = 1;
        let result = [];
        while (pageNumber <= totalPages) {
            const subscriptions = await apiClient.subscriptionsGET(subscriber.id, pageNumber, pageSize);
            totalPages = subscriptions.totalPages;
            result = [
                ...result,
                ...subscriptions.data.map(subscription => {
                    return {
                        ...subscription,
                        remark: subscription.remark?.replace(/\n/g, " ") ?? "",
                    };
                }),
            ];
            pageNumber++;
        }
        const rules = [
            new ColumnRule("primaryArea", "name"),
            new ColumnRule("cars", "licensePlate", true),
            new ColumnRule("areas", "name", true),
            new ColumnRule("subscriptionType", "subscriptionType"),
        ];
        if (result && result.length > 0)
            downloadCSV(result, rules, `${filename}.csv`);
    }
    /**
     * Fetch a subscriber given a subscription id
     * @param subscriberId
     * @returns
     */
    async fetchSubscriber(subscriberId) {
        return apiClient
            .subscribersGET2(subscriberId)
            .then(response => {
            this.context.commit("SET_SUBSCRIBER", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchSubscriptions(request) {
        statusModule.updateLoading(true);
        if (request.entityId) {
            return apiClient
                .subscriptionsGET(request.entityId, request.pageNumber, request.pageSize, request.sortOrderAsc, request.orderBy, request.includeInactive, request.search)
                .then(response => {
                this.context.commit("SET_SUBSCRIPTIONS", response.data);
                this.context.commit("SET_PAGECOUNT", response.totalRecords);
                statusModule.updateLoading(false);
            })
                .catch(error => {
                statusModule.updateLoading(false);
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
        else {
            return apiClient
                .subscriptionsGET2(request.pageNumber, request.pageSize, request.sortOrderAsc, request.orderBy, request.includeInactive, request.search)
                .then(response => {
                this.context.commit("SET_SUBSCRIPTIONS", response.data);
                this.context.commit("SET_PAGECOUNT", response.totalRecords);
                statusModule.updateLoading(false);
            })
                .catch(error => {
                statusModule.updateLoading(false);
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            });
        }
    }
    async fetchQrForSubscription(subscriptionId) {
        return apiClient
            .qRCodeGET(subscriptionId)
            .then(response => {
            const result = response.data;
            if (result) {
                this.context.commit("SET_QR_RESULT", response.data);
            }
            else
                Promise.reject("failed to fetch Qr code");
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async removeAreaFromSubscription(request) {
        return apiClient.areaDELETE(request.subscriptionId, request.area.id);
    }
    async addAreaToSubscription(request) {
        return apiClient.areaPOST(request.subscriptionId, request.area.id);
    }
    async addSubAreaToSubscription(request) {
        return apiClient.subAreaPOST(request.subscriptionId, request.area.id ?? -1);
    }
    async removeSubAreaFromSubscription(request) {
        return apiClient.subAreaDELETE(request.subscriptionId, request.area.id ?? -1);
    }
    async emailTemplateTypeResendQr(subscriptionId) {
        return apiClient.qRCodePATCH(subscriptionId);
    }
    async fetchSubscriptionTypes(customerId) {
        return await apiClient.subscriptiontypesGET(customerId).then(response => {
            this.context.commit("SET_SUBSCRIPTIONTYPES", response.data);
        });
    }
    async updateSubscriptionType(payload) {
        return await apiClient
            .subscriptiontypesPUT(payload.subscriptionType.subscriptionTypeId ?? -1, payload.customerId, payload.subscriptionType)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_subscription".t(),
                criticality: Criticality.Success,
            });
            this.fetchSubscriptionTypes();
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async deleteSubscriptionType(subscriptionTypeID) {
        return await apiClient
            .subscriptiontypesDELETE(subscriptionTypeID)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_subscription_type".t(),
                criticality: Criticality.Success,
            });
            this.fetchSubscriptionTypes();
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_delete_subscriptiontype".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async createSubscriptionType(payload) {
        return await apiClient
            .subscriptiontypesPOST(payload.customerId, payload.subscriptionType)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_subscription_type".t(),
                criticality: Criticality.Success,
            });
            this.fetchSubscriptionTypes();
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async updateSubscription(request) {
        return apiClient
            .subscriptionsPUT(request.subscriptionId, authenticationModule.getCustomerId, request.subscriptionUpdate)
            .then(response => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_subscriber".t(),
                criticality: Criticality.Success,
            });
            return response.data;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async activateSubscription({ subscriptionId, active, }) {
        return apiClient
            .activate2(subscriptionId, active)
            .then(response => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_subscriber".t(),
                criticality: Criticality.Success,
            });
            return response.data;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async createSubscription(request) {
        return apiClient
            .subscription(request.subscriberId, undefined, undefined, authenticationModule.getCustomerId, request.subscription)
            .then(response => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_subscriber".t(),
                criticality: Criticality.Success,
            });
            return response.data;
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async deleteSubscription(subscriptionId) {
        return apiClient
            .subscriptionsDELETE(subscriptionId)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_delete_subscription".t(),
                criticality: Criticality.Success,
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchSubscriptionById(subscriptionId) {
        return apiClient.subscriptionsGET3(subscriptionId).then(response => {
            this.context.commit("SET_SUBSCRIPTION", response.data);
        });
    }
    async fetchSubscriptionHistory(subscriptionId) {
        const res = await apiClient.history(subscriptionId);
        const data = res.data;
        return data;
    }
    async fetchSubscriptionCarHistory(subscriptionId) {
        const res = await apiClient.cars(subscriptionId);
        const data = res.data;
        return data;
    }
    SET_QR_RESULT(qrCode) {
        this.qrResult = qrCode;
    }
    SET_SUBSCRIBER(subscriber) {
        this.subscriber = subscriber;
    }
    SET_PAGECOUNT(count) {
        this.totalRecords = count;
    }
    SET_SUBSCRIPTIONTYPES(types) {
        this.subscriptionTypes = types;
    }
    SET_SUBSCRIPTIONS(subscriptions) {
        this.subscriptions = subscriptions;
    }
    SET_LOADING(loading) {
        this.loading = loading;
    }
    SET_SUBSCRIPTION(subscription) {
        this.subscription = subscription;
    }
};
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "exportCurrentSubscriptionsAsPDF", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "triggerCsvDownload", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchSubscriber", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchSubscriptions", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchQrForSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "removeAreaFromSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "addAreaToSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "addSubAreaToSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "removeSubAreaFromSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "emailTemplateTypeResendQr", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchSubscriptionTypes", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "updateSubscriptionType", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "deleteSubscriptionType", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "createSubscriptionType", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "updateSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "activateSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "createSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "deleteSubscription", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchSubscriptionById", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchSubscriptionHistory", null);
__decorate([
    Action({ rawError: true })
], SubscriptionModule.prototype, "fetchSubscriptionCarHistory", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_QR_RESULT", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_SUBSCRIBER", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_PAGECOUNT", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_SUBSCRIPTIONTYPES", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_SUBSCRIPTIONS", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_LOADING", null);
__decorate([
    Mutation
], SubscriptionModule.prototype, "SET_SUBSCRIPTION", null);
SubscriptionModule = __decorate([
    Module({ dynamic: true, store, name: "subscriptionModule", namespaced: true })
], SubscriptionModule);
export default SubscriptionModule;
export const subscriptionModule = getModule(SubscriptionModule);
