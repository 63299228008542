import axios from "axios";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { getApiBaseUrl } from "@/util/url/urlhelper";
import { sessionModule } from "@/store/modules/sessionModule";
var apiBaseUrl = getApiBaseUrl(process.env.NODE_ENV, window.location.host);
const httpClient = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        "Content-Type": "application/json",
    },
});
httpClient.interceptors.request.use(function (config) {
    const token = authenticationModule.getToken;
    config.headers.authorization = token ? `Bearer ${token}` : "";
    return config;
});
httpClient.interceptors.response.use(function (response) {
    return response;
}, error => {
    const msg = error.response.data.Message;
    if (msg === "Session is already in use." &&
        error.response.status === 401 &&
        authenticationModule.getMode === 3) {
        sessionModule.SET_SHOW_SESSION_PROMPT(true);
    }
    else if (error.response.status === 401) {
        // Unauthorized
    }
    else if (authenticationModule.getMode !== 3 &&
        (error.response.status === 401 || error.response.status === 403)) {
        // Forbidden
        authenticationModule.logout();
    }
    return Promise.reject(error);
});
export default httpClient;
