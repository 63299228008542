import { __decorate } from "tslib";
import { Criticality, notificationModule, } from "@/store/modules/notificationModule";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component } from "vue-property-decorator";
import { featureModule } from "@/store/modules/featureModule";
import { InputType } from "@/components/shared/enums";
import { maxNumber, minNumber } from "@/components/shared/validationRules";
import { ParkingTaggerRequest } from "@/apiclient/client";
import { prsTagModule } from "@/store/modules/prsTagModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomSlider from "@/components/shared/CustomSlider.vue";
import CustomSwitch from "@/components/shared/CustomSwitch.vue";
import Vue from "vue";
let TagConfigurationCard = class TagConfigurationCard extends Vue {
    type = InputType;
    action = Action;
    isLoading = true;
    isDMRenabled = false;
    isNMRenabled = false;
    threshold1 = 0;
    threshold2 = 0;
    threshold3 = 0;
    mounted() {
        apiClient
            .parkingTaggerGET(authenticationModule.customerId)
            .then((response) => {
            this.isDMRenabled = response.data?.lookupAndValidateDNK ?? false;
            this.isNMRenabled = response.data?.lookupAndValidateNOR ?? false;
            this.threshold1 = response.data?.duration1Value ?? 0;
            this.threshold2 = response.data?.duration2Value ?? 0;
            this.threshold3 = response.data?.duration3Value ?? 0;
            this.isLoading = false;
        });
    }
    isConfigurationValid() {
        if (this.threshold2 != 0)
            if (this.threshold2 < this.threshold1)
                return false;
        if (this.threshold3 != 0)
            if (this.threshold3 < this.threshold2)
                return false;
        return true;
    }
    onSaveFiltersClick() {
        if (!this.isConfigurationValid())
            return; // MML: Could we make the sliders shake if they are invalid?
        let parkingTagger = new ParkingTaggerRequest();
        parkingTagger.lookupAndValidateDNK = this.isDMRenabled;
        parkingTagger.lookupAndValidateNOR = this.isNMRenabled;
        parkingTagger.duration1Enabled = this.threshold1 != 0;
        parkingTagger.duration1Value = this.threshold1;
        parkingTagger.duration2Enabled =
            this.threshold1 != 0 &&
                this.threshold2 != 0 &&
                this.threshold2 >= this.threshold1;
        parkingTagger.duration2Value = this.threshold2;
        parkingTagger.duration3Enabled =
            this.threshold1 != 0 &&
                this.threshold2 != 0 &&
                this.threshold3 != 0 &&
                this.threshold3 >= this.threshold2 &&
                this.threshold3 >= this.threshold1;
        parkingTagger.duration3Value = this.threshold3;
        apiClient
            .parkingTaggerPUT(authenticationModule.customerId, parkingTagger)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_configuration".t(),
                criticality: Criticality.Success,
            });
            prsTagModule.fetchPrsTags();
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    get isChargeModuleFeatureEnabled() {
        return featureModule.getIsChargeModuleFeatureEnabled;
    }
};
TagConfigurationCard = __decorate([
    Component({
        components: {
            CustomButton,
            CustomSlider,
            CustomSwitch,
        },
        methods: {
            maxNumber,
            minNumber,
        },
    })
], TagConfigurationCard);
export default TagConfigurationCard;
