import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { SensitiveDataConfigurationRequest, SensitiveDataConfigurationResponse, } from "@/apiclient/client";
import { minNumber } from "../shared/validationRules";
import CustomTextField from "../shared/CustomTextField.vue";
import { InputType } from "../shared/enums";
import { customerModule } from "@/store/modules/customerModule";
let DataConfigurationInput = class DataConfigurationInput extends Vue {
    dataConfiguration;
    customerId;
    type = InputType;
    loading = false;
    async handleUpdateConfiguration(e) {
        if (!e.target)
            return;
        const value = e.target.value;
        if (value === "0" || Number(value) <= 0)
            return;
        this.loading = true;
        const lifeTime = Number(value);
        const request = new SensitiveDataConfigurationRequest({
            maskableEntity: this.dataConfiguration.maskableEntityID,
            maskingAction: this.dataConfiguration.maskingActionID,
            lifeTime,
        });
        await customerModule.updateDataConfiguration({
            customerId: this.customerId,
            dataConfig: request,
        });
        this.loading = false;
    }
    getTranslation(maskingActionID, maskableEntityID) {
        if (maskableEntityID === 1 && maskingActionID === 1)
            return this.$tc("data_config_delete_inactive_subscriptions");
        if (maskableEntityID === 1 && maskingActionID === 2)
            return this.$tc("data_config_mask_inactive_subscriptions");
        if (maskableEntityID === 2 && maskingActionID === 1)
            return this.$tc("data_config_delete_inactive_subscribers");
        if (maskableEntityID === 2 && maskingActionID === 2)
            return this.$tc("data_config_mask_inactive_subscribers");
    }
};
__decorate([
    Prop({ required: true, type: SensitiveDataConfigurationResponse })
], DataConfigurationInput.prototype, "dataConfiguration", void 0);
__decorate([
    Prop({ required: true, type: Number })
], DataConfigurationInput.prototype, "customerId", void 0);
DataConfigurationInput = __decorate([
    Component({
        components: {
            CustomTextField,
        },
        methods: {
            minNumber,
        },
    })
], DataConfigurationInput);
export default DataConfigurationInput;
