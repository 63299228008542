import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import Vue from "vue";
let AreasPage = class AreasPage extends Vue {
};
AreasPage = __decorate([
    Component({
        components: {},
        methods: {},
    })
], AreasPage);
export default AreasPage;
