import { __decorate } from "tslib";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDateTimePicker from "@/components/shared/CustomDateTimePicker.vue";
import Vue from "vue";
let CustomDatePickerExamples = class CustomDatePickerExamples extends Vue {
    action = Action;
    dateTimePickerFormValid = true;
    dateTimePicker1 = "2021-12-31T23:30:00Z"; // "2021-12-31T23:30:00Z"
    dateTimePicker2 = "2021-12-31T18:30:00+02:00"; // "2021-12-31T18:30:00+02:00";
    onDateTimePicker2Change(dateTime) {
        // console.log("onDateTimePicker2Change: " + dateTime);
    }
    dateTimePickerFormValidate() {
        this.$refs.dateTimePickerForm.validate();
    }
    dateTimePickerFormReset() {
        this.$refs.dateTimePickerForm.reset();
    }
    dateTimePickerFormResetValidation() {
        this.$refs.dateTimePickerForm.resetValidation();
    }
};
CustomDatePickerExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDateTimePicker,
        },
        methods: {
            required,
        },
    })
], CustomDatePickerExamples);
export default CustomDatePickerExamples;
