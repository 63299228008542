<template>
  <v-app>
    <CustomSnackbar
      :show.sync="showSnackbar"
      :text="snackbarText"
      :criticality="snackbarCriticality"
      :timeout="snackbarTimeout"
    />
    <v-app-bar app color="primary" dark flat dense elevation="0">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
        v-if="isAuthenticated && getInitialModulesLoaded"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div v-if="isAuthenticated && getInitialModulesLoaded">
        {{ getCustomerName }}
      </div>
      <v-menu
        offset-y
        :key="mini"
        :close-on-click="true"
        v-if="isAuthenticated && getInitialModulesLoaded"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="ml-4">mdi-account</v-icon>
        </template>
        <v-list>
          <v-list-item :key="page.key" v-for="page in getPages" @click="goto(page)">
            <v-list-item-title class="mr-2">{{ page.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="ml-4">mdi-web</v-icon>
        </template>
        <v-list>
          <v-list-item :key="locale.key" v-for="locale in locales" @click="changeLocale(locale)">
            <v-list-item-title class="mr-2">{{ locale.name }}</v-list-item-title>
            <v-icon v-if="getLocale === locale.key" small>mdi-check</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isAuthenticated && getInitialModulesLoaded"
      v-model="sidebarMenu"
      app
      :permanent="false"
      :mini-variant.sync="mini"
    >
      <div style="height: 47px">
        <v-container color="">
          <v-icon @click.stop="toggleMini = !toggleMini">{{ getSidebarIcon }}</v-icon>
        </v-container>
      </div>
      <v-list>
        <v-list-item
          v-for="item in getTranslatedItems"
          :key="item.title + (Math.random() + 1).toString(36).substring(7)"
          link
          :to="item.href"
          class="custom-hover-state"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content :id="item.id">
            <v-list-item-title class="menu-item-title">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider style="background-color: unset !important; margin: 0 !important;"></v-divider>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container class="px-4 py-0 fill-height mb-5" fluid>
        <v-row class="fill-height">
          <v-col class="position-relative">
            <v-progress-circular
              indeterminate
              v-if="isAuthenticated && !getInitialModulesLoaded && !getAuthenticationLoading"
              color="primary"
              class="centered-loader"
            ></v-progress-circular>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>

        <CustomDialog
          :show.sync="showSessionModal"
          :title="'dialog_title_session_takeover'.t()"
          :persistent="true"
        >
          <template v-slot:content>
            {{ "dialog_content_session_takeover".t() }}
          </template>

          <template v-slot:actions>
            <CustomButton
              :text="'button_cancel'.t()"
              :action="action.Cancel"
              @on-button-click="ignoreStealSession"
            />

            <CustomButton
              :text="'button_ok'.t()"
              :action="action.Primary"
              @on-button-click="handleStealSession"
            />
          </template>
        </CustomDialog>
      </v-container>
    </v-main>
    <v-footer padless>
      <v-col class="text-left pb-4" cols="6">
        <div
          v-bind:style="{ marginLeft: getVersionTextMargin + 'px' }"
          id="versionTextDiv"
          class="swing text-subtitle-2"
        >
          {{ getVersionText }}
        </div>
      </v-col>
      <v-col class="text-right" cols="6">
        <!--footer logo-->
        <img src="./assets/logoslogoblue.svg" height="20px" />
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { Criticality, notificationModule } from "@/store/modules/notificationModule";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { appModule } from "@/store/modules/appModule";
import { sessionModule } from "@/store/modules/sessionModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomSnackbar from "@/components/shared/CustomSnackbar.vue";
import router from "@/router";
import { modeModule } from "./store/modules/modeModule";
import { statusModule } from "./store/modules/statusModule";

export default {
  name: "App",
  components: {
    CustomButton,
    CustomDialog,
    CustomSnackbar,
  },
  computed: {
    locales() {
      return appModule.getLocales;
    },
    getLocale() {
      return appModule.locale;
    },
    pages() {
      return [
        {
          key: "myPage",
          name: "menu_my_account".t(),
          page: "/users/" + authenticationModule.getLoggedInUserId,
        },
        {
          key: "impersonation",
          name: "menu_change_customer".t(),
          page: "/customers",
        },
      ];
    },
    getPages() {
      if (this.isSuperAdmin) return this.pages;

      return this.pages.filter(x => x.key != "impersonation");
    },
    getSidebarIcon() {
      return this.toggleMini
        ? "mdi-chevron-right-circle-outline"
        : "mdi-chevron-left-circle-outline";
    },
    getCustomerName() {
      return authenticationModule.getLoggedInCustomerName;
    },
    getVersionTextMargin() {
      if (
        (!this.sidebarMenu && this.toggleMini) ||
        (!this.sidebarMenu && !this.toggleMini) ||
        !this.isAuthenticated ||
        !this.getInitialModulesLoaded
      )
        return 0;
      if (this.mini === true) return 56;

      return 256;
    },
    showSessionModal() {
      return sessionModule.getShowSEssionPrompt;
    },

    getVersionText() {
      return "ParkAdmin v" + process.env.VUE_APP_VERSION;
    },
    getTranslatedItems() {
      return appModule.getMenuItems;
    },
    isSuperAdmin() {
      return authenticationModule.isSuperAdmin;
    },
    GetUser() {
      return this.authenticationModule.getLoggedInUser();
    },
    mini: {
      get: function() {
        return this.toggleMini;
      },
      set: function(value) {},
    },
    showSnackbar: {
      get: function() {
        return notificationModule.show;
      },
      set: function(value) {
        notificationModule.SET_SHOW(value);
      },
    },
    snackbarText() {
      return notificationModule.getMessage;
    },
    snackbarCriticality() {
      return notificationModule.getCriticality;
    },
    snackbarTimeout() {
      return notificationModule.getTimeout;
    },
    isAuthenticated() {
      return authenticationModule.isAuthenticated;
    },
    getAccessLevel() {
      return authenticationModule.getAccessLevel;
    },
    getInitialModulesLoaded() {
      return appModule.getInitialModulesLoaded;
    },
    getAuthenticationLoading() {
      return statusModule.getAuthenticationLoading;
    },
  },
  data: () => ({
    action: Action,
    sidebarMenu: true,
    toggleMini: false,
    emailsToggled: false,
    refreshTokenInterval: null,
  }),
  methods: {
    handleStealSession() {
      //refresh the token
      authenticationModule
        .refreshLoginToken()
        .then(() => {
          notificationModule.SHOW_NOTIFICATION({
            message: "notification_success_update_session".t(),
            criticality: Criticality.Success,
          });
        })
        .catch(() => {
          authenticationModule.LOGOUT();

          notificationModule.SHOW_NOTIFICATION({
            message: "notification_error_occured".t(),
            criticality: Criticality.Error,
          });

          router.push("/login");
        })
        .finally(() => {
          sessionModule.SET_SHOW_SESSION_PROMPT(false);
        });
    },
    goto(page) {
      router.push(page.page);
    },
    ignoreStealSession() {
      authenticationModule.LOGOUT();
      sessionModule.SET_SHOW_SESSION_PROMPT(false);
      router.push("/login");
    },
    toggleEmails(event) {
      authenticationModule.toggleEmails(event);
      this.emailsToggled = event;
    },
    changeLocale(locale) {
      appModule.SET_LOCALE(locale);
    },
    logout() {
      authenticationModule.logout();
    },
  },
  beforeMount() {
    if (
      localStorage.getItem("locale") !== null &&
      appModule.getLocales.find(locale => locale.key == localStorage.getItem("locale"))
    ) {
      this.$root.$i18n.locale = localStorage.getItem("locale");
      appModule.SET_LOCALE(
        appModule.getLocales.find(locale => locale.key == localStorage.getItem("locale"))
      );
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      window.setInterval(() => {
        if (authenticationModule.isAuthenticated && modeModule.isDelegateLogin) {
          sessionModule.pingServer();
        }
      }, 10000);
    });
  },
};
</script>

<style>
.theme--light.v-footer {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87);
}
.menu-item-title {
  text-transform: capitalize;
}
#versionTextDiv {
  margin-left: 256px;
}
.custom-hover-state:hover,
.v-list-item:hover,
.v-data-table tbody > tr:not(.v-data-table__expanded__content):hover {
  background-color: lightgray !important;
  cursor: pointer;
}
.custom-hover-state.v-list-item--link:before,
.custom-active-state,
.v-list-item--active {
  background-color: lightgray !important;
}

.position-relative {
  position: relative;
}

.centered-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.filter-table table thead th:first-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.filter-table table tbody tr td:first-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.filter-table table tbody tr:hover:not(.v-table__expanded__content) {
  cursor: initial;
  background-color: transparent !important;
}

.filter-table table tbody .v-input--selection-controls__input {
  margin-right: 0;
}

.soft-rounded > .v-input__control > .v-input__slot:before {
  border: 0 !important;
}

.soft-rounded {
  border-radius: 4px !important;
}
</style>
