import { __decorate } from "tslib";
import { authenticationModule } from '@/store/modules/authenticationModule';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let CustomerCard = class CustomerCard extends Vue {
    customer;
    cardClicked() {
        this.$emit('card-clicked', this.customer);
    }
    get getBackgroundColor() {
        if (this.getIsLoggedIn) {
            return 'grey lighten-2';
        }
        return '';
    }
    get getIsLoggedIn() {
        return authenticationModule.loggedInCustomer.costumerID === this.customer.costumerID;
    }
};
__decorate([
    Prop()
], CustomerCard.prototype, "customer", void 0);
CustomerCard = __decorate([
    Component({
        components: {}
    })
], CustomerCard);
export default CustomerCard;
