var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"width":"500px"}},[_c('v-card-title',[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s("subscriptionType".tc(2))+" ")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.getLoadingStatus,"headers":_vm.headers,"items":_vm.getSubscriptionTypes,"item-key":"subscriptionTypeID","footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [5],
        'items-per-page-text': 'rows_per_page'.t(),
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s("table_footer_page_text".t())+" "+_vm._s(items.itemsLength)+" ")]}},{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end mr-2"},[_c('v-spacer'),_c('CustomButton',{attrs:{"text":'button_create'.t(),"action":_vm.action.Primary},on:{"on-button-click":function($event){return _vm.createSubscriptionType()}}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('CustomButton',{attrs:{"icon":"mdi-pencil","action":_vm.action.Primary},on:{"on-button-click":function($event){return _vm.editSubscriptionType(item)}}}),_c('CustomButton',{attrs:{"icon":"mdi-delete","action":_vm.action.Delete},on:{"on-button-click":function($event){return _vm.deleteSubscriptionType(item)}}})]}}],null,true)}),[_c('CustomDialog',{attrs:{"show":_vm.showDialog,"title":_vm.getDialogTitle,"persistent":false},on:{"update:show":function($event){_vm.showDialog=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-form',{ref:"subscriptionTypeForm",model:{value:(_vm.subscriptionTypeFormValid),callback:function ($$v) {_vm.subscriptionTypeFormValid=$$v},expression:"subscriptionTypeFormValid"}},[_c('CustomTextField',{attrs:{"text":_vm.subscriptionTypeInput,"label":'subscriptionType'.tc(1),"type":_vm.type.Text,"rules":[_vm.required]},on:{"update:text":function($event){_vm.subscriptionTypeInput=$event}}})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('CustomButton',{attrs:{"text":'button_cancel'.t(),"action":_vm.action.Cancel},on:{"on-button-click":_vm.onCancelClick}}),_c('CustomButton',{attrs:{"text":'button_save'.t(),"action":_vm.action.Primary},on:{"on-button-click":_vm.updateSubscriptionType}})]},proxy:true}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }