import { __decorate } from "tslib";
import { maxLength, minLength, required, } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTextArea from "../shared/CustomTextArea.vue";
import Vue from "vue";
let CustomTextAreaExamples = class CustomTextAreaExamples extends Vue {
    action = Action;
    text1 = null;
    text2 = null;
    onInputBlur(e) {
        // console.log(e.target.value);
    }
    textInputFormValidate() {
        this.$refs.textAreaForm.validate();
    }
    textInputFormReset() {
        this.$refs.textAreaForm.reset();
    }
    textInputFormResetValidation() {
        this.$refs.textAreaForm.resetValidation();
    }
};
CustomTextAreaExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomTextArea,
        },
        methods: {
            required,
            maxLength,
            minLength,
        },
    })
], CustomTextAreaExamples);
export default CustomTextAreaExamples;
