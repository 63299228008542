import { __decorate } from "tslib";
import { Criticality, notificationModule } from "@/store/modules/notificationModule";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
import { chargeModule } from "@/store/modules/chargeModule";
import { utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Vue from "vue";
let ActionPanel = class ActionPanel extends Vue {
    action = Action;
    showDeleteDialog = false;
    onCreateChargeClick() {
        chargeEditorModule
            .downloadPdf()
            .then(() => {
            chargeEditorModule.markCurrentAsPaid().then(() => {
                this.navigateBack();
            });
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    onDeleteChargeClick() {
        this.showDeleteDialog = true;
    }
    onConfirmDeleteClick() {
        if (!this.getEditedItem?.costumerID || !this.getEditedItem?.parkingRightID) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
            return;
        }
        chargeModule
            .deleteUnpaidParking({
            costumerID: this.getEditedItem.costumerID,
            parkingRightID: this.getEditedItem.parkingRightID,
        })
            .then(() => {
            chargeModule.fetchUnpaidParkings();
        })
            .finally(() => {
            this.navigateBack();
        });
    }
    onCancelClick() {
        this.navigateBack();
    }
    navigateBack() {
        this.$router.push("/charge");
    }
    getFormattedDate(date) {
        return date
            ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY")
            : "";
    }
    get getEditedItem() {
        return chargeEditorModule.getEditedItem;
    }
};
ActionPanel = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
        },
    })
], ActionPanel);
export default ActionPanel;
