import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { notificationModule, Criticality } from "./notificationModule";
import store from "@/store";
let ConstantsModule = class ConstantsModule extends VuexModule {
    accessLevels = undefined;
    modes;
    parkingTypes;
    parkingSystems;
    async fetchAccessLevels() {
        return await apiClient
            .accessLevels()
            .then(response => {
            this.context.commit("SET_ACCESS_LEVELS", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchModes() {
        return await apiClient
            .modes()
            .then(response => {
            this.context.commit("SET_MODES", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchParkingTypes() {
        return await apiClient
            .parkingTypes()
            .then(response => {
            this.context.commit("SET_PARKING_TYPES", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    async fetchParkingSystems() {
        return await apiClient
            .parkingSystems(authenticationModule.customerId)
            .then(response => {
            this.context.commit("SET_PARKING_SYSTEMS", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_ACCESS_LEVELS(accessLevels) {
        this.accessLevels = accessLevels;
    }
    SET_MODES(modes) {
        this.modes = modes;
    }
    SET_PARKING_TYPES(parkingTypes) {
        this.parkingTypes = parkingTypes;
    }
    SET_PARKING_SYSTEMS(parkingSystems) {
        this.parkingSystems = parkingSystems;
    }
    get getAccessLevels() {
        return this.accessLevels;
    }
    get getModes() {
        return this.modes;
    }
    get getParkingTypes() {
        return this.parkingTypes
            ? this.parkingTypes
                .slice()
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .filter(x => x.name !== "Residential")
            : [];
    }
    get getParkingSystems() {
        return this.parkingSystems
            ? this.parkingSystems
                .slice()
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            : [];
    }
};
__decorate([
    Action({ rawError: true })
], ConstantsModule.prototype, "fetchAccessLevels", null);
__decorate([
    Action({ rawError: true })
], ConstantsModule.prototype, "fetchModes", null);
__decorate([
    Action({ rawError: true })
], ConstantsModule.prototype, "fetchParkingTypes", null);
__decorate([
    Action({ rawError: true })
], ConstantsModule.prototype, "fetchParkingSystems", null);
__decorate([
    Mutation
], ConstantsModule.prototype, "SET_ACCESS_LEVELS", null);
__decorate([
    Mutation
], ConstantsModule.prototype, "SET_MODES", null);
__decorate([
    Mutation
], ConstantsModule.prototype, "SET_PARKING_TYPES", null);
__decorate([
    Mutation
], ConstantsModule.prototype, "SET_PARKING_SYSTEMS", null);
ConstantsModule = __decorate([
    Module({ dynamic: true, store, name: "constantsModule" })
], ConstantsModule);
export default ConstantsModule;
export const constantsModule = getModule(ConstantsModule);
