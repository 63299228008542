<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-2">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="400px"
          ref="signaturePad"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3 mt-2">
        <button class="btn btn-outline-secondary" @click="undo">
          {{ $t("clearsignature") }}
        </button>
      </div>
    </div>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-checkbox
          v-model="termsAgreed"
          class="mr-8"
          @click="save()"
          id="checkbox_termsagreed"
        >
          <template v-slot:label>
            <div>
              {{ $t("iaggreeto", { company: getCustomerName.possessive() }) }}
              <a @click="dialog = true" id="toggle_tos">
                {{ $t("termsandconditions") }}
              </a>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-model="emailsAccepted">
          <template v-slot:label>
            <div>
              {{
                $t("iwishtoreceivenewsletter", {
                  company: getCustomerName.possessive(),
                })
              }}
            </div>
          </template>
        </v-checkbox>

        <CustomDialog
          :show.sync="dialog"
          :title="'dialog_title_terms_of_service'.t()"
          :persistent="true"
        >
          <template v-slot:content>
            <TermsOfServiceComponent />
          </template>

          <template v-slot:actions>
            <CustomButton
              :text="'button_disagree'.t()"
              :action="action.Normal"
              @on-button-click="
                dialog = false;
                termsAgreed = false;
              "
            />
            <CustomButton
              :text="'button_accept'.t()"
              :action="action.Primary"
              @on-button-click="
                dialog = false;
                termsAgreed = true;
              "
            />
          </template>
        </CustomDialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { authenticationModule } from "@/store/modules/authenticationModule";
import { stepperModule } from "@/store/modules/stepperModule";
import { mapGetters } from "vuex";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import TermsOfServiceComponent from "@/components/TermsOfService.vue";

export default {
  name: "Signature",
  data: () => ({
    action: Action,
    emailsAccepted: false,
    dialog: false,
    termsAgreed: false,
    signed: false,
    signature: String,
  }),
  components: {
    CustomButton,
    CustomDialog,
    TermsOfServiceComponent,
  },
  computed: {
    signedAndAccepted() {
      return this.termsAgreed && !this.$refs.signaturePad.isEmpty();
    },
    getCustomerName() {
      return authenticationModule.getLoggedInCustomerName;
    },
    ...mapGetters({
      getAreas: "areaModule/getAreas",
      getSubscriber: "stepperModule/getSubscriber",
      getSubscription: "stepperModule/getSubscription",
    }),
  },
  watch: {
    signedAndAccepted(value) {
      this.$emit("signed-and-accepted", value);
      stepperModule.updateSignature(this.signature);
    },
    emailsAccepted(value) {
      stepperModule.SET_EMAILS_ACCEPTED(value);
    },
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
      this.signature = null;
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.signature = data;
      stepperModule.updateSignature(this.signature);
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>