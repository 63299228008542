function editItem (item) {
    this.editMode = true,
    this.editedIndex = item.id;
    this.editedItem = Object.assign({}, item)
    this.dialog = true
}

function deleteItem (item) {
    this.editedIndex = item.id;
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
}

function closeDelete () {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    })
}

function close () {
    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.shouldResetForm = true;
    })
}

export {
    editItem,
    deleteItem,
    closeDelete,
    close
}