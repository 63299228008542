export default class ModeConfigDispatch {
    userId;
    modeConfigurationID;
    defaultAreaID;
    subscriptonTypeID;
    welcomeEmailEmplateID;
    headerLogo; //base64
    footerLogo; //base64
    areas;
    features;
}
