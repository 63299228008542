import { __decorate } from "tslib";
import { InputType } from "./enums";
import { Prop, PropSync } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
let CustomTextField = class CustomTextField extends Vue {
    inputType = InputType;
    syncText;
    id;
    label;
    type;
    placeholder;
    counter;
    rules;
    disabled;
    loading;
    errorMessages;
    icon;
    autofocus;
    hideDetails;
    showPassword = false;
    onInput(e) {
        this.$emit("on-text-field-input", e);
    }
    onBlur(e) {
        this.$emit("on-text-field-blur", e);
    }
    get getLabel() {
        if (this.type === this.inputType.Search) {
            return undefined;
        }
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
    get getType() {
        if (this.type === this.inputType.Password) {
            return this.showPassword ? "text" : "password";
        }
        return this.inputType[this.type].toLowerCase();
    }
    get getIcon() {
        if (this.type === this.inputType.Search) {
            return "mdi-magnify";
        }
        if (this.type === this.inputType.Password) {
            return this.showPassword ? "mdi-eye" : "mdi-eye-off";
        }
        return this.icon;
    }
};
__decorate([
    PropSync("text")
], CustomTextField.prototype, "syncText", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomTextField.prototype, "id", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomTextField.prototype, "label", void 0);
__decorate([
    Prop({ required: true })
], CustomTextField.prototype, "type", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomTextField.prototype, "placeholder", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomTextField.prototype, "counter", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomTextField.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomTextField.prototype, "disabled", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomTextField.prototype, "loading", void 0);
__decorate([
    Prop({ required: false })
], CustomTextField.prototype, "errorMessages", void 0);
__decorate([
    Prop({ required: false })
], CustomTextField.prototype, "icon", void 0);
__decorate([
    Prop({ required: false, type: Boolean, default: false })
], CustomTextField.prototype, "autofocus", void 0);
__decorate([
    Prop({ required: false, type: Boolean, default: false })
], CustomTextField.prototype, "hideDetails", void 0);
CustomTextField = __decorate([
    Component
], CustomTextField);
export default CustomTextField;
