import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
let CustomTreeSelectOption = class CustomTreeSelectOption extends Vue {
    node;
    nodes;
    index;
    nodesLength;
    showChildNodes;
    disabled;
    onNodeClick;
    showChildNodesList = false;
    toggleChildNodeList() {
        this.showChildNodesList = !this.showChildNodesList;
    }
    handleNodeClick() {
        this.$emit("onNodeClick", this.node);
    }
    get getSubNodesLength() {
        return this.node.nodes ? this.node.nodes.length : 0;
    }
    get dropdownArrowClass() {
        let arrowIconClass = "";
        if (!this.node.nodes)
            return "hidden";
        arrowIconClass = 'pl-2';
        if (this.node.nodes.length <= 0) {
            arrowIconClass += ' hidden';
        }
        return arrowIconClass;
    }
    get paddingSize() {
        let paddingStyle = this.showChildNodes ? '0px' : '';
        let paddingSize = 12 * this.node.level;
        paddingStyle = this.isChildNode ? `${paddingSize}px` : paddingStyle;
        return paddingStyle;
    }
    get isChildNode() {
        return Boolean(this.node.parentNode);
    }
};
__decorate([
    Prop({ required: true })
], CustomTreeSelectOption.prototype, "node", void 0);
__decorate([
    Prop({ required: true })
], CustomTreeSelectOption.prototype, "nodes", void 0);
__decorate([
    Prop({ required: true })
], CustomTreeSelectOption.prototype, "index", void 0);
__decorate([
    Prop()
], CustomTreeSelectOption.prototype, "nodesLength", void 0);
__decorate([
    Prop()
], CustomTreeSelectOption.prototype, "showChildNodes", void 0);
__decorate([
    Prop()
], CustomTreeSelectOption.prototype, "disabled", void 0);
__decorate([
    Prop()
], CustomTreeSelectOption.prototype, "onNodeClick", void 0);
CustomTreeSelectOption = __decorate([
    Component({
        components: {},
        methods: {},
        name: "CustomTreeSelectOption"
    })
], CustomTreeSelectOption);
export default CustomTreeSelectOption;
