function compareJsonObjects(obj1, obj2) {
    const changes = {};
    const compareValues = (key, value1, value2) => {
        if (Array.isArray(value1) && Array.isArray(value2)) {
            const arrayChanges = compareArraysOfObjects(value1, value2);
            if (arrayChanges && arrayChanges.length > 0) {
                changes[key] = arrayChanges;
            }
        }
        else if (typeof value1 === "object" &&
            value1 !== null &&
            typeof value2 === "object" &&
            value2 !== null) {
            const nestedChanges = compareJsonObjects(value1, value2);
            if (Object.keys(nestedChanges).length > 0) {
                changes[key] = nestedChanges;
            }
        }
        else if (value1 !== value2) {
            changes[key] = { from: value1, to: value2 };
        }
    };
    Object.keys(obj1).forEach(key => {
        if (key in obj2) {
            compareValues(key, obj1[key], obj2[key]);
        }
        else {
            changes[key] = { from: obj1[key], to: undefined };
        }
    });
    Object.keys(obj2).forEach(key => {
        if (!(key in obj2)) {
            changes[key] = { from: undefined, to: obj2[key] };
        }
    });
    return changes;
}
function compareArraysOfObjects(array1, array2) {
    const changes = [];
    // Convert arrays to maps based on object 'id' for easy lookup
    const map1 = new Map(array1.map(item => [item.id, item]));
    const map2 = new Map(array2.map(item => [item.id, item]));
    // Check for removals or changes
    array1.forEach(item => {
        if (!map2.has(item.id)) {
            // Item removed
            changes.push({ id: item.id, removed: item });
        }
        else {
            // Item possibly changed, compare objects
            const itemChanges = compareJsonObjects(item, map2.get(item.id));
            if (Object.keys(itemChanges).length > 0) {
                changes.push({ id: item.id, changes: itemChanges });
            }
        }
    });
    // Check for additions
    array2.forEach(item => {
        if (!map1.has(item.id)) {
            // Item added
            changes.push({ id: item.id, added: item });
        }
        // Changes are already handled in the previous loop
    });
    return changes;
}
export { compareJsonObjects };
