import { __decorate } from "tslib";
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import ModalDialog from "@/components/shared/ModalDialog.vue";
import CenteredElement from "@/components/shared/CenteredElement.vue";
import NoImages from "@/components/datawash/NoImages.vue";
import { datawashModule } from "@/store/modules/datawashmodule";
let ImageWrapper = class ImageWrapper extends Vue {
    carouselModel = 0;
    imageHeight = 700;
    carousel;
    zoomcomponent;
    enableKeyHandler;
    imageModalVisible;
    isEmpty;
    images;
    imageStream;
    activeImageBase64;
    onCountChanged() {
        this.$emit("countChanged", this.carouselModel);
    }
    beforeMount() {
        //window.addEventListener("keydown", this.handleKeydown, undefined);
    }
    beforeDestroy() {
        //window.removeEventListener("keydown", this.handleKeydown);
    }
    //MAQ - Keyboard shortcuts have been postponed, due to unfortunate selection of shortcut keys. We need to rethink and consider appropiate shortcut keys e.g "a" for approve "d" for delete etc. before reimplementing
    // handleKeydown(e) {
    //   if (!this.enableKeyHandler) return;
    //   switch (e.keyCode) {
    //     case 37: //left arrow
    //       if (this.images.length > 0) this.carouselModel--;
    //       break;
    //     case 38: //up arrow
    //       if (!datawashModule.getIsImagesLoading) this.$emit("action-clicked", 0);
    //       break;
    //     case 39: //right arrow
    //       if (this.carouselModel < this.images.length) {
    //         this.carouselModel++;
    //       }
    //       break;
    //     case 46: //delete btn
    //       this.$emit("action-clicked", 3);
    //       break;
    //     case 45: //insert
    //       this.$emit("action-clicked", 1);
    //       break;
    //   }
    // }
    get showEmptyImageElement() {
        return !datawashModule.getIsImagesLoading && datawashModule.getImages.length == 0;
    }
    get showProgress() {
        return datawashModule.getIsImagesLoading;
    }
};
__decorate([
    Ref("carousel")
], ImageWrapper.prototype, "carousel", void 0);
__decorate([
    Ref("zoomcomponent")
], ImageWrapper.prototype, "zoomcomponent", void 0);
__decorate([
    Prop()
], ImageWrapper.prototype, "enableKeyHandler", void 0);
__decorate([
    Prop()
], ImageWrapper.prototype, "imageModalVisible", void 0);
__decorate([
    Prop()
], ImageWrapper.prototype, "isEmpty", void 0);
__decorate([
    Prop()
], ImageWrapper.prototype, "images", void 0);
__decorate([
    Prop()
], ImageWrapper.prototype, "imageStream", void 0);
__decorate([
    Prop()
], ImageWrapper.prototype, "activeImageBase64", void 0);
__decorate([
    Watch("carouselModel")
], ImageWrapper.prototype, "onCountChanged", null);
ImageWrapper = __decorate([
    Component({
        methods: {},
        components: { ModalDialog, CenteredElement, NoImages },
    })
], ImageWrapper);
export default ImageWrapper;
