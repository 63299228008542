import { __decorate } from "tslib";
import { ModeConfigurationRequest, UserRequest, UserResponse, } from "@/apiclient/client";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { authenticationModule } from "./authenticationModule";
import { constantsModule } from "./constantsModule";
import { Criticality, notificationModule } from "./notificationModule";
import store from "@/store";
import { apiClient } from "@/apiclient/baseclient";
let UserModule = class UserModule extends VuexModule {
    affiliationConfiguration;
    isLoading = false;
    loggedInUser = new UserRequest();
    modeUsers = [];
    pageNumber = 0;
    searchTerm = null;
    serverItemsCount = 0;
    totalCount = 0;
    user = new UserResponse();
    users = [];
    async fetchUsers() {
        var result = await apiClient.usersGET();
        this.context.commit("SET_USERS", result.data);
        this.context.commit("SET_SERVER_ITEMS_COUNT", result.data?.length);
    }
    async fetchUser(userId) {
        try {
            var result = await apiClient.usersGET2(userId);
            this.context.commit("SET_USER", result.data);
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
    }
    async fetchModeUsers(subscriberId) {
        var result = await apiClient.usersGET(authenticationModule.getCustomerId, subscriberId);
        this.context.commit("SET_MODE_USERS", result.data);
        this.context.commit("SET_SERVER_ITEMS_COUNT", result.data?.length);
    }
    async createUser(user) {
        try {
            return await apiClient.usersPOST(authenticationModule.getCustomerId, user).then(response => {
                this.fetchUsers();
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_create_user".t(),
                    criticality: Criticality.Success,
                });
                return response;
            });
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: error?.response?.Message ?? "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
            return false;
        }
    }
    async createUserModeLogin(request) {
        try {
            return await apiClient.usersPOST(request.customerId, request.userCreate).then(response => {
                this.fetchUsers();
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_create_user".t(),
                    criticality: Criticality.Success,
                });
                return response;
            });
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
            return error;
        }
    }
    async createUserModeLoginAndUpdateConfig({ userStoreRequest, modeConfigRequest, }) {
        try {
            return apiClient
                .usersPOST(userStoreRequest.customerId, userStoreRequest.userCreate)
                .then(response => {
                const config = new ModeConfigurationRequest();
                config.headerLogo = modeConfigRequest.headerLogo;
                config.footerLogo = modeConfigRequest.footerLogo;
                config.areas = modeConfigRequest.areas;
                config.subscriptonTypeID = modeConfigRequest.subscriptonTypeID;
                config.welcomeEmailEmplateID = modeConfigRequest.welcomeEmailEmplateID;
                config.features = modeConfigRequest.features ?? [];
                return apiClient
                    .configurationPOST(response.data?.id, config)
                    .then(() => {
                    notificationModule.SHOW_NOTIFICATION({
                        message: "notification_success_create_user".t(),
                        criticality: Criticality.Success,
                    });
                    return true;
                })
                    .catch(() => {
                    notificationModule.SHOW_NOTIFICATION({
                        message: "notification_error_occured".t(),
                        criticality: Criticality.Error,
                    });
                });
            })
                .catch(error => {
                notificationModule.SHOW_NOTIFICATION({
                    message: error.response?.Message,
                    criticality: Criticality.Error,
                });
            });
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
            return error;
        }
    }
    async updateUser(payload) {
        try {
            return await apiClient.usersPUT(payload.userId, payload.user).then(response => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_update_user".t(),
                    criticality: Criticality.Success,
                });
                this.context.commit("SET_USER", response.data);
                return response;
            });
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
    }
    async updateUserModeLogin(request) {
        try {
            return await apiClient.usersPUT(request.userId, request.userUpdate).then(response => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_update_user".t(),
                    criticality: Criticality.Success,
                });
                return response;
            });
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
    }
    async deleteUser(userId) {
        try {
            return await apiClient.usersDELETE(userId).then(response => {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_success_delete_user".t(),
                    criticality: Criticality.Success,
                });
            });
        }
        catch (error) {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        }
    }
    async fetchAffiliationConfiguration(userId) {
        var result = await apiClient.configurationGET(userId);
        const data = result.data;
        if (data) {
            this.context.commit("SET_AFFILIATION_CONFIGURATION", data);
        }
    }
    async updateAffiliationConfiguration(payload) {
        return apiClient
            .configurationPOST(payload.userId, payload.affiliationConfiguration)
            .then(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_update_modeConfig".t(),
                criticality: Criticality.Success,
            });
            this.fetchAffiliationConfiguration(payload.userId);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_AFFILIATION_CONFIGURATION(affiliationConfiguration) {
        this.affiliationConfiguration = affiliationConfiguration;
    }
    SET_SEARCH(text) {
        this.searchTerm = text;
    }
    SET_MODE_USERS(modeUsers) {
        this.modeUsers = modeUsers;
    }
    SET_USER(user) {
        this.user = user;
    }
    SET_USERS(users) {
        this.users = users;
    }
    SET_PAGE(page) {
        this.pageNumber = page;
    }
    SET_SERVER_ITEMS_COUNT(count) {
        this.serverItemsCount = count;
    }
    get getAffiliationConfiguration() {
        return this.affiliationConfiguration;
    }
    get getUserSearch() {
        return this.searchTerm;
    }
    get getIsLoading() {
        return this.isLoading;
    }
    get getPageNumber() {
        return this.pageNumber;
    }
    get getServerItemsCount() {
        return this.serverItemsCount;
    }
    get getModeLogins() {
        return this.modeUsers;
    }
    get getTotalCount() {
        return this.totalCount;
    }
    get getUsers() {
        return this.users;
    }
    get getUser() {
        return this.user;
    }
    get getAccessLevelsOnCreate() {
        return constantsModule.getAccessLevels
            ? constantsModule.getAccessLevels.slice().filter(x => {
                return x.accessLevelID >= authenticationModule.getAccessLevel && x.accessLevelID !== 4;
            })
            : [];
    }
    get getAccessLevelsOnUpdate() {
        return constantsModule.getAccessLevels
            ? constantsModule.getAccessLevels.slice().filter(x => {
                if (this.getUser?.accessLevel !== 4) {
                    return x.accessLevelID >= authenticationModule.getAccessLevel && x.accessLevelID !== 4;
                }
                else if (this.getUser.accessLevel === 4) {
                    return x.accessLevelID >= authenticationModule.getAccessLevel;
                }
            })
            : [];
    }
    get getModeFeatures() {
        return this.affiliationConfiguration?.features ?? [];
    }
    get getModeAreas() {
        return this.affiliationConfiguration?.areas ?? [];
    }
};
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "fetchUsers", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "fetchUser", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "fetchModeUsers", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "createUser", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "createUserModeLogin", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "createUserModeLoginAndUpdateConfig", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "updateUser", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "updateUserModeLogin", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "deleteUser", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "fetchAffiliationConfiguration", null);
__decorate([
    Action({ rawError: true })
], UserModule.prototype, "updateAffiliationConfiguration", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_AFFILIATION_CONFIGURATION", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_SEARCH", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_MODE_USERS", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_USER", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_USERS", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_PAGE", null);
__decorate([
    Mutation
], UserModule.prototype, "SET_SERVER_ITEMS_COUNT", null);
UserModule = __decorate([
    Module({ dynamic: true, store, name: "usersModule" })
], UserModule);
export default UserModule;
export const userModule = getModule(UserModule);
