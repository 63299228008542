import { __decorate } from "tslib";
import { accessControlModule } from "@/store/modules/accessControlModule";
import { Component } from "vue-property-decorator";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { featureModule } from "@/store/modules/featureModule";
import ConfigurationsTab from "@/components/settings/TagConfigurationCard.vue";
import GeneralSettingsTab from "@/components/settings/GeneralSettingsTab.vue";
import EmailTemplateConfig from "@/components/settings/EmailTemplateConfig.vue";
import EmailTemplateConfigPicker from "@/components/settings/EmailTemplateConfigPicker.vue";
import EmailTemplateDialog from "@/components/settings/EmailTemplateDialog.vue";
import ToggleCustomerFeatures from "@/views/ToggleCustomerFeatures.vue";
import TosConfig from "@/components/settings/TosConfig.vue";
import Vue from "vue";
import DataConfiguration from "./DataConfiguration.vue";
let SettingsPage = class SettingsPage extends Vue {
    tabs = 0;
    toggleEditor(template) {
        emailTemplateModule.SET_SELECTED_EMAIL_TEMPLATE_ID(template.id);
        const el = this.$el.getElementsByClassName("scroll-target")[0];
        if (el) {
            el.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
            });
        }
    }
    get getSelectedTemplate() {
        return emailTemplateModule.getSelectedEmailTemplate;
    }
    get getSettingItems() {
        return accessControlModule.settingItems;
    }
    get authorizedSettingsItems() {
        return accessControlModule.authorizedSettingItems;
    }
    get getTabResetCount() {
        return featureModule.getTabResetCount;
    }
    get showTosConfig() {
        return this.authorizedSettingsItems.some(s => s.id === 1);
    }
    get showEmailPages() {
        return this.authorizedSettingsItems.some(s => s.id === 2);
    }
    getItemTitle(title) {
        switch (title.id) {
            case 0:
                return this.$t("settings_tab_general");
            case 1:
                return this.$t("settings_tab_termsofservice");
            case 2:
                return this.$t("settings_tab_emailtemplate");
            case 3:
                return this.$t("settings_tab_features");
            case 4:
                return this.$t("settings_tab_configuration");
        }
    }
};
SettingsPage = __decorate([
    Component({
        components: {
            ConfigurationsTab,
            GeneralSettingsTab,
            EmailTemplateConfig,
            EmailTemplateConfigPicker,
            EmailTemplateDialog,
            ToggleCustomerFeatures,
            TosConfig,
            DataConfiguration,
        },
    })
], SettingsPage);
export default SettingsPage;
