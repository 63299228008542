import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import Vue from "vue";
let NotFoundPage = class NotFoundPage extends Vue {
};
NotFoundPage = __decorate([
    Component({
        components: {},
        methods: {}
    })
], NotFoundPage);
export default NotFoundPage;
