//Secure LS implemented with help from https://github.com/robinvdvleuten/vuex-persistedstate/issues/233
import SecureLS from 'secure-ls';
export const storageKey = 'vuex';
export const secureStorage = new SecureLS({ isCompression: true });
var initialStoreContent;
try {
    initialStoreContent = secureStorage.get(storageKey); //this will throw if the localstorage is not empty
}
catch (error) {
    localStorage.clear();
    initialStoreContent = secureStorage.get(storageKey);
}
export const initialUnencryptedStorage = initialStoreContent
    ? JSON.parse(initialStoreContent)
    : {}; // parse the localStorage value to an object when it's defined, else set it as an empty object
