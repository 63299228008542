import { __decorate } from "tslib";
import { datawashModule, EditOprhanPlateRequest, UpdatePrsNumberPlateRequest, } from "@/store/modules/datawashmodule";
import { InputType } from "@/components/shared/enums";
import { ParkingQueryRequest } from "@/apiclient/client";
import { Prop, PropSync, Watch } from "vue-property-decorator";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import SuccessPanel from "../shared/SuccessPanel.vue";
import Vue from "vue";
import { getDepartedBefore, utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "@/store/modules/authenticationModule";
let AddLicensePlateDialog = class AddLicensePlateDialog extends Vue {
    type = InputType;
    action = Action;
    syncLicensePlate;
    visible;
    licensePlate;
    plateformValid = false;
    formResetCount = 0;
    uiStep = 0;
    unpaidParkings = [];
    showUnpaidParkingsDialog = false;
    loading = false;
    onPlateChanged() {
        this.syncLicensePlate = this.licensePlate;
    }
    onVisibleChanged() {
        this.formResetCount++;
        this.uiStep = 0;
    }
    get disabledState() {
        if (this.uiStep === 0)
            return !this.plateformValid;
        return datawashModule.shouldCheckForOrphans
            ? datawashModule.getSelectedOrphan.orphanID === undefined
            : datawashModule.getSelectedParkingRight.parkingRightID === undefined;
    }
    get successText() {
        return `Nummerplade opdateret til \n ${this.syncLicensePlate}`;
    }
    get getSelectedParking() {
        return datawashModule.getSelectedParking;
    }
    get show() {
        return this.visible;
    }
    set show(show) {
        this.$emit("toggle-dialog", show);
    }
    /**
     * if a parking has a stop date and has entityType ParkingRight, we do not want to check for orphans
     */
    get shouldCheckForOrphans() {
        return this.getSelectedParking.entityType === "ParkingRight" && !this.getSelectedParking.stop;
        //return datawashModule.shouldCheckForOrphans;
    }
    get shouldCheckForParkingRights() {
        return this.getSelectedParking.entityType === "Orphan";
    }
    getFormattedDate(date) {
        return date
            ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY")
            : "";
    }
    isOrphan(parking) {
        return parking.entityType === "Orphan";
    }
    async handlePrimaryClick(force = false) {
        const isFormValid = this.$refs.plateform.validate();
        if (!isFormValid) {
            return;
        }
        const licensePlate = this.syncLicensePlate.replace(/\s+/g, "");
        this.loading = true;
        if (!force &&
            this.uiStep === 0 &&
            this.getSelectedParking.vehicleIdents?.every(ident => ident.toLowerCase() !== licensePlate.toLowerCase())) {
            this.unpaidParkings = await this.fetchUnpaidParkings(licensePlate);
            if (this.unpaidParkings.length > 0) {
                this.showUnpaidParkingsDialog = true;
                this.loading = false;
                return;
            }
        }
        else {
            this.showUnpaidParkingsDialog = false;
            this.unpaidParkings = [];
        }
        if (this.uiStep === 0) {
            if (this.isOrphan(this.getSelectedParking)) {
                const request = new EditOprhanPlateRequest(this.getSelectedParking.id, licensePlate);
                datawashModule.editOrphanLicensePlate(request).finally(() => {
                    this.handleNextUiStep();
                });
            }
            else {
                const request = new UpdatePrsNumberPlateRequest(this.getSelectedParking.id, licensePlate);
                datawashModule.editParkingRightLicensePlate(request).finally(() => {
                    this.handleNextUiStep();
                });
            }
        }
        else if (this.uiStep === 1) {
            //merge
            let tagAsAccepted = true;
            datawashModule.mergeSelected(tagAsAccepted).finally(() => {
                this.$emit("fetchparkings");
                this.$emit("toggle-dialog", false);
                this.loading = false;
            });
        }
    }
    async fetchUnpaidParkings(licensePlate) {
        const query = new ParkingQueryRequest({
            areas: datawashModule.filterOptions.areasSelected,
            inclusionTags: [],
            exclusionTags: [],
            acceptanceTags: [],
            vehicleIdent: licensePlate,
        });
        const departedBefore = getDepartedBefore();
        try {
            const parkings = await apiClient.unpaidPOST(authenticationModule.getCustomerId, 1, 10, undefined, undefined, departedBefore, query);
            return (parkings.data?.filter(res => res.vehicleIdents?.some(ident => ident.toLowerCase() === licensePlate.toLowerCase())) ?? []);
        }
        catch (err) {
            return [];
        }
    }
    async handleNextUiStep() {
        //reset state
        datawashModule.SET_PARKING_RIGHTS([]);
        datawashModule.SET_ORPHANS([]);
        if (this.shouldCheckForOrphans) {
            // check for orphans
            let orphans = await datawashModule.fetchOrphans(this.syncLicensePlate);
            //orphans!.filter(o=> new Date(o.departed!)<new Date(this.getSelectedParking.start!))
            if (orphans && orphans.length > 0) {
                this.uiStep++;
                this.loading = false;
                return true;
            }
        }
        else if (this.isOrphan(this.getSelectedParking)) {
            //check for parking rights
            const parkingRights = await datawashModule.fetchParkingRights(this.syncLicensePlate);
            if (parkingRights && parkingRights.length > 0) {
                this.uiStep++;
                this.loading = false;
                return true;
            }
        }
        this.loading = false;
        this.$emit("toggle-dialog", false);
        this.$emit("fetchparkings");
        return false;
    }
    async deleteParking() {
        this.loading = true;
        if (this.isOrphan(this.getSelectedParking)) {
            datawashModule.deleteOrphan(this.getSelectedParking).finally(() => {
                this.showUnpaidParkingsDialog = false;
                this.$emit("fetchparkings");
                this.$emit("toggle-dialog", false);
                this.loading = false;
            });
        }
        else {
            datawashModule.deleteParking(this.getSelectedParking).finally(() => {
                this.showUnpaidParkingsDialog = false;
                this.$emit("fetchparkings");
                this.$emit("toggle-dialog", false);
                this.loading = false;
            });
        }
    }
};
__decorate([
    PropSync("licensePlate", { required: true })
], AddLicensePlateDialog.prototype, "syncLicensePlate", void 0);
__decorate([
    Prop()
], AddLicensePlateDialog.prototype, "visible", void 0);
__decorate([
    Prop()
], AddLicensePlateDialog.prototype, "licensePlate", void 0);
__decorate([
    Watch("licensePlate")
], AddLicensePlateDialog.prototype, "onPlateChanged", null);
__decorate([
    Watch("visible")
], AddLicensePlateDialog.prototype, "onVisibleChanged", null);
AddLicensePlateDialog = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
            CustomTextField,
            SuccessPanel,
        },
        methods: {
            required,
        },
    })
], AddLicensePlateDialog);
export default AddLicensePlateDialog;
