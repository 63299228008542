import { __decorate } from "tslib";
import { UserRequest, UserResponse } from "@/apiclient/client";
import { password, passwordMatch, required } from "@/components/shared/validationRules";
import { Component } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { userModule } from "@/store/modules/userModule";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let Overview = class Overview extends Vue {
    type = InputType;
    action = Action;
    showCreateDialog = false;
    createUserFormValid = true;
    newUser = {
        userName: null,
        name: null,
        accessLevel: null,
        password1: null,
        password2: null,
    };
    showDeleteDialog = false;
    userToDelete = new UserResponse();
    mounted() {
        userModule.fetchUsers();
    }
    onCreateClick() {
        this.showCreateDialog = true;
    }
    onOptionsUpdate(options) {
        this.pageNumber = options.page;
    }
    onRowClick(user) {
        this.$router.push(`/users/${user.id}`);
    }
    onSaveClick() {
        const isFormValid = this.$refs.createUserForm.validate();
        if (!isFormValid) {
            return;
        }
        const user = new UserRequest({
            username: this.newUser.userName,
            name: this.newUser.name,
            accessLevel: this.newUser.accessLevel,
            password: this.newUser.password1,
            userTypeID: 1,
            modeID: 1,
        });
        userModule.createUser(user).then(res => {
            if (res) {
                this.$refs.createUserForm.reset();
                this.showCreateDialog = false;
            }
        });
    }
    onDeleteUser(user) {
        this.userToDelete = user;
        this.showDeleteDialog = true;
    }
    onConfirmDeleteClick() {
        userModule.deleteUser(this.userToDelete.id).then(response => {
            userModule.fetchUsers();
        });
        this.showDeleteDialog = false;
    }
    get search() {
        return userModule.getUserSearch;
    }
    set search(text) {
        userModule.SET_SEARCH(text);
    }
    get headers() {
        var headers = [
            { text: "name".t(), value: "name" },
            { text: "username".t(), value: "username" },
            { text: "role".t(), value: "role" },
            { text: "actions".t(), value: "actions", sortable: false },
        ];
        return headers;
    }
    get isLoading() {
        return userModule.getIsLoading;
    }
    get pageNumber() {
        return userModule.getPageNumber;
    }
    set pageNumber(page) {
        userModule.SET_PAGE(page);
    }
    get users() {
        return userModule.getUsers;
    }
    get accessLevels() {
        return userModule.getAccessLevelsOnCreate;
    }
};
Overview = __decorate([
    Component({
        components: {
            CustomButton,
            CustomDialog,
            CustomSelect,
            CustomTextField,
        },
        methods: {
            password,
            passwordMatch,
            required,
        },
    })
], Overview);
export default Overview;
