import { __decorate } from "tslib";
import { required } from "@/components/shared/validationRules";
import Component from "vue-class-component";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTimePicker from "@/components/shared/CustomTimePicker.vue";
import Vue from "vue";
let CustomTimePickerExamples = class CustomTimePickerExamples extends Vue {
    action = Action;
    timePickerFormValid = true;
    timepicker1 = "22:00";
    timepicker2 = "08:00";
    onTimePicker2Change(time) {
        // console.log("onTimePicker2Change: " + time);
    }
    timePickerFormValidate() {
        this.$refs.timePickerForm.validate();
    }
    timePickerFormReset() {
        this.$refs.timePickerForm.reset();
    }
    timePickerFormResetValidation() {
        this.$refs.timePickerForm.resetValidation();
    }
};
CustomTimePickerExamples = __decorate([
    Component({
        components: {
            CustomButton,
            CustomTimePicker,
        },
        methods: {
            required,
        },
    })
], CustomTimePickerExamples);
export default CustomTimePickerExamples;
