import { __decorate } from "tslib";
import { passwordConfirmationRule, passwordRule, clearValidation } from "@/util/forms/formutils";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import { password, passwordMatch, required, maxFileSize, } from "@/components/shared/validationRules";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import CardButtonActions from "@/components/shared/CardButtonActions.vue";
import Vue from "vue";
import { featureModule } from "@/store/modules/featureModule";
import { readUploadedFileAsText } from "@/util/imageconverter";
import CustomFileInput from "../shared/CustomFileInput.vue";
import CustomTreeSelect from "../shared/CustomTreeSelect.vue";
import AreaTreeSelect from "@/components/treeSelects/AreaTreeSelect.vue";
import { constantsModule } from "@/store/modules/constantsModule";
import { areaModule } from "@/store/modules/areaModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import router from "@/router";
import { ModeEnum } from "@/store/modules/enums";
let ModeCreationForm = class ModeCreationForm extends Vue {
    type = InputType;
    formValid = false;
    passwordConfirm = "";
    userProp;
    modeConfig;
    useSubareas() {
        return featureModule.useSubAreas;
    }
    onAreasChange(areas) {
        this.modeConfig.areas = areas;
    }
    async setImage(target, file) {
        try {
            const fileContents = await readUploadedFileAsText(file);
            this.modeConfig[target] = fileContents;
        }
        catch (e) {
            // console.log()
        }
    }
    handleFormSubmit() {
        this.$emit("createform-confirmed");
    }
    onCountChaged(count) {
        this.$refs.createForm.resetValidation();
        this.passwordConfirm = "";
    }
    onValidChanged() {
        this.$emit("state-changed", this.formValid);
    }
    get getAppModes() {
        return constantsModule.getModes;
    }
    get getAreas() {
        return areaModule.getAreas;
    }
    get getWelcomeEmailTemplates() {
        return emailTemplateModule.getEmailTemplates;
    }
    get getSubscriptionTypes() {
        return subscriptionModule.getSubscriptionTypes;
    }
    get getFeatures() {
        return featureModule.getLoggedInCustomerFeatures.filter(feature => router
            .getRoutes()
            .find(route => route.meta &&
            route.meta.modes &&
            route.meta.modes.includes(this.userProp.modeID) &&
            route.meta.feature &&
            route.meta.feature == feature.featureID));
    }
    get getMultipleSelectionText() {
        return this.modeConfig.features && this.modeConfig.features.length > 0
            ? this.modeConfig.features[0].feature
            : "";
    }
    get showFeatures() {
        return (this.userProp.modeID &&
            (this.userProp.modeID == parseInt(ModeEnum.Delegate.toString()) ||
                this.userProp.modeID == parseInt(ModeEnum.ParkAdmin.toString())));
    }
};
__decorate([
    PropSync("user", { type: Object })
], ModeCreationForm.prototype, "userProp", void 0);
__decorate([
    Prop()
], ModeCreationForm.prototype, "modeConfig", void 0);
__decorate([
    Watch("resetCount")
], ModeCreationForm.prototype, "onCountChaged", null);
__decorate([
    Watch("formValid")
], ModeCreationForm.prototype, "onValidChanged", null);
ModeCreationForm = __decorate([
    Component({
        components: {
            CardButtonActions,
            CustomFileInput,
            CustomSelect,
            CustomTreeSelect,
            AreaTreeSelect,
            CustomTextField,
        },
        methods: {
            maxFileSize,
            clearValidation,
            passwordConfirmationRule,
            passwordRule,
            password,
            passwordMatch,
            required,
        },
    })
], ModeCreationForm);
export default ModeCreationForm;
