import moment from "moment";
export const mapLabel = (value, translationKey) => {
    if (value == 1)
        return translationKey.tc(1);
    return translationKey.tc(2);
};
export const formatTimeLabel = (duration) => {
    return Math.floor(duration / 60) + "h";
    if (Math.floor(duration / 60) >= 1) {
        return Math.floor(duration / 60) + "h";
    }
    return duration + "m";
};
export const toLocaleFormattedDayOfWeek = (dayOfWeek) => {
    return moment()
        .day(dayOfWeek)
        .format("dddd").capitalize();
};
