import { CustomerEnum, LocaleEnum } from "@/store/modules/enums";
const locales = [
    {
        key: "da-JEUDAN",
        name: "🇩🇰 Dansk",
        customerID: CustomerEnum.Jeudan,
        localeType: LocaleEnum.DA
    },
    {
        key: "da",
        name: "🇩🇰 Dansk",
        localeType: LocaleEnum.DA
    },
    {
        key: "en",
        name: "🇬🇧 English",
        localeType: LocaleEnum.EN
    }
];
export default locales;
