import { __decorate } from "tslib";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
import { chargeModule } from "./chargeModule";
import { CostumerRequest, CostumerResponse, RefreshTokenRequest, RevokeTokenRequest, UserRequest, } from "@/apiclient/client";
import { Criticality, notificationModule } from "./notificationModule";
import { datawashModule } from "./datawashmodule";
import { initialUnencryptedStorage } from "@/global";
import { statisticsModule } from "./statisticsModule";
import evaluateAccess from "@/util/accesscontrol";
import router from "@/router";
import store from "@/store";
import { AccessLevelEnum } from "./enums";
import { appModule } from "./appModule";
const name = "authenticationModule";
let AuthenticationModule = class AuthenticationModule extends VuexModule {
    token = "";
    refreshToken = "";
    status = "";
    accessLevel = null;
    customerId = null;
    loggedInCustomer = new CostumerResponse();
    loggedInUserId = 0;
    subscriberId;
    mode;
    loggedInCustomerName;
    async authenticate(authRequest) {
        return apiClient
            .authenticate(true, authRequest.request)
            .then(response => {
            evaluateAccess(response.data?.modeID?.valueOf());
            this.context.commit("SET_AUTHENTICATION_INFO", response.data);
        })
            .then(() => {
            if (authenticationModule.accessLevel < 3)
                this.context.dispatch("fetchLoggedInCustomer", this.customerId);
        })
            .catch(error => {
            if (error.status === 401) {
                // 401 (Unauthorized) status code
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_wrongUserNameOrPassword".t(),
                    criticality: Criticality.Error,
                });
            }
            else {
                notificationModule.SHOW_NOTIFICATION({
                    message: "notification_error_occured".t(),
                    criticality: Criticality.Error,
                });
            }
            throw new Error();
        });
    }
    async fetchLoggedInCustomer(customerId) {
        return apiClient.costumersGET2(customerId).then(response => {
            this.context.commit("SET_LOGGEDIN_CUSTOMER", response.data);
            this.context.commit("SET_LOGGEDIN_CUSTOMER_NAME", response.data?.costumerName);
        });
    }
    async refreshLoginToken() {
        const revokeTokenRequest = new RevokeTokenRequest(undefined);
        revokeTokenRequest.refreshToken = this.refreshToken;
        const refreshTokenRequest = new RefreshTokenRequest(revokeTokenRequest);
        return apiClient.refreshToken(refreshTokenRequest).then(response => {
            this.context.commit("SET_AUTHENTICATION_INFO", response.data);
        });
    }
    async logout() {
        const revokeTokenRequest = new RevokeTokenRequest(undefined);
        revokeTokenRequest.refreshToken = this.refreshToken;
        await apiClient.revokeToken(revokeTokenRequest);
        //force the redirect
        router.push({ path: "/login" });
        this.context.commit("LOGOUT");
        this.context.commit("RESET_ALL_STATE");
        appModule.SET_INITIAL_TOKEN_REFRESHED(false);
        appModule.SET_INITIAL_MODULES_LOADED(false);
    }
    SET_AUTHENTICATION_INFO(data) {
        this.token = data.jwtToken;
        this.refreshToken = data.refreshToken;
        this.loggedInCustomer = data;
        this.customerId = data.costumerID;
        this.loggedInUserId = data.id;
        this.accessLevel = data.accessLevel;
        this.mode = data.modeID;
        this.subscriberId = data.subscriberID;
    }
    SET_LOGGEDIN_CUSTOMER_NAME(name) {
        this.loggedInCustomerName = name;
    }
    SET_LOGGEDIN_CUSTOMER(customer) {
        this.loggedInCustomer = customer;
    }
    SET_LOGGEDIN_COSTUMERID(id) {
        this.customerId = id;
    }
    SET_IMPERSONATION_STATE(data, customerName) {
        this.customerId = data?.costumerID ?? 0;
        this.loggedInCustomer = data || new CostumerRequest();
        this.loggedInCustomerName = customerName || "";
    }
    LOGOUT() {
        this.status = "";
        this.token = "";
        this.refreshToken = "";
        this.loggedInCustomer = new UserRequest();
        this.accessLevel = 999;
        this.customerId = -1;
        this.loggedInCustomerName = "";
    }
    RESET_ALL_STATE() {
        statisticsModule.RESET_STATE();
        datawashModule.RESET_STATE();
        chargeModule.RESET_STATE();
    }
    get getLoggedInUserId() {
        return this.loggedInUserId;
    }
    get getMode() {
        return this.mode;
    }
    get getSubscriberId() {
        return this.subscriberId;
    }
    get getLoggedInCustomerName() {
        return this.loggedInCustomerName;
    }
    get getAccessLevel() {
        return this.accessLevel;
    }
    get isSuperAdmin() {
        return this.getAccessLevel === AccessLevelEnum.SuperAdmin;
    }
    get isAdmin() {
        return this.getAccessLevel === AccessLevelEnum.Admin;
    }
    get isExternal() {
        return this.getAccessLevel === AccessLevelEnum.External;
    }
    get isOperator() {
        return this.getAccessLevel === AccessLevelEnum.Operator;
    }
    get getCustomerId() {
        return this.customerId;
    }
    get isAuthenticated() {
        return this.token && this.token !== "";
    }
    get loggedInUserCustomerId() {
        return this.customerId;
    }
    get getToken() {
        return this.token;
    }
    get authStatus() {
        return this.status;
    }
};
__decorate([
    Action({ rawError: true })
], AuthenticationModule.prototype, "authenticate", null);
__decorate([
    Action({ rawError: true })
], AuthenticationModule.prototype, "fetchLoggedInCustomer", null);
__decorate([
    Action({ rawError: true })
], AuthenticationModule.prototype, "refreshLoginToken", null);
__decorate([
    Action({ rawError: true })
], AuthenticationModule.prototype, "logout", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "SET_AUTHENTICATION_INFO", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "SET_LOGGEDIN_CUSTOMER_NAME", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "SET_LOGGEDIN_CUSTOMER", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "SET_LOGGEDIN_COSTUMERID", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "SET_IMPERSONATION_STATE", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "LOGOUT", null);
__decorate([
    Mutation
], AuthenticationModule.prototype, "RESET_ALL_STATE", null);
AuthenticationModule = __decorate([
    Module({
        dynamic: true,
        store,
        name,
        preserveState: Boolean(initialUnencryptedStorage[name]),
    })
], AuthenticationModule);
export const authenticationModule = getModule(AuthenticationModule);
