import { __decorate } from "tslib";
import store from "@/store/index";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { apiClient } from "@/apiclient/baseclient";
let TosModule = class TosModule extends VuexModule {
    mainHeader = "";
    sections = new Array();
    endSection = "";
    headerLogo;
    headerLogoBase64 = "";
    footerLogo;
    footerLogoBase64 = "";
    templateId = 1;
    get getParagraphs() {
        return this.sections;
    }
    get getFooterLogoBase64() {
        return this.footerLogoBase64;
    }
    get getFooterLogo() {
        return this.footerLogo;
    }
    get getHeaderLogoBase64() {
        return this.headerLogoBase64;
    }
    get getHeaderLogo() {
        return this.headerLogo;
    }
    get getMainHeader() {
        return this.mainHeader;
    }
    get getTemplateId() {
        return this.templateId;
    }
    get getEndSection() {
        return this.endSection;
    }
    get getSections() {
        return this.sections;
    }
    async updateTos(request) {
        var updatedTos = await apiClient.termsOfServicePUT(request.customerId, request.tos);
    }
    async fetchTosTemplate(customerId) {
        var configuration = await apiClient.termsOfServiceGET(customerId);
        this.context.commit("SET_TEMPLATEID", configuration.data?.id);
        this.context.commit("SET_FOOTERLOGO_BASE64", configuration.data?.footerLogo);
        this.context.commit("SET_HEADERLOGO_BASE64", configuration.data?.headerLogo);
        this.context.commit("SET_MAINHEADER", configuration.data?.mainTitle);
        this.context.commit("SET_TOS_PARAGRAPHS", configuration.data?.paragraphs);
    }
    updateHeaderLogo(logo) {
        this.context.commit("SET_HEADERLOGO", logo);
        try {
            const reader = new FileReader();
            reader.readAsDataURL(logo);
            reader.onload = () => {
                this.context.commit("SET_HEADERLOGO_BASE64", reader.result);
            };
        }
        catch (error) {
            //Not a file
            this.context.commit("SET_HEADERLOGO_BASE64", "");
        }
    }
    updateFooterLogo(logo) {
        this.context.commit("SET_FOOTERLOGO", logo);
        try {
            const reader = new FileReader();
            reader.readAsDataURL(logo);
            reader.onload = () => {
                this.context.commit("SET_FOOTERLOGO_BASE64", reader.result);
            };
        }
        catch (error) {
            //Not a file
            this.context.commit("SET_FOOTERLOGO_BASE64", "");
        }
    }
    updateMainHeader(mainHeader) {
        this.context.commit("SET_MAINHEADER", mainHeader);
    }
    updateEndSection(endSection) {
        this.context.commit("SET_ENDSECTION", endSection);
    }
    SET_TEMPLATEID(templateId) {
        this.templateId = templateId;
    }
    SET_TOS_PARAGRAPHS(paragraphs) {
        this.sections = paragraphs;
    }
    SET_FOOTERLOGO(logo) {
        this.footerLogo = logo;
    }
    SET_FOOTERLOGO_BASE64(logo) {
        this.footerLogoBase64 = logo;
    }
    SET_HEADERLOGO(logo) {
        this.headerLogo = logo;
    }
    SET_HEADERLOGO_BASE64(logo) {
        this.headerLogoBase64 = logo;
    }
    SET_ENDSECTION(endSection) {
        this.endSection = endSection;
    }
    SET_MAINHEADER(mainHeader) {
        this.mainHeader = mainHeader;
    }
};
__decorate([
    Action({ rawError: true })
], TosModule.prototype, "updateTos", null);
__decorate([
    Action({ rawError: true })
], TosModule.prototype, "fetchTosTemplate", null);
__decorate([
    Action({ rawError: true })
], TosModule.prototype, "updateHeaderLogo", null);
__decorate([
    Action({ rawError: true })
], TosModule.prototype, "updateFooterLogo", null);
__decorate([
    Action({ rawError: true })
], TosModule.prototype, "updateMainHeader", null);
__decorate([
    Action({ rawError: true })
], TosModule.prototype, "updateEndSection", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_TEMPLATEID", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_TOS_PARAGRAPHS", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_FOOTERLOGO", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_FOOTERLOGO_BASE64", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_HEADERLOGO", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_HEADERLOGO_BASE64", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_ENDSECTION", null);
__decorate([
    Mutation
], TosModule.prototype, "SET_MAINHEADER", null);
TosModule = __decorate([
    Module({ dynamic: true, store, name: "tosModule", namespaced: true })
], TosModule);
export default TosModule;
export const tosModule = getModule(TosModule);
