export default class EmailTemplateModuleRequest {
    customerId;
    templateId;
    emailTemplateUpdate;
    constructor(customerId, templateId, emailTemplateUpdate) {
        this.customerId = customerId;
        this.templateId = templateId;
        this.emailTemplateUpdate = emailTemplateUpdate;
    }
}
