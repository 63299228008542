import { __decorate } from "tslib";
import { Prop, PropSync } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
let CustomFileInput = class CustomFileInput extends Vue {
    syncFile;
    label;
    multiple;
    rules;
    disabled;
    onChange(e) {
        this.$emit("on-file-input-change", e);
    }
    onClickClear(e) {
        this.$emit("on-file-input-clear", e);
    }
    get getLabel() {
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
};
__decorate([
    PropSync("file", { required: false })
], CustomFileInput.prototype, "syncFile", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomFileInput.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomFileInput.prototype, "multiple", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomFileInput.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomFileInput.prototype, "disabled", void 0);
CustomFileInput = __decorate([
    Component
], CustomFileInput);
export default CustomFileInput;
