import { __decorate } from "tslib";
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import { InputType } from "@/components/shared/enums";
import { customerModule } from "@/store/modules/customerModule";
import { minNumber } from "@/components/shared/validationRules";
import DataConfigurationInput from "@/components/settings/DataConfigurationInput.vue";
let DataConfiguration = class DataConfiguration extends Vue {
    type = InputType;
    selectedCustomer = null;
    dataConfigurations = null;
    mounted() {
        customerModule.fetchCustomers();
    }
    onCustomerChanged(customer) {
        if (!customer?.costumerID)
            return;
        customerModule.fetchDataConfigurations(customer.costumerID);
    }
    onDataConfigurationChanged(dataConfigs) {
        if (!dataConfigs || dataConfigs.length === 0)
            return;
        this.dataConfigurations = [...dataConfigs].sort((a, b) => a.maskableEntityID - b.maskableEntityID);
    }
    get getCustomers() {
        return customerModule.getCustomers;
    }
    get getDataConfigurations() {
        return customerModule.dataConfigurations;
    }
};
__decorate([
    Watch("selectedCustomer")
], DataConfiguration.prototype, "onCustomerChanged", null);
__decorate([
    Watch("getDataConfigurations")
], DataConfiguration.prototype, "onDataConfigurationChanged", null);
DataConfiguration = __decorate([
    Component({
        components: {
            CustomSelect,
            DataConfigurationInput,
        },
        methods: {
            minNumber,
        },
    })
], DataConfiguration);
export default DataConfiguration;
