import Vue from "vue";
import VueRouter from "vue-router";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { featureModule } from "@/store/modules/featureModule";
import { modeModule } from "@/store/modules/modeModule";
import { appModule } from "@/store/modules/appModule";
import { userModule } from "@/store/modules/userModule";
import { hasAccessToRoute } from "./utils";
import { AccessLevelEnum, FeatureEnum, ModeEnum } from "@/store/modules/enums";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "root",
        component: () => import("../views/Login.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login/:modes?",
        name: "login",
        props: true,
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/signature",
        name: "signature",
        component: () => import("../views/Signature.vue"),
        meta: {
            requiresAuth: true,
            modes: [ModeEnum.ParkAdmin],
        },
    },
    {
        path: "/users/",
        props: true,
        component: () => import("@/views/UsersPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Accounts,
            accessLevel: AccessLevelEnum.Admin,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "account",
                    tIndex: 2,
                },
                icon: "mdi-key-change",
                order: 7,
            },
        },
        children: [
            {
                path: "",
                component: () => import("@/components/users/Overview.vue"),
            },
            {
                path: ":id",
                component: () => import("@/components/users/Details.vue"),
                meta: {
                    accessLevel: AccessLevelEnum.External,
                    modes: [ModeEnum.ParkAdmin, ModeEnum.Delegate],
                },
            },
        ],
    },
    {
        path: "/charge",
        name: "charge",
        component: () => import("../views/ChargePage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Charge,
            accessLevel: AccessLevelEnum.Operator,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "charge",
                    tIndex: 2,
                },
                icon: "$chargefine",
                order: 11,
            },
        },
    },
    {
        path: "/charge-template",
        name: "chargeTemplate",
        component: () => import("../views/ChargeTemplate.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Charge,
            accessLevel: AccessLevelEnum.Admin,
            modes: [ModeEnum.ParkAdmin],
        },
    },
    {
        path: "/datawash",
        name: "datawash",
        component: () => import("../views/DataWashPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Wash,
            accessLevel: AccessLevelEnum.Operator,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "plateWash",
                },
                icon: "$platewash",
                order: 10,
            },
        },
    },
    {
        path: "/statistics",
        name: "statistics",
        component: () => import("../views/StatisticsPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Statistic,
            accessLevel: AccessLevelEnum.External,
            modes: [ModeEnum.ParkAdmin, ModeEnum.Delegate],
            menuLink: {
                title: {
                    tKey: "statistics",
                },
                icon: "mdi-chart-line",
                order: 1,
            },
        },
    },
    {
        path: "/subscribers/",
        props: true,
        component: () => import("@/views/SubscribersPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Subscriptions,
            accessLevel: AccessLevelEnum.External,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "subscriber",
                    tIndex: 2,
                },
                icon: "mdi-account-search-outline",
                order: 4,
            },
        },
        children: [
            {
                path: "",
                component: () => import("@/components/subscribers/Overview.vue"),
            },
            {
                path: ":id",
                component: () => import("@/components/subscribers/Details.vue"),
            },
        ],
    },
    {
        path: "/editcharge/:id",
        name: "editcharge",
        component: () => import("../views/ChargeEditor.vue"),
        props: route => ({
            parking: {},
            ...route.params,
        }),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Charge,
            accessLevel: AccessLevelEnum.Operator,
            modes: [ModeEnum.ParkAdmin],
        },
    },
    {
        path: "/modelogins/:id",
        name: "modelogins",
        props: route => ({
            subscriber: {},
            ...route.params,
        }),
        component: () => import("@/views/ModeLoginManagement.vue"),
        meta: {
            requiresAuth: true,
            modes: [ModeEnum.ParkAdmin],
            accessLevel: AccessLevelEnum.SuperAdmin,
        },
    },
    {
        path: "/subscriptions/:id",
        name: "subscriptionlist",
        props: true,
        component: () => import("../views/listViews/SubscriptionList.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Subscriptions,
            accessLevel: AccessLevelEnum.External,
            modes: [ModeEnum.Delegate],
            getID: function () {
                return authenticationModule.getSubscriberId;
            },
            menuLink: {
                title: {
                    tKey: "subscription",
                    tIndex: 2,
                },
                icon: "mdi-file-document-edit-outline",
                order: 9,
            },
        },
    },
    {
        path: "/subscriptions/",
        name: "subscriptions",
        component: () => import("../views/listViews/SubscriptionList.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Subscriptions,
            accessLevel: AccessLevelEnum.External,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "subscription",
                    tIndex: 2,
                },
                icon: "mdi-file-document-edit-outline",
                order: 5,
            },
        },
    },
    {
        path: "/createprivatecustomer",
        name: "privatestepper",
        component: () => import("@/components/steppers/PrivateCustomerStepper.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.SubscriberQuickAdd,
            accessLevel: AccessLevelEnum.External,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "createprivatesubscriber",
                },
                icon: "mdi-account-plus",
                order: 3,
            },
        },
    },
    {
        path: "/emails",
        name: "emails",
        component: () => import("@/views/listViews/EmailsView.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Emails,
            accessLevel: AccessLevelEnum.Admin,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "emailQueue",
                },
                icon: "mdi-email",
                order: 7,
            },
        },
    },
    {
        path: "/settings/",
        name: "settings",
        props: true,
        component: () => import("@/views/SettingsPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Settings,
            accessLevel: AccessLevelEnum.Admin,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "settings",
                },
                icon: "mdi-wrench",
                order: 8,
            },
        },
    },
    {
        path: "/customers/",
        name: "customers",
        props: true,
        component: () => import("@/views/listViews/CustomersView.vue"),
        meta: {
            requiresAuth: true,
            modes: [ModeEnum.ParkAdmin],
            accessLevel: AccessLevelEnum.SuperAdmin,
        },
    },
    {
        path: "/areas/",
        props: true,
        component: () => import("@/views/AreasPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Areas,
            accessLevel: AccessLevelEnum.Admin,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "area",
                    tIndex: 2,
                },
                icon: "mdi-garage-open",
                order: 6,
            },
        },
        children: [
            {
                path: "",
                component: () => import("@/components/areas/Overview.vue"),
            },
            {
                path: ":id",
                component: () => import("@/components/areas/Details.vue"),
            },
        ],
    },
    {
        path: "/parking/",
        props: false,
        component: () => import("@/views/ParkingPage.vue"),
        meta: {
            requiresAuth: true,
            feature: FeatureEnum.Parking,
            accessLevel: AccessLevelEnum.External,
            modes: [ModeEnum.ParkAdmin],
            menuLink: {
                title: {
                    tKey: "parking",
                    tIndex: 2,
                },
                icon: "mdi-car",
                order: 2,
            },
        },
    },
    {
        path: "/designguide/",
        props: false,
        component: () => import("@/views/DesignGuidePage.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: AccessLevelEnum.SuperAdmin,
        },
    },
    {
        name: "not-found",
        path: "/:pathMatch(.*)*",
        component: () => import("@/views/NotFoundPage.vue"),
        meta: {
            requiresAuth: true,
        },
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    appModule
        .fetchInitialRefreshToken()
        .then(() => {
        if (!to.matched.some(record => record.meta.requiresAuth) &&
            !authenticationModule.isAuthenticated) {
            return next();
        }
        if (!authenticationModule.isAuthenticated) {
            return next("/login");
        }
        appModule.fetchInitialModules().then(() => {
            const route = to.meta && Object.keys(to.meta).length > 0 ? to : to.matched[0];
            if (to.path === "/" || to.path === "/login") {
                return next(appModule.getMenuItems[0] ? appModule.getMenuItems[0].href : "/not-found");
            }
            if (!route.meta) {
                return next();
            }
            const customerFeatures = modeModule.isDelegateLogin
                ? userModule.getModeFeatures
                : featureModule.getActiveCustomerFeatures;
            const customerAccessLevel = authenticationModule.getAccessLevel;
            const customerMode = authenticationModule.getMode;
            const hasAccess = hasAccessToRoute(route, customerAccessLevel, customerMode, customerFeatures);
            if (!hasAccess) {
                return next("/not-found");
            }
            return next();
        });
    })
        .catch(() => {
        authenticationModule.LOGOUT();
        return next("/login");
    });
});
export default router;
