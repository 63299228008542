import { __decorate } from "tslib";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component } from "vue-property-decorator";
import AuthModuleRequest from "@/util/storerequest/authRequest";
import LoginForm from "@/components/shared/LoginForm.vue";
import Vue from "vue";
import { appModule } from "@/store/modules/appModule";
import { statusModule } from "@/store/modules/statusModule";
let Login = class Login extends Vue {
    authenticate(request) {
        statusModule.SET_AUTHENTICATION_LOADING(true);
        let payload = new AuthModuleRequest(request.username, request.password, authenticationModule.getMode);
        authenticationModule
            .authenticate(payload)
            .then(() => {
            if (authenticationModule.isAuthenticated) {
                appModule
                    .fetchInitialModules(true)
                    .then(() => {
                    statusModule.SET_AUTHENTICATION_LOADING(false);
                    const route = appModule.getMenuItems[0];
                    this.$router.push({
                        path: route?.href ?? `/users/${authenticationModule.getLoggedInUserId}`,
                    });
                })
                    .finally(() => {
                    statusModule.SET_AUTHENTICATION_LOADING(false);
                });
            }
        })
            .catch(error => {
            statusModule.SET_AUTHENTICATION_LOADING(false);
        });
    }
    get getIsLoading() {
        return statusModule.getAuthenticationLoading;
    }
};
Login = __decorate([
    Component({
        components: {
            LoginForm,
        },
        methods: {},
    })
], Login);
export default Login;
