import { __decorate } from "tslib";
import { Prop, PropSync } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
let CustomSlider = class CustomSlider extends Vue {
    syncValue;
    label;
    step;
    thumbColor;
    disabled;
    rules;
    onChange(value) {
        this.$emit("on-slider-change", value);
    }
};
__decorate([
    PropSync("value", { required: true, type: Number })
], CustomSlider.prototype, "syncValue", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomSlider.prototype, "label", void 0);
__decorate([
    Prop({ required: false, type: Number })
], CustomSlider.prototype, "step", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomSlider.prototype, "thumbColor", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomSlider.prototype, "disabled", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomSlider.prototype, "rules", void 0);
CustomSlider = __decorate([
    Component
], CustomSlider);
export default CustomSlider;
