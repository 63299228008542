import { __decorate } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
let NoImages = class NoImages extends Vue {
    noImageSrc = require('../../assets/stateicons/noimages.png');
};
NoImages = __decorate([
    Component
], NoImages);
export default NoImages;
