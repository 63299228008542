import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";
let CustomSelect = class CustomSelect extends Vue {
    syncSelected;
    items;
    itemText; // for each list item, show this object property e.g. if list object has property 'name' or 'text'
    itemValue; // if only a specific value is needed from a selected list item e.g. 'id', this property will be returned (this property has no relevance if returnObject="true", since the entire object is returned)
    label;
    multipleSelection;
    multipleSelectionText;
    returnObject;
    rules;
    disabled;
    height;
    hideDetails;
    onChange(selected) {
        this.$emit("on-select-change", selected);
    }
    get getLabel() {
        return this.rules?.some(rule => rule.name && rule.name.includes("required"))
            ? this.label + " *"
            : this.label;
    }
};
__decorate([
    PropSync("selected", { required: true })
], CustomSelect.prototype, "syncSelected", void 0);
__decorate([
    Prop({ required: true, type: Array() })
], CustomSelect.prototype, "items", void 0);
__decorate([
    Prop({ required: false })
], CustomSelect.prototype, "itemText", void 0);
__decorate([
    Prop({ required: false })
], CustomSelect.prototype, "itemValue", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomSelect.prototype, "label", void 0);
__decorate([
    Prop({ required: true, type: Boolean })
], CustomSelect.prototype, "multipleSelection", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomSelect.prototype, "multipleSelectionText", void 0);
__decorate([
    Prop({ required: true, type: Boolean })
], CustomSelect.prototype, "returnObject", void 0);
__decorate([
    Prop({ required: false, type: Array() })
], CustomSelect.prototype, "rules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomSelect.prototype, "disabled", void 0);
__decorate([
    Prop({ type: String || Number })
], CustomSelect.prototype, "height", void 0);
__decorate([
    Prop({ type: Boolean })
], CustomSelect.prototype, "hideDetails", void 0);
CustomSelect = __decorate([
    Component
], CustomSelect);
export default CustomSelect;
