import { __decorate } from "tslib";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import CustomButton from "../shared/CustomButton.vue";
import CustomDialog from "../shared/CustomDialog.vue";
import moment from "moment";
import { compareJsonObjects } from "@/util/utils";
import SubscriptionHistoryDetails from "./SubscriptionHistoryDetails.vue";
let SubscriptionHistory = class SubscriptionHistory extends Vue {
    subscription;
    subscriptionHistory = [];
    selectedHistory = null;
    changes = {};
    isLoading = true;
    async onShow() {
        if (!this.show) {
            setTimeout(() => {
                this.selectedHistory = null;
            }, 500);
            return;
        }
        this.isLoading = true;
        const response = await subscriptionModule.fetchSubscriptionHistory(this.subscription.id);
        if (!response)
            return;
        this.subscriptionHistory = response;
        this.isLoading = false;
    }
    getChangeState(modifyActionID) {
        switch (modifyActionID) {
            case 1:
                return "Created";
            case 2:
                return "Updated";
            case 3:
                return "Deleted";
        }
    }
    getChangeStateIcon(modifyActionID) {
        switch (modifyActionID) {
            case 1:
                return "mdi-plus-circle";
            case 2:
                return "mdi-pencil";
            case 3:
                return "mdi-delete";
        }
    }
    setSelectedHistory(hist) {
        const selectedIndex = this.subscriptionHistory.findIndex(h => h.historyID === hist.historyID);
        const obj = {};
        if (selectedIndex === this.subscriptionHistory.length - 1) {
            const changes = compareJsonObjects(this.subscriptionHistory[this.subscriptionHistory.length - 1].entity, {});
            for (const [key, val] of Object.entries(changes)) {
                if (val["from"] && !val["to"]) {
                    val["to"] = val["from"];
                    val["from"] = undefined;
                    obj[key] = val;
                }
            }
            this.changes = obj;
        }
        else {
            const changes = compareJsonObjects(this.subscriptionHistory[selectedIndex + 1].entity, this.subscriptionHistory[selectedIndex].entity);
            this.changes = changes;
        }
        this.selectedHistory = hist;
    }
    get show() {
        return Boolean(this.subscription);
    }
    get showDetails() {
        return Boolean(this.selectedHistory);
    }
};
__decorate([
    Prop()
], SubscriptionHistory.prototype, "subscription", void 0);
__decorate([
    Watch("subscription", { immediate: true })
], SubscriptionHistory.prototype, "onShow", null);
SubscriptionHistory = __decorate([
    Component({
        name: "SubscriptionHistory",
        components: {
            CustomButton,
            CustomDialog,
            SubscriptionHistoryDetails,
        },
        methods: {
            moment,
        },
    })
], SubscriptionHistory);
export default SubscriptionHistory;
