export default class ParkAdminUser {
    id;
    costumerID;
    name;
    username;
    accessLevel;
    role;
    language;
    laguageName;
    userTypeID;
    userType;
    modeID;
    mode;
    subscriberID;
    password;
    constructor(id, costumerID, name, username, accessLevel, role, language, laguageName, userTypeID, userType, modeID, mode, subscriberID, password) {
        this.id = id;
        this.costumerID = costumerID;
        this.name = name;
        this.username = username;
        this.accessLevel = accessLevel;
        this.role = role;
        this.language = language;
        this.laguageName = laguageName;
        this.userTypeID = userTypeID;
        this.userType = userType;
        this.modeID = modeID;
        this.mode = mode;
        this.subscriberID = subscriberID;
        this.password = password;
    }
}
