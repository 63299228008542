<template>
  <section class="pa-6 mb-5">
    <v-stepper v-model="curr" color="green">
      <v-stepper-header>
        <div v-for="(step, idx) in steps" :key="idx">
          <v-stepper-step
            :complete="stepComplete(idx + 1)"
            :step="idx + 1"
            :rules="[(value) => validator(step, idx)]"
            :color="stepStatus(idx + 1)"
          >
            {{ $t(step.name) }}
          </v-stepper-step>
          <v-divider></v-divider>
        </div>
      </v-stepper-header>
      <v-stepper-content
        v-for="(step, idx) in steps"
        :step="idx + 1"
        :key="idx"
      >
        <h3>{{ $t(step.name) }}</h3>
        <v-container>
          <v-card>
            <SubscriberForm
              ref="privateSubscriberForm"
              :showNewsPaperSwitch="false"
              :showCustomerTypeToggle="false"
              :isPrivateCustomerProp="true"
              :customerTypeText.sync="customerTypeText"
              :isPrivate="isPrivate"
              @form-valid="
                subscriberformValid = $event;
                steps[0].valid = $event;
              "
              v-if="idx + 1 === 1"
              :editMode="false"
              :subscriber.sync="subscriber"
            />
            <SubscriptionForm
              ref="privateSubscriptionForm"
              v-model="subscription"
              v-if="idx + 1 === 2"
              @form-valid="steps[1].valid = $event"
              @update-areas="updateAreas = $event"
              :editMode="false"
              :showRemark="false"
            />
          </v-card>
        </v-container>
        <CarList
          class="mb-5"
          @save-clicked="save()"
          @close="close()"
          @car-updated="updateCarInStore($event)"
          @car-created="createCarInStore($event)"
          :subscription="getSubscription"
          :singleSubscriptionMode="true"
          :showdeleteicon="true"
          v-if="idx + 1 === 3"
        />

        <SignatureComponent
          :signature="signature"
          @signed-and-accepted="steps[3].valid = $event"
          v-if="idx + 1 === 4"
        />
        <CustomButton
          v-if="curr < 3"
          :text="'button_next'.t()"
          :action="action.Primary"
          @on-button-click="validate(idx)"
          class="float-right"
        />

        <CustomButton
          v-if="idx + 1 === 3"
          :text="'button_next'.t()"
          :action="action.Primary"
          :disabled="!steps[2].valid"
          @on-button-click="validate(idx)"
          class="float-right"
        />

        <CustomButton
          v-if="idx + 1 === 4"
          :text="'button_create'.t()"
          :action="action.Primary"
          :disabled="!steps[3].valid"
          @on-button-click="createSubscription"
          class="float-right"
        />

        <CustomButton
          v-if="curr > 1"
          :text="'button_back'.t()"
          :action="action.Back"
          @on-button-click="curr = idx"
        />
      </v-stepper-content>
    </v-stepper>
  </section>
</template>

<script>
import {
  Criticality,
  notificationModule,
} from "@/store/modules/notificationModule";
import { castToSubscription } from "@/util/subscriptionConverter";
import { stepperModule } from "@/store/modules/stepperModule";
import { subscriberModule } from "@/store/modules/subscriberModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import CarList from "@/views/listViews/CarList.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import router from "@/router";
import SignatureComponent from "@/views/Signature.vue";
import SubscriberForm from "@/components/forms/SubscriberForm.vue";
import SubscriptionForm from "@/components/forms/SubscriptionForm.vue";

export default {
  components: {
    CarList,
    CustomButton,
    SignatureComponent,
    SubscriberForm,
    SubscriptionForm,
  },
  data: () => ({
    action: Action,
    customerTypeText: 'private',
    subscriberformValid: false,
    updateAreas: [],
    emailsAccepted: false,
    dialog: false,
    termsAgreed: false,
    signed: false,
    curr: 1,
    max: 1,
    lastStep: 4,
    subscriber: {
      subscriberType: 2,
    },
    subscription: {
      name: null,
      email: null,
      remark: null,
      recieveNewsLetter: false,
      fromDate: null,
      toDate: null,
    },
    signature: {},
    steps: [
      {
        name: "customerdata",
        valid: false,
      },
      {
        name: "adduser",
        valid: false,
      },
      {
        name: "addcars",
        valid: true,
      },
      {
        name: "signature",
        valid: false,
      },
    ],
  }),
  watch: {
    curr(val) {
      this.max = Math.max(val, this.max);
    },
  },
  computed: {
    getSubscription() {
      return stepperModule.getSubscription;
    },
    getSubscriber() {
      return stepperModule.getSubscriber;
    },
    getStepperCars() {
      return stepperModule.getCars;
    },
    isPrivate() {
      return this.customerTypeText == 'private';
    }
  },

  methods: {
    validator(s, idx) {
      // This method ensures that the validation state of not-yet-reached steps is always valid.
      // This avoids issues whereby future states are invalid

      if (idx + 1 > this.max) {
        // if not reached yet, unconditionally valid.
        return true;
      }
      if (idx + 1 == this.max && idx + 1 == this.curr) {
        // if step is farthest yet reached, unconditionally valid
        return true;
      }
      // else use validation state
      return s.valid;
    },
    createCarInStore(car) {
      stepperModule.createSubscriptionCar(car);
    },
    updateCarInStore(car) {
      stepperModule.updateSubscriptionCar(car);
    },
    updateSubscriptionInStore(subscription) {
      stepperModule.updateSubscription(subscription);
    },
    updateSubscriberInStore(subscriber) {
      this.subscription.email = subscriber.email;
      this.subscription.name = `${subscriber.firstName} ${subscriber.lastName}`;
      this.subscription.phonenumber = subscriber.phonenumber;
      stepperModule.updateSubscriber(subscriber);
    },
    stepComplete(step) {
      return this.curr > step;
    },
    stepStatus(step) {
      return this.curr > step ? "green" : "blue";
    },
    validate(idx) {
      if (idx === 0) {
        this.$refs.privateSubscriberForm[0].$refs.subscriberform.validate();
        if (!this.steps[0].valid) return;
      } else if (idx === 1) {
        this.$refs.privateSubscriptionForm[0].$refs.subscriptionform.validate();
        if (!this.steps[1].valid) return;
      }

      if (idx === 0) {
        this.updateSubscriberInStore(this.subscriber);
      } else if (idx === 1) {
        this.updateSubscriptionInStore(this.subscription);
      }
      this.curr = idx + 2;
    },

    async createSubscription() {
      //Create subscriber
      var subscriber = this.getSubscriber;
      subscriberModule
        .createSubscriber(subscriber)
        .then((response) => {
          //Response contains the created subscriber
          var subscription = this.getSubscription;
          //this.$store.dispatch('subscriptionModule/createsubscriptionBySubscriberId', {subscription, id:response.id})
          subscriptionModule
            .createSubscription({
              subscription: {
                ...castToSubscription(subscription),
                cars: this.getStepperCars,
              },
              subscriberId: response.id,
            })
            .then(() => {
              notificationModule.SHOW_NOTIFICATION({
                message: "notification_success_create_subscription".t(),
                criticality: Criticality.Success,
              });

              router.push({ path: "/subscribers" });
            });
        })
        .catch(() => {
          notificationModule.SHOW_NOTIFICATION({
            message: "notification_error_occured".t(),
            criticality: Criticality.Error,
          });
        });
    },
  },
  created() {
    stepperModule.clearModule();
  },
};
</script>