import { __decorate } from "tslib";
import EmailConfigurationCard from "@/components/settings/EmailConfigurationCard.vue";
import SubscriptionTypeConfigurationCard from "@/components/settings/SubscriptionTypeConfigurationCard.vue";
import TagConfigurationCard from "@/components/settings/TagConfigurationCard.vue";
import { featureModule } from "@/store/modules/featureModule";
import Vue from "vue";
import { Component } from "vue-property-decorator";
let GeneralSettingsTab = class GeneralSettingsTab extends Vue {
    get hasEmailModule() {
        return featureModule.loggedInCustomerFeatures.some((x) => x.featureID === 3 && x.active);
    }
    get hasSubscriptionModule() {
        return featureModule.loggedInCustomerFeatures.some((x) => x.featureID === 4 && x.active);
    }
    get hasWashOrChargeModule() {
        return featureModule.loggedInCustomerFeatures.some((x) => (x.featureID === 5 && x.active) || (x.featureID === 6 && x.active));
    }
};
GeneralSettingsTab = __decorate([
    Component({
        components: {
            EmailConfigurationCard,
            SubscriptionTypeConfigurationCard,
            TagConfigurationCard,
        },
    })
], GeneralSettingsTab);
export default GeneralSettingsTab;
