import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { getBrowserSize } from "@/util/browserSize";
export const exportToPDF = async (element, options) => {
    const PDF_TITLE = options?.title ?? 'Document';
    const PDF_IMG_WIDTH = options?.imgWidth ?? 215;
    const PDF_PAGE_HEIGHT = options?.pageHeight ?? 295;
    const RESIZE_INNER_CANVAS = options?.innerCanvasOptions?.resizeCanvas ?? true;
    const INNER_CANVAS_WIDTH = options?.innerCanvasOptions?.width ?? 950;
    const INNER_CANVAS_HEIGHT = options?.innerCanvasOptions?.height ?? 400;
    const pdfOptions = {
        ...options?.pdfOptions,
        orientation: options?.pdfOptions?.orientation ?? 'p',
        unit: options?.pdfOptions?.unit ?? 'mm',
        format: options?.pdfOptions?.format ?? 'a4'
    };
    const canvasOptions = {
        ...options?.canvasOptions,
        scale: options?.canvasOptions?.scale ?? 2,
        windowWidth: options?.canvasOptions?.windowWidth ?? 1400,
        windowHeight: options?.canvasOptions?.windowHeight ?? element.clientHeight,
    };
    const pdfDoc = new jsPDF(pdfOptions);
    const changeViewport = getBrowserSize() == 'xs' || getBrowserSize() == 'sm';
    if (changeViewport) {
        setViewportSize(document, `${canvasOptions.windowWidth}`);
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            html2canvas(element, { ...canvasOptions, onclone: function (doc) {
                    if (!RESIZE_INNER_CANVAS)
                        return;
                    resizeInnerCanvas(doc, INNER_CANVAS_WIDTH, INNER_CANVAS_HEIGHT);
                } }).then((canvas) => {
                const imgHeight = (canvas.height * PDF_IMG_WIDTH) / canvas.width;
                const dataURL = canvas.toDataURL("image/png");
                let heightLeft = imgHeight - PDF_PAGE_HEIGHT;
                let position = 0;
                pdfDoc.addImage(dataURL, 'PNG', 0, position, PDF_IMG_WIDTH, imgHeight);
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdfDoc.addPage();
                    pdfDoc.addImage(dataURL, 'PNG', 0, position, PDF_IMG_WIDTH, imgHeight);
                    heightLeft -= PDF_PAGE_HEIGHT;
                }
                pdfDoc.save(`${PDF_TITLE}.pdf`);
                if (changeViewport) {
                    changeViewport && setViewportSize(document, 'device-width, initial-scale=1');
                }
            });
            resolve();
        }, 300);
    });
};
const setViewportSize = (doc, width) => {
    const viewportEl = doc.querySelector('meta[name="viewport"]');
    viewportEl.setAttribute("content", `width=${width}`);
};
const resizeInnerCanvas = (doc, canvasWidth, canvasHeight) => {
    const innerCanvas = doc.querySelectorAll("canvas");
    innerCanvas.forEach(canvas => {
        canvas.style.width = canvasWidth + "px";
        canvas.style.height = canvasHeight + "px";
    });
};
