var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc("car", 2))+" ")]),_c('v-data-table',{attrs:{"loading":_vm.getLoadingStatus,"server-items-length":_vm.getCount,"headers":_vm.getHeaders,"items":_vm.getCarList,"search":_vm.search,"item-key":"id","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [10, 25, 100],
      'items-per-page-text': 'rows_per_page'.t(),
    }},on:{"update:page":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s("table_footer_page_text".t())+" "+_vm._s(items.itemsLength)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(_vm.isAllowedToAddCars)?_c('CustomButton',{attrs:{"text":'button_create'.t(),"action":_vm.action.Primary},on:{"on-button-click":function($event){_vm.dialog = true}}}):_vm._e(),_c('CustomDialog',{attrs:{"show":_vm.dialog,"title":_vm.formTitle,"persistent":false},on:{"update:show":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-form',{ref:"carForm",model:{value:(_vm.carFormValid),callback:function ($$v) {_vm.carFormValid=$$v},expression:"carFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomTextField',{attrs:{"text":_vm.editedItem.name,"label":_vm.$t('carname'),"type":_vm.type.Text},on:{"update:text":function($event){return _vm.$set(_vm.editedItem, "name", $event)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('CustomTextField',{attrs:{"text":_vm.editedItem.licensePlate,"label":_vm.$t('numberplate'),"type":_vm.type.Text,"rules":[_vm.required]},on:{"update:text":function($event){return _vm.$set(_vm.editedItem, "licensePlate", $event)}}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('CustomButton',{attrs:{"text":'button_cancel'.t(),"action":_vm.action.Cancel},on:{"on-button-click":function($event){_vm.$refs.carForm.reset();
                _vm.dialog = false;}}}),_c('CustomButton',{attrs:{"text":'button_save'.t(),"action":_vm.action.Primary},on:{"on-button-click":_vm.save}})]},proxy:true}])}),_c('ConfirmPrompt',{attrs:{"title":((_vm.$tc('delete', 1)) + " " + (_vm.editedItem.licensePlate) + "?"),"persistent":false},on:{"state-changed":function($event){_vm.dialogDelete = $event},"dialog-confirmed":function($event){return _vm.deleteItemConfirm()},"dialog-close":function($event){return _vm.closeDelete()}},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('CustomButton',{attrs:{"icon":"mdi-pencil","action":_vm.action.Primary},on:{"on-button-click":function($event){return _vm.editItem(item)}}}),_c('CustomButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showdeleteicon),expression:"showdeleteicon"}],attrs:{"icon":"mdi-delete","action":_vm.action.Delete},on:{"on-button-click":function($event){return _vm.deleteItem(item)}}})]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }