import { __decorate } from "tslib";
import { InputType } from "@/components/shared/enums";
import { modeModule } from "@/store/modules/modeModule";
import { Prop } from "vue-property-decorator/lib/decorators/Prop";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import { synchronizeAreas } from "@/util/area/areautil";
import { Watch } from "vue-property-decorator";
import CarSubList from "@/components/subLists/CarSubList.vue";
import Component from "vue-class-component";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import EntityToolTip from "@/components/entitypreview/EntityTooltip.vue";
import ListBase from "@/util/mixins/listbase";
import QrManagementModal from "@/components/modals/QrManagementModal.vue";
import SubscriptionForm from "@/components/forms/SubscriptionForm.vue";
import SubscriptionPreview from "@/components/entitypreview/SubscriptionPreview.vue";
import SubscriptionTable from "@/components/subscription/SubscriptionTable.vue";
import debounce from "lodash.debounce";
let SubscriptionList = class SubscriptionList extends ListBase {
    type = InputType;
    subscriber;
    showTableOnCard;
    showQrModal = false;
    awaitingSearch = false;
    qrHeaderTitle = "";
    updateAreas = [];
    subscriberId = 0;
    unmodifiedEditItem = null;
    searchTerm = "";
    onShowQrModalChanged(showModal) {
        if (!showModal) {
            this.qrHeaderTitle = "";
        }
    }
    onEditChanged(val) {
        if (!this.unmodifiedEditItem) {
            this.unmodifiedEditItem = JSON.parse(JSON.stringify(val));
        }
        if (val.areas !== undefined) {
            this.updateAreas = val.areas ?? [];
        }
    }
    onSearchChange(search) {
        this.searchTerm = search;
    }
    debouncedSearchChange = debounce(this.onSearchChange, 500);
    get getId() {
        return parseInt(this.$route.params.id) || this.subscriberId;
    }
    get getSubscriptions() {
        return subscriptionModule.getSubscriptions;
    }
    get isDelegateLogin() {
        return modeModule.isDelegateLogin;
    }
};
__decorate([
    Prop()
], SubscriptionList.prototype, "subscriber", void 0);
__decorate([
    Prop({ required: false, type: Boolean, default: true })
], SubscriptionList.prototype, "showTableOnCard", void 0);
__decorate([
    Watch("showQrModal")
], SubscriptionList.prototype, "onShowQrModalChanged", null);
__decorate([
    Watch("editedItem", { deep: true })
], SubscriptionList.prototype, "onEditChanged", null);
SubscriptionList = __decorate([
    Component({
        components: {
            CarSubList,
            ConfirmPrompt,
            CustomTextField,
            EntityToolTip,
            QrManagementModal,
            SubscriptionForm,
            SubscriptionPreview,
            SubscriptionTable,
        },
        methods: {
            synchronizeAreas,
        },
    })
], SubscriptionList);
export default SubscriptionList;
