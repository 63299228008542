import { __decorate } from "tslib";
import { statisticsModule } from "@/store/modules/statisticsModule";
import { sharedChartOptions, default_colors, } from "@/components/statistics/chartOptions";
import { InputType } from "@/components/shared/enums";
import { required } from "@/components/shared/validationRules";
import BarChart from "@/components/shared/BarChart.vue";
import Component from "vue-class-component";
import { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import CustomButton from "@/components/shared/CustomButton.vue";
import InfoCircle from "@/components/shared/InfoCircle.vue";
import LineChart from "@/components/shared/LineChart.vue";
import Vue from "vue";
import { formatTimeLabel, mapLabel, toLocaleFormattedDayOfWeek, } from "@/components/statistics/chartHelpers";
import ChartWrapper from "./ChartWrapper.vue";
let DurationGraphs = class DurationGraphs extends Vue {
    requiredRule = required;
    chartOptions = sharedChartOptions;
    type = InputType;
    action = Action;
    showDurationSettingsModal = false;
    updateDurationsFormValid = true;
    durationGraphOptions = {
        chartTitle: "statistics_parking_duration",
        label: "parkings",
        xAxisTitle: "statistics_parking_duration",
        yAxisTitle: "statistics_number_of_parkings",
        labelOnHover: (tooltipItem, data) => {
            return `${"parkings".t()}: ${tooltipItem.value ?? 0}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : "+").toString()}`;
        },
    };
    durationPerWeekdayGraphOptions = {
        chartTitle: "statistics_parking_duration_per_weekday",
        label: "parkings",
        xAxisTitle: "statistics_parking_duration",
        yAxisTitle: "statistics_number_of_parkings",
        labelOnHover: (tooltipItem, data) => {
            return `${data?.datasets[tooltipItem.datasetIndex]?.label}: ${tooltipItem.value ??
                0} ${mapLabel(Number(tooltipItem.value), "parking").capitalize()}`;
        },
        titleOnHover: (tooltipItems, data) => {
            return `${tooltipItems[0].xLabel?.toString()}${(data.labels[tooltipItems[0].index + 1]
                ? ` - ${data.labels[tooltipItems[0].index + 1]}`
                : "+").toString()}`;
        },
    };
    parkingDurationsSettings = {
        ...statisticsModule.parkingDurationSettings,
    };
    get getDurationGraphData() {
        const data = statisticsModule.getStatistics?.statisticsDurationGraphs?.durationGraph ?? [];
        return {
            labels: data?.map(row => formatTimeLabel(row.fromDurationInMinutes)),
            datasets: [
                {
                    label: "duration".t(),
                    data: data?.map(row => row.numberOfParkings),
                    borderWidth: 1,
                    borderColor: default_colors[2],
                    backgroundColor: default_colors[2],
                    fill: true,
                    pointRadius: 2,
                    pointHitRadius: 4,
                },
            ],
        };
    }
    get getDurationPerWeekdayGraphData() {
        const data = statisticsModule.getStatistics?.statisticsDurationGraphs?.durationPerWeekdayGraph ?? [];
        return {
            labels: data?.[0]?.graphIntervals?.map(row => formatTimeLabel(row.fromDurationInMinutes)),
            datasets: data?.map((data, i) => ({
                label: toLocaleFormattedDayOfWeek(data.dayOfWeekAsNumber),
                data: data.graphIntervals?.map(row => row.numberOfParkings),
                borderWidth: 1,
                backgroundColor: default_colors[i],
                borderColor: default_colors[i],
                fill: false,
                pointRadius: 3,
            })),
        };
    }
    onDurationSettingsSave() {
        const isFormValid = this.$refs.updateDurationsForm.validate();
        if (!isFormValid)
            return;
        statisticsModule.SET_PARKINGDURATION_SETTINGS({
            binSize: parseInt(this.parkingDurationsSettings.binSize.toString()),
            bins: parseInt(this.parkingDurationsSettings.bins.toString()),
            binsSkip: parseInt(this.parkingDurationsSettings.binsSkip.toString()) >
                parseInt(this.parkingDurationsSettings.bins.toString())
                ? 0
                : parseInt(this.parkingDurationsSettings.binsSkip.toString()),
        });
        this.parkingDurationsSettings = {
            ...statisticsModule.parkingDurationSettings,
        };
    }
    get endSkipItems() {
        return Array.from({ length: this.parkingDurationsSettings.bins }, (value, key) => key);
    }
};
DurationGraphs = __decorate([
    Component({
        components: {
            InfoCircle,
            LineChart,
            BarChart,
            CustomDialog,
            CustomButton,
            ChartWrapper,
        },
        methods: {
            required,
            sharedChartOptions,
        },
    })
], DurationGraphs);
export default DurationGraphs;
