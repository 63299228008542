function isStringInRange(s, limit, message) {
    if (!s)
        return true;
    return s.length < limit || (message || '');
}
function clearValidation(element) {
    const ref = element;
    if (ref) {
        ref.resetValidation();
    }
}
function clearForm(element) {
    const ref = element;
    if (ref) {
        ref.reset();
    }
}
function requiredRule(message) {
    return (value) => {
        if (Array.isArray(value) && value.length === 0) {
            return message;
        }
        if (!value) {
            return message;
        }
        return true;
    };
}
/**
 * test if string in format 'hh : mm is a valid time'
 * @param input
 * @returns
 */
function timeInputRule(input) {
    return new RegExp('^([0-1]?[0-9]|2[0-3]) : [0-5][0-9]$').test(input);
}
function emailValid(email) {
    return /.+@.+\..+/.test(email) || 'E-mail must be valid';
}
function cvrRule(cvr) {
    if (!cvr)
        return false;
    return new RegExp('^\\d{8}$').test(cvr);
}
function postCodeRule(v) {
    if (!v)
        return false;
    return new RegExp('^\\d{4}$').test(v.toString());
}
function passwordRule(password) {
    // https://stackoverflow.com/a/64098819
    if (!password)
        return false;
    const hasSpecial = str => /[^\p{L}\p{N}]+/ug.test(str);
    const hasText = str => /[\p{L}]+/ug.test(str);
    const hasNum = str => /[\p{N}]+/ug.test(str);
    const isLong = str => str.length >= 8;
    return hasSpecial(password) && hasText(password) && hasNum(password) && isLong(password) || 'Kræver et stort bogstav, et special tegn og et tal. Længde 8';
}
function passwordConfirmationRule(password, password1) {
    return (password === password1) || 'Password must match';
}
export { clearForm, requiredRule, postCodeRule, emailValid, cvrRule, clearValidation, passwordRule, passwordConfirmationRule, timeInputRule, isStringInRange };
