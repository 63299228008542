export default class UpdateSubscriberDispatch {
    subscriber;
    subscriberId;
    subscriberType;
    constructor(subscriber, subscriberId, subscriberType) {
        this.subscriber = subscriber;
        this.subscriberId = subscriberId;
        this.subscriberType = subscriberType;
    }
}
