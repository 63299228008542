import { __decorate } from "tslib";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
import { InputType } from "@/components/shared/enums";
import Component from "vue-class-component";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
let ChargeForm = class ChargeForm extends Vue {
    type = InputType;
    onInputChanged() {
        chargeEditorModule.previewPdf();
    }
    get getParking() {
        return chargeEditorModule.editedItem;
    }
    get getChargeEditorData() {
        return chargeEditorModule.chargeEditorData;
    }
};
ChargeForm = __decorate([
    Component({
        components: {
            CustomSelect,
            CustomTextField,
        },
        methods: {},
    })
], ChargeForm);
export default ChargeForm;
