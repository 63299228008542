import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import Vue from "vue";
let SubscribersPage = class SubscribersPage extends Vue {
};
SubscribersPage = __decorate([
    Component({
        components: {},
    })
], SubscribersPage);
export default SubscribersPage;
