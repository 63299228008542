import { AreaRequest, SubAreaRequest } from "@/apiclient/client";
import { getObjectsByKeyValue } from "@/components/treeSelects/util";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
function difference(a, b) {
    return a.filter(xa => !b.some(xb => xb.id === xa.id));
}
function synchronizeAreas(subscription, existingAreas, newAreas) {
    if (!existingAreas || !newAreas) {
        return new Promise((reject) => {
            reject(false);
        });
    }
    //add areas to subscription
    let toCreate = difference(newAreas, existingAreas);
    let a = toCreate.map(a => subscriptionModule.addAreaToSubscription({ subscriptionId: subscription.id, area: a }));
    //delete the areas that needs to be removed from the subscription
    let toDelete = difference(existingAreas, newAreas);
    let b = toDelete.map(a => subscriptionModule.removeAreaFromSubscription({ subscriptionId: subscription.id, area: a }));
    //add subareas to subscription
    let toCreate2 = difference(newAreas.flatMap(s => s.subAreas ?? []), existingAreas.flatMap(s => s.subAreas ?? []))
        .filter(s => !toCreate.flatMap(xs => xs.subAreas ?? []).some(xs => xs.id == s.id));
    let c = toCreate2.map(a => subscriptionModule.addSubAreaToSubscription({ subscriptionId: subscription.id, area: a }));
    //delete the subareas that needs to be removed from the subscription
    let toDelete2 = difference(existingAreas.flatMap(s => s.subAreas ?? []), newAreas.flatMap(s => s.subAreas ?? []))
        .filter(s => !toDelete.flatMap(xs => xs.subAreas ?? []).some(xs => xs.id == s.id));
    let d = toDelete2.map(a => subscriptionModule.removeSubAreaFromSubscription({ subscriptionId: subscription.id, area: a }));
    return Promise.all([...a, ...b, ...c, ...d]).then(() => true);
}
const getAreasFromNodes = (nodes) => {
    const areas = [];
    nodes.forEach(node => {
        const area = copyArea(node.mappedObj);
        area.mergedID = `${area.name}-${area.id}-1`;
        area.subAreas = [];
        node.nodes?.forEach(subNode => {
            const subArea = copySubArea(subNode.mappedObj);
            subArea.mergedID = `${subArea.name}-${subArea.id}-2`;
            area.subAreas.push(subArea);
        });
        areas.push(area);
    });
    return areas;
};
const getAreaFromNode = (node) => {
    const area = copyArea(node.mappedObj);
    area.showInParkAdmin = node.selected;
    area.subAreas = [];
    node.nodes?.forEach(subNode => {
        const subArea = copySubArea(subNode.mappedObj);
        subArea.showInParkAdmin = subNode.selected;
        area.subAreas.push(subArea);
    });
    return area;
};
const updateShowInParkadminProp = (areasSelection, initialAreas) => {
    const areasCopy = [...initialAreas].map(area => ({
        id: area.id,
        name: area.name,
        showInParkAdmin: Boolean(areasSelection.find(a => a.id === area.id)),
        subAreas: area.subAreas.map(subArea => ({
            id: subArea.id,
            name: subArea.name,
            showInParkAdmin: Boolean(getObjectsByKeyValue([...areasSelection], "id", `${subArea.name}-${subArea.id}-2`)[0])
        }))
    }));
    return areasCopy;
};
function copyArea(area) {
    return Object.assign(Object.create(AreaRequest.prototype), { ...area });
}
function copySubArea(subArea) {
    return Object.assign(Object.create(SubAreaRequest.prototype), {
        ...subArea,
    });
}
export { copyArea, copySubArea, getAreasFromNodes, synchronizeAreas, getAreaFromNode, updateShowInParkadminProp };
