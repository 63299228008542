import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import CustomButton, { Action } from "../shared/CustomButton.vue";
import ConfirmPrompt from "../shared/ConfirmPrompt.vue";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
let EmailTemplateCard = class EmailTemplateCard extends Vue {
    action = Action;
    dialogDelete = false;
    emailTemplate;
    cardClicked() {
        this.$emit("templateclicked", this.emailTemplate);
    }
    getBodyText(emailTemplate) {
        const firstParagraph = emailTemplate.paragraphs
            ? emailTemplate.paragraphs[0]
            : undefined;
        return firstParagraph ? firstParagraph.body : "";
    }
    deleteTemplate(emailTemplate) {
        if (emailTemplate?.id)
            emailTemplateModule.deleteEmailTemplate(emailTemplate.id);
        this.dialogDelete = false;
    }
};
__decorate([
    Prop()
], EmailTemplateCard.prototype, "emailTemplate", void 0);
EmailTemplateCard = __decorate([
    Component({
        components: {
            CustomButton,
            ConfirmPrompt
        },
    })
], EmailTemplateCard);
export default EmailTemplateCard;
