import { __decorate } from "tslib";
import testimage from "@/constants/carimages";
import Vue from "vue";
import Component from "vue-class-component";
let TestImageElement = class TestImageElement extends Vue {
    img = `data:image/png;base64,${testimage}`;
};
TestImageElement = __decorate([
    Component({})
], TestImageElement);
export default TestImageElement;
