import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let EntityTooltip = class EntityTooltip extends Vue {
    entity;
    get isPrivate() {
        return this.entity.subscriberType === 2;
    }
    get getNewsPaperSwitchLabel() {
        return this.entity.recieveNewsLetter ? this.$tc('receivesnewsletter', 1).toString()
            : this.$tc('receivesnewsletter', 2).toString();
    }
    get getActiveSwitchLabel() {
        return this.entity.active ? this.$t('active').toString() : this.$t('inactive').toString();
    }
};
__decorate([
    Prop()
], EntityTooltip.prototype, "entity", void 0);
EntityTooltip = __decorate([
    Component({
        components: {},
        methods: {},
    })
], EntityTooltip);
export default EntityTooltip;
