import { __decorate } from "tslib";
import { Component, Mixins, PropSync, Watch } from "vue-property-decorator";
import { Line, mixins } from "vue-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
let LineChart = class LineChart extends Mixins(mixins.reactiveProp, Line) {
    options;
    optionsWatcher(options) {
        this.renderChart(this.chartData, options);
    }
    mounted() {
        this.addPlugin([zoomPlugin]);
        this.renderChart(this.chartData, this.options);
    }
};
__decorate([
    PropSync("chartOptions", {})
], LineChart.prototype, "options", void 0);
__decorate([
    Watch("options", { deep: true })
], LineChart.prototype, "optionsWatcher", null);
LineChart = __decorate([
    Component({
        extends: Line,
        mixins: [mixins.reactiveProp],
    })
], LineChart);
export default LineChart;
