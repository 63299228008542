import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { chargeEditorModule } from "@/store/modules/chargeEditorModule";
import Editor from "@tinymce/tinymce-vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomTextArea from "@/components/shared/CustomTextArea.vue";
import { ChargeTemplateRequest, PreviewTemplateRequest } from "@/apiclient/client";
import CustomFileInput from "@/components/shared/CustomFileInput.vue";
import { customerModule } from "@/store/modules/customerModule";
import { readUploadedFileAsText } from "@/util/imageconverter";
import { maxFileSize, required } from "@/components/shared/validationRules";
let ChargeTemplate = class ChargeTemplate extends Vue {
    template = "";
    customerLogo = "";
    action = Action;
    isLogoUpdateLoading = false;
    async mounted() {
        this.customerLogo = await customerModule.fetchCustomerLogo();
        this.template = await chargeEditorModule.fetchChargeTemplate();
    }
    async handleChargeTemplateSave() {
        const request = new ChargeTemplateRequest({ template: this.template });
        await chargeEditorModule.saveChargeTemplate(request);
    }
    async previewChargeTemplate() {
        const request = new PreviewTemplateRequest({
            template: this.template,
            logo: this.customerLogo,
        });
        await chargeEditorModule.previewChargeTemplate(request);
    }
    async handleLogoSave() {
        const isValid = this.$refs.logoForm.validate();
        if (!isValid)
            return;
        this.isLogoUpdateLoading = true;
        this.customerLogo = await customerModule.addCustomerLogo(this.customerLogo);
        this.isLogoUpdateLoading = false;
    }
    async setLogo(file) {
        try {
            const base64string = await readUploadedFileAsText(file);
            this.customerLogo = base64string;
        }
        catch (e) {
            // console.log()
        }
    }
    get getTemplate() {
        return chargeEditorModule.getChargeTemplate?.template;
    }
};
ChargeTemplate = __decorate([
    Component({
        name: "ChargeTemplate",
        components: {
            Editor,
            CustomButton,
            CustomTextArea,
            CustomFileInput,
        },
        methods: {
            maxFileSize,
            required,
        },
    })
], ChargeTemplate);
export default ChargeTemplate;
