import { __decorate } from "tslib";
import { editItem, deleteItem, closeDelete, close } from "@/helperfunctions/listhelpers.js";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component, Watch } from "vue-property-decorator";
import { emailModule } from "@/store/modules/emailModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import { statusModule } from "@/store/modules/statusModule";
import { utcStringToBrowserLocalTime } from "@/util/date/dateutil";
import ConfirmPrompt from "@/components/shared/ConfirmPrompt.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import ListRequest from "@/util/storerequest/ListRequest";
import PendingEmailsDeleteRequest from "@/util/storerequest/pendingEmailsDeleteRequest";
import PendingEmailsRequest from "@/util/storerequest/pendingEmailsRequest";
import QrPlaceholder from "@/util/qrplaceholder";
import Vue from "vue";
let EmailsView = class EmailsView extends Vue {
    action = Action;
    search = "";
    dialog = false;
    dialogDelete = false;
    editedIndex = -1;
    editedItem = {};
    expanded = new Array();
    requestParams = new ListRequest();
    onChildChanged(val, oldVal) {
        this.requestParams.search = val;
        emailModule.fetchPendingEmails(new PendingEmailsRequest(this.requestParams, authenticationModule.getCustomerId));
    }
    get getCount() {
        return emailModule.getCount;
    }
    get getLoadingStatus() {
        return statusModule.getLoadingState;
    }
    get getHeaders() {
        var headers = [
            { text: "recipient".t(), value: "recipient" },
            { text: "type".t(), value: "emailTemplateType" },
            { text: "emailSendsAt".t(), value: "expectedSendTime" },
            { text: "actions".t(), value: "actions", sortable: false },
        ];
        return headers;
    }
    get getEmails() {
        return emailModule.getEmails;
    }
    get getSignatureLogo() {
        return emailTemplateModule.getSignatureLogoBase64;
    }
    deleteItem(email) {
        emailModule.deleteEmail(new PendingEmailsDeleteRequest(email.mailID, authenticationModule.getCustomerId)).then((response) => {
            emailModule.fetchPendingEmails(new PendingEmailsRequest(this.requestParams, authenticationModule.getCustomerId));
        });
    }
    updateEmail(email) {
        emailModule.updateEmail(email);
        this.dialog = false;
    }
    updateOptions(options) {
        this.requestParams.pageNumber = options.page;
        this.requestParams.pageSize = options.itemsPerPage;
        this.requestParams.orderBy = options.sortBy;
        this.requestParams.sortOrderAsc = options.sortOrderAsc;
        this.requestParams.search = this.search;
        var customerId = authenticationModule.getCustomerId;
        var emailRequest = new PendingEmailsRequest(this.requestParams, customerId);
        emailModule.fetchPendingEmails(emailRequest);
    }
    updatePagination(pagination) {
        this.requestParams.pageNumber = pagination;
    }
    getItemBodyText(body, type) {
        return body
            .replaceAll("cid:cid_QrCode.png", QrPlaceholder)
            .replaceAll("cid:cid_signature.png", emailTemplateModule.getEmailTemplates.find((t) => t.emailTemplateName === type)?.signatureLogo);
    }
    getFormattedDate(date) {
        return date ? utcStringToBrowserLocalTime(date, "YYYY-MM-DD-hh-mm-ss", "HH:mm : DD-MM-YYYY") : "";
    }
    mounted() {
        emailTemplateModule.fetchEmailConfigurations(authenticationModule.getCustomerId);
    }
};
__decorate([
    Watch("search")
], EmailsView.prototype, "onChildChanged", null);
EmailsView = __decorate([
    Component({
        components: {
            ConfirmPrompt,
            CustomButton,
        },
        methods: {
            editItem,
            deleteItem,
            closeDelete,
            close,
        },
    })
], EmailsView);
export default EmailsView;
