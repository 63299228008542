import { __decorate } from "tslib";
import store from "@/store";
import { getModule, Module, VuexModule } from "vuex-module-decorators";
import { authenticationModule } from "./authenticationModule";
import { featureModule } from "./featureModule";
export class ParkAdminSettingTab {
    id;
    title;
    icon;
    accesslevels;
    requiresFeatureId;
    constructor(id, icon, accesslevels, requiresFeatureId) {
        this.id = id;
        this.icon = icon;
        this.accesslevels = accesslevels;
        this.requiresFeatureId = requiresFeatureId;
    }
}
const settingsList = [
    new ParkAdminSettingTab(0, "mdi-cog", [1, 2]),
    new ParkAdminSettingTab(1, "mdi-book-edit-outline", [1, 2], 1003),
    new ParkAdminSettingTab(2, "mdi-book-edit-outline", [1, 2], 3),
    new ParkAdminSettingTab(3, "mdi-toggle-switch", [1]),
    new ParkAdminSettingTab(4, "mdi-delete-clock", [1]),
];
let AccessControlModule = class AccessControlModule extends VuexModule {
    get settingItems() {
        const customerFeatures = featureModule.getLoggedInCustomerFeatures;
        //filter based on customer features
        return settingsList.filter(item => customerFeatures.filter(e => e.featureID === item.requiresFeatureId)[0]?.active ||
            !item.requiresFeatureId);
    }
    get authorizedSettingItems() {
        const accessLevel = authenticationModule.accessLevel;
        return this.settingItems.filter(s => s.accesslevels.some(a => a === accessLevel));
    }
    get isQrEnabled() {
        const customerFeatures = featureModule.getLoggedInCustomerFeatures;
        const item = customerFeatures.find(f => f.featureID === 1); //QR codes is ID 1
        return item ? item.active : false;
    }
};
AccessControlModule = __decorate([
    Module({ dynamic: true, store, name: "accessControlModule" })
], AccessControlModule);
export default AccessControlModule;
export const accessControlModule = getModule(AccessControlModule);
