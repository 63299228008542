import { __decorate } from "tslib";
import i18n from "@/i18n";
import locales from "@/locales/Locales";
import router from "@/router";
import { getPathWithID, hasAccessToRoute } from "@/router/utils";
import store from "@/store";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { areaModule } from "./areaModule";
import { authenticationModule } from "./authenticationModule";
import { constantsModule } from "./constantsModule";
import { emailTemplateModule } from "./emailtemplateModule";
import { LocaleEnum } from "./enums";
import { featureModule } from "./featureModule";
import { modeModule } from "./modeModule";
import { prsTagModule } from "./prsTagModule";
import { subscriptionModule } from "./subscriptionModule";
import { userModule } from "./userModule";
let AppModule = class AppModule extends VuexModule {
    locales = locales;
    locale = i18n.locale ?? process.env.VUE_APP_I18N_LOCALE;
    initialModulesLoaded = false;
    initialTokenRefresh = false;
    modulesToLoad;
    async fetchInitialModules(shouldNotRefresh = false) {
        if (shouldNotRefresh) {
            this.context.commit("SET_INITIAL_TOKEN_REFRESHED", true);
        }
        if (this.initialModulesLoaded)
            return new Promise(resolve => resolve());
        this.modulesToLoad = [
            featureModule.fetchFeatures(authenticationModule.getCustomerId),
            areaModule.fetchAreas(authenticationModule.getCustomerId),
            prsTagModule.fetchPrsTags(authenticationModule.getCustomerId),
            constantsModule.fetchParkingTypes(),
            constantsModule.fetchParkingSystems(),
            constantsModule.fetchAccessLevels(),
        ];
        if (!modeModule.isDelegateLogin) {
            this.modulesToLoad = [
                ...this.modulesToLoad,
                emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId),
                subscriptionModule.fetchSubscriptionTypes(authenticationModule.getCustomerId),
                authenticationModule.fetchLoggedInCustomer(authenticationModule.getCustomerId),
            ];
        }
        if (modeModule.isDelegateLogin) {
            this.modulesToLoad = [
                ...this.modulesToLoad,
                userModule.fetchAffiliationConfiguration(authenticationModule.loggedInUserId),
            ];
        }
        await Promise.allSettled(this.modulesToLoad);
        this.context.commit("SET_LOCALE", this.getLocales.find(locale => locale.key === this.getLocale?.key));
        this.context.commit("SET_INITIAL_MODULES_LOADED", true);
    }
    async fetchImpersonationModules() {
        this.modulesToLoad = [
            featureModule.fetchFeatures(authenticationModule.customerId),
            areaModule.fetchAreas(authenticationModule.customerId),
            prsTagModule.fetchPrsTags(authenticationModule.customerId),
            constantsModule.fetchParkingSystems(),
            emailTemplateModule.fetchEmailTemplates(authenticationModule.getCustomerId),
            authenticationModule.fetchLoggedInCustomer(authenticationModule.customerId),
        ];
        await Promise.allSettled(this.modulesToLoad);
        this.context.commit("SET_LOCALE", this.getLocales.find(locale => locale.key === this.getLocale?.key));
    }
    async fetchInitialRefreshToken() {
        if (this.initialTokenRefresh || !authenticationModule.isAuthenticated)
            return new Promise(resolve => resolve());
        await authenticationModule.refreshLoginToken();
        this.context.commit("SET_INITIAL_TOKEN_REFRESHED", true);
    }
    SET_INITIAL_MODULES_LOADED(loadingState) {
        this.initialModulesLoaded = loadingState;
    }
    SET_INITIAL_TOKEN_REFRESHED(payload) {
        this.initialTokenRefresh = payload;
    }
    SET_LOCALE(locale) {
        this.locale = locale.key;
        i18n.locale = locale.key;
        localStorage.setItem("locale", locale.key);
    }
    get getMenuItems() {
        const customerFeatures = modeModule.isDelegateLogin
            ? userModule.getModeFeatures
            : featureModule.getActiveCustomerFeatures;
        const customerAccessLevel = authenticationModule.getAccessLevel;
        const customerMode = authenticationModule.getMode;
        return router
            .getRoutes()
            .filter(route => Boolean(route.meta?.menuLink) &&
            hasAccessToRoute(route, customerAccessLevel, customerMode, customerFeatures))
            .sort((a, b) => a.meta.menuLink.order - b.meta.menuLink.order)
            .map(route => {
            return {
                ...route,
                title: route.meta?.menuLink.title.tIndex
                    ? i18n.tc(route.meta?.menuLink.title.tKey, route.meta?.menuLink.title.tIndex)
                    : i18n.t(route.meta?.menuLink.title.tKey),
                icon: route.meta?.menuLink.icon,
                href: getPathWithID(route.path, route.meta?.getID && route.meta?.getID()),
                feature: route.meta?.feature,
                modes: route.meta?.modes,
                accessLevel: route.meta?.accessLevel,
            };
        });
    }
    get getLocale() {
        const currentLocaleKey = this.locale
            .slice(0, this.locale.indexOf("-") !== -1 ? this.locale.indexOf("-") : this.locale.length)
            .toUpperCase();
        const localeType = Object.keys(LocaleEnum).find(localeKey => localeKey === currentLocaleKey) ?? "DA";
        const customerSpecificLocale = this.locales.find(locale => locale?.customerID === authenticationModule.customerId &&
            locale?.localeType?.toUpperCase() === localeType);
        if (!customerSpecificLocale)
            return this.locales.find(locale => !locale.customerID && locale.localeType?.toUpperCase() === localeType);
        return customerSpecificLocale;
    }
    get getLocales() {
        const customerSpecificLocales = this.locales.filter(locale => locale?.customerID === authenticationModule.customerId);
        if (customerSpecificLocales.length === 0)
            return this.locales.filter(locale => !locale.customerID);
        return customerSpecificLocales.concat(this.locales.filter(locale => !customerSpecificLocales.map(locale => locale.localeType).includes(locale.localeType)));
    }
    get getInitialModulesLoaded() {
        return this.initialModulesLoaded;
    }
};
__decorate([
    Action({ rawError: true })
], AppModule.prototype, "fetchInitialModules", null);
__decorate([
    Action({ rawError: true })
], AppModule.prototype, "fetchImpersonationModules", null);
__decorate([
    Action({ rawError: true })
], AppModule.prototype, "fetchInitialRefreshToken", null);
__decorate([
    Mutation
], AppModule.prototype, "SET_INITIAL_MODULES_LOADED", null);
__decorate([
    Mutation
], AppModule.prototype, "SET_INITIAL_TOKEN_REFRESHED", null);
__decorate([
    Mutation
], AppModule.prototype, "SET_LOCALE", null);
AppModule = __decorate([
    Module({ dynamic: true, store, name: "appModule" })
], AppModule);
export default AppModule;
export const appModule = getModule(AppModule);
