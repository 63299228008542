import { render, staticRenderFns } from "./CustomSlider.vue?vue&type=template&id=725e194c&scoped=true&"
import script from "./CustomSlider.vue?vue&type=script&lang=ts&"
export * from "./CustomSlider.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725e194c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VSlider})
