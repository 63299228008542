import { __decorate } from "tslib";
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import CustomDatePicker from "@/components/shared/CustomDatePicker.vue";
import CustomTimePicker from "@/components/shared/CustomTimePicker.vue";
import moment from "moment";
let CustomDateTimePicker = class CustomDateTimePicker extends Vue {
    syncDateTime;
    labelDate;
    labelTime;
    minDate;
    minTime;
    pickerDate;
    syncRules;
    disabled;
    onDateTimePickerChange(e) {
        this.$emit("on-date-time-picker-change", e);
    }
    internalDate = null;
    internalTime = null;
    get visualDate() {
        if (this.internalDate) {
            return this.internalDate;
        }
        else if (this.syncDateTime && this.syncDateTime.includes("Z")) {
            this.internalDate = moment
                .utc(this.syncDateTime)
                .local()
                .format();
            return this.internalDate;
        }
        else if (this.syncDateTime && this.syncDateTime.includes("+")) {
            this.internalDate = moment(this.syncDateTime).format();
            return this.internalDate;
        }
        else {
            return null;
        }
    }
    set visualDate(date) {
        if (!date) {
            this.internalDate = null;
            this.syncDateTime = null;
            return;
        }
        this.internalDate = moment(date)
            .local()
            .format();
        if (this.internalDate && this.internalTime) {
            const regex = /T\d{2}:\d{2}/g;
            const selected = this.internalDate.replace(regex, `T${this.internalTime}`);
            this.syncDateTime = selected;
        }
    }
    get visualTime() {
        if (this.internalTime) {
            return this.internalTime;
        }
        else if (this.syncDateTime && this.syncDateTime.includes("Z")) {
            const original = moment
                .utc(this.syncDateTime)
                .local()
                .format();
            this.internalTime = original.substring(11, 16);
            return this.internalTime;
        }
        else if (this.syncDateTime && this.syncDateTime.includes("+")) {
            this.internalTime = this.syncDateTime.substring(11, 16);
            return this.internalTime;
        }
        else {
            return null;
        }
    }
    set visualTime(time) {
        if (!time) {
            this.internalTime = null;
            this.syncDateTime = null;
            return;
        }
        this.internalTime = time;
        if (this.internalDate && this.internalTime) {
            const original = moment(this.internalDate).format();
            const regex = /T\d{2}:\d{2}/g;
            const selected = original.replace(regex, `T${time}`);
            this.syncDateTime = selected;
        }
    }
};
__decorate([
    PropSync("dateTime", { required: true })
], CustomDateTimePicker.prototype, "syncDateTime", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomDateTimePicker.prototype, "labelDate", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomDateTimePicker.prototype, "labelTime", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomDateTimePicker.prototype, "minDate", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomDateTimePicker.prototype, "minTime", void 0);
__decorate([
    Prop({ required: false, type: String })
], CustomDateTimePicker.prototype, "pickerDate", void 0);
__decorate([
    PropSync("rules", { required: false })
], CustomDateTimePicker.prototype, "syncRules", void 0);
__decorate([
    Prop({ required: false, type: Boolean })
], CustomDateTimePicker.prototype, "disabled", void 0);
CustomDateTimePicker = __decorate([
    Component({
        components: {
            CustomDatePicker,
            CustomTimePicker,
        },
        methods: {},
    })
], CustomDateTimePicker);
export default CustomDateTimePicker;
