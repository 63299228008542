import { __decorate } from "tslib";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import CustomButton, { Action } from "../shared/CustomButton.vue";
let ChartWrapper = class ChartWrapper extends Vue {
    xAxisTitle;
    yAxisTitle;
    action = Action;
    isZoomActive = false;
    randomKey = this.uuidv4();
    startZoom() {
        if (this.isZoomActive) {
            this.randomKey = this.uuidv4();
            this.isZoomActive = false;
            return;
        }
        this.isZoomActive = true;
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    }
};
__decorate([
    Prop({ required: false, type: String })
], ChartWrapper.prototype, "xAxisTitle", void 0);
__decorate([
    Prop({ required: false, type: String })
], ChartWrapper.prototype, "yAxisTitle", void 0);
ChartWrapper = __decorate([
    Component({
        components: {
            CustomButton,
        },
        methods: {},
    })
], ChartWrapper);
export default ChartWrapper;
