export default class UserStoreRequest {
    customerId;
    userId;
    userUpdate;
    userCreate;
    constructor(customerId, userId, userUpdate, userCreate) {
        this.customerId = customerId;
        this.userId = userId;
        this.userUpdate = userUpdate;
        this.userCreate = userCreate;
    }
}
