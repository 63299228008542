export var AccessLevelEnum;
(function (AccessLevelEnum) {
    AccessLevelEnum[AccessLevelEnum["SuperAdmin"] = 1] = "SuperAdmin";
    AccessLevelEnum[AccessLevelEnum["Admin"] = 2] = "Admin";
    AccessLevelEnum[AccessLevelEnum["Operator"] = 3] = "Operator";
    AccessLevelEnum[AccessLevelEnum["External"] = 4] = "External";
})(AccessLevelEnum || (AccessLevelEnum = {}));
export var FeatureEnum;
(function (FeatureEnum) {
    FeatureEnum[FeatureEnum["Qr"] = 1] = "Qr";
    FeatureEnum[FeatureEnum["PRSSync"] = 2] = "PRSSync";
    FeatureEnum[FeatureEnum["Emails"] = 3] = "Emails";
    FeatureEnum[FeatureEnum["Subscriptions"] = 4] = "Subscriptions";
    FeatureEnum[FeatureEnum["Wash"] = 5] = "Wash";
    FeatureEnum[FeatureEnum["Charge"] = 6] = "Charge";
    FeatureEnum[FeatureEnum["ParkingImport"] = 7] = "ParkingImport";
    FeatureEnum[FeatureEnum["LisaLogging"] = 8] = "LisaLogging";
    FeatureEnum[FeatureEnum["SubAreas"] = 9] = "SubAreas";
    FeatureEnum[FeatureEnum["Statistic"] = 10] = "Statistic";
    FeatureEnum[FeatureEnum["Parking"] = 11] = "Parking";
    FeatureEnum[FeatureEnum["Areas"] = 1000] = "Areas";
    FeatureEnum[FeatureEnum["Accounts"] = 1001] = "Accounts";
    FeatureEnum[FeatureEnum["Settings"] = 1002] = "Settings";
    FeatureEnum[FeatureEnum["SubscriberQuickAdd"] = 1003] = "SubscriberQuickAdd";
})(FeatureEnum || (FeatureEnum = {}));
export var ModeEnum;
(function (ModeEnum) {
    ModeEnum[ModeEnum["ParkAdmin"] = 1] = "ParkAdmin";
    ModeEnum[ModeEnum["HotelReception"] = 2] = "HotelReception";
    ModeEnum[ModeEnum["Delegate"] = 3] = "Delegate";
    ModeEnum[ModeEnum["HotelConference"] = 4] = "HotelConference";
})(ModeEnum || (ModeEnum = {}));
export var CustomerEnum;
(function (CustomerEnum) {
    CustomerEnum[CustomerEnum["Logos"] = 1] = "Logos";
    CustomerEnum[CustomerEnum["Jeudan"] = 2] = "Jeudan";
    CustomerEnum[CustomerEnum["QPark"] = 3] = "QPark";
    CustomerEnum[CustomerEnum["Byfjellet"] = 4] = "Byfjellet";
    CustomerEnum[CustomerEnum["Haugesund"] = 5] = "Haugesund";
    CustomerEnum[CustomerEnum["Borgen"] = 6] = "Borgen";
    CustomerEnum[CustomerEnum["Logossupport"] = 7] = "Logossupport";
})(CustomerEnum || (CustomerEnum = {}));
export var LocaleEnum;
(function (LocaleEnum) {
    LocaleEnum["DA"] = "da";
    LocaleEnum["EN"] = "en";
})(LocaleEnum || (LocaleEnum = {}));
