import { render, staticRenderFns } from "./EntityTooltip.vue?vue&type=template&id=334c6275&scoped=true&"
import script from "./EntityTooltip.vue?vue&type=script&lang=ts&"
export * from "./EntityTooltip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "334c6275",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
