import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let EntityTooltip = class EntityTooltip extends Vue {
    maxWidth;
};
__decorate([
    Prop()
], EntityTooltip.prototype, "maxWidth", void 0);
EntityTooltip = __decorate([
    Component({
        components: {},
        methods: {},
    })
], EntityTooltip);
export default EntityTooltip;
