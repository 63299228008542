import { __decorate } from "tslib";
import { maxNumber, minNumber, required, maxLength } from "@/components/shared/validationRules";
import { postCodeRule, emailValid, cvrRule, clearValidation, } from "@/util/forms/formutils";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { InputType } from "@/components/shared/enums";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import Vue from "vue";
import { CustomerType } from "@/components/subscribers/enums";
let SubscriberForm = class SubscriberForm extends Vue {
    type = InputType;
    subscriberProp;
    customerTypeProp;
    isPrivate;
    showNewsPaperSwitch;
    showCustomerTypeToggle;
    isPrivateCustomerProp;
    editMode;
    formTitle;
    resetFormCount;
    valid = true;
    $fieldRefs;
    onCountChanged() {
        clearValidation(this.$refs.subscriberform);
    }
    onValidChanged(valid) {
        this.$emit("form-valid", this.valid);
    }
    get getSwitchLabel() {
        return this.isPrivate
            ? this.$tc("choosecustomer", 1).toString()
            : this.$tc("choosecustomer", 2).toString();
    }
    get toggleActive() {
        return this.subscriberProp.active || false;
    }
    set toggleActive(active) {
        this.subscriberProp.active = active;
        this.$emit("toggle-active-status", this.subscriberProp);
    }
    get getNewsPaperSwitchLabel() {
        return this.subscriberProp.recieveNewsLetter
            ? this.$tc("receivesnewsletter", 1).toString()
            : this.$tc("receivesnewsletter", 2).toString();
    }
    get getActiveSwitchLabel() {
        return this.subscriberProp.active
            ? this.$t("active").toString()
            : this.$t("inactive").toString();
    }
};
__decorate([
    PropSync("subscriber", { type: Object })
], SubscriberForm.prototype, "subscriberProp", void 0);
__decorate([
    PropSync("customerTypeText", { type: String })
], SubscriberForm.prototype, "customerTypeProp", void 0);
__decorate([
    Prop()
], SubscriberForm.prototype, "isPrivate", void 0);
__decorate([
    Prop({ default: false })
], SubscriberForm.prototype, "showNewsPaperSwitch", void 0);
__decorate([
    Prop({ default: false })
], SubscriberForm.prototype, "showCustomerTypeToggle", void 0);
__decorate([
    Prop({ default: false })
], SubscriberForm.prototype, "isPrivateCustomerProp", void 0);
__decorate([
    Prop({ default: false })
], SubscriberForm.prototype, "editMode", void 0);
__decorate([
    Prop({ default: "" })
], SubscriberForm.prototype, "formTitle", void 0);
__decorate([
    Prop({ default: 1 })
], SubscriberForm.prototype, "resetFormCount", void 0);
__decorate([
    Watch("resetFormCount")
], SubscriberForm.prototype, "onCountChanged", null);
__decorate([
    Watch("valid", { immediate: true })
], SubscriberForm.prototype, "onValidChanged", null);
SubscriberForm = __decorate([
    Component({
        components: {
            CustomTextField,
        },
        methods: {
            cvrRule,
            emailValid,
            maxNumber,
            minNumber,
            postCodeRule,
            required,
            maxLength
        },
        data() {
            return {
                CustomerType
            };
        }
    })
], SubscriberForm);
export default SubscriberForm;
