import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";
import { Criticality } from "@/store/modules/notificationModule";
let CustomSnackbar = class CustomSnackbar extends Vue {
    criticalityType = Criticality;
    syncShow;
    criticality;
    text;
    timeout; // if timeout is not set, Vuetifys default value is 5000 ms (5 seconds)
    get criticalityColor() {
        switch (this.criticality) {
            case Criticality.Success:
                return "green";
            case Criticality.Warning:
                return "orange";
            case Criticality.Error:
                return "red";
            default:
                return "green";
        }
    }
    get getTimeout() {
        return this.timeout ? this.timeout : undefined; // if timeout is not set, Vuetifys default value is 5000 ms (5 seconds)
    }
};
__decorate([
    PropSync("show", { required: true, type: Boolean })
], CustomSnackbar.prototype, "syncShow", void 0);
__decorate([
    Prop({ required: true })
], CustomSnackbar.prototype, "criticality", void 0);
__decorate([
    Prop({ required: true, type: String })
], CustomSnackbar.prototype, "text", void 0);
__decorate([
    Prop({ required: false, type: Number })
], CustomSnackbar.prototype, "timeout", void 0);
CustomSnackbar = __decorate([
    Component
], CustomSnackbar);
export default CustomSnackbar;
