var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-10"},[_c('v-card-title',[_c('v-breadcrumbs',{attrs:{"large":"","items":[
        {
          text: ((_vm.$tc('subscriber', 1)) + " " + (_vm.$t('list'))),
          disabled: false,
          href: '/subscribers',
        },
        {
          text: ("mode logins(" + (_vm.internalSubscriber.name ||
            ((_vm.internalSubscriber.firstName) + " " + (_vm.internalSubscriber.lastName))) + ")"),
          disabled: true,
          href: '',
        } ]},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-forward")])]},proxy:true}])})],1),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end mb-6"},[_c('CustomButton',{attrs:{"text":'button_create'.t(),"action":_vm.action.Primary},on:{"on-button-click":_vm.createdClicked}})],1),_c('ModeLoginsRow',{attrs:{"modeLogins":_vm.getModeLogins},on:{"modeloginclicked":function($event){return _vm.modeLoginClicked($event)},"refetch-login-list":function($event){return _vm.refetchList()}}}),_c('ModalDialog',{attrs:{"maxWidth":"600px"},on:{"state-changed":function($event){_vm.toggleModeForm = $event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.isEditing)?_c('ModeLoginEditor',{attrs:{"user":_vm.modeUser,"modeConfig":_vm.modeConfig,"subscriberId":_vm.id,"isEditing":_vm.isEditing,"formResetCount":_vm.resetCount},on:{"userform-confirmed":function($event){return _vm.updateUserInfo()},"modeform-confirmed":function($event){return _vm.updateModeConfig()},"form-close":function($event){_vm.toggleModeForm = false}}}):_c('ModeCreationForm',{attrs:{"user":_vm.modeUser,"modeConfig":_vm.modeConfig,"formResetCount":_vm.resetCount},on:{"createform-confirmed":function($event){return _vm.handleCreateUser()},"form-close":function($event){_vm.toggleModeForm = false}}})]},proxy:true}]),model:{value:(_vm.toggleModeForm),callback:function ($$v) {_vm.toggleModeForm=$$v},expression:"toggleModeForm"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }