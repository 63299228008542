import { __decorate } from "tslib";
import { ModeConfigurationResponse } from "@/apiclient/client";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { apiClient } from "@/apiclient/baseclient";
import { authenticationModule } from "./authenticationModule";
import { notificationModule, Criticality } from "./notificationModule";
import { ModeEnum } from "./enums";
let ModeModule = class ModeModule extends VuexModule {
    config = new ModeConfigurationResponse();
    get getSubscriptonTypeId() {
        return this.config.subscriptonTypeID;
    }
    get isDelegateLogin() {
        return authenticationModule.getMode === ModeEnum.Delegate;
    }
    get getModeConfigAreas() {
        return this.config.areas;
    }
    async fetchConfiguration() {
        return await apiClient
            .configurationGET(authenticationModule.getLoggedInUserId)
            .then(response => {
            this.context.commit("SET_MODE_CONFIG", response.data);
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    SET_MODE_CONFIG(config) {
        this.config = config;
    }
};
__decorate([
    Action({ rawError: true })
], ModeModule.prototype, "fetchConfiguration", null);
__decorate([
    Mutation
], ModeModule.prototype, "SET_MODE_CONFIG", null);
ModeModule = __decorate([
    Module({ dynamic: true, store, name: "modeModule" })
], ModeModule);
export default ModeModule;
export const modeModule = getModule(ModeModule);
