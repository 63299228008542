export async function convertFileToBase64(logo) {
    return new Promise((resolve, reject) => {
        try {
            if (!logo) {
                resolve("");
            }
            const reader = new FileReader();
            reader.readAsDataURL(logo);
            reader.onload = () => {
                if (typeof reader.result === "string") {
                    resolve(reader.result);
                }
                else {
                    reject("error");
                }
            };
        }
        catch (error) {
            reject(error);
        }
    });
}
export const readUploadedFileAsText = inputFile => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };
        temporaryFileReader.readAsDataURL(inputFile);
        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
    });
};
export const getFileBase64String = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            const base64String = reader.result;
            const encodedString = base64String.split(",")[1];
            resolve(encodedString);
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};
