import { __decorate } from "tslib";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
let SubscriptionCarHistory = class SubscriptionCarHistory extends Vue {
    subscription;
    carsHistory = [];
    isLoading = true;
    async onShow() {
        if (!this.show)
            return;
        this.isLoading = true;
        try {
            const response = await subscriptionModule.fetchSubscriptionCarHistory(this.subscription.id);
            this.carsHistory = response;
        }
        finally {
            this.isLoading = false;
        }
    }
    getChangeState(modifyActionID) {
        switch (modifyActionID) {
            case 1:
                return "cars_history_dialog_created".t() + " " + "cars_history_dialog_by".t();
            case 3:
                return "cars_history_dialog_deleted".t() + " " + "cars_history_dialog_by".t();
        }
    }
    get show() {
        return Boolean(this.subscription);
    }
};
__decorate([
    Prop()
], SubscriptionCarHistory.prototype, "subscription", void 0);
__decorate([
    Watch("subscription", { immediate: true })
], SubscriptionCarHistory.prototype, "onShow", null);
SubscriptionCarHistory = __decorate([
    Component({
        name: "SubscriptionCarHistory",
        components: {},
        methods: {
            moment,
        },
    })
], SubscriptionCarHistory);
export default SubscriptionCarHistory;
