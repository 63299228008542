export default class SubscriptionCarRequest {
    car;
    subscriptionId;
    showStatus;
    constructor(car, subscriptionId, showStatus) {
        this.car = car;
        this.subscriptionId = subscriptionId;
        this.showStatus = showStatus;
    }
}
export class UpdateCarRequest {
    carUpdate;
    subscriptionId;
    carId;
    constructor(carUpdate, subscriptionId, carId) {
        this.carUpdate = carUpdate;
        this.subscriptionId = subscriptionId;
        this.carId = carId;
    }
}
