import { __decorate } from "tslib";
import Vue from "vue";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component, Prop } from "vue-property-decorator";
import { Criticality, notificationModule, } from "@/store/modules/notificationModule";
import { emailTemplateModule } from "@/store/modules/emailtemplateModule";
import EmailTemplateEditor from "@/components/settings/EmailTemplateEditor.vue";
import EmailTemplateView from "@/components/EmailTemplateView.vue";
let EmailTemplateConfig = class EmailTemplateConfig extends Vue {
    template;
    mounted() {
        emailTemplateModule
            .fetchEmailConfigurations(authenticationModule.getCustomerId)
            .then(() => {
            emailTemplateModule.toggleFirstTemplate();
        })
            .catch(() => {
            notificationModule.SHOW_NOTIFICATION({
                message: "notification_error_occured".t(),
                criticality: Criticality.Error,
            });
        });
    }
    get getEmailTemplates() {
        return emailTemplateModule.getEmailTemplates;
    }
};
__decorate([
    Prop()
], EmailTemplateConfig.prototype, "template", void 0);
EmailTemplateConfig = __decorate([
    Component({
        components: { EmailTemplateView, EmailTemplateEditor },
    })
], EmailTemplateConfig);
export default EmailTemplateConfig;
