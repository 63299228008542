export const getBrowserSize = () => {
    if (window.innerWidth < 768) {
        return "xs";
    }
    if (window.innerWidth < 991) {
        return "sm";
    }
    if (window.innerWidth < 1199) {
        return "md";
    }
    return "lg";
};
