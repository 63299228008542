import { __decorate } from "tslib";
import { Component } from "vue-property-decorator";
import Vue from "vue";
let UsersPage = class UsersPage extends Vue {
};
UsersPage = __decorate([
    Component({
        components: {},
    })
], UsersPage);
export default UsersPage;
