import { __decorate } from "tslib";
import "moment/locale/da";
import { statisticsModule } from "@/store/modules/statisticsModule";
import { Component } from "vue-property-decorator";
import BarChart from "@/components/shared/BarChart.vue";
import CustomButton, { Action } from "@/components/shared/CustomButton.vue";
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Filters from "@/components/statistics/Filters.vue";
import Summary from "@/components/statistics/Summary.vue";
import OccupancyGraphs from "@/components/statistics/OccupancyGraphs.vue";
import InfoCircle from "@/components/shared/InfoCircle.vue";
import LineChart from "@/components/shared/LineChart.vue";
import moment from "moment";
import Vue from "vue";
import { InputType } from "@/components/shared/enums";
import CustomTextField from "@/components/shared/CustomTextField.vue";
import CustomSelect from "@/components/shared/CustomSelect.vue";
import { required } from "@/components/shared/validationRules";
import { exportToPDF } from "@/util/pdfExport";
import DurationGraphs from "@/components/statistics/DurationGraphs.vue";
import ActivityGraphs from "@/components/statistics/ActivityGraphs.vue";
import TestSearch from "@/components/statistics/TestSearch.vue";
let StatisticsPage = class StatisticsPage extends Vue {
    type = InputType;
    action = Action;
    statisticsFormValid = true;
    showTestStatisticsButton = location.href.includes("localhost") || location.href.includes("test.parkadmin");
    showTestStatistics = false;
    getGraphData(fromDate, toDate) {
        const isFormValid = this.$refs.statisticsForm.validate();
        if (!isFormValid) {
            this.$refs.statisticsForm.validate();
            return;
        }
        const payload = {
            areas: statisticsModule.getAreasSelected,
            intervalBegin: fromDate,
            intervalEnd: toDate,
            parkingTypes: statisticsModule.getParkingTypesSelected,
            parkingSystems: statisticsModule.getParkingSystemsSelected,
        };
        statisticsModule.getGraphData(payload);
    }
    onExportClick() {
        const isFormValid = this.$refs.statisticsForm.validate();
        if (isFormValid) {
            if (!this.$refs.statisticsPage || !statisticsModule.getAllowedToExportPDF)
                return statisticsModule.exportToCsv();
            exportToPDF(this.$refs.statisticsPage, {
                title: `${moment(statisticsModule.getFromDate).format("DD-MM-YYYY")}-${moment(statisticsModule.getToDate).format("DD-MM-YYYY")}`,
            }).then(() => {
                statisticsModule.exportToCsv();
            });
        }
    }
    get isLoading() {
        return statisticsModule.isLoading;
    }
};
StatisticsPage = __decorate([
    Component({
        components: {
            BarChart,
            CustomButton,
            Filters,
            InfoCircle,
            LineChart,
            CustomDialog,
            CustomTextField,
            CustomSelect,
            Summary,
            DurationGraphs,
            ActivityGraphs,
            OccupancyGraphs,
            TestSearch,
        },
        methods: {
            required,
        },
    })
], StatisticsPage);
export default StatisticsPage;
