export function getApiBaseUrl(environment, host) {
    if (environment !== "production")
        return process.env.VUE_APP_BASE_URL_LOCALHOST;
    switch (host) {
        case "parkadmin.dk":
            return process.env.VUE_APP_BASE_URL_PROD;
        case "stage.parkadmin.dk":
            return process.env.VUE_APP_BASE_URL_STAGE;
        case "test.parkadmin.dk":
            return process.env.VUE_APP_BASE_URL_TEST;
        default:
            return process.env.VUE_APP_BASE_URL;
    }
}
