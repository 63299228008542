import { __decorate } from "tslib";
import { SubscriptionTypeRequest } from "@/apiclient/client";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { Component } from "vue-property-decorator";
import { InputType } from "../shared/enums";
import { required } from "../shared/validationRules";
import { statusModule } from "@/store/modules/statusModule";
import { subscriptionModule } from "@/store/modules/subscriptionModule";
import CustomButton, { Action } from "../shared/CustomButton.vue";
import CustomDialog from "../shared/CustomDialog.vue";
import CustomTextField from "../shared/CustomTextField.vue";
import DefaultEmailTemplateToggle from "@/components/settings/DefaultEmailTemplateToggle.vue";
import Vue from "vue";
let SubscriptionTypeConfigurationCard = class SubscriptionTypeConfigurationCard extends Vue {
    type = InputType;
    action = Action;
    showDialog = false;
    selectedSubscriptionType = null;
    subscriptionTypeInput = null;
    subscriptionTypeFormValid = false;
    mounted() {
        subscriptionModule.fetchSubscriptionTypes();
    }
    get headers() {
        return [
            {
                text: this.$tc("subscriptionType", 2).toString(),
                sortable: false,
                value: "subscriptionType",
            },
            {
                text: this.$t("actions").toString(),
                value: "actions",
                sortable: false,
                width: "15%",
            },
        ];
    }
    get getSubscriptionTypes() {
        return subscriptionModule.getSubscriptionTypes;
    }
    get getLoadingStatus() {
        return statusModule.getLoadingState;
    }
    get getDialogTitle() {
        if (this.selectedSubscriptionType) {
            return "update".t() + " " + "subscriptionType".tc(1);
        }
        return "createnew".t() + " " + "subscriptionType".tc(1);
    }
    createSubscriptionType() {
        this.showDialog = true;
    }
    editSubscriptionType(subscriptionType) {
        this.selectedSubscriptionType = subscriptionType;
        this.subscriptionTypeInput = this.selectedSubscriptionType.subscriptionType;
        this.showDialog = true;
    }
    deleteSubscriptionType(subscriptionType) {
        subscriptionModule.deleteSubscriptionType(subscriptionType.subscriptionTypeId ?? -1);
    }
    onCancelClick() {
        this.$refs.subscriptionTypeForm.reset();
        this.selectedSubscriptionType = null;
        this.subscriptionTypeInput = null;
        this.showDialog = false;
        this.subscriptionTypeFormValid = false;
    }
    updateSubscriptionType() {
        const isFormValid = this.$refs.subscriptionTypeForm.validate();
        if (!isFormValid) {
            return;
        }
        if (this.selectedSubscriptionType) {
            let updatePayload = {
                customerId: authenticationModule.customerId,
                subscriptionType: new SubscriptionTypeRequest({
                    subscriptionTypeId: this.selectedSubscriptionType.subscriptionTypeId,
                    subscriptionType: this.subscriptionTypeInput ?? this.selectedSubscriptionType.subscriptionType,
                }),
            };
            subscriptionModule.updateSubscriptionType(updatePayload);
        }
        else {
            if (this.subscriptionTypeInput) {
                let createPayload = {
                    customerId: authenticationModule.customerId,
                    subscriptionType: new SubscriptionTypeRequest({
                        subscriptionType: this.subscriptionTypeInput,
                    }),
                };
                subscriptionModule.createSubscriptionType(createPayload);
            }
        }
        this.selectedSubscriptionType = null;
        this.subscriptionTypeInput = null;
        this.showDialog = false;
        this.subscriptionTypeFormValid = false;
    }
};
SubscriptionTypeConfigurationCard = __decorate([
    Component({
        components: {
            DefaultEmailTemplateToggle,
            CustomButton,
            CustomDialog,
            CustomTextField,
        },
        methods: {
            required,
        },
    })
], SubscriptionTypeConfigurationCard);
export default SubscriptionTypeConfigurationCard;
