import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let CenteredElement = class CenteredElement extends Vue {
    imagePath;
    imageHeader;
    get getImgPath() {
        return require('@/assets/stateicons/noimages.png');
    }
};
__decorate([
    Prop()
], CenteredElement.prototype, "imagePath", void 0);
__decorate([
    Prop()
], CenteredElement.prototype, "imageHeader", void 0);
CenteredElement = __decorate([
    Component
], CenteredElement);
export default CenteredElement;
